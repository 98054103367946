import * as t from 'io-ts';
import { defineModels, ModelType } from 'utils/types/models';

// Helpers for re-using the definitions

const ServiceBase = {
  serviceId: t.string,
  serviceName: t.string,
};
const Service = {
  ...ServiceBase,
  chatId: t.union([t.number, t.null]),
  isDental: t.boolean,
  referralRequired: t.boolean,
  bookingEnabled: t.boolean,
};
const SpecialistMinimal = {
  specialistId: t.null,
  fullName: t.string,
};
const SpecialistBase = {
  specialistId: t.string,
  firstName: t.string,
  lastName: t.string,
  specialistTitle: t.string,
};
const ClinicBase = {
  clinicId: t.string,
  name: t.string,
};
const Clinic = {
  ...ClinicBase,
  address: t.string,
  city: t.string,
  postCode: t.string,
  checkInInstructions: t.string,
  arrivalInstructions: t.string,
};
const AppointmentPartial = {
  dateTime: t.string,
  specialist: t.union([t.type(SpecialistBase), t.type(SpecialistMinimal)]),
  duration: t.number, // minutes
  service: t.union([t.type(ServiceBase), t.null]),
};
const AppointmentBase = {
  ...AppointmentPartial,
  clinic: t.type(Clinic),
  hideClinicDetails: t.boolean,
};
const AppointmentType = t.union([t.literal('clinic'), t.literal('phone'), t.literal('video')]);
const AppointmentTypes = t.array(AppointmentType);
const Appointment = {
  ...AppointmentBase,
  appointmentId: t.string,
  appointmentTypes: AppointmentTypes,
  services: t.array(t.type(ServiceBase)),
  flags: t.union([t.array(t.literal('Emergency')), t.null]),
};
export type AppointmentTimeOfDay = 'weekday' | 'saturday' | 'sunday' | 'night';
const AppointmentTimeOfDay = t.union([
  t.literal('weekday'),
  t.literal('saturday'),
  t.literal('sunday'),
  t.literal('night'),
]);
const CallRequestBase = {
  dateTime: t.string,
  slotLength: t.number, // minutes
  duration: t.number, // minutes
};
const CallRequestBookingBase = {
  ...CallRequestBase,
  bookingId: t.string,
  delegatePatientName: t.union([t.string, t.undefined]),
};
const RespiratorySymptoms = t.union([t.literal('symptoms'), t.literal('no-symptoms'), t.undefined]);
const User = {
  customerId: t.string,
  firstName: t.string,
  lastName: t.string,
  occupational: t.boolean,
  age: t.union([t.number, t.null]),
  email: t.union([t.string, t.null]),
  phone: t.union([t.string, t.null]),
};
export type CustomerType = 'private' | 'occupational' | 'insurance' | 'municipality';
const CustomerType = t.union([
  t.literal('private'),
  t.literal('occupational'),
  t.literal('insurance'),
  t.literal('municipality'),
  t.null,
]);

// Occupational data helpers
const OccupationalRestrictions = {
  specialistIds: t.union([t.array(t.string), t.null]),
  clinics: t.union([
    t.array(
      t.type({
        id: t.string,
        home: t.boolean,
      }),
    ),
    t.null,
  ]),
};
const OccupationalData = {
  employerCode: t.string,
  companyName: t.string,
  mainClinicCode: t.union([t.string, t.null]),
  doctorCodes: t.array(t.string),
  nurseCodes: t.array(t.string),
  physiotherapistCodes: t.array(t.string),
  psychologistCodes: t.array(t.string),
  generalDoctorCodes: t.array(t.string),
  nutritionistCodes: t.array(t.string),
  socialWorkerCodes: t.array(t.string),
  webReservationDenied: t.boolean,
  redirectionFormId: t.number,
  personalizedBooking: t.boolean,
  selfTriageVisible: t.boolean,
  selfTriageSkips: t.type({
    services: t.array(t.string),
    specialists: t.array(t.string),
  }),
};
const BooleanWithUndefined = t.union([t.boolean, t.undefined]);
const OccupationalContractService = {
  serviceId: t.string,
  included: BooleanWithUndefined,
  paymentCommitmentRequired: BooleanWithUndefined,
  directAllowed: BooleanWithUndefined,
  acceptedSpecialistReferrals: t.array(
    t.union([
      t.literal('GeneralPractitioner'),
      t.literal('OccupationalHealthPhysician'),
      t.literal('NamedOccupationalHealthPhysician'),
      t.literal('OccupationalHealthNurse'),
      t.literal('OccupationalPhysicalTherapist'),
      t.literal('CoordinatingOccupationalPhysician'),
      t.literal('KeyAccountPhysician'),
      t.literal('NamedOccupationalHealthNurse'),
      t.literal('NamedOccupationalPhysicalTherapist'),
    ]),
  ),
  acceptedPermissions: t.array(
    t.union([
      t.literal('KeyAccountPhysician'),
      t.literal('CoordinatingOccupationalPhysician'),
      t.literal('NamedOccupationalHealthPhysician'),
      t.literal('Employer'),
    ]),
  ),
};
const OccupationalContract = {
  services: t.array(t.type(OccupationalContractService)),
  officeHoursOnly: t.union([t.boolean, t.null]),
};
type RedirectionFormResult = {
  kind: 'result';
  resultId: number;
  text: string | null;
  defaultText: string | null;
  appText: string | null;
  defaultAppText: string | null;
  disabledText: string | null;
  disabled: boolean;
  defaultDisabledText: string | null;
  titleReplacement: string | null;
  button: {
    type: 'link' | 'chat' | 'booking';
    text: string | null;
    defaultText: string;
    url: string | null;
    defaultUrl: string | null;
    chatId: number | null;
    params: string | null;
  } | null;
};
const RedirectionFormButton = {
  type: t.union([t.literal('link'), t.literal('chat'), t.literal('booking')]),
  text: t.union([t.string, t.null]),
  defaultText: t.string,
  url: t.union([t.string, t.null]),
  defaultUrl: t.union([t.string, t.null]),
  chatId: t.union([t.number, t.null]),
  params: t.union([t.string, t.null]),
};
const RedirectionFormResultObj = {
  kind: t.literal('result'),
  resultId: t.number,
  text: t.union([t.string, t.null]),
  defaultText: t.union([t.string, t.null]),
  appText: t.union([t.string, t.null]),
  defaultAppText: t.union([t.string, t.null]),
  disabledText: t.union([t.string, t.null]),
  disabled: t.boolean,
  defaultDisabledText: t.union([t.string, t.null]),
  titleReplacement: t.union([t.string, t.null]),
  button: t.union([t.type(RedirectionFormButton), t.null]),
};
export type RedirectionFormQuestion = {
  kind: 'question';
  questionId: number;
  text: string | null;
  defaultText: string;
  disabled: boolean;
  bookingParams: string | null;
  children: Array<RedirectionFormQuestion | RedirectionFormResult>;
};
const RedirectionFormQuestionObj = t.recursion<RedirectionFormQuestion>('RedirectionFormQuestion', self =>
  t.type({
    kind: t.literal('question'),
    questionId: t.number,
    text: t.union([t.string, t.null]),
    defaultText: t.string,
    disabled: t.boolean,
    bookingParams: t.union([t.string, t.null]),
    children: t.array(t.union([self, t.type(RedirectionFormResultObj)])),
  }),
);
const RedirectionForm = {
  formId: t.number,
  name: t.union([t.string, t.null]),
  defaultName: t.string,
  text: t.union([t.string, t.null]),
  customerServiceNumber: t.union([t.string, t.null]),
  defaultText: t.string,
  disabled: t.boolean,
  deleted: t.boolean,
  children: t.array(t.union([RedirectionFormQuestionObj, t.type(RedirectionFormResultObj)])),
  coronaRestrictions: t.union([t.array(t.string), t.null]),
  influenzaVaccineParams: t.union([t.partial({ serviceId: t.string, specialistId: t.string }), t.null]),
  skips: t.type({
    services: t.array(t.string),
    specialists: t.array(t.string),
  }),
};
// Helpers for popularServices
export type PopularServicesMainTypes = 'primary' | 'occupational' | 'dental' | 'child';
const PopularServicesImaging = {
  mainServiceId: t.string,
  referralCode: t.string,
  subServiceIds: t.array(t.string),
};

export type FokusServiceCategories =
  | 'fysio'
  | 'tule'
  | 'urology'
  | 'gynaecology'
  | 'mieli-link-banner'
  | 'laboratory'
  | 'migrane';
const PopularServicesFokus = {
  mainServiceId: t.string,
  serviceCategory: t.union([
    t.literal('fysio'),
    t.literal('urology'),
    t.literal('gynaecology'),
    t.literal('tule'),
    t.literal('mieli-link-banner'),
    t.literal('laboratory'),
    t.literal('migrane'),
  ]),
  serviceIds: t.array(t.string),
};
const PopularServicesAlternative = {
  mainServiceId: t.string,
  searchType: t.union([t.literal('sequential'), t.literal('simultaneous'), t.literal('simultaneousOccupational')]),
  serviceIds: t.array(t.string),
};

// Helpers for price estimate
const PriceEstimate = {
  estimatedPrice: t.number,
  code: t.union([t.string, t.null]),
  deductiblePrice: t.union([t.number, t.null]),
  fixedPrice: t.boolean,
  name: t.string,
  info: t.string,
};
const ValidateGiftCardResponse = {
  status: t.union([
    t.literal('ok'),
    t.literal('notFound'),
    t.literal('expired'),
    t.literal('notForGivenService'),
    t.literal('alreadyUsed'),
    t.literal('notProductGiftcard'),
    t.literal('notValidForGivenUser'),
    t.literal('notValid'),
    t.literal('throttling'),
    t.undefined,
  ]),
  code: t.union([t.string, t.undefined]),
  validServiceId: t.union([t.string, t.undefined]),
};
export type GiftCardValidationResult =
  | 'ok'
  | 'notFound'
  | 'expired'
  | 'notForGivenService'
  | 'alreadyUsed'
  | 'notProductGiftcard'
  | 'notValidForGivenUser'
  | 'notValid'
  | 'throttling';

// This list also defines the preferred order of the types in the UI
export type AppointmentType = t.TypeOf<typeof AppointmentType>;
export const regularAppointmentTypes: AppointmentType[] = ['clinic', 'video', 'phone'];

export type ApiErrorStatus = 'noPolicy' | 'userNotFound';

// Define a category of Models for API data
export const ApiModel = defineModels({
  // Represents a service provided to patients, e.g. "Cataract diagnosis and treatment"
  ServiceBase,
  Service,
  // Represents a service with additional information
  ServiceExtended: {
    ...Service,
    serviceDescription: t.string,
    serviceBookingInstruction: t.string,
    preparationInfo: t.string,
    priceDisclaimer: t.union([t.string, t.null]),
  },
  // Represents a competence that's available, e.g. "General Hospital Psychiatry"
  Competence: {
    competenceId: t.string,
    competenceName: t.string,
  },
  // Specialist data available with appointments
  SpecialistBase,
  // Represents a specialist that's available, e.g. "Yleislääkäri"
  Specialist: {
    ...SpecialistBase,
    isDental: t.boolean,
    imageUri: t.union([t.string, t.null]),
  },
  // Represents a specialist that does not have complete info yet, but already can have appointments
  SpecialistMinimal,
  // Represents a specialist with additional information
  SpecialistExtended: {
    ...SpecialistBase,
    imageUri: t.union([t.string, t.null]),
    languages: t.array(
      t.type({
        code: t.string,
        native: t.boolean,
      }),
    ),
    clinicIds: t.array(t.string),
    serviceIds: t.array(t.string),
    competenceIds: t.array(t.string),
    presentation: t.string,
    shortDescription: t.string,
    bookingInstruction: t.string,
    preparationInstruction: t.string,
    isDental: t.boolean,
  },
  // Represents visited specialists data
  VisitedSpecialist: {
    specialist: t.type(SpecialistBase),
    lastVisitDate: t.string,
    lastVisitCustomerType: CustomerType,
  },
  // Represents a customer of Terveystalo, e.g. "Matti Meikäläinen"
  User,
  // Represents data for a user that has not yet registered
  NotRegisteredUser: {
    ssn: t.string,
    firstName: t.string,
    lastName: t.string,
  },
  // Referral check response
  ReferralStatus: {
    referralCode: t.string,
    hasReferral: t.boolean,
  },
  // Represents a clinic, e.g. "Terveystalo Kamppi"
  Clinic,
  // Represents a clinic with additional information
  ClinicExtended: {
    ...Clinic,
    phoneReservation: t.string,
    fax: t.string,
    entryInstructions: t.string,
    latitude: t.union([t.number, t.null]),
    longitude: t.union([t.number, t.null]),
    isDental: t.boolean,
  },
  // Represents an available appointment search result
  Appointment,
  AppointmentsResult: {
    occupationalRestrictions: t.union([t.type(OccupationalRestrictions), t.null]),
    availableAppointments: t.array(t.type(Appointment)),
  },
  AppointmentAvailable: {
    isAvailable: t.boolean,
  },
  // Represents a set of alternative appointments from a certain clinic
  AlternativeAppointments: {
    appointments: t.array(
      t.type({
        ...AppointmentPartial,
        appointmentType: t.literal('clinic'),
        appointmentId: t.string,
      }),
    ),
    clinic: t.type(ClinicBase),
  },
  // Represents a day and the number of appointments available
  AppointmentDay: {
    date: t.string,
    appointmentCount: t.number,
  },
  // Represents an appointment for which the booking process has been started
  TemporaryBooking: {
    ...AppointmentBase,
    bookingId: t.string,
    appointmentType: AppointmentType,
    expiresIn: t.number, // seconds
    delegatePatientName: t.union([t.string, t.undefined]),
    principalCustomerId: t.union([t.string, t.undefined]),
    respiratorySymptoms: RespiratorySymptoms,
  },
  // Represents a booked appointment
  Booking: {
    ...AppointmentBase,
    bookingId: t.string,
    bookingCode: t.string,
    appointmentType: AppointmentType,
    customerType: CustomerType,
    optionalAppointmentTypes: AppointmentTypes,
    delegatePatientName: t.union([t.string, t.undefined]),
    respiratorySymptoms: RespiratorySymptoms,
    workTimeType: t.union([t.string, t.undefined]),
    visibleForGuardian: t.union([t.boolean, t.undefined]),
  },
  CallRequestBase,
  CallRequestSlot: {
    ...CallRequestBase,
    slotId: t.string,
    specialistId: t.string,
  },
  CallRequestBookingBase,
  CallRequestTemporaryBooking: {
    ...CallRequestBookingBase,
    specialistId: t.string,
    expiresIn: t.number, // seconds
    principalCustomerId: t.union([t.string, t.undefined]),
  },
  CallRequestBooking: {
    ...CallRequestBookingBase,
    specialistId: t.string,
  },
  CallRequestOwnBooking: {
    ...CallRequestBookingBase,
    bookingCode: t.string,
    specialist: t.type(SpecialistBase),
    customerType: CustomerType,
  },
  // Represents a trimmed version of a clinic result
  ClinicBase,
  // Represents an area of Finland and the clinics there
  Area: {
    areaId: t.string,
    name: t.string,
    clinicIds: t.array(t.string),
  },
  // Represents a city in Finland and the clinics there
  City: {
    cityId: t.string,
    name: t.string,
    clinicIds: t.array(t.string),
  },
  // Represents Api Health check response
  ApiHealth: {
    system: t.union([t.literal('Ok'), t.literal('Error'), t.literal('Maintenance')]),
    dental: t.union([t.literal('Ok'), t.literal('Error'), t.literal('Maintenance'), t.null]),
  },
  // Represents a response from the geocoding search api
  GeoLocation: {
    name: t.string,
    latitude: t.number,
    longitude: t.number,
  },
  // Represents chat queue details
  ChatDetails: {
    chatId: t.number,
    chatName: t.string,
    chatDescription: t.string,
    queueOpen: t.boolean,
    queueLength: t.number,
  },
  // Represents a user's occupational data, e.g. own clinic and assigned doctors
  // New occupational data provided by ux api
  RedirectionForm,
  RedirectionFormResult: RedirectionFormResultObj,
  RedirectionFormButton,
  OccupationalData: {
    ...OccupationalData,
    redirectionForm: t.union([t.type(RedirectionForm), t.undefined]),
  },
  OccupationalContract,
  OccupationalContractService,
  // Occupational restrictions for booking certain services, depends on selected service/specialist
  OccupationalRestrictions,
  // Popular and hidden services (id) listings
  PopularServicesImaging,
  PopularServicesFokus,
  PopularServicesAlternative,
  PopularServices: {
    primary: t.array(t.string),
    occupational: t.array(t.string),
    dental: t.array(t.string),
    child: t.array(t.string),
    sort: t.array(t.string),
    imaging: t.array(t.type(PopularServicesImaging)),
    hidden: t.array(t.string),
    fokus: t.array(t.type(PopularServicesFokus)),
    rela: t.array(t.string),
    rehab: t.array(t.string),
    alternative: t.array(t.type(PopularServicesAlternative)),
  },
  // For booking for child / other person info needed for suomi.fi
  DelegateInfo: {
    url: t.string,
    registration: t.string,
  },
  DelegateTokenData: {
    token: t.string,
    notRegistered: t.boolean,
    name: t.string,
    age: t.union([t.number, t.undefined]),
  },
  DelegateUser: {
    ...User,
    onBehalfConnection: t.boolean,
  },

  // Represents a list of specialist's prices for services
  SpecialistPriceItem: {
    itemName: t.string,
    price: t.number,
    deductiblePrice: t.union([t.number, t.null]),
    priceType: t.union([t.literal('Special'), t.literal('General'), t.literal('Service')]),
    serviceId: t.union([t.string, t.null]),
    priceCodeMap: t.union([
      t.type({
        durationInMinutes: t.union([t.number, t.undefined]),
        specialistType: t.union([
          t.union([t.literal('Special'), t.literal('General'), t.literal('Other')]),
          t.undefined,
        ]),
        timeOfDay: t.union([AppointmentTimeOfDay, t.undefined]),
        appointmentType: t.union([AppointmentType, t.undefined]),
      }),
      t.undefined,
    ]),
  },
  // Represents an estimate price for an appointment
  AppointmentPriceEstimate: {
    price: t.union([t.type(PriceEstimate), t.undefined]),
    isFixedPriceService: t.boolean, // e.g. Certificate for a driver’s license is fixed price service
  },
  ValidateGiftCardResponse,
  DirectCompensationPermit: {
    id: t.string,
    validFrom: t.union([t.string, t.null]),
    validTo: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),
    errorStatus: t.union([t.literal('noPolicy'), t.literal('userNotFound'), t.undefined]),
  },
  DirectCompensationChat: {
    status: t.union([t.literal('Ok'), t.literal('Error')]),
  },
});

// Export the compile-time types with a separate name, so both them and the run-time types can be imported at the same time, where needed
export type ApiModelType = ModelType<typeof ApiModel>;
