import { parse, stringify } from 'query-string';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'utils/react/ui-context';

/**
 * Provides a hook for handling a modal open state via history (url query string)
 * modalName needs to be unique and do not use the string 'Open' in the modalName
 */
export const useModalOpenState = (modalName: string) => {
  const paramName = `${modalName}Open`; // The 'Open' in the name is used in mobileapp-helpers to determine if any modal is open
  const history = useHistory();
  const location = useLocation();
  const { [paramName]: openParam, ...otherSearch } = parse(location.search);
  const modalOpen = !!openParam;
  const [initialOpen] = useState(modalOpen);

  const setModalOpen = (toOpen: boolean, notBack?: boolean) => {
    // If trying to open when already open or close when closed, do nothing
    if (toOpen === modalOpen) return;
    // Use browser history back when closing the modal unless forward navigation was requested
    if (!toOpen && !initialOpen && !notBack) {
      history.goBack();
    } else {
      history.push({
        pathname: location.pathname,
        search: stringify({ ...otherSearch, [paramName]: toOpen || undefined }),
      });
    }
  };

  return [modalOpen, setModalOpen] as const;
};
