import { parse } from 'query-string';
import { InsuranceCompanies } from 'utils/partnerAndInsurance/types';
import { useTranslations } from 'utils/react/ui-context';
import { BookingRoot, isInsurancePartnerRoot, isPartnerRoot } from 'utils/routes/routes';

/* 
  Helper to get insurance company id from url parameter
*/
export const getIcIDFromParam = (locationSearch: string) => {
  const { icid } = parse(locationSearch);
  if (icid && typeof icid === 'string') return icid;
  return undefined;
};

/* 
  Helper to get insurance company data fetch user agreement from url parameter
*/
export const getDataFetchAgreedFromParam = (locationSearch: string) => {
  const { icFetchAgreed } = parse(locationSearch);
  if (icFetchAgreed && typeof icFetchAgreed === 'string' && icFetchAgreed === 'true') return true;
  return false;
};

/* 
  Helper to map insurance company name from id number
*/
export const getInsuranceCompanyFromIdNumber = (id: string): InsuranceCompanies | undefined => {
  switch (id) {
    case '1':
      return 'ADIS Vahingonkäsittely';
    case '2':
      return 'Aktia';
    case '3':
      return 'Fennia';
    case '4':
      return 'If';
    case '5':
      return 'LähiTapiolaIndividual';
    case '6':
      return 'Mandatum';
    case '7':
      return 'Mela';
    case '8':
      return 'Pohjola Vakuutus';
    case '9':
      return 'Pohjantähti';
    case '10':
      return 'POP Vakuutus';
    case '11':
      return 'Protector';
    case '12':
      return 'Pv-Vahingonkäsittely';
    case '13':
      return 'Säästöpankki';
    case '14':
      return 'Turva';
    case '15':
      return 'Valtiokonttori';
    case '16':
      return 'OtherInsuranceCompany';
    case '17':
      return 'HealthInsuranceFund';
    case '18':
      return 'LähiTapiolaCompany';
    case '19':
      return 'LähiTapiolaPrivate';
    case '20':
      return 'HealthInsuranceFund';
    case '1995':
      return 'LähiTapiolaTest';
    default:
      return undefined;
  }
};

/* 
  Helper to map insurance company id number from insurance company name
*/
export const getInsuranceCompanyIdFromInsuranceCompany = (
  company: InsuranceCompanies | undefined,
): string | undefined => {
  switch (company) {
    case 'ADIS Vahingonkäsittely':
      return '1';
    case 'Aktia':
      return '2';
    case 'Fennia':
      return '3';
    case 'If':
      return '4';
    case 'LähiTapiolaIndividual':
      return '5';
    case 'Mandatum':
      return '6';
    case 'Mela':
      return '7';
    case 'Pohjola Vakuutus':
      return '8';
    case 'Pohjantähti':
      return '9';
    case 'POP Vakuutus':
      return '10';
    case 'Protector':
      return '11';
    case 'Pv-Vahingonkäsittely':
      return '12';
    case 'Säästöpankki':
      return '13';
    case 'Turva':
      return '14';
    case 'Valtiokonttori':
      return '15';
    case 'OtherInsuranceCompany':
      return '16';
    case 'LähiTapiolaCompany':
      return '18';
    case 'LähiTapiolaPrivate':
      return '19';
    case 'HealthInsuranceFund':
      return '20';
    case 'LähiTapiolaTest':
      return '1995';
    default:
      return undefined;
  }
};

/* 
  Returns translated value for some insurane company selection options, otherwise just passes the value as is.
*/
export const useTranslationForInsuranceSelection = (selectedValue?: InsuranceCompanies) => {
  const t = useTranslations();
  if (selectedValue === 'OtherInsuranceCompany') return t.something_else;
  if (selectedValue === 'HealthInsuranceFund') return t.health_insurance_fund;
  if (selectedValue === 'LähiTapiolaIndividual') return t.lahitapiola_private_insurance_customer;
  if (selectedValue === 'LähiTapiolaCompany') return t.lahitapiola_company_customer;
  if (selectedValue === 'LähiTapiolaPrivate') return t.lahitapiola_private_customer;
  return selectedValue; // Note: can return undefined
};

/* 
  Returns insurance customer option as finnish text from data type value for DH and persons manually operating data on DH.
*/
export const useInsuranceCompanyForDH = (companyName: InsuranceCompanies | undefined) => {
  if (companyName === 'LähiTapiolaIndividual') return 'LähiTapiola yksityishenkilön vakuutus';
  if (companyName === 'LähiTapiolaCompany') return 'LähiTapiola yrityksen vakuutus';
  if (companyName === 'LähiTapiolaPrivate') return 'LähiTapiola yksityisasiakas';
  if (companyName === 'OtherInsuranceCompany') return 'Muu vakuutusyhtiö';
  if (companyName === 'HealthInsuranceFund') return 'Sairauskassa tai vakuutuskassa';
  return companyName; // Note: can return undefined
};

/* 
  Returns insurance company name for backend, frontend might have multiple subnames for one insurance company
*/
export const useInsuranceCompanyForBackend = (companyName: InsuranceCompanies | undefined) => {
  if (companyName === 'LähiTapiolaIndividual') return 'LähiTapiola';
  if (companyName === 'LähiTapiolaCompany') return 'LähiTapiola';
  if (companyName === 'LähiTapiolaPrivate') return 'LähiTapiola';
  if (companyName === 'LähiTapiolaTest') return 'LähiTapiola';
  return companyName; // Note: can return undefined
};

/* 
  Returns list of insurance companies that are part of the direct compensation
*/
export const insuranceCompanyRedirectionList: InsuranceCompanies[] = ['LähiTapiolaTest', 'LähiTapiolaIndividual'];

/* 
  If current booking root is a partner booking root, returns that as a string
*/
export const getPartnerCompany = (bookingRoot: BookingRoot) => {
  // Needs to return undefined if current bookingRoot is not Partner booking root
  if (!isPartnerRoot(bookingRoot)) return undefined;
  return bookingRoot as string; // TODO Return value could be defined better
};

/* 
  Returns partner type insurance or other for partner booking roots, undefined for other booking roots.
*/
export type partnerType = 'insurance' | 'other';
export const getPartnerType = (bookingRoot: BookingRoot) => {
  // Needs to return undefined if current bookingRoot is not Partner booking root
  if (!isPartnerRoot(bookingRoot)) return undefined;

  // For insurancePartners Terveysmestari, Terveyshelppi and Fenniahoitaja partnerType is insurance, for other like Migri it is other
  return isInsurancePartnerRoot(bookingRoot) ? 'insurance' : 'other';
};
