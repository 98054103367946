/* eslint-disable */

/// <reference path="./custom.d.ts" />
/**
 * LocationIQ
 * LocationIQ provides flexible enterprise-grade location based solutions. We work with developers, startups and enterprises worldwide serving billions of requests everyday. This page provides an overview of the technical aspects of our API and will help you get started.
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  house_number?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  road?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  residential?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  borough?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  neighbourhood?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  quarter?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  hamlet?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  suburb?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  island?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  village?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  town?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city_district?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  county?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state_district?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country_code?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state_code?: string;
}
/**
 *
 * @export
 * @interface Balance
 */
export interface Balance {
  /**
   *
   * @type {string}
   * @memberof Balance
   */
  status?: string;
  /**
   *
   * @type {Daybalance}
   * @memberof Balance
   */
  balance?: Daybalance;
}
/**
 *
 * @export
 * @interface Daybalance
 */
export interface Daybalance {
  /**
   *
   * @type {number}
   * @memberof Daybalance
   */
  day?: number;
  /**
   *
   * @type {number}
   * @memberof Daybalance
   */
  bonus?: number;
}
/**
 *
 * @export
 * @interface DirectionsDirections
 */
export interface DirectionsDirections {
  /**
   *
   * @type {string}
   * @memberof DirectionsDirections
   */
  code?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof DirectionsDirections
   */
  waypoints?: Array<object>;
  /**
   *
   * @type {Array<DirectionsdirectionsRoutes>}
   * @memberof DirectionsDirections
   */
  routes?: Array<DirectionsdirectionsRoutes>;
}
/**
 *
 * @export
 * @interface DirectionsMatching
 */
export interface DirectionsMatching {
  /**
   *
   * @type {string}
   * @memberof DirectionsMatching
   */
  code?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof DirectionsMatching
   */
  tracepoints?: Array<object>;
  /**
   *
   * @type {Array<object>}
   * @memberof DirectionsMatching
   */
  matchings?: Array<object>;
}
/**
 *
 * @export
 * @interface DirectionsMatrix
 */
export interface DirectionsMatrix {
  /**
   *
   * @type {string}
   * @memberof DirectionsMatrix
   */
  code?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof DirectionsMatrix
   */
  distances?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof DirectionsMatrix
   */
  fallback_speed_cells?: Array<number>;
  /**
   *
   * @type {Array<DirectionsmatrixSources>}
   * @memberof DirectionsMatrix
   */
  sources?: Array<DirectionsmatrixSources>;
  /**
   *
   * @type {Array<DirectionsmatrixSources>}
   * @memberof DirectionsMatrix
   */
  destinations?: Array<DirectionsmatrixSources>;
}
/**
 *
 * @export
 * @interface DirectionsNearest
 */
export interface DirectionsNearest {
  /**
   *
   * @type {string}
   * @memberof DirectionsNearest
   */
  code?: string;
  /**
   *
   * @type {Array<DirectionsnearestWaypoints>}
   * @memberof DirectionsNearest
   */
  waypoints?: Array<DirectionsnearestWaypoints>;
}
/**
 *
 * @export
 * @interface DirectionsdirectionsRoutes
 */
export interface DirectionsdirectionsRoutes {
  /**
   *
   * @type {Array<object>}
   * @memberof DirectionsdirectionsRoutes
   */
  legs?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof DirectionsdirectionsRoutes
   */
  weight_name?: string;
  /**
   *
   * @type {string}
   * @memberof DirectionsdirectionsRoutes
   */
  geometry?: string;
  /**
   *
   * @type {number}
   * @memberof DirectionsdirectionsRoutes
   */
  weight?: number;
  /**
   *
   * @type {number}
   * @memberof DirectionsdirectionsRoutes
   */
  distance?: number;
  /**
   *
   * @type {number}
   * @memberof DirectionsdirectionsRoutes
   */
  duration?: number;
}
/**
 *
 * @export
 * @interface DirectionsmatrixSources
 */
export interface DirectionsmatrixSources {
  /**
   *
   * @type {number}
   * @memberof DirectionsmatrixSources
   */
  distance?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof DirectionsmatrixSources
   */
  location?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof DirectionsmatrixSources
   */
  name?: string;
}
/**
 *
 * @export
 * @interface DirectionsnearestWaypoints
 */
export interface DirectionsnearestWaypoints {
  /**
   *
   * @type {Array<number>}
   * @memberof DirectionsnearestWaypoints
   */
  nodes?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof DirectionsnearestWaypoints
   */
  distance?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof DirectionsnearestWaypoints
   */
  location?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof DirectionsnearestWaypoints
   */
  name?: string;
}
/**
 *
 * @export
 * @interface Error
 */
export interface Error {
  /**
   *
   * @type {string}
   * @memberof Error
   */
  error?: string;
}
/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {number}
   * @memberof Location
   */
  distance?: number;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  place_id?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  licence?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  osm_type?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  osm_id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Location
   */
  boundingbox?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  lat?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  lon?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  display_name?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  _class?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof Location
   */
  importance?: number;
  /**
   *
   * @type {Address}
   * @memberof Location
   */
  address?: Address;
  /**
   *
   * @type {Namedetails}
   * @memberof Location
   */
  namedetails?: Namedetails;
  /**
   *
   * @type {Matchquality}
   * @memberof Location
   */
  matchquality?: Matchquality;
}
/**
 *
 * @export
 * @interface Matchquality
 */
export interface Matchquality {
  /**
   *
   * @type {string}
   * @memberof Matchquality
   */
  matchcode?: string;
  /**
   *
   * @type {string}
   * @memberof Matchquality
   */
  matchtype?: string;
  /**
   *
   * @type {string}
   * @memberof Matchquality
   */
  matchlevel?: string;
}
/**
 *
 * @export
 * @interface Namedetails
 */
export interface Namedetails {
  /**
   *
   * @type {string}
   * @memberof Namedetails
   */
  name?: string;
}

/**
 * AutocompleteApi - axios parameter creator
 * @export
 */
export const AutocompleteApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * The Autocomplete API is a variant of the Search API that returns place predictions in response to an HTTP request.  The request specifies a textual search string and optional geographic bounds.  The service can be used to provide autocomplete functionality for text-based geographic searches, by returning places such as businesses, addresses and points of interest as a user types. The Autocomplete API can match on full words as well as substrings. Applications can therefore send queries as the user types, to provide on-the-fly place predictions.
     * @param {string} q Address to geocode
     * @param {1} normalizecity For responses with no city value in the address section, the next available element in this order - city_district, locality, town, borough, municipality, village, hamlet, quarter, neighbourhood - from the address section will be normalized to city. Defaults to 1 for SDKs.
     * @param {number} [limit] Limit the number of returned results. Default is 10.
     * @param {string} [viewbox] The preferred area to find search results.  To restrict results to those within the viewbox, use along with the bounded option. Tuple of 4 floats. Any two corner points of the box - &#x60;max_lon,max_lat,min_lon,min_lat&#x60; or &#x60;min_lon,min_lat,max_lon,max_lat&#x60; - are accepted in any order as long as they span a real box.
     * @param {0 | 1} [bounded] Restrict the results to only items contained with the viewbox
     * @param {string} [countrycodes] Limit search to a list of countries.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {string} [tag] Restricts the autocomplete search results to elements of specific OSM class and type.  Example - To restrict results to only class place and type city: tag&#x3D;place:city, To restrict the results to all of OSM class place: tag&#x3D;place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autocomplete(
      q: string,
      normalizecity: 1,
      limit?: number,
      viewbox?: string,
      bounded?: 0 | 1,
      countrycodes?: string,
      accept_language?: string,
      tag?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'q' is not null or undefined
      if (q === null || q === undefined) {
        throw new RequiredError('q', 'Required parameter q was null or undefined when calling autocomplete.');
      }
      // verify required parameter 'normalizecity' is not null or undefined
      if (normalizecity === null || normalizecity === undefined) {
        throw new RequiredError(
          'normalizecity',
          'Required parameter normalizecity was null or undefined when calling autocomplete.',
        );
      }
      const localVarPath = `/autocomplete.php`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('key') : configuration.apiKey;
        localVarQueryParameter['key'] = localVarApiKeyValue;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (viewbox !== undefined) {
        localVarQueryParameter['viewbox'] = viewbox;
      }

      if (bounded !== undefined) {
        localVarQueryParameter['bounded'] = bounded;
      }

      if (countrycodes !== undefined) {
        localVarQueryParameter['countrycodes'] = countrycodes;
      }

      if (normalizecity !== undefined) {
        localVarQueryParameter['normalizecity'] = normalizecity;
      }

      if (accept_language !== undefined) {
        localVarQueryParameter['accept-language'] = accept_language;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AutocompleteApi - functional programming interface
 * @export
 */
export const AutocompleteApiFp = function(configuration?: Configuration) {
  return {
    /**
     * The Autocomplete API is a variant of the Search API that returns place predictions in response to an HTTP request.  The request specifies a textual search string and optional geographic bounds.  The service can be used to provide autocomplete functionality for text-based geographic searches, by returning places such as businesses, addresses and points of interest as a user types. The Autocomplete API can match on full words as well as substrings. Applications can therefore send queries as the user types, to provide on-the-fly place predictions.
     * @param {string} q Address to geocode
     * @param {1} normalizecity For responses with no city value in the address section, the next available element in this order - city_district, locality, town, borough, municipality, village, hamlet, quarter, neighbourhood - from the address section will be normalized to city. Defaults to 1 for SDKs.
     * @param {number} [limit] Limit the number of returned results. Default is 10.
     * @param {string} [viewbox] The preferred area to find search results.  To restrict results to those within the viewbox, use along with the bounded option. Tuple of 4 floats. Any two corner points of the box - &#x60;max_lon,max_lat,min_lon,min_lat&#x60; or &#x60;min_lon,min_lat,max_lon,max_lat&#x60; - are accepted in any order as long as they span a real box.
     * @param {0 | 1} [bounded] Restrict the results to only items contained with the viewbox
     * @param {string} [countrycodes] Limit search to a list of countries.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {string} [tag] Restricts the autocomplete search results to elements of specific OSM class and type.  Example - To restrict results to only class place and type city: tag&#x3D;place:city, To restrict the results to all of OSM class place: tag&#x3D;place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autocomplete(
      q: string,
      normalizecity: 1,
      limit?: number,
      viewbox?: string,
      bounded?: 0 | 1,
      countrycodes?: string,
      accept_language?: string,
      tag?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>> {
      const localVarAxiosArgs = AutocompleteApiAxiosParamCreator(configuration).autocomplete(
        q,
        normalizecity,
        limit,
        viewbox,
        bounded,
        countrycodes,
        accept_language,
        tag,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AutocompleteApi - factory interface
 * @export
 */
export const AutocompleteApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     * The Autocomplete API is a variant of the Search API that returns place predictions in response to an HTTP request.  The request specifies a textual search string and optional geographic bounds.  The service can be used to provide autocomplete functionality for text-based geographic searches, by returning places such as businesses, addresses and points of interest as a user types. The Autocomplete API can match on full words as well as substrings. Applications can therefore send queries as the user types, to provide on-the-fly place predictions.
     * @param {string} q Address to geocode
     * @param {1} normalizecity For responses with no city value in the address section, the next available element in this order - city_district, locality, town, borough, municipality, village, hamlet, quarter, neighbourhood - from the address section will be normalized to city. Defaults to 1 for SDKs.
     * @param {number} [limit] Limit the number of returned results. Default is 10.
     * @param {string} [viewbox] The preferred area to find search results.  To restrict results to those within the viewbox, use along with the bounded option. Tuple of 4 floats. Any two corner points of the box - &#x60;max_lon,max_lat,min_lon,min_lat&#x60; or &#x60;min_lon,min_lat,max_lon,max_lat&#x60; - are accepted in any order as long as they span a real box.
     * @param {0 | 1} [bounded] Restrict the results to only items contained with the viewbox
     * @param {string} [countrycodes] Limit search to a list of countries.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {string} [tag] Restricts the autocomplete search results to elements of specific OSM class and type.  Example - To restrict results to only class place and type city: tag&#x3D;place:city, To restrict the results to all of OSM class place: tag&#x3D;place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autocomplete(
      q: string,
      normalizecity: 1,
      limit?: number,
      viewbox?: string,
      bounded?: 0 | 1,
      countrycodes?: string,
      accept_language?: string,
      tag?: string,
      options?: any,
    ) {
      return AutocompleteApiFp(configuration).autocomplete(
        q,
        normalizecity,
        limit,
        viewbox,
        bounded,
        countrycodes,
        accept_language,
        tag,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * AutocompleteApi - object-oriented interface
 * @export
 * @class AutocompleteApi
 * @extends {BaseAPI}
 */
export class AutocompleteApi extends BaseAPI {
  /**
   * The Autocomplete API is a variant of the Search API that returns place predictions in response to an HTTP request.  The request specifies a textual search string and optional geographic bounds.  The service can be used to provide autocomplete functionality for text-based geographic searches, by returning places such as businesses, addresses and points of interest as a user types. The Autocomplete API can match on full words as well as substrings. Applications can therefore send queries as the user types, to provide on-the-fly place predictions.
   * @param {string} q Address to geocode
   * @param {1} normalizecity For responses with no city value in the address section, the next available element in this order - city_district, locality, town, borough, municipality, village, hamlet, quarter, neighbourhood - from the address section will be normalized to city. Defaults to 1 for SDKs.
   * @param {number} [limit] Limit the number of returned results. Default is 10.
   * @param {string} [viewbox] The preferred area to find search results.  To restrict results to those within the viewbox, use along with the bounded option. Tuple of 4 floats. Any two corner points of the box - &#x60;max_lon,max_lat,min_lon,min_lat&#x60; or &#x60;min_lon,min_lat,max_lon,max_lat&#x60; - are accepted in any order as long as they span a real box.
   * @param {0 | 1} [bounded] Restrict the results to only items contained with the viewbox
   * @param {string} [countrycodes] Limit search to a list of countries.
   * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
   * @param {string} [tag] Restricts the autocomplete search results to elements of specific OSM class and type.  Example - To restrict results to only class place and type city: tag&#x3D;place:city, To restrict the results to all of OSM class place: tag&#x3D;place
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AutocompleteApi
   */
  public autocomplete(
    q: string,
    normalizecity: 1,
    limit?: number,
    viewbox?: string,
    bounded?: 0 | 1,
    countrycodes?: string,
    accept_language?: string,
    tag?: string,
    options?: any,
  ) {
    return AutocompleteApiFp(this.configuration).autocomplete(
      q,
      normalizecity,
      limit,
      viewbox,
      bounded,
      countrycodes,
      accept_language,
      tag,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * The Balance API provides a count of request credits left in the user\'s account for the day. Balance is reset at midnight UTC everyday (00:00 UTC).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balance(options: any = {}): RequestArgs {
      const localVarPath = `/balance.php`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('key') : configuration.apiKey;
        localVarQueryParameter['key'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
  return {
    /**
     * The Balance API provides a count of request credits left in the user\'s account for the day. Balance is reset at midnight UTC everyday (00:00 UTC).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balance(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Balance> {
      const localVarAxiosArgs = BalanceApiAxiosParamCreator(configuration).balance(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * The Balance API provides a count of request credits left in the user\'s account for the day. Balance is reset at midnight UTC everyday (00:00 UTC).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balance(options?: any) {
      return BalanceApiFp(configuration).balance(options)(axios, basePath);
    },
  };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
  /**
   * The Balance API provides a count of request credits left in the user\'s account for the day. Balance is reset at midnight UTC everyday (00:00 UTC).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BalanceApi
   */
  public balance(options?: any) {
    return BalanceApiFp(this.configuration).balance(options)(this.axios, this.basePath);
  }
}

/**
 * DirectionsApi - axios parameter creator
 * @export
 */
export const DirectionsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Finds the fastest route between coordinates in the supplied order.
     * @summary Directions Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {number} [alternatives] Search for alternative routes. Passing a number alternatives&#x3D;n searches for up to n alternative routes. [ true, false (default), or Number ]
     * @param {string} [steps] Returned route steps for each route leg [ true, false (default) ]
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {string} [geometries] Returned route geometry format (influences overview and per step) [ polyline (default), polyline6, geojson ]
     * @param {string} [overview] Add overview geometry either full, simplified according to highest zoom level it could be display on, or not at all. [ simplified (default), full, false ]
     * @param {string} [continue_straight] Forces the route to keep going straight at waypoints constraining uturns there even if it would be faster. Default value depends on the profile [ default (default), true, false ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    directions(
      coordinates: string,
      bearings?: string,
      radiuses?: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      alternatives?: number,
      steps?: string,
      annotations?: string,
      geometries?: string,
      overview?: string,
      continue_straight?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          'coordinates',
          'Required parameter coordinates was null or undefined when calling directions.',
        );
      }
      const localVarPath = `/directions/driving/{coordinates}`.replace(
        `{${'coordinates'}}`,
        encodeURIComponent(String(coordinates)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('key') : configuration.apiKey;
        localVarQueryParameter['key'] = localVarApiKeyValue;
      }

      if (bearings !== undefined) {
        localVarQueryParameter['bearings'] = bearings;
      }

      if (radiuses !== undefined) {
        localVarQueryParameter['radiuses'] = radiuses;
      }

      if (generate_hints !== undefined) {
        localVarQueryParameter['generate_hints'] = generate_hints;
      }

      if (approaches !== undefined) {
        localVarQueryParameter['approaches'] = approaches;
      }

      if (exclude !== undefined) {
        localVarQueryParameter['exclude'] = exclude;
      }

      if (alternatives !== undefined) {
        localVarQueryParameter['alternatives'] = alternatives;
      }

      if (steps !== undefined) {
        localVarQueryParameter['steps'] = steps;
      }

      if (annotations !== undefined) {
        localVarQueryParameter['annotations'] = annotations;
      }

      if (geometries !== undefined) {
        localVarQueryParameter['geometries'] = geometries;
      }

      if (overview !== undefined) {
        localVarQueryParameter['overview'] = overview;
      }

      if (continue_straight !== undefined) {
        localVarQueryParameter['continue_straight'] = continue_straight;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DirectionsApi - functional programming interface
 * @export
 */
export const DirectionsApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Finds the fastest route between coordinates in the supplied order.
     * @summary Directions Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {number} [alternatives] Search for alternative routes. Passing a number alternatives&#x3D;n searches for up to n alternative routes. [ true, false (default), or Number ]
     * @param {string} [steps] Returned route steps for each route leg [ true, false (default) ]
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {string} [geometries] Returned route geometry format (influences overview and per step) [ polyline (default), polyline6, geojson ]
     * @param {string} [overview] Add overview geometry either full, simplified according to highest zoom level it could be display on, or not at all. [ simplified (default), full, false ]
     * @param {string} [continue_straight] Forces the route to keep going straight at waypoints constraining uturns there even if it would be faster. Default value depends on the profile [ default (default), true, false ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    directions(
      coordinates: string,
      bearings?: string,
      radiuses?: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      alternatives?: number,
      steps?: string,
      annotations?: string,
      geometries?: string,
      overview?: string,
      continue_straight?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionsDirections> {
      const localVarAxiosArgs = DirectionsApiAxiosParamCreator(configuration).directions(
        coordinates,
        bearings,
        radiuses,
        generate_hints,
        approaches,
        exclude,
        alternatives,
        steps,
        annotations,
        geometries,
        overview,
        continue_straight,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DirectionsApi - factory interface
 * @export
 */
export const DirectionsApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Finds the fastest route between coordinates in the supplied order.
     * @summary Directions Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {number} [alternatives] Search for alternative routes. Passing a number alternatives&#x3D;n searches for up to n alternative routes. [ true, false (default), or Number ]
     * @param {string} [steps] Returned route steps for each route leg [ true, false (default) ]
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {string} [geometries] Returned route geometry format (influences overview and per step) [ polyline (default), polyline6, geojson ]
     * @param {string} [overview] Add overview geometry either full, simplified according to highest zoom level it could be display on, or not at all. [ simplified (default), full, false ]
     * @param {string} [continue_straight] Forces the route to keep going straight at waypoints constraining uturns there even if it would be faster. Default value depends on the profile [ default (default), true, false ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    directions(
      coordinates: string,
      bearings?: string,
      radiuses?: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      alternatives?: number,
      steps?: string,
      annotations?: string,
      geometries?: string,
      overview?: string,
      continue_straight?: string,
      options?: any,
    ) {
      return DirectionsApiFp(configuration).directions(
        coordinates,
        bearings,
        radiuses,
        generate_hints,
        approaches,
        exclude,
        alternatives,
        steps,
        annotations,
        geometries,
        overview,
        continue_straight,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * DirectionsApi - object-oriented interface
 * @export
 * @class DirectionsApi
 * @extends {BaseAPI}
 */
export class DirectionsApi extends BaseAPI {
  /**
   * Finds the fastest route between coordinates in the supplied order.
   * @summary Directions Service
   * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
   * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
   * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
   * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
   * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
   * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
   * @param {number} [alternatives] Search for alternative routes. Passing a number alternatives&#x3D;n searches for up to n alternative routes. [ true, false (default), or Number ]
   * @param {string} [steps] Returned route steps for each route leg [ true, false (default) ]
   * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
   * @param {string} [geometries] Returned route geometry format (influences overview and per step) [ polyline (default), polyline6, geojson ]
   * @param {string} [overview] Add overview geometry either full, simplified according to highest zoom level it could be display on, or not at all. [ simplified (default), full, false ]
   * @param {string} [continue_straight] Forces the route to keep going straight at waypoints constraining uturns there even if it would be faster. Default value depends on the profile [ default (default), true, false ]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DirectionsApi
   */
  public directions(
    coordinates: string,
    bearings?: string,
    radiuses?: string,
    generate_hints?: string,
    approaches?: string,
    exclude?: string,
    alternatives?: number,
    steps?: string,
    annotations?: string,
    geometries?: string,
    overview?: string,
    continue_straight?: string,
    options?: any,
  ) {
    return DirectionsApiFp(this.configuration).directions(
      coordinates,
      bearings,
      radiuses,
      generate_hints,
      approaches,
      exclude,
      alternatives,
      steps,
      annotations,
      geometries,
      overview,
      continue_straight,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * MatchingApi - axios parameter creator
 * @export
 */
export const MatchingApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Matching API matches or snaps given GPS points to the road network in the most plausible way.  Please note the request might result multiple sub-traces.  Large jumps in the timestamps (> 60s) or improbable transitions lead to trace splits if a complete matching could not be found. The algorithm might not be able to match all points. Outliers are removed if they can not be matched successfully.
     * @summary Matching Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [steps] Returned route steps for each route leg [ true, false (default) ]
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {string} [geometries] Returned route geometry format (influences overview and per step) [ polyline (default), polyline6, geojson ]
     * @param {string} [overview] Add overview geometry either full, simplified according to highest zoom level it could be display on, or not at all. [ simplified (default), full, false ]
     * @param {string} [timestamps] Timestamps for the input locations in seconds since UNIX epoch. Timestamps need to be monotonically increasing. [ {timestamp};{timestamp}[;{timestamp} ...]  integer seconds since UNIX epoch
     * @param {string} [gaps] Allows the input track splitting based on huge timestamp gaps between points. [ split (default), ignore ]
     * @param {string} [tidy] Allows the input track modification to obtain better matching quality for noisy tracks. [ true, false (default) ]
     * @param {string} [waypoints] Treats input coordinates indicated by given indices as waypoints in returned Match object. Default is to treat all input coordinates as waypoints. [ {index};{index};{index}... ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matching(
      coordinates: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      bearings?: string,
      radiuses?: string,
      steps?: string,
      annotations?: string,
      geometries?: string,
      overview?: string,
      timestamps?: string,
      gaps?: string,
      tidy?: string,
      waypoints?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          'coordinates',
          'Required parameter coordinates was null or undefined when calling matching.',
        );
      }
      const localVarPath = `/matching/driving/{coordinates}`.replace(
        `{${'coordinates'}}`,
        encodeURIComponent(String(coordinates)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('key') : configuration.apiKey;
        localVarQueryParameter['key'] = localVarApiKeyValue;
      }

      if (generate_hints !== undefined) {
        localVarQueryParameter['generate_hints'] = generate_hints;
      }

      if (approaches !== undefined) {
        localVarQueryParameter['approaches'] = approaches;
      }

      if (exclude !== undefined) {
        localVarQueryParameter['exclude'] = exclude;
      }

      if (bearings !== undefined) {
        localVarQueryParameter['bearings'] = bearings;
      }

      if (radiuses !== undefined) {
        localVarQueryParameter['radiuses'] = radiuses;
      }

      if (steps !== undefined) {
        localVarQueryParameter['steps'] = steps;
      }

      if (annotations !== undefined) {
        localVarQueryParameter['annotations'] = annotations;
      }

      if (geometries !== undefined) {
        localVarQueryParameter['geometries'] = geometries;
      }

      if (overview !== undefined) {
        localVarQueryParameter['overview'] = overview;
      }

      if (timestamps !== undefined) {
        localVarQueryParameter['timestamps'] = timestamps;
      }

      if (gaps !== undefined) {
        localVarQueryParameter['gaps'] = gaps;
      }

      if (tidy !== undefined) {
        localVarQueryParameter['tidy'] = tidy;
      }

      if (waypoints !== undefined) {
        localVarQueryParameter['waypoints'] = waypoints;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MatchingApi - functional programming interface
 * @export
 */
export const MatchingApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Matching API matches or snaps given GPS points to the road network in the most plausible way.  Please note the request might result multiple sub-traces.  Large jumps in the timestamps (> 60s) or improbable transitions lead to trace splits if a complete matching could not be found. The algorithm might not be able to match all points. Outliers are removed if they can not be matched successfully.
     * @summary Matching Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [steps] Returned route steps for each route leg [ true, false (default) ]
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {string} [geometries] Returned route geometry format (influences overview and per step) [ polyline (default), polyline6, geojson ]
     * @param {string} [overview] Add overview geometry either full, simplified according to highest zoom level it could be display on, or not at all. [ simplified (default), full, false ]
     * @param {string} [timestamps] Timestamps for the input locations in seconds since UNIX epoch. Timestamps need to be monotonically increasing. [ {timestamp};{timestamp}[;{timestamp} ...]  integer seconds since UNIX epoch
     * @param {string} [gaps] Allows the input track splitting based on huge timestamp gaps between points. [ split (default), ignore ]
     * @param {string} [tidy] Allows the input track modification to obtain better matching quality for noisy tracks. [ true, false (default) ]
     * @param {string} [waypoints] Treats input coordinates indicated by given indices as waypoints in returned Match object. Default is to treat all input coordinates as waypoints. [ {index};{index};{index}... ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matching(
      coordinates: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      bearings?: string,
      radiuses?: string,
      steps?: string,
      annotations?: string,
      geometries?: string,
      overview?: string,
      timestamps?: string,
      gaps?: string,
      tidy?: string,
      waypoints?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionsMatching> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).matching(
        coordinates,
        generate_hints,
        approaches,
        exclude,
        bearings,
        radiuses,
        steps,
        annotations,
        geometries,
        overview,
        timestamps,
        gaps,
        tidy,
        waypoints,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MatchingApi - factory interface
 * @export
 */
export const MatchingApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Matching API matches or snaps given GPS points to the road network in the most plausible way.  Please note the request might result multiple sub-traces.  Large jumps in the timestamps (> 60s) or improbable transitions lead to trace splits if a complete matching could not be found. The algorithm might not be able to match all points. Outliers are removed if they can not be matched successfully.
     * @summary Matching Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [steps] Returned route steps for each route leg [ true, false (default) ]
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {string} [geometries] Returned route geometry format (influences overview and per step) [ polyline (default), polyline6, geojson ]
     * @param {string} [overview] Add overview geometry either full, simplified according to highest zoom level it could be display on, or not at all. [ simplified (default), full, false ]
     * @param {string} [timestamps] Timestamps for the input locations in seconds since UNIX epoch. Timestamps need to be monotonically increasing. [ {timestamp};{timestamp}[;{timestamp} ...]  integer seconds since UNIX epoch
     * @param {string} [gaps] Allows the input track splitting based on huge timestamp gaps between points. [ split (default), ignore ]
     * @param {string} [tidy] Allows the input track modification to obtain better matching quality for noisy tracks. [ true, false (default) ]
     * @param {string} [waypoints] Treats input coordinates indicated by given indices as waypoints in returned Match object. Default is to treat all input coordinates as waypoints. [ {index};{index};{index}... ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matching(
      coordinates: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      bearings?: string,
      radiuses?: string,
      steps?: string,
      annotations?: string,
      geometries?: string,
      overview?: string,
      timestamps?: string,
      gaps?: string,
      tidy?: string,
      waypoints?: string,
      options?: any,
    ) {
      return MatchingApiFp(configuration).matching(
        coordinates,
        generate_hints,
        approaches,
        exclude,
        bearings,
        radiuses,
        steps,
        annotations,
        geometries,
        overview,
        timestamps,
        gaps,
        tidy,
        waypoints,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * MatchingApi - object-oriented interface
 * @export
 * @class MatchingApi
 * @extends {BaseAPI}
 */
export class MatchingApi extends BaseAPI {
  /**
   * Matching API matches or snaps given GPS points to the road network in the most plausible way.  Please note the request might result multiple sub-traces.  Large jumps in the timestamps (> 60s) or improbable transitions lead to trace splits if a complete matching could not be found. The algorithm might not be able to match all points. Outliers are removed if they can not be matched successfully.
   * @summary Matching Service
   * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
   * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
   * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
   * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
   * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
   * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
   * @param {string} [steps] Returned route steps for each route leg [ true, false (default) ]
   * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
   * @param {string} [geometries] Returned route geometry format (influences overview and per step) [ polyline (default), polyline6, geojson ]
   * @param {string} [overview] Add overview geometry either full, simplified according to highest zoom level it could be display on, or not at all. [ simplified (default), full, false ]
   * @param {string} [timestamps] Timestamps for the input locations in seconds since UNIX epoch. Timestamps need to be monotonically increasing. [ {timestamp};{timestamp}[;{timestamp} ...]  integer seconds since UNIX epoch
   * @param {string} [gaps] Allows the input track splitting based on huge timestamp gaps between points. [ split (default), ignore ]
   * @param {string} [tidy] Allows the input track modification to obtain better matching quality for noisy tracks. [ true, false (default) ]
   * @param {string} [waypoints] Treats input coordinates indicated by given indices as waypoints in returned Match object. Default is to treat all input coordinates as waypoints. [ {index};{index};{index}... ]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public matching(
    coordinates: string,
    generate_hints?: string,
    approaches?: string,
    exclude?: string,
    bearings?: string,
    radiuses?: string,
    steps?: string,
    annotations?: string,
    geometries?: string,
    overview?: string,
    timestamps?: string,
    gaps?: string,
    tidy?: string,
    waypoints?: string,
    options?: any,
  ) {
    return MatchingApiFp(this.configuration).matching(
      coordinates,
      generate_hints,
      approaches,
      exclude,
      bearings,
      radiuses,
      steps,
      annotations,
      geometries,
      overview,
      timestamps,
      gaps,
      tidy,
      waypoints,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * MatrixApi - axios parameter creator
 * @export
 */
export const MatrixApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Computes duration of the fastest route between all pairs of supplied coordinates. Returns the durations or distances or both between the coordinate pairs. Note that the distances are not the shortest distance between two coordinates, but rather the distances of the fastest routes.
     * @summary Matrix Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {number} [sources] Use location with given index as source. [ {index};{index}[;{index} ...] or all (default) ] &#x3D;&gt; index  0 &lt;&#x3D; integer &lt; #locations
     * @param {number} [destinations] Use location with given index as destination. [ {index};{index}[;{index} ...] or all (default) ]
     * @param {number} [fallback_speed] If no route found between a source/destination pair, calculate the as-the-crow-flies distance,  then use this speed to estimate duration. double &gt; 0
     * @param {string} [fallback_coordinate] When using a fallback_speed, use the user-supplied coordinate (input), or the snapped location (snapped) for calculating distances. [ input (default), or snapped ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrix(
      coordinates: string,
      bearings?: string,
      radiuses?: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      annotations?: string,
      sources?: number,
      destinations?: number,
      fallback_speed?: number,
      fallback_coordinate?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          'coordinates',
          'Required parameter coordinates was null or undefined when calling matrix.',
        );
      }
      const localVarPath = `/matrix/driving/{coordinates}`.replace(
        `{${'coordinates'}}`,
        encodeURIComponent(String(coordinates)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('key') : configuration.apiKey;
        localVarQueryParameter['key'] = localVarApiKeyValue;
      }

      if (bearings !== undefined) {
        localVarQueryParameter['bearings'] = bearings;
      }

      if (radiuses !== undefined) {
        localVarQueryParameter['radiuses'] = radiuses;
      }

      if (generate_hints !== undefined) {
        localVarQueryParameter['generate_hints'] = generate_hints;
      }

      if (approaches !== undefined) {
        localVarQueryParameter['approaches'] = approaches;
      }

      if (exclude !== undefined) {
        localVarQueryParameter['exclude'] = exclude;
      }

      if (annotations !== undefined) {
        localVarQueryParameter['annotations'] = annotations;
      }

      if (sources !== undefined) {
        localVarQueryParameter['sources'] = sources;
      }

      if (destinations !== undefined) {
        localVarQueryParameter['destinations'] = destinations;
      }

      if (fallback_speed !== undefined) {
        localVarQueryParameter['fallback_speed'] = fallback_speed;
      }

      if (fallback_coordinate !== undefined) {
        localVarQueryParameter['fallback_coordinate'] = fallback_coordinate;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MatrixApi - functional programming interface
 * @export
 */
export const MatrixApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Computes duration of the fastest route between all pairs of supplied coordinates. Returns the durations or distances or both between the coordinate pairs. Note that the distances are not the shortest distance between two coordinates, but rather the distances of the fastest routes.
     * @summary Matrix Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {number} [sources] Use location with given index as source. [ {index};{index}[;{index} ...] or all (default) ] &#x3D;&gt; index  0 &lt;&#x3D; integer &lt; #locations
     * @param {number} [destinations] Use location with given index as destination. [ {index};{index}[;{index} ...] or all (default) ]
     * @param {number} [fallback_speed] If no route found between a source/destination pair, calculate the as-the-crow-flies distance,  then use this speed to estimate duration. double &gt; 0
     * @param {string} [fallback_coordinate] When using a fallback_speed, use the user-supplied coordinate (input), or the snapped location (snapped) for calculating distances. [ input (default), or snapped ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrix(
      coordinates: string,
      bearings?: string,
      radiuses?: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      annotations?: string,
      sources?: number,
      destinations?: number,
      fallback_speed?: number,
      fallback_coordinate?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionsMatrix> {
      const localVarAxiosArgs = MatrixApiAxiosParamCreator(configuration).matrix(
        coordinates,
        bearings,
        radiuses,
        generate_hints,
        approaches,
        exclude,
        annotations,
        sources,
        destinations,
        fallback_speed,
        fallback_coordinate,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MatrixApi - factory interface
 * @export
 */
export const MatrixApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Computes duration of the fastest route between all pairs of supplied coordinates. Returns the durations or distances or both between the coordinate pairs. Note that the distances are not the shortest distance between two coordinates, but rather the distances of the fastest routes.
     * @summary Matrix Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
     * @param {number} [sources] Use location with given index as source. [ {index};{index}[;{index} ...] or all (default) ] &#x3D;&gt; index  0 &lt;&#x3D; integer &lt; #locations
     * @param {number} [destinations] Use location with given index as destination. [ {index};{index}[;{index} ...] or all (default) ]
     * @param {number} [fallback_speed] If no route found between a source/destination pair, calculate the as-the-crow-flies distance,  then use this speed to estimate duration. double &gt; 0
     * @param {string} [fallback_coordinate] When using a fallback_speed, use the user-supplied coordinate (input), or the snapped location (snapped) for calculating distances. [ input (default), or snapped ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrix(
      coordinates: string,
      bearings?: string,
      radiuses?: string,
      generate_hints?: string,
      approaches?: string,
      exclude?: string,
      annotations?: string,
      sources?: number,
      destinations?: number,
      fallback_speed?: number,
      fallback_coordinate?: string,
      options?: any,
    ) {
      return MatrixApiFp(configuration).matrix(
        coordinates,
        bearings,
        radiuses,
        generate_hints,
        approaches,
        exclude,
        annotations,
        sources,
        destinations,
        fallback_speed,
        fallback_coordinate,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * MatrixApi - object-oriented interface
 * @export
 * @class MatrixApi
 * @extends {BaseAPI}
 */
export class MatrixApi extends BaseAPI {
  /**
   * Computes duration of the fastest route between all pairs of supplied coordinates. Returns the durations or distances or both between the coordinate pairs. Note that the distances are not the shortest distance between two coordinates, but rather the distances of the fastest routes.
   * @summary Matrix Service
   * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
   * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
   * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
   * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
   * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
   * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
   * @param {string} [annotations] Returns additional metadata for each coordinate along the route geometry.  [ true, false (default), nodes, distance, duration, datasources, weight, speed ]
   * @param {number} [sources] Use location with given index as source. [ {index};{index}[;{index} ...] or all (default) ] &#x3D;&gt; index  0 &lt;&#x3D; integer &lt; #locations
   * @param {number} [destinations] Use location with given index as destination. [ {index};{index}[;{index} ...] or all (default) ]
   * @param {number} [fallback_speed] If no route found between a source/destination pair, calculate the as-the-crow-flies distance,  then use this speed to estimate duration. double &gt; 0
   * @param {string} [fallback_coordinate] When using a fallback_speed, use the user-supplied coordinate (input), or the snapped location (snapped) for calculating distances. [ input (default), or snapped ]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatrixApi
   */
  public matrix(
    coordinates: string,
    bearings?: string,
    radiuses?: string,
    generate_hints?: string,
    approaches?: string,
    exclude?: string,
    annotations?: string,
    sources?: number,
    destinations?: number,
    fallback_speed?: number,
    fallback_coordinate?: string,
    options?: any,
  ) {
    return MatrixApiFp(this.configuration).matrix(
      coordinates,
      bearings,
      radiuses,
      generate_hints,
      approaches,
      exclude,
      annotations,
      sources,
      destinations,
      fallback_speed,
      fallback_coordinate,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * NearestApi - axios parameter creator
 * @export
 */
export const NearestApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Snaps a coordinate to the street network and returns the nearest n matches. Where coordinates only supports a single {longitude},{latitude} entry.
     * @summary Nearest Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {number} [number] Number of nearest segments that should be returned. [ integer &gt;&#x3D; 1 (default 1) ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nearest(
      coordinates: string,
      generate_hints?: string,
      exclude?: string,
      bearings?: string,
      radiuses?: string,
      approaches?: string,
      number?: number,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          'coordinates',
          'Required parameter coordinates was null or undefined when calling nearest.',
        );
      }
      const localVarPath = `/nearest/driving/{coordinates}`.replace(
        `{${'coordinates'}}`,
        encodeURIComponent(String(coordinates)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('key') : configuration.apiKey;
        localVarQueryParameter['key'] = localVarApiKeyValue;
      }

      if (generate_hints !== undefined) {
        localVarQueryParameter['generate_hints'] = generate_hints;
      }

      if (exclude !== undefined) {
        localVarQueryParameter['exclude'] = exclude;
      }

      if (bearings !== undefined) {
        localVarQueryParameter['bearings'] = bearings;
      }

      if (radiuses !== undefined) {
        localVarQueryParameter['radiuses'] = radiuses;
      }

      if (approaches !== undefined) {
        localVarQueryParameter['approaches'] = approaches;
      }

      if (number !== undefined) {
        localVarQueryParameter['number'] = number;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NearestApi - functional programming interface
 * @export
 */
export const NearestApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Snaps a coordinate to the street network and returns the nearest n matches. Where coordinates only supports a single {longitude},{latitude} entry.
     * @summary Nearest Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {number} [number] Number of nearest segments that should be returned. [ integer &gt;&#x3D; 1 (default 1) ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nearest(
      coordinates: string,
      generate_hints?: string,
      exclude?: string,
      bearings?: string,
      radiuses?: string,
      approaches?: string,
      number?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionsNearest> {
      const localVarAxiosArgs = NearestApiAxiosParamCreator(configuration).nearest(
        coordinates,
        generate_hints,
        exclude,
        bearings,
        radiuses,
        approaches,
        number,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * NearestApi - factory interface
 * @export
 */
export const NearestApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Snaps a coordinate to the street network and returns the nearest n matches. Where coordinates only supports a single {longitude},{latitude} entry.
     * @summary Nearest Service
     * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
     * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
     * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
     * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
     * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
     * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
     * @param {number} [number] Number of nearest segments that should be returned. [ integer &gt;&#x3D; 1 (default 1) ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nearest(
      coordinates: string,
      generate_hints?: string,
      exclude?: string,
      bearings?: string,
      radiuses?: string,
      approaches?: string,
      number?: number,
      options?: any,
    ) {
      return NearestApiFp(configuration).nearest(
        coordinates,
        generate_hints,
        exclude,
        bearings,
        radiuses,
        approaches,
        number,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * NearestApi - object-oriented interface
 * @export
 * @class NearestApi
 * @extends {BaseAPI}
 */
export class NearestApi extends BaseAPI {
  /**
   * Snaps a coordinate to the street network and returns the nearest n matches. Where coordinates only supports a single {longitude},{latitude} entry.
   * @summary Nearest Service
   * @param {string} coordinates String of format {longitude},{latitude};{longitude},{latitude}[;{longitude},{latitude} ...] or polyline({polyline}) or polyline6({polyline6}). polyline follows Google\&#39;s polyline format with precision 5
   * @param {string} [generate_hints] Adds a Hint to the response which can be used in subsequent requests, see hints parameter. Input Value - true (default), false Format - Base64 String
   * @param {string} [exclude] Additive list of classes to avoid, order does not matter. input Value - {class}[,{class}] Format - A class name determined by the profile or none.
   * @param {string} [bearings] Limits the search to segments with given bearing in degrees towards true north in clockwise direction. List of positive integer pairs separated by semi-colon and bearings array should be equal to length of coordinate array. Input Value :- {bearing};{bearing}[;{bearing} ...] Bearing follows the following format : bearing {value},{range} integer 0 .. 360,integer 0 .. 180
   * @param {string} [radiuses] Limits the search to given radius in meters Radiuses array length should be same as coordinates array, eaach value separated by semi-colon. Input Value - {radius};{radius}[;{radius} ...] Radius has following format :- double &gt;&#x3D; 0 or unlimited (default)
   * @param {string} [approaches] Keep waypoints on curb side. Input Value - {approach};{approach}[;{approach} ...] Format - curb or unrestricted (default)
   * @param {number} [number] Number of nearest segments that should be returned. [ integer &gt;&#x3D; 1 (default 1) ]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NearestApi
   */
  public nearest(
    coordinates: string,
    generate_hints?: string,
    exclude?: string,
    bearings?: string,
    radiuses?: string,
    approaches?: string,
    number?: number,
    options?: any,
  ) {
    return NearestApiFp(this.configuration).nearest(
      coordinates,
      generate_hints,
      exclude,
      bearings,
      radiuses,
      approaches,
      number,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * ReverseApi - axios parameter creator
 * @export
 */
export const ReverseApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Reverse geocoding is the process of converting a coordinate or location (latitude, longitude) to a readable address or place name. This permits the identification of nearby street addresses, places, and/or area subdivisions such as a neighborhood, county, state, or country.
     * @summary Reverse Geocoding
     * @param {number} lat Latitude of the location to generate an address for.
     * @param {number} lon Longitude of the location to generate an address for.
     * @param {'json'} format Format to geocode. Only JSON supported for SDKs
     * @param {1} normalizecity Normalizes village to city level data to city
     * @param {0 | 1} [addressdetails] Include a breakdown of the address into elements. Defaults to 1.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {0 | 1} [namedetails] Include a list of alternative names in the results. These may include language variants, references, operator and brand.
     * @param {0 | 1} [extratags] Include additional information in the result if available, e.g. wikipedia link, opening hours.
     * @param {0 | 1} [statecode] Adds state or province code when available to the statecode key inside the address element. Currently supported for addresses in the USA, Canada and Australia. Defaults to 0
     * @param {number} [showdistance] Returns the straight line distance (meters) between the input location and the result\&#39;s location. Value is set in the distance key of the response. Defaults to 0 [0,1]
     * @param {number} [postaladdress] Returns address inside the postaladdress key, that is specifically formatted for each country. Currently supported for addresses in Germany. Defaults to 0 [0,1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reverse(
      lat: number,
      lon: number,
      format: 'json',
      normalizecity: 1,
      addressdetails?: 0 | 1,
      accept_language?: string,
      namedetails?: 0 | 1,
      extratags?: 0 | 1,
      statecode?: 0 | 1,
      showdistance?: number,
      postaladdress?: number,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'lat' is not null or undefined
      if (lat === null || lat === undefined) {
        throw new RequiredError('lat', 'Required parameter lat was null or undefined when calling reverse.');
      }
      // verify required parameter 'lon' is not null or undefined
      if (lon === null || lon === undefined) {
        throw new RequiredError('lon', 'Required parameter lon was null or undefined when calling reverse.');
      }
      // verify required parameter 'format' is not null or undefined
      if (format === null || format === undefined) {
        throw new RequiredError('format', 'Required parameter format was null or undefined when calling reverse.');
      }
      // verify required parameter 'normalizecity' is not null or undefined
      if (normalizecity === null || normalizecity === undefined) {
        throw new RequiredError(
          'normalizecity',
          'Required parameter normalizecity was null or undefined when calling reverse.',
        );
      }
      const localVarPath = `/reverse.php`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('key') : configuration.apiKey;
        localVarQueryParameter['key'] = localVarApiKeyValue;
      }

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (normalizecity !== undefined) {
        localVarQueryParameter['normalizecity'] = normalizecity;
      }

      if (addressdetails !== undefined) {
        localVarQueryParameter['addressdetails'] = addressdetails;
      }

      if (accept_language !== undefined) {
        localVarQueryParameter['accept-language'] = accept_language;
      }

      if (namedetails !== undefined) {
        localVarQueryParameter['namedetails'] = namedetails;
      }

      if (extratags !== undefined) {
        localVarQueryParameter['extratags'] = extratags;
      }

      if (statecode !== undefined) {
        localVarQueryParameter['statecode'] = statecode;
      }

      if (showdistance !== undefined) {
        localVarQueryParameter['showdistance'] = showdistance;
      }

      if (postaladdress !== undefined) {
        localVarQueryParameter['postaladdress'] = postaladdress;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReverseApi - functional programming interface
 * @export
 */
export const ReverseApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Reverse geocoding is the process of converting a coordinate or location (latitude, longitude) to a readable address or place name. This permits the identification of nearby street addresses, places, and/or area subdivisions such as a neighborhood, county, state, or country.
     * @summary Reverse Geocoding
     * @param {number} lat Latitude of the location to generate an address for.
     * @param {number} lon Longitude of the location to generate an address for.
     * @param {'json'} format Format to geocode. Only JSON supported for SDKs
     * @param {1} normalizecity Normalizes village to city level data to city
     * @param {0 | 1} [addressdetails] Include a breakdown of the address into elements. Defaults to 1.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {0 | 1} [namedetails] Include a list of alternative names in the results. These may include language variants, references, operator and brand.
     * @param {0 | 1} [extratags] Include additional information in the result if available, e.g. wikipedia link, opening hours.
     * @param {0 | 1} [statecode] Adds state or province code when available to the statecode key inside the address element. Currently supported for addresses in the USA, Canada and Australia. Defaults to 0
     * @param {number} [showdistance] Returns the straight line distance (meters) between the input location and the result\&#39;s location. Value is set in the distance key of the response. Defaults to 0 [0,1]
     * @param {number} [postaladdress] Returns address inside the postaladdress key, that is specifically formatted for each country. Currently supported for addresses in Germany. Defaults to 0 [0,1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reverse(
      lat: number,
      lon: number,
      format: 'json',
      normalizecity: 1,
      addressdetails?: 0 | 1,
      accept_language?: string,
      namedetails?: 0 | 1,
      extratags?: 0 | 1,
      statecode?: 0 | 1,
      showdistance?: number,
      postaladdress?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location> {
      const localVarAxiosArgs = ReverseApiAxiosParamCreator(configuration).reverse(
        lat,
        lon,
        format,
        normalizecity,
        addressdetails,
        accept_language,
        namedetails,
        extratags,
        statecode,
        showdistance,
        postaladdress,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReverseApi - factory interface
 * @export
 */
export const ReverseApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Reverse geocoding is the process of converting a coordinate or location (latitude, longitude) to a readable address or place name. This permits the identification of nearby street addresses, places, and/or area subdivisions such as a neighborhood, county, state, or country.
     * @summary Reverse Geocoding
     * @param {number} lat Latitude of the location to generate an address for.
     * @param {number} lon Longitude of the location to generate an address for.
     * @param {'json'} format Format to geocode. Only JSON supported for SDKs
     * @param {1} normalizecity Normalizes village to city level data to city
     * @param {0 | 1} [addressdetails] Include a breakdown of the address into elements. Defaults to 1.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {0 | 1} [namedetails] Include a list of alternative names in the results. These may include language variants, references, operator and brand.
     * @param {0 | 1} [extratags] Include additional information in the result if available, e.g. wikipedia link, opening hours.
     * @param {0 | 1} [statecode] Adds state or province code when available to the statecode key inside the address element. Currently supported for addresses in the USA, Canada and Australia. Defaults to 0
     * @param {number} [showdistance] Returns the straight line distance (meters) between the input location and the result\&#39;s location. Value is set in the distance key of the response. Defaults to 0 [0,1]
     * @param {number} [postaladdress] Returns address inside the postaladdress key, that is specifically formatted for each country. Currently supported for addresses in Germany. Defaults to 0 [0,1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reverse(
      lat: number,
      lon: number,
      format: 'json',
      normalizecity: 1,
      addressdetails?: 0 | 1,
      accept_language?: string,
      namedetails?: 0 | 1,
      extratags?: 0 | 1,
      statecode?: 0 | 1,
      showdistance?: number,
      postaladdress?: number,
      options?: any,
    ) {
      return ReverseApiFp(configuration).reverse(
        lat,
        lon,
        format,
        normalizecity,
        addressdetails,
        accept_language,
        namedetails,
        extratags,
        statecode,
        showdistance,
        postaladdress,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * ReverseApi - object-oriented interface
 * @export
 * @class ReverseApi
 * @extends {BaseAPI}
 */
export class ReverseApi extends BaseAPI {
  /**
   * Reverse geocoding is the process of converting a coordinate or location (latitude, longitude) to a readable address or place name. This permits the identification of nearby street addresses, places, and/or area subdivisions such as a neighborhood, county, state, or country.
   * @summary Reverse Geocoding
   * @param {number} lat Latitude of the location to generate an address for.
   * @param {number} lon Longitude of the location to generate an address for.
   * @param {'json'} format Format to geocode. Only JSON supported for SDKs
   * @param {1} normalizecity Normalizes village to city level data to city
   * @param {0 | 1} [addressdetails] Include a breakdown of the address into elements. Defaults to 1.
   * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
   * @param {0 | 1} [namedetails] Include a list of alternative names in the results. These may include language variants, references, operator and brand.
   * @param {0 | 1} [extratags] Include additional information in the result if available, e.g. wikipedia link, opening hours.
   * @param {0 | 1} [statecode] Adds state or province code when available to the statecode key inside the address element. Currently supported for addresses in the USA, Canada and Australia. Defaults to 0
   * @param {number} [showdistance] Returns the straight line distance (meters) between the input location and the result\&#39;s location. Value is set in the distance key of the response. Defaults to 0 [0,1]
   * @param {number} [postaladdress] Returns address inside the postaladdress key, that is specifically formatted for each country. Currently supported for addresses in Germany. Defaults to 0 [0,1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReverseApi
   */
  public reverse(
    lat: number,
    lon: number,
    format: 'json',
    normalizecity: 1,
    addressdetails?: 0 | 1,
    accept_language?: string,
    namedetails?: 0 | 1,
    extratags?: 0 | 1,
    statecode?: 0 | 1,
    showdistance?: number,
    postaladdress?: number,
    options?: any,
  ) {
    return ReverseApiFp(this.configuration).reverse(
      lat,
      lon,
      format,
      normalizecity,
      addressdetails,
      accept_language,
      namedetails,
      extratags,
      statecode,
      showdistance,
      postaladdress,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * The Search API allows converting addresses, such as a street address, into geographic coordinates (latitude and longitude). These coordinates can serve various use-cases, from placing markers on a map to helping algorithms determine nearby bus stops. This process is also known as Forward Geocoding.
     * @summary Forward Geocoding
     * @param {string} q Address to geocode
     * @param {'json'} format Format to geocode. Only JSON supported for SDKs
     * @param {1} normalizecity For responses with no city value in the address section, the next available element in this order - city_district, locality, town, borough, municipality, village, hamlet, quarter, neighbourhood - from the address section will be normalized to city. Defaults to 1 for SDKs.
     * @param {0 | 1} [addressdetails] Include a breakdown of the address into elements. Defaults to 0.
     * @param {string} [viewbox] The preferred area to find search results.  To restrict results to those within the viewbox, use along with the bounded option. Tuple of 4 floats. Any two corner points of the box - &#x60;max_lon,max_lat,min_lon,min_lat&#x60; or &#x60;min_lon,min_lat,max_lon,max_lat&#x60; - are accepted in any order as long as they span a real box.
     * @param {0 | 1} [bounded] Restrict the results to only items contained with the viewbox
     * @param {number} [limit] Limit the number of returned results. Default is 10.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {string} [countrycodes] Limit search to a list of countries.
     * @param {0 | 1} [namedetails] Include a list of alternative names in the results. These may include language variants, references, operator and brand.
     * @param {0 | 1} [dedupe] Sometimes you have several objects in OSM identifying the same place or object in reality. The simplest case is a street being split in many different OSM ways due to different characteristics. Nominatim will attempt to detect such duplicates and only return one match; this is controlled by the dedupe parameter which defaults to 1. Since the limit is, for reasons of efficiency, enforced before and not after de-duplicating, it is possible that de-duplicating leaves you with less results than requested.
     * @param {0 | 1} [extratags] Include additional information in the result if available, e.g. wikipedia link, opening hours.
     * @param {0 | 1} [statecode] Adds state or province code when available to the statecode key inside the address element. Currently supported for addresses in the USA, Canada and Australia. Defaults to 0
     * @param {number} [matchquality] Returns additional information about quality of the result in a matchquality object. Read more Defaults to 0 [0,1]
     * @param {number} [postaladdress] Returns address inside the postaladdress key, that is specifically formatted for each country. Currently supported for addresses in Germany. Defaults to 0 [0,1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      q: string,
      format: 'json',
      normalizecity: 1,
      addressdetails?: 0 | 1,
      viewbox?: string,
      bounded?: 0 | 1,
      limit?: number,
      accept_language?: string,
      countrycodes?: string,
      namedetails?: 0 | 1,
      dedupe?: 0 | 1,
      extratags?: 0 | 1,
      statecode?: 0 | 1,
      matchquality?: number,
      postaladdress?: number,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'q' is not null or undefined
      if (q === null || q === undefined) {
        throw new RequiredError('q', 'Required parameter q was null or undefined when calling search.');
      }
      // verify required parameter 'format' is not null or undefined
      if (format === null || format === undefined) {
        throw new RequiredError('format', 'Required parameter format was null or undefined when calling search.');
      }
      // verify required parameter 'normalizecity' is not null or undefined
      if (normalizecity === null || normalizecity === undefined) {
        throw new RequiredError(
          'normalizecity',
          'Required parameter normalizecity was null or undefined when calling search.',
        );
      }
      const localVarPath = `/search.php`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('key') : configuration.apiKey;
        localVarQueryParameter['key'] = localVarApiKeyValue;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (normalizecity !== undefined) {
        localVarQueryParameter['normalizecity'] = normalizecity;
      }

      if (addressdetails !== undefined) {
        localVarQueryParameter['addressdetails'] = addressdetails;
      }

      if (viewbox !== undefined) {
        localVarQueryParameter['viewbox'] = viewbox;
      }

      if (bounded !== undefined) {
        localVarQueryParameter['bounded'] = bounded;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (accept_language !== undefined) {
        localVarQueryParameter['accept-language'] = accept_language;
      }

      if (countrycodes !== undefined) {
        localVarQueryParameter['countrycodes'] = countrycodes;
      }

      if (namedetails !== undefined) {
        localVarQueryParameter['namedetails'] = namedetails;
      }

      if (dedupe !== undefined) {
        localVarQueryParameter['dedupe'] = dedupe;
      }

      if (extratags !== undefined) {
        localVarQueryParameter['extratags'] = extratags;
      }

      if (statecode !== undefined) {
        localVarQueryParameter['statecode'] = statecode;
      }

      if (matchquality !== undefined) {
        localVarQueryParameter['matchquality'] = matchquality;
      }

      if (postaladdress !== undefined) {
        localVarQueryParameter['postaladdress'] = postaladdress;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
  return {
    /**
     * The Search API allows converting addresses, such as a street address, into geographic coordinates (latitude and longitude). These coordinates can serve various use-cases, from placing markers on a map to helping algorithms determine nearby bus stops. This process is also known as Forward Geocoding.
     * @summary Forward Geocoding
     * @param {string} q Address to geocode
     * @param {'json'} format Format to geocode. Only JSON supported for SDKs
     * @param {1} normalizecity For responses with no city value in the address section, the next available element in this order - city_district, locality, town, borough, municipality, village, hamlet, quarter, neighbourhood - from the address section will be normalized to city. Defaults to 1 for SDKs.
     * @param {0 | 1} [addressdetails] Include a breakdown of the address into elements. Defaults to 0.
     * @param {string} [viewbox] The preferred area to find search results.  To restrict results to those within the viewbox, use along with the bounded option. Tuple of 4 floats. Any two corner points of the box - &#x60;max_lon,max_lat,min_lon,min_lat&#x60; or &#x60;min_lon,min_lat,max_lon,max_lat&#x60; - are accepted in any order as long as they span a real box.
     * @param {0 | 1} [bounded] Restrict the results to only items contained with the viewbox
     * @param {number} [limit] Limit the number of returned results. Default is 10.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {string} [countrycodes] Limit search to a list of countries.
     * @param {0 | 1} [namedetails] Include a list of alternative names in the results. These may include language variants, references, operator and brand.
     * @param {0 | 1} [dedupe] Sometimes you have several objects in OSM identifying the same place or object in reality. The simplest case is a street being split in many different OSM ways due to different characteristics. Nominatim will attempt to detect such duplicates and only return one match; this is controlled by the dedupe parameter which defaults to 1. Since the limit is, for reasons of efficiency, enforced before and not after de-duplicating, it is possible that de-duplicating leaves you with less results than requested.
     * @param {0 | 1} [extratags] Include additional information in the result if available, e.g. wikipedia link, opening hours.
     * @param {0 | 1} [statecode] Adds state or province code when available to the statecode key inside the address element. Currently supported for addresses in the USA, Canada and Australia. Defaults to 0
     * @param {number} [matchquality] Returns additional information about quality of the result in a matchquality object. Read more Defaults to 0 [0,1]
     * @param {number} [postaladdress] Returns address inside the postaladdress key, that is specifically formatted for each country. Currently supported for addresses in Germany. Defaults to 0 [0,1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      q: string,
      format: 'json',
      normalizecity: 1,
      addressdetails?: 0 | 1,
      viewbox?: string,
      bounded?: 0 | 1,
      limit?: number,
      accept_language?: string,
      countrycodes?: string,
      namedetails?: 0 | 1,
      dedupe?: 0 | 1,
      extratags?: 0 | 1,
      statecode?: 0 | 1,
      matchquality?: number,
      postaladdress?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Location>> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).search(
        q,
        format,
        normalizecity,
        addressdetails,
        viewbox,
        bounded,
        limit,
        accept_language,
        countrycodes,
        namedetails,
        dedupe,
        extratags,
        statecode,
        matchquality,
        postaladdress,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * The Search API allows converting addresses, such as a street address, into geographic coordinates (latitude and longitude). These coordinates can serve various use-cases, from placing markers on a map to helping algorithms determine nearby bus stops. This process is also known as Forward Geocoding.
     * @summary Forward Geocoding
     * @param {string} q Address to geocode
     * @param {'json'} format Format to geocode. Only JSON supported for SDKs
     * @param {1} normalizecity For responses with no city value in the address section, the next available element in this order - city_district, locality, town, borough, municipality, village, hamlet, quarter, neighbourhood - from the address section will be normalized to city. Defaults to 1 for SDKs.
     * @param {0 | 1} [addressdetails] Include a breakdown of the address into elements. Defaults to 0.
     * @param {string} [viewbox] The preferred area to find search results.  To restrict results to those within the viewbox, use along with the bounded option. Tuple of 4 floats. Any two corner points of the box - &#x60;max_lon,max_lat,min_lon,min_lat&#x60; or &#x60;min_lon,min_lat,max_lon,max_lat&#x60; - are accepted in any order as long as they span a real box.
     * @param {0 | 1} [bounded] Restrict the results to only items contained with the viewbox
     * @param {number} [limit] Limit the number of returned results. Default is 10.
     * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
     * @param {string} [countrycodes] Limit search to a list of countries.
     * @param {0 | 1} [namedetails] Include a list of alternative names in the results. These may include language variants, references, operator and brand.
     * @param {0 | 1} [dedupe] Sometimes you have several objects in OSM identifying the same place or object in reality. The simplest case is a street being split in many different OSM ways due to different characteristics. Nominatim will attempt to detect such duplicates and only return one match; this is controlled by the dedupe parameter which defaults to 1. Since the limit is, for reasons of efficiency, enforced before and not after de-duplicating, it is possible that de-duplicating leaves you with less results than requested.
     * @param {0 | 1} [extratags] Include additional information in the result if available, e.g. wikipedia link, opening hours.
     * @param {0 | 1} [statecode] Adds state or province code when available to the statecode key inside the address element. Currently supported for addresses in the USA, Canada and Australia. Defaults to 0
     * @param {number} [matchquality] Returns additional information about quality of the result in a matchquality object. Read more Defaults to 0 [0,1]
     * @param {number} [postaladdress] Returns address inside the postaladdress key, that is specifically formatted for each country. Currently supported for addresses in Germany. Defaults to 0 [0,1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      q: string,
      format: 'json',
      normalizecity: 1,
      addressdetails?: 0 | 1,
      viewbox?: string,
      bounded?: 0 | 1,
      limit?: number,
      accept_language?: string,
      countrycodes?: string,
      namedetails?: 0 | 1,
      dedupe?: 0 | 1,
      extratags?: 0 | 1,
      statecode?: 0 | 1,
      matchquality?: number,
      postaladdress?: number,
      options?: any,
    ) {
      return SearchApiFp(configuration).search(
        q,
        format,
        normalizecity,
        addressdetails,
        viewbox,
        bounded,
        limit,
        accept_language,
        countrycodes,
        namedetails,
        dedupe,
        extratags,
        statecode,
        matchquality,
        postaladdress,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   * The Search API allows converting addresses, such as a street address, into geographic coordinates (latitude and longitude). These coordinates can serve various use-cases, from placing markers on a map to helping algorithms determine nearby bus stops. This process is also known as Forward Geocoding.
   * @summary Forward Geocoding
   * @param {string} q Address to geocode
   * @param {'json'} format Format to geocode. Only JSON supported for SDKs
   * @param {1} normalizecity For responses with no city value in the address section, the next available element in this order - city_district, locality, town, borough, municipality, village, hamlet, quarter, neighbourhood - from the address section will be normalized to city. Defaults to 1 for SDKs.
   * @param {0 | 1} [addressdetails] Include a breakdown of the address into elements. Defaults to 0.
   * @param {string} [viewbox] The preferred area to find search results.  To restrict results to those within the viewbox, use along with the bounded option. Tuple of 4 floats. Any two corner points of the box - &#x60;max_lon,max_lat,min_lon,min_lat&#x60; or &#x60;min_lon,min_lat,max_lon,max_lat&#x60; - are accepted in any order as long as they span a real box.
   * @param {0 | 1} [bounded] Restrict the results to only items contained with the viewbox
   * @param {number} [limit] Limit the number of returned results. Default is 10.
   * @param {string} [accept_language] Preferred language order for showing search results, overrides the value specified in the Accept-Language HTTP header. Defaults to en. To use native language for the response when available, use accept-language&#x3D;native
   * @param {string} [countrycodes] Limit search to a list of countries.
   * @param {0 | 1} [namedetails] Include a list of alternative names in the results. These may include language variants, references, operator and brand.
   * @param {0 | 1} [dedupe] Sometimes you have several objects in OSM identifying the same place or object in reality. The simplest case is a street being split in many different OSM ways due to different characteristics. Nominatim will attempt to detect such duplicates and only return one match; this is controlled by the dedupe parameter which defaults to 1. Since the limit is, for reasons of efficiency, enforced before and not after de-duplicating, it is possible that de-duplicating leaves you with less results than requested.
   * @param {0 | 1} [extratags] Include additional information in the result if available, e.g. wikipedia link, opening hours.
   * @param {0 | 1} [statecode] Adds state or province code when available to the statecode key inside the address element. Currently supported for addresses in the USA, Canada and Australia. Defaults to 0
   * @param {number} [matchquality] Returns additional information about quality of the result in a matchquality object. Read more Defaults to 0 [0,1]
   * @param {number} [postaladdress] Returns address inside the postaladdress key, that is specifically formatted for each country. Currently supported for addresses in Germany. Defaults to 0 [0,1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public search(
    q: string,
    format: 'json',
    normalizecity: 1,
    addressdetails?: 0 | 1,
    viewbox?: string,
    bounded?: 0 | 1,
    limit?: number,
    accept_language?: string,
    countrycodes?: string,
    namedetails?: 0 | 1,
    dedupe?: 0 | 1,
    extratags?: 0 | 1,
    statecode?: 0 | 1,
    matchquality?: number,
    postaladdress?: number,
    options?: any,
  ) {
    return SearchApiFp(this.configuration).search(
      q,
      format,
      normalizecity,
      addressdetails,
      viewbox,
      bounded,
      limit,
      accept_language,
      countrycodes,
      namedetails,
      dedupe,
      extratags,
      statecode,
      matchquality,
      postaladdress,
      options,
    )(this.axios, this.basePath);
  }
}
