/** @jsxImportSource @emotion/react */
import { List, ListItem } from '@terveystalo/design-system-react-components';
import { RedirectionFormQuestion } from 'model/api';
import React, { FC } from 'react';
import { classNames } from 'utils/styles/helpers';

export const RedirectionFormChoicesSummary: FC<{ title: string; choices: RedirectionFormQuestion[] }> = ({
  title,
  choices,
}) =>
  choices.length ? (
    <div className={classNames('mb-2')}>
      <h2 className={classNames('mb-0.5', 'text-style-subtitle-s')}>{title}</h2>
      <List>
        {choices.map((c, i) => (
          <ListItem
            as="span"
            key={`summary-${i}-${c.questionId}`}
            passive={true}
            iconRight="Check"
            iconColor="okayGreen"
            onWhiteBackground={true}
          >
            {c.text || c.defaultText}
          </ListItem>
        ))}
      </List>
    </div>
  ) : null;
