import { FC, useContext } from 'react';
import { ConfigContext } from 'utils/config/ConfigContextProvider';

export const RedirectionService: FC = () => {
  const redirectionStartPath = '/redirection';
  const { redirectionServices } = useContext(ConfigContext);

  const isRedirectionService = () => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.pathname.startsWith(redirectionStartPath);
  };

  if (!isRedirectionService()) {
    return null;
  }

  if (!redirectionServices.length) {
    return null;
  }

  const currentUrl = new URL(window.location.href);
  const matchedService = redirectionServices.find(service => {
    const serviceSearchParams = Array.from(service.from.searchParams);
    const currentSearchParams = Array.from(currentUrl.searchParams);

    // Check if the number of parameters in the service URL and the current URL are the same
    if (serviceSearchParams.length !== currentSearchParams.length) {
      return false;
    }

    // Check if every parameter in the service URL is also in the current URL
    for (const param of serviceSearchParams) {
      if (currentSearchParams.find(([key, value]) => key === param[0] && value === param[1]) === undefined) {
        return false;
      }
    }

    return true;
  });
  if (matchedService) {
    window.location.href = matchedService.to.pathname + matchedService.to.search;
  } else {
    window.location.href = '/';
  }
  return null;
};

export default RedirectionService;
