/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Notice } from 'ui/misc/Notice';
import { useReduxDispatch, useReduxState, useTranslations } from 'utils/react/ui-context';
import { getStorageItem, setStorageItem } from 'utils/storage/helpers';

export const InfoNotice: FC<{ className?: string }> = ({ className }) => {
  const t = useTranslations();
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const storageKey = bookingRoot === 'dental' ? 'st-inotice-dental' : 'st-inotice';
  const closed = useReduxState(state =>
    bookingRoot === 'dental' ? state.notice.infoNoticeDentalClosed : state.notice.infoNoticeClosed,
  );
  const dispatch = useReduxDispatch();

  // Dental version
  if (bookingRoot === 'dental') {
    if (!t.info_notice_text_dental) return null;
    // Other than dental
  } else {
    if (!t.info_notice_text) return null;
  }

  return (
    <Notice
      variant="info"
      text={bookingRoot === 'dental' ? t.info_notice_text_dental : t.info_notice_text}
      href={bookingRoot === 'dental' ? t.info_notice_link_dental : t.info_notice_link}
      close={{
        closed: closed || !!getStorageItem(storageKey),
        onClose: () => {
          bookingRoot === 'dental' ? dispatch.closeInfoNoticeDental() : dispatch.closeInfoNotice();
          setStorageItem(storageKey, 'closed');
        },
        buttonLabel: t.close,
      }}
      className={className}
    />
  );
};
