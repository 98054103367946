/** @jsxImportSource @emotion/react */
import React, { FC, Fragment } from 'react';
import { OccupationalContractModal } from 'ui/occupational/OccupationalContractModal';
import { useModalOpenState } from 'utils/react/modal-helpers';
import { useApiData, useReduxState } from 'utils/react/ui-context';
import { notAsked } from 'utils/types/remoteData';

export const OccupationalContractWrapper: FC<{
  name: string;
  buttonComponent: FC<{ onClick: () => void }>;
}> = ({ name, buttonComponent: Component }) => {
  const auth = useReduxState(state => state.auth);
  const [modalOpen, setModalOpen] = useModalOpenState(`occupationalContract${name}`);
  const getOccupationalData = useApiData('getOccupationalData');
  const getClinicId = useApiData('getClinicId');
  const getClinic = useApiData('getClinic');

  const occupationalData = modalOpen && auth.status == 'LOGGED_IN' ? getOccupationalData([auth.token]) : notAsked;

  const clinicCode = occupationalData.status === 'REMOTE_DATA_SUCCESS' ? occupationalData.data.mainClinicCode : null;
  const clinicIdData = clinicCode ? getClinicId([clinicCode]) : notAsked;
  const clinicData = clinicIdData.status === 'REMOTE_DATA_SUCCESS' ? getClinic([clinicIdData.data]) : notAsked;

  return (
    <Fragment>
      <Component onClick={() => setModalOpen(true)} />
      <OccupationalContractModal
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        occupationalData={occupationalData}
        clinicData={clinicData}
      />
    </Fragment>
  );
};
