import { TypeOf } from 'io-ts';
import { ApiModel, ApiModelType } from 'model/api';
import { assertIs, createError, ErrorModelType, getErrorAsString, isError } from 'model/error';
import { Analytics } from 'utils/analytics/analytics-utils';
import { Logger } from 'utils/logging/logging-utils';
import { AnyModel } from 'utils/types/models';

// Runs the given callback, safeguarding its conversion of data from API models to our Models.
// If the process fails, we document the failure in a specialized Error type.
export function guardedModelConversion<OurModel extends AnyModel<typeof ApiModel>, TheirData>(
  analytics: Analytics,
  log: Logger,
  modelType: OurModel,
  convert: (x: TheirData) => TypeOf<OurModel>,
): (response: TheirData) => TypeOf<OurModel> {
  return response => {
    try {
      const result = convert(response); // run the conversion function from API data to our Models
      assertIs(modelType)(result); // validate the result against our io-ts schema
      return result; // every step passed -> we can be reasonably sure we have clean data :tada:
    } catch (err) {
      const refinedErr = createError.ModelConversionError(
        `Couldn't convert given data to Model of type "${modelType.name}"`,
        {
          expectedModel: modelType.name,
          failureDetails: isError.ModelValidationError(err)
            ? err.ModelValidationError.failureDetails
            : isError.ModelConversionError(err)
            ? err.ModelConversionError.failureDetails
            : err instanceof Error
            ? [`${err.message}`] // we know nothing special about the underlying error -> just stick its message in there and call it a day
            : [],
        },
      );
      log(refinedErr.message, refinedErr.ModelConversionError);
      analytics.sendDataConversionError(modelType.name, getErrorAsString(refinedErr));
      throw refinedErr;
    }
  };
}

export function guardedStringConversion<TheirData>(
  analytics: Analytics,
  log: Logger,
  convert: (x: TheirData) => string,
): (response: TheirData) => string {
  return response => {
    try {
      const result = convert(response); // run the conversion function from API data to our Models
      if (typeof result === 'string') {
        return result; // every step passed -> we can be reasonably sure we have clean data :tada:
      } else {
        throw createError.ModelValidationError(`Given data does NOT look like a string`, {
          expectedModel: 'string',
          failureDetails: [],
        });
      }
    } catch (err) {
      const refinedErr = createError.ModelConversionError("Couldn't convert given data to string", {
        expectedModel: 'string',
        failureDetails: isError.ModelValidationError(err)
          ? err.ModelValidationError.failureDetails
          : isError.ModelConversionError(err)
          ? err.ModelConversionError.failureDetails
          : err instanceof Error
          ? [`${err.message}`] // we know nothing special about the underlying error -> just stick its message in there and call it a day
          : [],
      });
      log(refinedErr.message, refinedErr.ModelConversionError);
      analytics.sendDataConversionError('string', getErrorAsString(refinedErr));
      throw refinedErr;
    }
  };
}

// Runs the given callback, safeguarding its conversion of an array of data from API models to our Models.
// If the process fails for single items, we drop them, but if all items fail we document the failure in a specialized Error type.
export function guardedModelArrayConversion<OurModel extends AnyModel<typeof ApiModel>, TheirModel>(
  analytics: Analytics,
  log: Logger,
  data: TheirModel[],
  modelType: OurModel,
  convert: (x: TheirModel) => TypeOf<OurModel>,
): Array<TypeOf<OurModel>> {
  const causes: ErrorModelType['ModelArrayConversionError']['causes'] = [];
  const validItems = data.reduce<Array<TypeOf<OurModel>>>((list, d) => {
    try {
      list.push(guardedModelConversion(analytics, log, modelType, convert)(d));
    } catch (err) {
      if (isError.ModelConversionError(err)) {
        causes.push(err.ModelConversionError);
      } else {
        throw err; // no idea what happened -> better crash and burn
      }
    }
    return list;
  }, []);
  if (data.length > 0 && validItems.length === 0) {
    throw createError.ModelArrayConversionError(
      `Couldn't convert ANY part of given data to Models of type "${modelType.name}"`,
      { causes }, // attach all the underlying errors which caused this error to be raised
    );
  }
  return validItems;
}

// Like guardedModelArrayConversion but takes in a conversion function to be able to support multiple types in one array
// Use together with guardedModelConversion
export function guardedModelArrayConversionMultiType<OurModels, TheirModel>(
  data: TheirModel[],
  modelNames: Array<keyof ApiModelType>,
  guardedConversion: (x: TheirModel) => (x: TheirModel) => OurModels,
): Array<OurModels> {
  const causes: ErrorModelType['ModelArrayConversionError']['causes'] = [];
  const validItems = data.reduce<Array<OurModels>>((list, d) => {
    try {
      list.push(guardedConversion(d)(d));
    } catch (err) {
      if (isError.ModelConversionError(err)) {
        causes.push(err.ModelConversionError);
      } else {
        throw err; // no idea what happened -> better crash and burn
      }
    }
    return list;
  }, []);
  if (data.length > 0 && validItems.length === 0) {
    throw createError.ModelArrayConversionError(
      `Couldn't convert ANY part of given data to Models of types "${modelNames.join('" or "')}"`,
      { causes }, // attach all the underlying errors which caused this error to be raised
    );
  }
  return validItems;
}

export function guardedStringArrayConversion<TheirModel>(
  analytics: Analytics,
  log: Logger,
  data: TheirModel[],
  convert: (x: TheirModel) => string,
): Array<string> {
  const causes: ErrorModelType['ModelArrayConversionError']['causes'] = [];
  const validItems = data.reduce<Array<string>>((list, d) => {
    try {
      list.push(guardedStringConversion(analytics, log, convert)(d));
    } catch (err) {
      if (isError.ModelConversionError(err)) {
        causes.push(err.ModelConversionError);
      } else {
        throw err; // no idea what happened -> better crash and burn
      }
    }
    return list;
  }, []);
  if (data.length > 0 && validItems.length === 0) {
    throw createError.ModelArrayConversionError(
      `Couldn't convert ANY part of given data to strings`,
      { causes }, // attach all the underlying errors which caused this error to be raised
    );
  }
  return validItems;
}

// Asserts that the given value is defined.
// This is needed a lot of the time because the API's response types allow every property to be omitted, but
// using the responses that way would be pretty maddening.
export function defined<T extends null | string | number | boolean | Date | Record<string, any>>(
  val: T | undefined,
  name: string,
): T {
  if (val === undefined) {
    throw new Error(`Found undefined value where expecting value in API response (${name})`);
  } else {
    return val;
  }
}

// Asserts that the given value is defined and not null
export function definedNotNull<T extends string | number | boolean | Date | Record<string, any>>(
  val: T | null | undefined,
  name: string,
): T {
  if (val === null) {
    throw new Error(`Found null value where expecting value in API response (${name})`);
  } else {
    return defined(val, name); // Note: defined function returns error for undefined value
  }
}
