/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Modal, SkeletonLine } from '@terveystalo/design-system-react-components';
import { theme } from '@terveystalo/design-system-theme';
import { ApiModelType } from 'model/api';
import React, { FC } from 'react';
import { ErrorPageContent } from 'ui/error-pages/ErrorPageContent';
import { LoadingPageContent } from 'ui/utils/LoadingPageContent';
import { useTranslations } from 'utils/react/ui-context';
import { classNames } from 'utils/styles/helpers';
import { RemoteData } from 'utils/types/remoteData';

export const OccupationalContractModal: FC<{
  isOpen: boolean;
  closeModal: () => void;
  occupationalData: RemoteData<ApiModelType['OccupationalData']>;
  clinicData: RemoteData<ApiModelType['Clinic']>;
}> = ({ isOpen, closeModal, occupationalData, clinicData }) => {
  const t = useTranslations();

  return (
    <Modal
      id="occupational-contract-modal"
      open={isOpen}
      title={<span className={classNames('break-words')}>{t.information_your_occupational_services}</span>}
      onClose={closeModal}
      closeButtonLabel={t.close}
      initialFocus="closeButton"
    >
      {occupationalData.status === 'REMOTE_DATA_SUCCESS' ? (
        <ul className={classNames('list-none')}>
          <PermissionItem label={t.employer} value={occupationalData.data.companyName} />
          <PermissionItem
            label={t.occupational_healthcare_home_clinic}
            value={
              clinicData.status === 'REMOTE_DATA_SUCCESS' ? `${clinicData.data.name}, ${clinicData.data.city}` : ''
            }
            loadingText={clinicData.status === 'REMOTE_DATA_LOADING' ? 'Terveystalo Kamppi, Helsinki' : undefined}
          />
        </ul>
      ) : (
        <div className={classNames('pb-2')}>
          {occupationalData.status === 'REMOTE_DATA_FAILURE' ? (
            <ErrorPageContent
              title={t.no_occupational_contract_found}
              titleLevel="h3"
              message={
                occupationalData.errorCode === 401
                  ? t.try_again_later_or_call
                  : t.occupational_healthcare_data_not_found_check_contract
              }
              noIcon={true}
            />
          ) : (
            <LoadingPageContent translations={t} />
          )}
        </div>
      )}
    </Modal>
  );
};

// Helper component
const PermissionItem: FC<{ label: string; value: string; loadingText?: string }> = ({ label, value, loadingText }) => (
  <li
    className={classNames('s:flex', 'justify-between', 'items-end', 'p-1')}
    css={css`
      & + & {
        border-top: ${theme.border.width.xxs} solid ${theme.color.zodiacBlack15};
      }
    `}
  >
    <div>{label}</div>
    <div className={classNames('font-bold', 's:w-1/2', 'm:w-2/5', 'mt-0.25', 's:mt-0')}>
      {loadingText ? <SkeletonLine width="120px" className={classNames('my-0.5')} /> : value}
    </div>
  </li>
);
