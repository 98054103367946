import rootReducer, { State } from 'reducers';
import { createStore as reduxCreateStore } from 'redux';

export function createStore(initialState?: State) {
  return reduxCreateStore(
    rootReducer,
    initialState,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(), // https://github.com/zalmoxisus/redux-devtools-extension#usage
  );
}

export type Store = ReturnType<typeof createStore>;
