/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import { theme } from '@terveystalo/design-system-theme';
import React, { FC, Fragment, useState } from 'react';
import { Router } from 'react-router-dom';
import { MaintenanceBreak } from 'ui/error-pages/MaintenanceBreakPage';
import { ApiHealthChecker } from 'ui/misc/ApiHealthChecker';
import { Routes } from 'ui/Routes';
import { AuthTimeoutTracker } from 'ui/utils/AuthTimeoutTracker';
import { ScrollToTop } from 'ui/utils/ScrollToTop';
import { useAutoLogin } from 'utils/auth/helpers';
import { useConfig, useHistory, useReduxState } from 'utils/react/ui-context';
import { useCheckStorageEnabled } from 'utils/storage/helpers';
import { classNames } from 'utils/styles/helpers';
import useLogging from 'utils/useLogging';

export const App: FC = () => {
  useLogging('MainApp');
  const { MAINTENANCE_BREAK } = useConfig();
  const history = useHistory();
  useCheckStorageEnabled();
  useAutoLogin();

  const [maintenanceBreak, setMaintenanceBreak] = useState<MaintenanceBreak | undefined>(
    MAINTENANCE_BREAK ? { type: 'manual' } : undefined,
  );

  // Figure out if the page is displayed in the app on iOS
  // for setting a gutter for the body and all dialogs to counter a weird effect of the screen height
  // being bigger than the visual area after opening a native control such as a soft keyboard
  const embedInApp = useReduxState(state => state.location.embedInApp);
  const isIosApp =
    embedInApp &&
    ((navigator.platform && /iP(ad|hone|od)/.test(navigator.platform)) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document));

  return (
    <Fragment>
      <Global
        styles={css`
          /* Global styles */
          body {
            background: ${theme.color.zodiacBlack04};
            position: relative;
          }
          /* Target body and modals for iOS gutter */
          ${isIosApp &&
            css`
              div[role='dialog'][id$='-modal'] > div, /* FeedbackDialog and Modal */
              div[role='dialog'][id$='-modal-full'] .overflow-y-auto, /* custom full height modals (DialogModalBase) */
              body {
                padding-bottom: 58px; /* approximately the height of the area outside the screen */
              }
            `}
        `}
      />
      <ApiHealthChecker isBreak={!!maintenanceBreak} setMaintenanceBreak={setMaintenanceBreak} />
      <Router history={history}>
        <div className={classNames('flex', 'flex-col', 'min-h-screen')}>
          <ScrollToTop>
            <Routes maintenanceBreak={maintenanceBreak} />
          </ScrollToTop>
        </div>
        <AuthTimeoutTracker />
      </Router>
    </Fragment>
  );
};
