import { isFuture, isPast, TimeSource } from 'utils/datetime/helpers';

// The control character at the end of a social security number, 31 characters
const SSN_CONTROL = '0123456789ABCDEFHJKLMNPRSTUVWXY';

// Latin alphabet and Latin-1 supplements for letters for regexp matchers
const letters = 'a-zA-ZÀ-ÖØ-öø-ÿ';

export const isValidSsn = (ssn: string) =>
  // The format is ddmmyy*xxxy, where * is - or alphabetical letter A to F and where xxx is a number 002-899 and y is a control character
  !!ssn.match(/^[0-3][0-9][0-1][0-9]{3}[-A-FUVWXY][0-9]{3}[0-9A-Za-z]$/) &&
  // If the format is correct, check that the calculated control character matches
  SSN_CONTROL[parseInt(ssn.slice(0, 10).replace(/[-A-FUVWXY]/, ''), 10) % 31] === ssn.slice(10).toUpperCase();

export const isValidDhPhoneNumber = (phone: string) =>
  // 7-12 characters (numbers only) for storing in DH, starting with 0
  !!phone.match(/^0[0-9]{6,11}$/);

export const isValidMobilePhoneNumber = (phone: string) =>
  // The format is 0401234567 or +358401234567
  !!phone.match(/^(0|\+[0-9]{1,3})[0-9]{5,11}$/);

export const isValidEmail = (email: string) =>
  // The lax format is x@x, but do not allow whitespace
  !email.match(/\s/) && !!email.match(/^[^@;]+@[^@;]+$/);

export const isValidName = (str: string) =>
  // 1-20 characters, no numbers, no special characters other than dash(-) and space( )
  !!str.match(new RegExp(`^[${letters}- ]{1,20}$`));

// More relaxed validation for fields containing full name
export const isValidFullName = (str: string) => new RegExp(`^[${letters}- '()]{1,50}$`).test(str);

export const isValidAddress = (str: string) =>
  // 4-30 characters, must include at least one letter, numbers and special characters allowed
  !!str.match(new RegExp(`[${letters}]`)) && !!str.match(/^.{4,30}$/);

export const isValidPostCode = (str: string) =>
  // 1-5 characters (numbers only)
  !!str.match(/^[0-9]{1,5}$/);

export const isValidCity = (str: string) =>
  // 1-30 characters, must include at least one letter, no numbers or special characters other than dash(-) and space( )
  !!str.match(new RegExp(`[${letters}]`)) && !!str.match(new RegExp(`^[${letters}- ]{1,30}$`));

export const validDate = (str: string, getCurrentTime: TimeSource, limitTo?: 'future' | 'past') => {
  // Allows only finnish format dd.mm.yyyy (or shorter d.m.yyyy for applicable dates)
  if (!str.match(/^[0-3]{0,1}[0-9]\.[0-1]{0,1}[0-9]\.[0-9]{4}$/)) return undefined;
  const parts = str.split('.').map(s => parseInt(s, 10));
  const date = new Date(`${parts[2]}-${parts[1] < 10 ? '0' : ''}${parts[1]}-${parts[0] < 10 ? '0' : ''}${parts[0]}`);
  if (isNaN(date.getTime())) return undefined;
  if (limitTo === 'future') {
    return isFuture(date, getCurrentTime, true) ? date : undefined;
  } else if (limitTo === 'past') {
    return isPast(date, getCurrentTime, true) ? date : undefined;
  }
  return date;
};

export const withoutSpaces = (str: string) => str.replace(/ /g, '');

export const cleanUpEmail = (str: string) => {
  if (!str) {
    return null;
  }

  const trimmed = str.trim();
  if (isValidEmail(trimmed)) {
    return trimmed;
  }

  // Handle special case of two email addresses
  const firstPart = trimmed.split(';')[0].trim();
  if (isValidEmail(firstPart)) {
    return firstPart;
  }

  return null;
};

export const cleanUpPhoneNumber = (str: string) => {
  if (!str) {
    return null;
  }

  const trimmed = withoutSpaces(str);
  if (isValidMobilePhoneNumber(trimmed)) {
    return trimmed;
  }

  // Handle special case of two phone numbers
  const firstPart = trimmed.split(';')[0];
  if (isValidMobilePhoneNumber(firstPart)) {
    return firstPart;
  }

  const cleaned = firstPart.replace(/[^+0-9]/g, '');
  if (isValidMobilePhoneNumber(cleaned)) {
    return cleaned;
  }

  return null;
};
