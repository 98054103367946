/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { callPriceInfoLinkProps } from '@terveystalo/design-system-react-organisms';
import { theme } from '@terveystalo/design-system-theme';
import React, { FC, Fragment, MouseEventHandler } from 'react';
import { priceInfoId as footerPriceInfoId } from 'ui/header-and-footer/Footer';
import { StyledLink } from 'ui/utils/LinkHelper';
import { useTranslations } from 'utils/react/ui-context';
import { classNames, onNotSmall } from 'utils/styles/helpers';

export const PhoneNumber: FC<{
  numberOverride?: string;
  large?: boolean;
  onClick?: MouseEventHandler;
  customerServiceCallFeeId?: string;
}> = ({ numberOverride, large, onClick, customerServiceCallFeeId = footerPriceInfoId }) => {
  const t = useTranslations();

  const additionalStyles = large
    ? css`
        font-weight: ${theme.typography.fontWeight.subtitle};
        font-size: ${theme.typography.fontSize.subtitle.mplus};
        line-height: ${theme.typography.lineHeight.subtitle.mplus};
        ${onNotSmall} {
          font-size: ${theme.typography.fontSize.subtitle.l};
          line-height: ${theme.typography.lineHeight.subtitle.l};
        }
      `
    : undefined;
  const tel = (numberOverride || t.tel_only_number).replace(/[^\+0-9]/g, '');
  const describedby = tel === t.tel_only_number.replace(/[^\+0-9]/g, '') ? customerServiceCallFeeId : undefined;

  const linkProps = callPriceInfoLinkProps(customerServiceCallFeeId);

  return (
    <Fragment>
      <StyledLink
        href={`tel:${tel}`}
        onClick={onClick}
        className={classNames('whitespace-nowrap')}
        css={additionalStyles}
        aria-describedby={describedby}
      >
        {numberOverride || t.tel_only_number}
      </StyledLink>
      <StyledLink
        href={linkProps.href}
        onClick={describedby ? linkProps.onClick : undefined}
        css={additionalStyles}
        aria-hidden
      >
        *
      </StyledLink>
    </Fragment>
  );
};
