import { createBrowserHistory } from 'history';
import noop from 'lodash/noop';
import React, { useContext, useMemo } from 'react';
import { Language } from 'translations/generated/translation-languages';
import { AbTestingContext } from 'utils/analytics/ab-testing';
import { Analytics } from 'utils/analytics/analytics-utils';
import { createApplicationInsightsClient } from 'utils/analytics/application-insights';
import { createPiwikAnalyticsClient } from 'utils/analytics/piwik-analytics';
import { ConfigContext } from 'utils/config/ConfigContextProvider';
import { addScript } from 'utils/dom/helpers';
import { parseLangFromPath } from 'utils/react/url-state-utils';

export const AnalyticsContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const config = useContext(ConfigContext);
  const abTesting = useContext(AbTestingContext);
  const history = createBrowserHistory();

  const getPiwikVisitorId = () => {
    // Check from consent if we're allowed to use the id
    const consentObj = (window as any).stConsent;
    if (!consentObj || !consentObj.choices || consentObj.choices.development !== true) {
      return null;
    }

    // Safeguard the retrieval of the visitorid
    const piwik = (window as any).Piwik;
    if (piwik && typeof piwik.getAsyncTracker === 'function') {
      const tracker = piwik.getAsyncTracker();
      if (tracker && typeof tracker.getVisitorId === 'function') {
        const visitorId = tracker.getVisitorId();
        if (typeof visitorId === 'string') {
          return visitorId;
        }
      }
    }

    return null;
  };

  const changeAnalyticsLang = (lang: Language) => {
    const consentObj = (window as any).stConsent;
    if (consentObj && typeof consentObj.changeLang === 'function') {
      consentObj.changeLang(lang);
    }
  };

  const analytics = useMemo(() => {
    if (config.ANALYTICS_CONSENT_SRC) {
      // Do not show the consent manager when site is embedded in the mobile app
      if (!history.location.search.match('embedInApp=true')) {
        const lang = parseLangFromPath(history.location.pathname);
        addScript(config.ANALYTICS_CONSENT_SRC, [
          { key: 'data-st-consent-lang', value: lang },
          { key: 'data-z-index-override', value: '100000001' }, // to go on top of also the customer service chat plugin
        ]);
      }
    }

    if (!config.FEATURE_ANALYTICS) {
      return NO_ANALYTICS;
    }

    let ai;
    // Initialize AI only if so configured
    if (config.APPLICATION_INSIGHTS_KEY) {
      ai = createApplicationInsightsClient(config, history);
    }

    let piwik;
    // Initialize Piwik only if so configured
    if (config.ANALYTICS_ID) {
      piwik = createPiwikAnalyticsClient(config, abTesting);
    }

    return {
      sendFetchDetails: ai ? ai.sendFetchDetails : noop,
      trackPageView: piwik ? piwik.trackPageView : noop,
      sendInitialDataLoaded: ai ? ai.sendInitialDataLoaded : noop,
      sendFetchBatchMetrics: ai ? ai.sendFetchBatchMetrics : noop,
      sendDataConversionError: ai ? ai.sendDataConversionError : noop,
      trackBookingProcess: piwik ? piwik.trackBookingProcess : noop,
      trackAppointmentsSearch: piwik ? piwik.trackAppointmentsSearch : noop,
      trackAlternativeServicesAppointmentsSearch: piwik ? piwik.trackAlternativeServicesAppointmentsSearch : noop,
      trackOccupationalLanderSelection: piwik ? piwik.trackOccupationalLanderSelection : noop,
      trackPartnerBookingProcess: piwik ? piwik.trackPartnerBookingProcess : noop,
      sendStrongAuthError: ai ? ai.sendStrongAuthError : noop,
      trackOccupationalDisallowed: piwik ? piwik.trackOccupationalDisallowed : noop,
      sendGeoLocationSearch: ai ? ai.sendGeoLocationSearch : noop,
      trackGuideModal: piwik ? piwik.trackGuideModal : noop,
      sendInvalidRedirectionFormParams: ai ? ai.sendInvalidRedirectionFormParams : noop,
      trackOccupationalRedirectionForm: piwik ? piwik.trackOccupationalRedirectionForm : noop,
      trackOccupationalPersonalization: piwik ? piwik.trackOccupationalPersonalization : noop,
      trackCoronaVaccineGuide: piwik ? piwik.trackCoronaVaccineGuide : noop,
      trackHtaTeamForm: piwik ? piwik.trackHtaTeamForm : noop,
      sendBookingFailureDetails: ai ? ai.sendBookingFailureDetails : noop,
      sendStorageNotEnabled: ai ? ai.sendStorageNotEnabled : noop,
      sendMaintenanceBreakPageShown: ai ? ai.sendMaintenanceBreakPageShown : noop,
      trackManageBooking: piwik ? piwik.trackManageBooking : noop,
      trackEditBooking: piwik ? piwik.trackEditBooking : noop,
      trackCancelBooking: piwik ? piwik.trackCancelBooking : noop,
      sendCustomerServiceChatObserverFailed: ai ? ai.sendCustomerServiceChatObserverFailed : noop,
      sendOccupationalWebReservationDeniedFound: ai ? ai.sendOccupationalWebReservationDeniedFound : noop,
      trackFrontPageClick: piwik ? piwik.trackFrontPageClick : noop,
      trackClickManageBooking: piwik ? piwik.trackClickManageBooking : noop,
      trackOpenSpecialistModal: piwik ? piwik.trackOpenSpecialistModal : noop,
      trackOpenClinicModal: piwik ? piwik.trackOpenClinicModal : noop,
      trackServiceBannerClick: piwik ? piwik.trackServiceBannerClick : noop,
      trackRelaLinkClick: piwik ? piwik.trackRelaLinkClick : noop,
      trackRehabLinkClick: piwik ? piwik.trackRehabLinkClick : noop,
      sendAppError: ai ? ai.sendAppError : noop,
      trackServiceDescriptionExpandClick: piwik ? piwik.trackServiceDescriptionExpandClick : noop,
      trackUserIsLoggedIn: piwik ? piwik.trackUserIsLoggedIn : noop,
      trackRemoteAppoinmentsAvailableBanner: piwik ? piwik.trackRemoteAppoinmentsAvailableBanner : noop,
      trackThankYouCheckYourCustomerInformation: piwik ? piwik.trackThankYouCheckYourCustomerInformation : noop,
      trackThankYouGoToTerveystaloComFrontPage: piwik ? piwik.trackThankYouGoToTerveystaloComFrontPage : noop,
      trackThankYouGoToTerveystaloForTeens: piwik ? piwik.trackThankYouGoToTerveystaloForTeens : noop,
      trackGiftCardSuccess: piwik ? piwik.trackGiftCardSuccess : noop,
      trackInsuranceCompanySelected: piwik ? piwik.trackInsuranceCompanySelected : noop,
      trackDirectCompensationPreview: piwik ? piwik.trackDirectCompensationPreview : noop,
      trackIcChooseAppointmentType: piwik ? piwik.trackIcChooseAppointmentType : noop,
      trackDirectCompensationError: piwik ? piwik.trackDirectCompensationError : noop,
    };
  }, [abTesting, config, history]);

  return (
    <AnalyticsContext.Provider
      value={{ analytics: analytics, changeAnalyticsLang: changeAnalyticsLang, getPiwikVisitorId: getPiwikVisitorId }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const NO_ANALYTICS: Analytics = {
  sendFetchDetails: noop,
  trackPageView: noop,
  sendInitialDataLoaded: noop,
  sendFetchBatchMetrics: noop,
  sendDataConversionError: noop,
  trackBookingProcess: noop,
  trackAppointmentsSearch: noop,
  trackAlternativeServicesAppointmentsSearch: noop,
  trackOccupationalLanderSelection: noop,
  trackPartnerBookingProcess: noop,
  sendStrongAuthError: noop,
  trackOccupationalDisallowed: noop,
  sendGeoLocationSearch: noop,
  trackGuideModal: noop,
  sendInvalidRedirectionFormParams: noop,
  trackOccupationalRedirectionForm: noop,
  trackOccupationalPersonalization: noop,
  trackCoronaVaccineGuide: noop,
  trackHtaTeamForm: noop,
  sendBookingFailureDetails: noop,
  sendStorageNotEnabled: noop,
  sendMaintenanceBreakPageShown: noop,
  trackManageBooking: noop,
  trackEditBooking: noop,
  trackCancelBooking: noop,
  sendCustomerServiceChatObserverFailed: noop,
  sendOccupationalWebReservationDeniedFound: noop,
  trackFrontPageClick: noop,
  trackClickManageBooking: noop,
  trackOpenSpecialistModal: noop,
  trackOpenClinicModal: noop,
  trackServiceBannerClick: noop,
  trackRelaLinkClick: noop,
  trackRehabLinkClick: noop,
  sendAppError: noop,
  trackServiceDescriptionExpandClick: noop,
  trackUserIsLoggedIn: noop,
  trackRemoteAppoinmentsAvailableBanner: noop,
  trackThankYouCheckYourCustomerInformation: noop,
  trackThankYouGoToTerveystaloComFrontPage: noop,
  trackThankYouGoToTerveystaloForTeens: noop,
  trackGiftCardSuccess: noop,
  trackInsuranceCompanySelected: noop,
  trackDirectCompensationPreview: noop,
  trackIcChooseAppointmentType: noop,
  trackDirectCompensationError: noop,
};

export type AnalyticsContextState = {
  analytics: Analytics;
  changeAnalyticsLang: (lang: Language) => void;
  getPiwikVisitorId: () => string | null;
};

export const AnalyticsContext = React.createContext<AnalyticsContextState>({
  analytics: NO_ANALYTICS,
  changeAnalyticsLang: () => null,
  getPiwikVisitorId: () => null,
});
