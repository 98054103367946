import { CustomerType } from 'model/api';
import { Language } from 'translations/generated/translation-languages';
import { DirectCompensationItemData, InsuranceCompanies } from 'utils/partnerAndInsurance/types';
import { Action } from 'utils/redux/actions';
import { BookingRoot } from 'utils/routes/routes';

export const initState: {
  lang: Language;
  bookingRoot: BookingRoot | undefined;
  customerTypeAddition: CustomerType | undefined; // If bookingRoot does not define an unambiguous customerType, use this addition
  embedInApp: boolean;
  moveBookingWebCode: string | undefined;
  selectedInsuranceCompany: InsuranceCompanies | undefined;
  selectedDirectCompensation: DirectCompensationItemData | undefined;
  agreementDirectCompensationDataFetch: boolean;
} = {
  lang: 'fi',
  bookingRoot: undefined,
  customerTypeAddition: undefined,
  embedInApp: false,
  moveBookingWebCode: undefined,
  selectedInsuranceCompany: undefined,
  selectedDirectCompensation: undefined,
  agreementDirectCompensationDataFetch: false,
};

export default function reducer(state = initState, action: Action) {
  switch (action.type) {
    case 'changeLang':
      return { ...state, lang: action.lang };
    case 'changeBookingRoot':
      return { ...state, bookingRoot: action.bookingRoot };
    case 'changeCustomerTypeAddition':
      return { ...state, customerTypeAddition: action.customerTypeAddition };
    case 'changeEmbedInApp':
      return { ...state, embedInApp: action.embedInApp };
    case 'setMoveBookingWebCode':
      return { ...state, moveBookingWebCode: action.moveBookingWebCode };
    case 'changeSelectedInsuranceCompany':
      return { ...state, selectedInsuranceCompany: action.selectedInsuranceCompany };
    case 'changeSelectedDirectCompensation':
      return { ...state, selectedDirectCompensation: action.selectedDirectCompensation };
    case 'changeAgreementDirectCompensationDataFetch':
      return { ...state, agreementDirectCompensationDataFetch: action.agreementDirectCompensationDataFetch };
    default:
      return state;
  }
}
