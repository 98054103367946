/** @jsxImportSource @emotion/react */
import { ApiModelType, RedirectionFormQuestion } from 'model/api';
import React, { FC, Fragment, useEffect, useRef } from 'react';
import { RestrictionsState } from 'reducers/filters';
import { RedirectionFormBookingResult } from 'ui/redirection-form/RedirectionFormBookingResult';
import { RedirectionFormChatResult } from 'ui/redirection-form/RedirectionFormChatResult';
import { RedirectionFormResultLink } from 'ui/redirection-form/RedirectionFormResultLink';
import { RedirectionFormResultText } from 'ui/redirection-form/RedirectionFormResultText';
import { useUiContext } from 'utils/react/ui-context';
import { classNames } from 'utils/styles/helpers';
import { assertExhausted } from 'utils/types/misc';

export const RedirectionFormResult: FC<{
  result: ApiModelType['RedirectionFormResult'];
  replaceNumber?: string | null;
  boldTextOnly?: boolean;
  asInfoText?: boolean;
  choices: RedirectionFormQuestion[];
  isDeadEnd: boolean;
  onClose: (restrictions: RestrictionsState) => void;
  sendAnalytics: (props: { type: 'chat' | 'booking' | 'link' | 'deadend'; actionParams?: string }) => void;
}> = ({ result, replaceNumber, boldTextOnly, asInfoText, choices, isDeadEnd, onClose, sendAnalytics }) => {
  const analytics = useUiContext().analytics;

  const disabledText = result.disabledText || result.defaultDisabledText;
  const appText = result.appText || result.defaultAppText;
  const baseText = result.text || result.defaultText;
  const text = result.disabled && disabledText ? disabledText : appText ? appText : baseText;
  const textType = asInfoText ? 'info' : boldTextOnly ? 'subtitle' : 'default';

  const firstLoad = useRef(true);
  useEffect(() => {
    if (isDeadEnd && firstLoad.current) {
      firstLoad.current = false;
      sendAnalytics({ type: 'deadend' });
    }
  }, [isDeadEnd, sendAnalytics]);

  if (!result.button) {
    return <RedirectionFormResultText text={text} type={textType} />;
  }

  switch (result.button.type) {
    case 'booking':
      const { params } = result.button;
      return (
        <RedirectionFormBookingResult
          text={text}
          button={result.button}
          disabled={result.disabled}
          replaceNumber={replaceNumber}
          additionalParams={choices
            .filter(c => c.bookingParams)
            .map(c => c.bookingParams)
            .join('&')}
          onButtonClick={onBookingClickFn(`bookingParams: ${params}`)}
          sendInvalidParamsAnalytics={() => analytics.sendInvalidRedirectionFormParams(result.resultId, params || '')}
        />
      );
    case 'chat':
      return (
        <RedirectionFormChatResult
          baseText={baseText}
          appText={appText}
          button={result.button}
          replaceNumber={replaceNumber}
          resultDisabled={result.disabled}
          disabledText={disabledText}
          onButtonClick={onClickFn('chat', `chatId: ${result.button.chatId}`)}
        />
      );
    case 'link':
      const url = result.button.url || result.button.defaultUrl || undefined;
      return (
        <Fragment>
          <RedirectionFormResultText
            text={text}
            type={textType}
            className={classNames(!!text && textType !== 'info' && 'mb-1')}
          />
          {textType === 'info' && ' '}
          {!result.disabled && (
            <RedirectionFormResultLink
              small={textType === 'info'}
              text={result.button.text || result.button.defaultText}
              linkUrl={url}
              onClick={onClickFn('link', `linkUrl: ${url}`)}
            />
          )}
        </Fragment>
      );
    default:
      assertExhausted(result.button.type);
      return null;
  }

  function onClickFn(type: 'link' | 'chat', actionParams: string) {
    return () => sendAnalytics({ type, actionParams });
  }

  function onBookingClickFn(actionParams: string) {
    return (restrictions: RestrictionsState) => {
      sendAnalytics({ type: 'booking', actionParams });
      onClose(restrictions);
    };
  }
};
