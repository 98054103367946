/** @jsxImportSource @emotion/react */
import { Button } from '@terveystalo/design-system-react-components';
import { ApiModelType } from 'model/api';
import React, { FC, Fragment } from 'react';
import { RedirectionFormResultText } from 'ui/redirection-form/RedirectionFormResultText';
import { TextWithPhoneNumber } from 'ui/utils/TextWithPhoneNumber';
import { useChatUrl } from 'utils/chat/helpers';
import { sendGoToChatMessage } from 'utils/react/mobileapp-helpers';
import { useApiData, useReduxState, useTranslations } from 'utils/react/ui-context';
import { classNames, smallerButtonTextCss } from 'utils/styles/helpers';
import { notAsked } from 'utils/types/remoteData';

export const RedirectionFormChatResult: FC<{
  baseText: string | null;
  appText: string | null;
  button: ApiModelType['RedirectionFormButton'];
  replaceNumber?: string | null;
  resultDisabled: boolean;
  disabledText: string | null;
  onButtonClick: () => void;
}> = ({ baseText, appText, button, resultDisabled, disabledText, replaceNumber, onButtonClick }) => {
  const t = useTranslations();
  const chatUrl = useChatUrl();
  const embedInApp = useReduxState(state => state.location.embedInApp);
  const getChatDetails = useApiData('getChatDetails');

  const { chatId } = button;
  const chatData = chatId ? getChatDetails([chatId]) : notAsked;

  const loading =
    !!chatId && (chatData.status === 'REMOTE_DATA_LOADING' || chatData.status === 'REMOTE_DATA_NOT_ASKED');

  const buttonDisabled =
    chatData.status === 'REMOTE_DATA_FAILURE' ||
    (chatData.status === 'REMOTE_DATA_SUCCESS' && !chatData.data.queueOpen);
  const chatSuffix = chatData.status === 'REMOTE_DATA_SUCCESS' ? ` "${chatData.data.chatName}"` : '';
  const text =
    resultDisabled && disabledText ? disabledText : embedInApp && appText ? `${appText}${chatSuffix}` : baseText;

  return (
    <Fragment>
      <RedirectionFormResultText text={text} className={classNames(!!text && 'mb-1')} />
      {!resultDisabled &&
        (embedInApp || buttonDisabled || loading ? (
          <Button
            as="button"
            icon={loading ? 'Spinner' : 'ArrowRight'}
            className={classNames('w-full')}
            css={smallerButtonTextCss}
            onClick={loading ? undefined : onClick}
            disabled={buttonDisabled}
          >
            {button.text || button.defaultText}
          </Button>
        ) : (
          <Button
            as="a"
            href={chatUrl(chatId, null)}
            icon="ArrowRight"
            className={classNames('w-full')}
            css={smallerButtonTextCss}
            onClick={onClick}
          >
            {button.text || button.defaultText}
          </Button>
        ))}
      {buttonDisabled && (
        <div className={classNames('mt-0.75', 'mb-2', 'text-center')}>
          <TextWithPhoneNumber
            text={`${t.chat_is_closed}. ${t.call_our_booking_services} ${t.tel_only_number}.`}
            replaceNumber={replaceNumber}
          />
        </div>
      )}
    </Fragment>
  );

  function onClick() {
    if (embedInApp) {
      sendGoToChatMessage(chatId);
    }
    onButtonClick();
  }
};
