import reduce from 'lodash/reduce';
import { ApiModelType, AppointmentType } from 'model/api';
import { ConfigModelType } from 'model/config';
import { parse } from 'query-string';
import { AbTesting } from 'utils/analytics/ab-testing';
import { PiwikInstalationScript } from 'utils/analytics/pwik-instalation-script';
import { dateISOString } from 'utils/datetime/format';
import { isSameDay } from 'utils/datetime/helpers';
import { createLogger } from 'utils/logging/logging-utils';
import { BookingRoot } from 'utils/routes/routes';
import { v4 as uuidv4 } from 'uuid';

export type ServiceDescriptionLocation =
  | 'AppointmentSearch'
  | 'AppointmentCard'
  | 'AppointmentCard (chosen)'
  | 'AppointmentCard (booked)'
  | 'AppointmentCard (moved)';

export function createPiwikAnalyticsClient(
  config: ConfigModelType['FrontendConfig'],
  abTesting: AbTesting,
  testClient?: boolean,
) {
  if (!config.ANALYTICS_ID && !testClient) {
    throw new Error('Cannot instantiate Analytics without ANALYTICS_ID set');
  }
  const log = createLogger(config, 'PiwikAnalytics');
  const { embedInApp } = parse(window.location.search);
  // When embed in app, store a variable for the tag manager to know to categorise the analytics for the app instead
  if (embedInApp === 'true') {
    (window as any).MatomoBookingSource = 'app';
  }

  const _mtmInitial: any = ((window as any)._mtm = []);
  _mtmInitial.push({ start: new Date().getTime(), event: 'stg.start' });

  const _paqInitial: any = ((window as any)._paq = []);
  _paqInitial.push([
    'setCustomRequestProcessing',
    (req: string) => {
      // Redact guids and reservation webcodes from sent urls' paths
      const redacted = req
        .replace(
          /%2F[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/g,
          encodeURIComponent('/<guid>'),
        ) // mainly appointmentIds
        .replace(/%2FD[0-9]{8}/g, encodeURIComponent('/<webcode>')) // DH manual reservation webcode in path
        .replace(/%3DD[0-9]{8}/g, encodeURIComponent('=<webcode>')) // DH manual reservation webcode in param
        .replace(/%2F[0-9A-Z]{9}/g, encodeURIComponent('/<webcode>')) // AD6 webcode in path
        .replace(/%3D[0-9A-Z]{9}/g, encodeURIComponent('=<webcode>')) // AD6 webcode in param
        .replace(/%2F[0-9]{8}/g, encodeURIComponent('/<webcode>')) // "normal" DH webcode in path
        .replace(/%3D[0-9]{8}/g, encodeURIComponent('=<webcode>')); // "normal" DH webcode in param
      log('Sending analytics data', redacted, parse(redacted));
      return redacted;
    },
  ]);

  const isDebug =
    (window.location.href.match('stg_debug') || document.cookie.match('stg_debug')) &&
    !window.location.href.match('stg_disable_debug');
  document.cookie = `stg_debug=${isDebug ? 1 : ''}; path=/; expires=${new Date(
    Date.now() + (isDebug ? 14 : -1) * 24 * 60 * 60 * 1000,
  ).toUTCString()}`;

  const ppmsInitial: any = ((window as any).ppms = {});
  ['tm', 'cm'].forEach(v => {
    ppmsInitial[v] = ppmsInitial[v] || {};
    ppmsInitial[v].api =
      ppmsInitial[v].api ||
      function(name: any, ...params: any[]) {
        if (typeof name === 'string') {
          (window as any)._mtm.push({
            event: `ppms.${v}:${name}`,
            parameters: params,
          });
        }
      };
  });

  // Do not use script when init for testing
  if (!testClient) {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = PiwikInstalationScript();
    document.head.appendChild(scriptElement);
  }

  let initialPageView = true;
  const trackPageView = () => {
    const _paq = (window as any)._paq;
    // Do not track the initial page view manually as it is tracked automatically
    if (initialPageView) {
      initialPageView = false;
      log('Skipping sending manual page view for initial page load');
      return;
    }
    const {
      location: { pathname },
      title,
    } = document;
    log('Sending manual page view, data before redacts:', { title, pathname });
    // The paths and titles change dynamically, so we need to explicitly set them
    _paq.push(['setCustomUrl', pathname]);
    _paq.push(['setDocumentTitle', title]);
    _paq.push(['trackPageView']);
  };

  const mtmPush = (logMessage: string, data: Record<string, any>) => {
    const _mtm = (window as any)._mtm;
    const enrichedData = {
      ...data,
      abGroups: reduce(
        abTesting.groups,
        (l, g, k) => {
          if (g) {
            l.push(`${k}:${g}`);
          }
          return l;
        },
        [] as string[],
      ).join(';'), // e.g. "exampleChange:B1;testThing:A2"
    };
    log(logMessage, enrichedData);
    _mtm.push(enrichedData);
  };

  // When the analytics is initialized, send info on set A/B groups
  mtmPush('Sending A/B testing groups initial data', { event: 'setAbGroups' });

  const trackBookingProcess = (
    bookingRoot: BookingRoot,
    stepName:
      | 'BookingStep2' // selecting a free appointment
      | 'BookingStep3Fail' // could not find the selected free appointment or not available
      | 'BookingStep3Interrupt' // the user interrupted at booking preview page
      | 'BookingStep3' // continuing to temporary booking from preview page (i.e. already logged in)
      | 'BookingStep3PatientInterrupt' // the booking flow was interrupted at booking patient page
      | 'BookingStep3Patient' // continuing to temporary booking from patient page (i.e. needed login and/or enter delegation info)
      | 'BookingStep4Success' // temporary booking created successfully
      | 'BookingStep4Fail' // temporary booking creation failed
      | 'BookingStep5Interrupt' // the booking flow was interrupted at booking confirm page
      | 'BookingStep5' // continuing to confirm booking
      | 'BookingStep6Success' // confirmed booking successfully
      | 'BookingStep6Fail', // failed to confirm booking
    bookingForOther: boolean,
    appointment:
      | 'no-appointment-data'
      | {
          dateTime: ApiModelType['Appointment']['dateTime'];
          duration: ApiModelType['Appointment']['duration'];
          service: ApiModelType['Appointment']['service'];
          specialist: ApiModelType['Appointment']['specialist'];
          clinic?: ApiModelType['ClinicBase'] | ApiModelType['Clinic'];
          appointmentType?: AppointmentType | 'callrequest';
          appointmentTypes?: AppointmentType[];
        },
    additionalFields: {
      appointmentResultType?:
        | 'mainResult'
        | 'alternativeDayResult'
        | 'alternativeVideoResult'
        | 'alternativeLocationResult'
        | 'additionalDaysResult';
      appointmentType?: AppointmentType;
      promotionalCode?: string;
      confirmationType?: 'email' | 'sms';
      revenue?: number;
      patient?: 'self' | 'child' | 'other';
      moveBooking: boolean;
      alternativeServiceId: string | undefined;
    },
    estimatedPrice?: number | undefined,
  ) => {
    const baseData = {
      event: stepName,
      quantity: 1,
      bookingType: bookingRoot,
      vicarious: `${bookingForOther}`,
      price: estimatedPrice ?? 140, // static average until we have an actual price
      ...additionalFields,
      moveBooking: additionalFields.moveBooking ? 'true' : undefined, // override as string for piwik to understand
      bookingFlowDentalRecall: additionalFields.moveBooking && bookingRoot === 'dental' ? 'true' : undefined,
    };
    let data;
    if (appointment !== 'no-appointment-data') {
      const { dateTime, duration, service, specialist, clinic, appointmentType, appointmentTypes } = appointment;
      data = {
        date: dateTime.slice(0, 10), // yyyy-mm-dd
        time: dateTime.slice(11, 16), // HH:MM
        serviceDuration: duration,
        service: service ? service.serviceName : undefined,
        serviceId: service ? service.serviceId : undefined,
        specialist:
          specialist.specialistId === null ? specialist.fullName : `${specialist.firstName} ${specialist.lastName}`,
        specialistId: specialist.specialistId || undefined,
        clinic: clinic ? clinic.name : undefined,
        clinicId: clinic ? clinic.clinicId : undefined,
        city: clinic && 'city' in clinic ? clinic.city : undefined,
        appointmentTypes,
        appointmentType: appointmentTypes ? undefined : appointmentType,
        ...baseData,
      };
    } else {
      data = baseData;
    }
    if (stepName === 'BookingStep6Success') {
      // the step that indicates a completed purchase
      data = {
        ...data,
        revenue: data.price, // no discounts available, only one "product"
        purchaseId: uuidv4(),
      };
    }
    mtmPush('Sending booking process analytics', data);
  };

  const trackPartnerBookingProcess = (
    bookingRoot: BookingRoot,
    stepName:
      | 'BookingStep7Insurance'
      | 'BookingStep7InsuranceSkip'
      | 'BookingStep7InsuranceSuccess'
      | 'BookingStep7InsuranceFail'
      | 'BookingStep7PublicInvoicing'
      | 'BookingStep7PublicInvoicingSkip'
      | 'BookingStep7PublicInvoicingSuccess'
      | 'BookingStep7PublicInvoicingFail'
      | 'BookingStep7MigriInvoicing'
      | 'BookingStep7MigriInvoicingSkip'
      | 'BookingStep7MigriInvoicingSuccess'
      | 'BookingStep7MigriInvoicingFail',
  ) => {
    const data = { event: stepName, bookingType: bookingRoot };
    mtmPush('Sending partner booking analytics', data);
  };

  const trackManageBooking = (type: 'cancel' | 'change' | 'move') => {
    const data = {
      event: 'onlineBookingClicktoManageBooking',
      category: 'online booking',
      action: 'user interaction',
      name: `click to ${type} booking`,
    };
    mtmPush('Sending manage booking analytics', data);
  };

  const trackEditBooking = (booking: ApiModelType['Booking'], type: 'submit' | 'success', toType: AppointmentType) => {
    const { appointmentType, service, clinic } = booking;
    const data = {
      event: type === 'submit' ? 'onlineBookingChangeBooking' : 'onlineBookingChangeBookingSuccess',
      category: 'online booking',
      action: type === 'submit' ? 'user interaction' : 'auto event',
      name: `change from ${appointmentType} to ${toType}`,
      service: service ? service.serviceName : undefined,
      clinic: clinic ? clinic.name : undefined,
      city: clinic && 'city' in clinic ? clinic.city : undefined,
      vicarious: 'false', // edit not enabled for bookings for other
    };
    mtmPush('Sending booking edit analytics', data);
  };

  const trackCancelBooking = (
    booking: ApiModelType['Booking'] | ApiModelType['CallRequestBookingBase'],
    type: 'submit' | 'success',
    bookingForOther: boolean,
  ) => {
    const serviceName = 'service' in booking && booking.service ? booking.service.serviceName : undefined;
    const appointmentType = 'appointmentType' in booking ? booking.appointmentType : 'callrequest';
    const clinic = 'clinic' in booking ? booking.clinic : undefined;
    const data = {
      event: type === 'submit' ? 'onlineBookingCancelBooking' : 'onlineBookingCancelBookingSuccess',
      category: 'online booking',
      action: type === 'submit' ? 'user interaction' : 'auto event',
      name: `cancel booking ${appointmentType}`,
      service: serviceName,
      clinic: clinic ? clinic.name : undefined,
      city: clinic && 'city' in clinic ? clinic.city : undefined,
      vicarious: `${bookingForOther}`,
    };
    mtmPush('Sending booking cancel analytics', data);
  };

  const trackAppointmentsSearch = (
    results: Array<ApiModelType['Appointment']> | ApiModelType['AppointmentsResult'] | 'fail',
    bookingRoot: BookingRoot,
    serviceName: string,
    locationName: string,
    date: number,
    hasOtherFilters: boolean,
    moveBooking: boolean,
    alternativeServiceId: string | undefined,
  ) => {
    const sharedData = {
      date: dateISOString(date),
      bookingType: bookingRoot,
      location: locationName,
      specialistOrService: serviceName,
      alternativeServiceId,
      otherFilters: `${hasOtherFilters}`,
      moveBooking: moveBooking ? 'true' : undefined,
      bookingFlowDentalRecall: moveBooking && bookingRoot === 'dental' ? 'true' : undefined,
    };
    const data =
      results === 'fail'
        ? { event: 'onlineBookingSearchFail', ...sharedData }
        : {
            event: 'onlineBookingSearch',
            searchResults: `${
              (Array.isArray(results) ? results : results.availableAppointments).filter(r =>
                isSameDay(r.dateTime, date),
              ).length
            }`,
            ...sharedData,
          };
    mtmPush('Sending appointments search analytics', data);
  };

  const trackAlternativeServicesAppointmentsSearch = (
    serviceName: string,
    bookingRoot: BookingRoot,
    locationName: string,
    date: number,
    hasOtherFilters: boolean,
  ) => {
    const data = {
      event: 'onlineBookingAlternativeServicesSearch',
      date: dateISOString(date),
      bookingType: bookingRoot,
      location: locationName,
      specialistOrService: serviceName,
      otherFilters: `${hasOtherFilters}`,
    };
    mtmPush('Sending alternative services appointments search analytics', data);
  };

  const trackOccupationalLanderSelection = (
    type: 'card' | 'popular' | 'search' | 'team' | 'popup',
    serviceName: string,
  ) => {
    const data = {
      event: 'occupationalHealthSelect',
      category: 'online booking',
      action: 'user interaction',
      name: `occupationalLander: ${type}: ${serviceName}`,
    };
    mtmPush('Sending occupational lander selection analytics', data);
  };

  const trackOccupationalDisallowed = () => {
    const data = { event: 'occupationalReservationNotAvailable' };
    mtmPush('Sending occupational booking not available analytics', data);
  };

  const trackOccupationalPersonalization = (props: { type: 'show' } | { type: 'choice'; choice: 'yes' | 'no' }) => {
    let data;
    if (props.type === 'show') {
      data = {
        event: 'occupationalPersonalizationShow',
        category: 'online booking',
        action: 'auto event',
        name: 'corp personalized booking consent show',
      };
    } else {
      data = {
        event: 'occupationalPersonalizationSelect',
        category: 'online booking',
        action: 'user interaction',
        name: `occupationalPersonalization: ${props.choice}`,
      };
    }
    mtmPush('Sending occupational personalization analytics', data);
  };

  const trackOccupationalRedirectionForm = (
    id: number,
    props:
      | { type: 'show' }
      | {
          type: 'choice' | 'chat' | 'booking' | 'link' | 'back' | 'deadend';
          navigationStr?: string;
          actionParams?: string;
        },
  ) => {
    const occupationalRedirectionFormId = `${id}`;
    let data;
    if (props.type === 'show') {
      data = {
        event: 'occupationalRedirectionFormShow',
        name: `occupationalRedirectionFormShow - ${id}`,
        category: 'online booking',
        action: 'auto event',
        occupationalRedirectionFormId,
      };
    } else {
      data = {
        event: 'occupationalRedirectionFormSelect',
        category: 'online booking',
        action: props.type === 'deadend' ? 'auto event' : 'user interaction',
        occupationalRedirectionFormId,
        name: `occupationalRedirectionForm: ${
          props.type === 'choice' || props.type === 'back' || props.type === 'deadend'
            ? props.type
            : `action: ${props.type}`
        }`,
        occupationalRedirectionFormNavigationStr: props.navigationStr,
        occupationalRedirectionFormActionParams: props.type === 'deadend' ? undefined : props.actionParams,
      };
    }
    mtmPush('Sending occupational redirection form analytics', data);
  };

  const trackCoronaVaccineGuide = (
    id: number | undefined,
    props:
      | { type: 'show' }
      | { type: 'start'; choice: string }
      | {
          type: 'choice' | 'chat' | 'booking' | 'link' | 'back' | 'deadend';
          choices?: string[];
          actionParams?: string;
        },
  ) => {
    const commonData = {
      event: 'coronaVaccineFlow',
      category: 'online booking',
      occupationalRedirectionFormId: id ? `${id}` : undefined,
    };
    let data;
    if (props.type === 'show') {
      data = { ...commonData, action: 'auto event', name: 'show' };
    } else if (props.type === 'start') {
      data = { ...commonData, action: 'user interaction', name: `start ${props.choice}` };
    } else {
      data = {
        ...commonData,
        action: props.type === 'deadend' ? 'auto event' : 'user interaction',
        name: `${props.type === 'chat' || props.type === 'booking' || props.type === 'link' ? `action: ` : ''}${
          props.type
        }`,
        occupationalRedirectionFormChoices: props.choices ? props.choices.join(' | ') : undefined,
        occupationalRedirectionFormActionParams: props.actionParams,
      };
    }
    mtmPush('Sending corona vaccine guide analytics', data);
  };

  const trackHtaTeamForm = (
    id: number,
    props:
      | { type: 'show' }
      | { type: 'skip' }
      | {
          type: 'choice' | 'chat' | 'booking' | 'link' | 'back' | 'deadend';
          choices?: string[];
          actionParams?: string;
        },
  ) => {
    const commonData = {
      event: 'htaTeamForm',
      category: 'online booking',
      occupationalRedirectionFormId: id ? `${id}` : undefined,
    };
    let data;
    if (props.type === 'show') {
      data = { ...commonData, action: 'auto event', name: 'show' };
    } else if (props.type === 'skip') {
      data = { ...commonData, action: 'user interaction', name: 'skip to booking' };
    } else {
      data = {
        ...commonData,
        action: props.type === 'deadend' ? 'auto event' : 'user interaction',
        name: `${props.type === 'chat' || props.type === 'booking' || props.type === 'link' ? `action: ` : ''}${
          props.type
        }`,
        occupationalRedirectionFormChoices: props.choices ? props.choices.join(' | ') : undefined,
        occupationalRedirectionFormActionParams: props.actionParams,
      };
    }
    mtmPush('Sending HTA team form analytics', data);
  };

  const trackGuideModal = (
    eventName: 'shown' | 'testShown' | string,
    bookingRoot: BookingRoot | undefined,
    bookingForOther: boolean,
  ) => {
    const data =
      eventName === 'shown' || eventName === 'testShown'
        ? {
            event: 'koronaPopUp',
            category: 'element visibility',
            action: 'coronamodal',
            name: eventName === 'shown' ? 'element view new booking' : 'element view coronatest',
            bookingType: bookingRoot,
            vicarious: `${bookingForOther}`,
          }
        : {
            event: 'koronaPopUp',
            category: 'coronamodal',
            action: 'click',
            name: eventName,
            bookingType: bookingRoot,
            vicarious: `${bookingForOther}`,
          };
    mtmPush('Sending guide modal analytics', data);
  };

  const trackFrontPageClick = (name: string) => {
    const data = {
      event: 'onlineBookingFrontCTA',
      category: 'online booking',
      action: 'user interaction',
      name: `fp click to ${name}`,
    };
    mtmPush('Sending front page analytics', data);
  };

  const trackRelaLinkClick = (type: 'banner', bookingRoot: BookingRoot | undefined, serviceId?: string) => {
    const data = {
      event: 'onlineBookingRelaLinkClick',
      category: 'online booking',
      action: 'user interaction',
      name: `${type} click to Rela`,
      bookingType: bookingRoot,
      serviceId,
    };
    mtmPush('Sending Rela link click analytics', data);
  };

  const trackRehabLinkClick = (type: 'banner', bookingRoot: BookingRoot | undefined, serviceId?: string) => {
    const data = {
      event: 'onlineBookingRehabLinkClick',
      category: 'online booking',
      action: 'user interaction',
      name: `${type} click to Rehab`,
      bookingType: bookingRoot,
      serviceId,
    };
    mtmPush('Sending Rehab link click analytics', data);
  };

  const trackClickManageBooking = (booking: ApiModelType['Booking'] | ApiModelType['CallRequestOwnBooking']) => {
    const service = 'service' in booking ? booking.service : null;
    const clinic = 'clinic' in booking ? booking.clinic : null;
    const specialist = booking.specialist;
    const data = {
      event: 'onlineBookingManageBooking',
      category: 'online booking',
      action: 'user interaction',
      name: 'select booking for edit',
      service: service ? service.serviceName : undefined,
      serviceId: service ? service.serviceId : undefined,
      clinic: clinic ? clinic.name : undefined,
      city: clinic && 'city' in clinic ? clinic.city : undefined,
      appointmentType: 'appointmentType' in booking ? booking.appointmentType : 'callrequest',
      specialist:
        specialist.specialistId === null ? specialist.fullName : `${specialist.firstName} ${specialist.lastName}`,
      specialistId: specialist.specialistId || undefined,
    };
    mtmPush('Sending my own appoinments listing click edit analytics', data);
  };

  const trackOpenSpecialistModal = (
    bookingRoot: BookingRoot | undefined,
    specialist: ApiModelType['SpecialistBase'],
    fromAppointmentCard?: boolean,
  ) => {
    const data = {
      event: fromAppointmentCard ? 'openAppointmentSpecialistDetails' : 'openSpecialistDetailCard',
      category: 'online booking',
      action: 'user interaction',
      name: fromAppointmentCard ? 'open appointment specialist details' : 'open specialist details',
      bookingType: bookingRoot,
      specialist: `${specialist.firstName} ${specialist.lastName}`,
      specialistId: specialist.specialistId || undefined,
    };
    mtmPush(`Sending ${fromAppointmentCard ? 'appointment ' : ''}specialist info modal open analytics`, data);
  };

  const trackOpenClinicModal = (bookingRoot: BookingRoot | undefined, clinic: ApiModelType['ClinicBase']) => {
    const data = {
      event: 'openAppointmentClinicDetails',
      category: 'online booking',
      action: 'user interaction',
      name: 'open appointment clinic details',
      bookingType: bookingRoot,
      clinic: clinic.name,
      clinicId: clinic.clinicId,
    };
    mtmPush('Sending clinic info modal open analytics', data);
  };

  const trackServiceBannerClick = (
    bookingRoot: BookingRoot | undefined,
    currentSpecialistOrServiceName: string,
    serviceName: string,
    type: 'children' | 'fokus' | 'mental',
    fokusUnit?: string,
  ) => {
    const data = {
      bookingType: bookingRoot,
      event:
        type === 'children'
          ? 'onlineBookingMarsuPrompt'
          : type === 'fokus'
          ? `onlineBookingFokusPrompt`
          : 'onlineBookingMentalPrompt',
      category: 'online booking',
      action: 'user interaction',
      name: `prompt click to ${serviceName}${fokusUnit ? ` ${fokusUnit}` : ''}`,
      specialistOrService: currentSpecialistOrServiceName,
    };
    mtmPush('Sending serviceBanner analytics', data);
  };

  const trackServiceDescriptionExpandClick = (
    bookingRoot: BookingRoot | undefined,
    serviceName: string,
    expandAction: 'open' | 'close',
    elementLocation: ServiceDescriptionLocation | undefined,
  ) => {
    const data = {
      bookingType: bookingRoot,
      event: 'onlineBookingOpenServiceDetails',
      category: 'online booking',
      action: 'user interaction',
      name: `click to ${expandAction} ${serviceName} at ${elementLocation}`,
    };
    mtmPush('Sending toggle serviceDescription analytics', data);
  };

  const trackUserIsLoggedIn = (loggedIn: boolean) => {
    const _paq = (window as any)._paq;
    _paq.push(['setCustomDimension', 38, loggedIn ? 'true' : 'false']);
  };

  const trackRemoteAppoinmentsAvailableBanner = (bookingRoot: BookingRoot | undefined) => {
    const data = {
      bookingType: bookingRoot,
      category: 'online booking',
      action: 'user interaction',
      event: 'remoteAppoinmentsAvailableBannerClicked',
      name: 'remote appointments available banner clicked',
    };
    mtmPush('Sending trackRemoteAppoinmentsAvailableBanner analytics', data);
  };

  const trackThankYouCheckYourCustomerInformation = (bookingRoot: BookingRoot | undefined) => {
    const data = {
      bookingType: bookingRoot,
      category: 'online booking',
      action: 'user interaction',
      event: 'thankYouCheckYourCustomerInformation',
      name: 'thank you check your customer information',
    };
    mtmPush('Sending trackRemoteAppoinmentsAvailableBanner analytics', data);
  };

  const trackThankYouGoToTerveystaloComFrontPage = (bookingRoot: BookingRoot | undefined) => {
    const data = {
      bookingType: bookingRoot,
      category: 'online booking',
      action: 'user interaction',
      event: 'thankYouGoToTerveystaloComFrontPage',
      name: 'thank you go to terveystalo.com front page',
    };
    mtmPush('Sending trackThankYouGoToTerveystaloComFrontPage analytics', data);
  };

  const trackThankYouGoToTerveystaloForTeens = (bookingRoot: BookingRoot | undefined) => {
    const data = {
      bookingType: bookingRoot,
      category: 'online booking',
      action: 'user interaction',
      event: 'thankYouGoToTerveystaloForTeens',
      name: 'thank you go to terveystalo for teens',
    };
    mtmPush('Sending trackThankYouGoToTerveystaloForTeens analytics', data);
  };

  const trackGiftCardSuccess = (bookingRoot: BookingRoot | undefined) => {
    const data = {
      bookingType: bookingRoot,
      category: 'online booking',
      action: 'auto event',
      event: 'giftCardSuccess',
      name: 'giftcard used successfully',
    };
    mtmPush('Sending trackGiftCardSuccess analytics', data);
  };

  const trackInsuranceCompanySelected = (
    icName: string,
    additional?: boolean,
    hasPermit?: boolean,
    accept?: boolean,
  ) => {
    const data = {
      event: 'insuranceCompanySelection',
      category: 'online booking',
      action: 'user interaction',
      name: `click to select ${icName} at insurance company customer path`,
      direct_claim_permit: additional ? (hasPermit ? 'yes' : 'no') : undefined,
      permit_pin_to_ic: additional ? (accept ? 'yes' : 'no') : undefined,
    };
    mtmPush('Sending trackInsuranceCompanySelected analytics', data);
  };

  const trackDirectCompensationPreview = (service: string) => {
    const data = {
      event: 'icChooseApprovedService',
      category: 'online booking',
      action: 'user interaction',
      name: `direct claim service ${service} chosen`,
    };
    mtmPush('Sending trackDirectCompensationPreview analytics', data);
  };

  const trackIcChooseAppointmentType = (type: 'chat' | 'online booking') => {
    const data = {
      event: 'insuranceCompanySelection',
      category: 'online booking',
      action: 'user interaction',
      name: `direct claim appointment type ${type} chosen`,
    };
    mtmPush('Sending trackIcChooseAppointmentType analytics', data);
  };

  const trackDirectCompensationError = (type: 'noInsurance' | 'noResults' | 'TechnicalError', icName: string) => {
    const data = {
      event: type === 'noInsurance' ? 'icNoContract' : type === 'noResults' ? 'icNoDirectClaim' : 'icTechnicalError',
      category: 'online booking',
      action: 'auto event',
      name:
        type === 'noInsurance'
          ? `no contract with the insurance company ${icName}`
          : type === 'noResults'
          ? `no direct claim approval with the insurance company ${icName}`
          : 'technical error occured',
    };
    mtmPush('Sending trackDirectCompensationError analytics', data);
  };

  return {
    trackPageView,
    trackBookingProcess,
    trackManageBooking,
    trackEditBooking,
    trackCancelBooking,
    trackAppointmentsSearch,
    trackAlternativeServicesAppointmentsSearch,
    trackOccupationalLanderSelection,
    trackPartnerBookingProcess,
    trackOccupationalDisallowed,
    trackOccupationalPersonalization,
    trackOccupationalRedirectionForm,
    trackCoronaVaccineGuide,
    trackHtaTeamForm,
    trackGuideModal,
    trackFrontPageClick,
    trackClickManageBooking,
    trackOpenSpecialistModal,
    trackOpenClinicModal,
    trackServiceBannerClick,
    trackRelaLinkClick,
    trackRehabLinkClick,
    trackServiceDescriptionExpandClick,
    trackUserIsLoggedIn,
    trackRemoteAppoinmentsAvailableBanner,
    trackThankYouCheckYourCustomerInformation,
    trackThankYouGoToTerveystaloComFrontPage,
    trackThankYouGoToTerveystaloForTeens,
    trackGiftCardSuccess,
    trackInsuranceCompanySelected,
    trackDirectCompensationPreview,
    trackIcChooseAppointmentType,
    trackDirectCompensationError,
  };
}
