import { TranslationMap } from 'translations';
import { BookingRoot } from 'utils/routes/routes';
import { assertExhausted } from 'utils/types/misc';

export const getBookingRootTitle = (bookingRoot: BookingRoot, t: TranslationMap) => {
  switch (bookingRoot) {
    case 'privateCustomer':
    case 'privateCustomerV2':
      return t.private_customer_booking;
    case 'occupationalHealthcare':
      return t.occupational_healthcare_booking;
    case 'dental':
      return t.dental_care_booking;
    case 'insurance':
      return t.insurance_booking;
    case 'voucher':
      return t.voucher_booking;
    case 'terveysmestari':
    case 'fenniahoitaja':
    case 'terveyshelppi':
      return t.insurance_partner_booking;
    case 'publicPartner':
      return 'Julkisten palveluiden ajanvaraus';
    case 'htaTeam':
      return 'HTA ajanvaraus';
    case 'migri':
      return 'Migri ajanvaraus';
    default:
      assertExhausted(bookingRoot);
      return '';
  }
};

export const getCustomerTypeText = (bookingRoot: BookingRoot, t: TranslationMap) => {
  switch (bookingRoot) {
    case 'privateCustomer':
    case 'privateCustomerV2':
      return t.private_customer;
    case 'occupationalHealthcare':
      return t.occupational_healthcare_customer;
    case 'dental':
      return t.dental_care;
    case 'insurance':
      return t.insurance;
    case 'voucher':
      return t.voucher_from_municipality;
    case 'terveysmestari':
    case 'fenniahoitaja':
    case 'terveyshelppi':
      return t.insurance_partner_booking;
    case 'publicPartner':
      return 'Julkisten palveluiden ajanvaraus';
    case 'htaTeam':
      return t.hta;
    case 'migri':
      return 'Migri ajanvaraus';
    default:
      assertExhausted(bookingRoot);
      return '';
  }
};
