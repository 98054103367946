import { localized } from 'api/AdaApiClient';
import {
  GiftCardValidateStatus,
  DefaultApiFactory as UxApiFactory,
  Language as UxApiLanguage,
} from 'api/generated/UxApi/api';
import axios from 'axios';
import { ApiModel, GiftCardValidationResult } from 'model/api';
import { ConfigModelType } from 'model/config';
import { AuthToken } from 'reducers/auth';
import { Language } from 'translations/generated/translation-languages';
import { Analytics } from 'utils/analytics/analytics-utils';
import { createLogger } from 'utils/logging/logging-utils';
import {
  defined,
  definedNotNull,
  guardedModelArrayConversion,
  guardedModelConversion,
  guardedStringArrayConversion,
} from 'utils/types/api';
import { assertExhausted } from 'utils/types/misc';

// Dummy for openAPI typing
const isJsonMime = () => {
  return true;
};

// Creates low-level bindings to the UX API.
export function createUxApiClient(config: ConfigModelType['FrontendConfig'], axiosImpl = axios, analytics: Analytics) {
  const log = createLogger(config, 'UxApiClient');
  const authConfig = (accessToken?: string) => ({ apiKey: accessToken || '', isJsonMime });
  const api = (accessToken?: string) => UxApiFactory(authConfig(accessToken), config.API_ENDPOINT_UXAPI, axiosImpl);
  return {
    async getOccupationalData(lang: Language, token: AuthToken) {
      return api(token.accessToken)
        .getOccupationalData(langToUxApiLanguage(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.OccupationalData, ({ data }) => ({
            employerCode: definedNotNull(data.EmployerCode, 'employerCode'),
            companyName: definedNotNull(data.CompanyName, 'companyName'),
            mainClinicCode: data.PeriodicHealthExaminationClinic || null,
            doctorCodes: definedNotNull(data.ResponsibleDoctorDynamicHealthCodes, 'doctorCodes'),
            nurseCodes: definedNotNull(data.ResponsibleNurseDynamicHealthCodes, 'nurseCodes'),
            physiotherapistCodes: definedNotNull(
              data.ResponsiblePhysiotherapistDynamicHealthCodes,
              'physiotherapistCodes',
            ),
            psychologistCodes: definedNotNull(data.ResponsiblePsychologistDynamicHealthCodes, 'psychologistCodes'),
            generalDoctorCodes: definedNotNull(data.ResponsibleGeneralDoctorDynamicHealthCodes, 'generalDoctorCodes'),
            nutritionistCodes: definedNotNull(data.ResponsibleNutritionistDynamicHealthCodes, 'nutritionistCodes'),
            socialWorkerCodes: definedNotNull(data.ResponsibleSocialWorkerDynamicHealthCodes, 'socialWorkerCodes'),
            webReservationDenied: definedNotNull(data.WebReservationDeniedByEmployer, 'webReservationDenied'),
            redirectionFormId: definedNotNull(data.RedirectionFormID, 'redirectionFormId'),
            redirectionForm: data.redirectionForm,
            personalizedBooking: !!data.HasPersonalizedBooking,
            selfTriageVisible: data.selfTriageVisible ?? false, // use false (no redirection to htia) as a default backup.
            selfTriageSkips: data.selfTriageSkips,
          })),
        );
    },
    async getOccupationalContract(_: Language, token: AuthToken) {
      return api(token.accessToken)
        .getOccupationalContract()
        .then(
          guardedModelConversion(analytics, log, ApiModel.OccupationalContract, ({ data }) => {
            const services = data.services ?? [];
            const officeHoursOnly = data.officeHoursOnly ?? false;
            return {
              services: services.map(service => ({
                serviceId: definedNotNull(service.serviceId, 'serviceId'),
                included: service.included ?? undefined,
                paymentCommitmentRequired: service.paymentCommitmentRequired ?? undefined,
                directAllowed: service.directAllowed ?? undefined,
                acceptedSpecialistReferrals: service.acceptedSpecialistReferrals ?? [],
                acceptedPermissions: service.acceptedPermissions ?? [],
              })),
              officeHoursOnly,
            };
          }),
        );
    },
    async getHtaTeamForm(lang: Language) {
      return api()
        .getHtaTeamForm(langToUxApiLanguage(lang))
        .then(guardedModelConversion(analytics, log, ApiModel.RedirectionForm, ({ data }) => data));
    },
    async validateGiftCardCode(_lang: Language, serviceId: string, code: string, token: AuthToken) {
      return api(`Bearer ${token.accessToken}`)
        .validateGiftCard({ serviceId, code })
        .then(
          guardedModelConversion(analytics, log, ApiModel.ValidateGiftCardResponse, ({ data }) => ({
            status: giftCardValidationResultToOwn(data.status),
            code: data.code ?? undefined,
            validServiceId: data.validForServiceId ?? undefined,
          })),
        );
    },
    async getNewFromOldBookingParams(params: string) {
      return api()
        .transformOldToNewFilterParams(params)
        .then(({ data }) => data)
        .catch(() => ({})); // no-op on failure
    },
    async getSpecialistsWithDhCodes(lang: Language, dhCodes: string[]) {
      return api()
        .getSpecialistsWithDhCodes(dhCodes.join(','), langToUxApiLanguage(lang))
        .then(res =>
          guardedModelArrayConversion(analytics, log, res.data, ApiModel.Specialist, data => ({
            specialistId: defined(data.Id, 'specialistId'),
            firstName: definedNotNull(data.FirstName, 'firstName'),
            lastName: definedNotNull(data.LastName, 'lastName'),
            imageUri: data.ImageUri || null,
            specialistTitle: localized(lang, data.Title, 'specialistTitle'),
            isDental: !data.SubsystemIDs || !!data.SubsystemIDs.Assisdent,
          })),
        );
    },
    async getPopularServices() {
      return api()
        .getPopularServices()
        .then(
          guardedModelConversion(analytics, log, ApiModel.PopularServices, ({ data }) => ({
            // Transform all seprately to drop invalid items instead of marking all return data invalid
            primary: guardedStringArrayConversion(analytics, log, data.primary, d => d),
            occupational: guardedStringArrayConversion(analytics, log, data.occupational, d => d),
            dental: guardedStringArrayConversion(analytics, log, data.dental, d => d),
            child: guardedStringArrayConversion(analytics, log, data.child, d => d),
            sort: guardedStringArrayConversion(analytics, log, data.sort, d => d),
            imaging: guardedModelArrayConversion(analytics, log, data.imaging, ApiModel.PopularServicesImaging, d => d),
            hidden: guardedStringArrayConversion(analytics, log, data.hidden, data => data),
            fokus: guardedModelArrayConversion(analytics, log, data.fokus, ApiModel.PopularServicesFokus, d => d),
            rela: guardedStringArrayConversion(analytics, log, data.rela, data => data),
            rehab: guardedStringArrayConversion(analytics, log, data.rehab, data => data),
            alternative: guardedModelArrayConversion(
              analytics,
              log,
              data.alternative,
              ApiModel.PopularServicesAlternative,
              d => d,
            ),
          })),
        );
    },
  };
}

function langToUxApiLanguage(lang: Language) {
  switch (lang) {
    case 'fi':
      return UxApiLanguage.Fi;
    case 'sv':
      return UxApiLanguage.Sv;
    case 'en':
      return UxApiLanguage.En;
    default:
      assertExhausted(lang);
      return undefined;
  }
}

function giftCardValidationResultToOwn(status: GiftCardValidateStatus): GiftCardValidationResult | undefined {
  switch (status) {
    case 'ok':
      return 'ok';
    case 'notFound':
      return 'notFound';
    case 'expired':
      return 'expired';
    case 'notForGivenServiceId':
      return 'notForGivenService';
    case 'alreadyUsed':
      return 'alreadyUsed';
    case 'notAProductGiftCard':
      return 'notProductGiftcard';
    case 'notValidForCurrentUser':
      return 'notValidForGivenUser';
    case 'notValid':
      return 'notValid';
    case 'throttling':
      return 'throttling';
    default:
      assertExhausted();
      return undefined;
  }
}
