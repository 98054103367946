import { ApiModelType } from 'model/api';
import { Action } from 'utils/redux/actions';

type OneWelcomeToken = { accessToken: string; type: 'oneWelcome' };
type WeakToken = { accessToken: string; type: 'weak' };
export type AuthToken = OneWelcomeToken | WeakToken;
export type AuthError = {
  type: 'token_invalid' | 'oneWelcome_error' | 'online_booking_denied' | 'other';
};

export const initState:
  | { status: 'NOTHING'; loadingAuthChange?: boolean }
  | { status: 'ERROR'; error: AuthError }
  | { status: 'TOKEN_RETRIEVED'; token: AuthToken }
  | { status: 'LOADING_USER_DATA'; token: AuthToken }
  | { status: 'USER_NOT_REGISTERED'; data: ApiModelType['NotRegisteredUser'] | null; token: AuthToken }
  | { status: 'LOGGED_IN'; user: ApiModelType['User']; token: AuthToken } = { status: 'NOTHING' };

export default function reducer(state = initState, action: Action) {
  switch (action.type) {
    case 'authError':
      return { status: 'ERROR' as const, error: action.error };
    case 'setToken':
      return { status: 'TOKEN_RETRIEVED' as const, token: action.token };
    case 'userDataLoading':
      return { status: 'LOADING_USER_DATA' as const, token: action.token };
    case 'newUser':
      return { status: 'USER_NOT_REGISTERED' as const, data: action.data, token: action.token };
    case 'login':
      return { status: 'LOGGED_IN' as const, user: action.user, token: action.token };
    case 'logout':
      return { status: 'NOTHING' as const };
    case 'loadingAuthChange':
      return { status: 'NOTHING' as const, loadingAuthChange: true };
    case 'updateToken':
      if ('token' in state) {
        return { ...state, token: action.token };
      }
      return { status: 'TOKEN_RETRIEVED' as const, token: action.token };
    default:
      return state;
  }
}
