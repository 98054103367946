/** @jsxImportSource @emotion/react */
import React, { FC, Fragment, lazy, Suspense } from 'react';
import { Footer } from 'ui/header-and-footer/Footer';
import { Header } from 'ui/header-and-footer/Header';
import { FeedbackBubble } from 'ui/misc/FeedbackBubble';
import { Notices } from 'ui/misc/Notices';
import ErrorBoundary from 'ui/utils/ErrorBoundary';
import { LoadingPageContent } from 'ui/utils/LoadingPageContent';
import { useEnvironment, useReduxState, useTranslations, useUiContext } from 'utils/react/ui-context';
import { isPartnerRoot } from 'utils/routes/routes';
import { classNames, maxWidthContainer } from 'utils/styles/helpers';

const FloatingNotesBox = lazy(() => import('ui/misc/FloatingNotesBox'));
const PartnerAccessWrapper = lazy(() => import('ui/partner/PartnerAccessWrapper'));

export const PageWrapper: FC<{
  headerTitleOverride?: string;
  withNotice?: boolean;
  noMaxWidthMain?: boolean;
  children?: React.ReactNode;
}> = ({ headerTitleOverride, withNotice, noMaxWidthMain, children }) => {
  const t = useTranslations();
  const { bookingRoot, embedInApp } = useReduxState(state => state.location);
  const isPartner = isPartnerRoot(bookingRoot);
  const analytics = useUiContext().analytics;
  const environment = useEnvironment();
  const auth = useReduxState(state => state.auth);

  return (
    <Fragment>
      {!embedInApp && (
        <Header bookingRoot={bookingRoot} titleOverride={headerTitleOverride} environment={environment} />
      )}
      {withNotice && <Notices />}
      <main
        className={classNames('mb-5', 'flex', 'flex-col', 'flex-grow', !noMaxWidthMain && maxWidthContainer.className)}
      >
        <ErrorBoundary analytics={analytics}>
          <Suspense fallback={<LoadingPageContent translations={t} />}>
            {isPartner ? (
              <PartnerAccessWrapper bookingRoot={bookingRoot as any}>{children}</PartnerAccessWrapper>
            ) : auth.status === 'NOTHING' && auth.loadingAuthChange ? (
              <LoadingPageContent translations={t} />
            ) : (
              children
            )}
          </Suspense>
          <Suspense fallback={null}>{bookingRoot === 'htaTeam' && <FloatingNotesBox />}</Suspense>
        </ErrorBoundary>
        <FeedbackBubble />
      </main>
      {!embedInApp && <Footer environment={environment} />}
    </Fragment>
  );
};
