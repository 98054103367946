/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 * CustomerAPI
 * CustomerAPI provides customer information.<p>Accept-Language header supported values: fi-FI (default),sv-FI,en-FI</p>
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface CreateAuthenticatedCustomerRequest
 */
export interface CreateAuthenticatedCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof CreateAuthenticatedCustomerRequest
   */
  FirstName: string;
  /**
   *
   * @type {string}
   * @memberof CreateAuthenticatedCustomerRequest
   */
  LastName: string;
  /**
   *
   * @type {string}
   * @memberof CreateAuthenticatedCustomerRequest
   */
  MobilePhoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof CreateAuthenticatedCustomerRequest
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateAuthenticatedCustomerRequest
   */
  StreetAddress: string;
  /**
   *
   * @type {string}
   * @memberof CreateAuthenticatedCustomerRequest
   */
  PostCode: string;
  /**
   *
   * @type {string}
   * @memberof CreateAuthenticatedCustomerRequest
   */
  PostOffice: string;
  /**
   *
   * @type {string}
   * @memberof CreateAuthenticatedCustomerRequest
   */
  PhoneNumber?: string | null;
}
/**
 *
 * @export
 * @interface CreateCustomerRequest
 */
export interface CreateCustomerRequest {
  /**
   * Finnish personal identity code
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  PersonalIdentityCode: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  FirstName: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  LastName: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  MobilePhoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  StreetAddress: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  PostCode: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  PostOffice: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  PhoneNumber?: string | null;
}
/**
 *
 * @export
 * @interface CustomerResponse
 */
export interface CustomerResponse {
  /**
   *
   * @type {string}
   * @memberof CustomerResponse
   */
  Id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerResponse
   */
  PersonalIdentityCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerResponse
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerResponse
   */
  LastName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CustomerResponse
   */
  Occupational?: boolean;
  /**
   *
   * @type {number}
   * @memberof CustomerResponse
   */
  Age?: number;
  /**
   *
   * @type {string}
   * @memberof CustomerResponse
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerResponse
   */
  PhoneNumber?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CustomerResponse
   */
  ValidOnBehalfConnection?: boolean;
}
/**
 *
 * @export
 * @interface PingResult
 */
export interface PingResult {
  /**
   *
   * @type {string}
   * @memberof PingResult
   */
  Message?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCreateCustomerAPIException
 */
export interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCreateCustomerAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsExceptionsCreateCustomerAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerAlreadyExistsAPIException
 */
export interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerAlreadyExistsAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerAlreadyExistsAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerDeniedWebReservationAPIException
 */
export interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerDeniedWebReservationAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerDeniedWebReservationAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerHasBadCreditRecordAPIException
 */
export interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerHasBadCreditRecordAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerHasBadCreditRecordAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerHasDeniedAccessAPIException
 */
export interface TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerHasDeniedAccessAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsExceptionsCustomerHasDeniedAccessAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsExceptionsDelegationForbiddenApiException
 */
export interface TerveystaloCustomerAPIAPIAbstractionsExceptionsDelegationForbiddenApiException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsExceptionsDelegationForbiddenApiException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsExceptionsLoginFailedAPIException
 */
export interface TerveystaloCustomerAPIAPIAbstractionsExceptionsLoginFailedAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsExceptionsLoginFailedAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsExceptionsUserNotRegisteredAPIException
 */
export interface TerveystaloCustomerAPIAPIAbstractionsExceptionsUserNotRegisteredAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsExceptionsUserNotRegisteredAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
 */
export interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
   */
  FirstName: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
   */
  LastName: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
   */
  MobilePhoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
   */
  StreetAddress: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
   */
  PostCode: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
   */
  PostOffice: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest
   */
  PhoneNumber?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerEmployerCodeResponse
 */
export interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerEmployerCodeResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerEmployerCodeResponse
   */
  EmployerCode?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
 */
export interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  EmployerCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  CompanyName?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  ResponsibleDoctorDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  ResponsibleNurseDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  ResponsiblePhysiotherapistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  ResponsiblePsychologistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  ResponsibleGeneralDoctorDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  ResponsibleNutritionistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  ResponsibleSocialWorkerDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  PeriodicHealthExaminationClinic?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  WebReservationDeniedByEmployer?: boolean;
  /**
   *
   * @type {Array<TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  Clinics?: Array<TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic> | null;
  /**
   *
   * @type {number}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  RedirectionFormID?: number;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse
   */
  HasPersonalizedBooking?: boolean;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
 */
export interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  DynamicHealthCode?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsibleDoctorDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsibleNurseDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsiblePhysiotherapistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsiblePsychologistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsibleGeneralDoctorDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsibleNutritionistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsibleSocialWorkerDynamicHealthCodes?: Array<string> | null;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest
 */
export interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest {
  /**
   * Finnish personal identity code
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest
   */
  PersonalIdentityCode: string;
}
/**
 * This request only DelegateToken and OnBehalfConnection properties matters
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
 */
export interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  LastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  MobilePhoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  StreetAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  PostCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  PostOffice?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  PhoneNumber?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest
   */
  OnBehalfConnection?: boolean;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest
 */
export interface TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest {
  /**
   * Url to return from external service
   * @type {string}
   * @memberof TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest
   */
  RedirectUri?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIControllersV2CreateRedirectURLResponse
 */
export interface TerveystaloCustomerAPIControllersV2CreateRedirectURLResponse {
  /**
   * Url to redirect end user browser to
   * @type {string}
   * @memberof TerveystaloCustomerAPIControllersV2CreateRedirectURLResponse
   */
  Url?: string | null;
  /**
   * Used with POST /v2/delegate/token
   * @type {string}
   * @memberof TerveystaloCustomerAPIControllersV2CreateRedirectURLResponse
   */
  Registration?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIControllersV2CreateTokenRequest
 */
export interface TerveystaloCustomerAPIControllersV2CreateTokenRequest {
  /**
   * Value returned from POST /v2/delegate/redirectURL
   * @type {string}
   * @memberof TerveystaloCustomerAPIControllersV2CreateTokenRequest
   */
  Registration?: string | null;
  /**
   * Value returned from external service
   * @type {string}
   * @memberof TerveystaloCustomerAPIControllersV2CreateTokenRequest
   */
  Code?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIControllersV2CreateTokenResponse
 */
export interface TerveystaloCustomerAPIControllersV2CreateTokenResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIControllersV2CreateTokenResponse
   */
  Token?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloCustomerAPIControllersV2CreateTokenResponse
   */
  PrincipalCustomerExists?: boolean;
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIControllersV2CreateTokenResponse
   */
  Name?: string | null;
  /**
   *
   * @type {number}
   * @memberof TerveystaloCustomerAPIControllersV2CreateTokenResponse
   */
  Age?: number;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIControllersV2DelegationForbiddenAPIException
 */
export interface TerveystaloCustomerAPIControllersV2DelegationForbiddenAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIControllersV2DelegationForbiddenAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIControllersV2HasReferralResponse
 */
export interface TerveystaloCustomerAPIControllersV2HasReferralResponse {
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloCustomerAPIControllersV2HasReferralResponse
   */
  HasReferral?: boolean;
}
/**
 *
 * @export
 * @interface TerveystaloCustomerAPIControllersV2SelfServiceAllowedResponse
 */
export interface TerveystaloCustomerAPIControllersV2SelfServiceAllowedResponse {
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloCustomerAPIControllersV2SelfServiceAllowedResponse
   */
  IsSelfServiceAllowed?: boolean;
}
/**
 *
 * @export
 * @interface V2CustomersPrincipalGet499Response
 */
export interface V2CustomersPrincipalGet499Response {
  /**
   *
   * @type {string}
   * @memberof V2CustomersPrincipalGet499Response
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof V2CustomersPrincipalGet499Response
   */
  message?: string;
  /**
   * Error specific details
   * @type {any}
   * @memberof V2CustomersPrincipalGet499Response
   */
  details?: any;
}
/**
 *
 * @export
 * @interface WeakLoginRequest
 */
export interface WeakLoginRequest {
  /**
   *
   * @type {string}
   * @memberof WeakLoginRequest
   */
  PersonalIdentityCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof WeakLoginRequest
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof WeakLoginRequest
   */
  LastName?: string | null;
}
/**
 *
 * @export
 * @interface WeakLoginResponse
 */
export interface WeakLoginResponse {
  /**
   *
   * @type {string}
   * @memberof WeakLoginResponse
   */
  BearerToken?: string | null;
}

/**
 * Customer2Api - axios parameter creator
 * @export
 */
export const Customer2ApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create customer with identified by token
     * @param {CreateAuthenticatedCustomerRequest} [createAuthenticatedCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersAuthenticatedPost: async (
      createAuthenticatedCustomerRequest?: CreateAuthenticatedCustomerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/customers/authenticated`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAuthenticatedCustomerRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get customer employerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersEmployercodeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/customers/employercode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get authorized customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get customer by id or userGuid
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('v2CustomersIdGet', 'id', id);
      const localVarPath = `/v2/customers/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get occupational data. Returns HTTP status 404, if the customer doesn\'t have it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersOccupationalDataGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/customers/occupationalData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create customer
     * @param {CreateCustomerRequest} [createCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPost: async (
      createCustomerRequest?: CreateCustomerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createCustomerRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get principal customer
     * @param {string} delegateToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPrincipalGet: async (delegateToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'delegateToken' is not null or undefined
      assertParamExists('v2CustomersPrincipalGet', 'delegateToken', delegateToken);
      const localVarPath = `/v2/customers/principal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (delegateToken !== undefined && delegateToken !== null) {
        localVarHeaderParameter['delegate-token'] = String(delegateToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create principal customer for delegation
     * @param {string} delegateToken
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPrincipalPost: async (
      delegateToken: string,
      terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'delegateToken' is not null or undefined
      assertParamExists('v2CustomersPrincipalPost', 'delegateToken', delegateToken);
      const localVarPath = `/v2/customers/principal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (delegateToken !== undefined && delegateToken !== null) {
        localVarHeaderParameter['delegate-token'] = String(delegateToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create or extend on behalf connection between delegate (Guerdian) and principal (Child) by one year.  Only delegateToken and onBehalfConnection properties matters at the moment.
     * @param {string} delegateToken
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPrincipalPut: async (
      delegateToken: string,
      terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'delegateToken' is not null or undefined
      assertParamExists('v2CustomersPrincipalPut', 'delegateToken', delegateToken);
      const localVarPath = `/v2/customers/principal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (delegateToken !== undefined && delegateToken !== null) {
        localVarHeaderParameter['delegate-token'] = String(delegateToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search principal customer.
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPrincipalSearchPost: async (
      terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/customers/principal/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * Customer2Api - functional programming interface
 * @export
 */
export const Customer2ApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = Customer2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create customer with identified by token
     * @param {CreateAuthenticatedCustomerRequest} [createAuthenticatedCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersAuthenticatedPost(
      createAuthenticatedCustomerRequest?: CreateAuthenticatedCustomerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersAuthenticatedPost(
        createAuthenticatedCustomerRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get customer employerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersEmployercodeGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloCustomerAPIAPIAbstractionsV2CustomerEmployerCodeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersEmployercodeGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get authorized customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get customer by id or userGuid
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersIdGet(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get occupational data. Returns HTTP status 404, if the customer doesn\'t have it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersOccupationalDataGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersOccupationalDataGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create customer
     * @param {CreateCustomerRequest} [createCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersPost(
      createCustomerRequest?: CreateCustomerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersPost(createCustomerRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get principal customer
     * @param {string} delegateToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersPrincipalGet(
      delegateToken: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersPrincipalGet(delegateToken, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create principal customer for delegation
     * @param {string} delegateToken
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersPrincipalPost(
      delegateToken: string,
      terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersPrincipalPost(
        delegateToken,
        terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create or extend on behalf connection between delegate (Guerdian) and principal (Child) by one year.  Only delegateToken and onBehalfConnection properties matters at the moment.
     * @param {string} delegateToken
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersPrincipalPut(
      delegateToken: string,
      terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersPrincipalPut(
        delegateToken,
        terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Search principal customer.
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersPrincipalSearchPost(
      terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersPrincipalSearchPost(
        terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * Customer2Api - factory interface
 * @export
 */
export const Customer2ApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = Customer2ApiFp(configuration);
  return {
    /**
     *
     * @summary Create customer with identified by token
     * @param {CreateAuthenticatedCustomerRequest} [createAuthenticatedCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersAuthenticatedPost(
      createAuthenticatedCustomerRequest?: CreateAuthenticatedCustomerRequest,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .v2CustomersAuthenticatedPost(createAuthenticatedCustomerRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get customer employerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersEmployercodeGet(
      options?: any,
    ): AxiosPromise<TerveystaloCustomerAPIAPIAbstractionsV2CustomerEmployerCodeResponse> {
      return localVarFp.v2CustomersEmployercodeGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get authorized customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersGet(options?: any): AxiosPromise<CustomerResponse> {
      return localVarFp.v2CustomersGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get customer by id or userGuid
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersIdGet(id: string, options?: any): AxiosPromise<CustomerResponse> {
      return localVarFp.v2CustomersIdGet(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get occupational data. Returns HTTP status 404, if the customer doesn\'t have it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersOccupationalDataGet(
      options?: any,
    ): AxiosPromise<TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponse> {
      return localVarFp.v2CustomersOccupationalDataGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create customer
     * @param {CreateCustomerRequest} [createCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPost(createCustomerRequest?: CreateCustomerRequest, options?: any): AxiosPromise<string> {
      return localVarFp.v2CustomersPost(createCustomerRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get principal customer
     * @param {string} delegateToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPrincipalGet(delegateToken: string, options?: any): AxiosPromise<CustomerResponse> {
      return localVarFp.v2CustomersPrincipalGet(delegateToken, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create principal customer for delegation
     * @param {string} delegateToken
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPrincipalPost(
      delegateToken: string,
      terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .v2CustomersPrincipalPost(
          delegateToken,
          terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create or extend on behalf connection between delegate (Guerdian) and principal (Child) by one year.  Only delegateToken and onBehalfConnection properties matters at the moment.
     * @param {string} delegateToken
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPrincipalPut(
      delegateToken: string,
      terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .v2CustomersPrincipalPut(
          delegateToken,
          terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search principal customer.
     * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersPrincipalSearchPost(
      terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest,
      options?: any,
    ): AxiosPromise<CustomerResponse> {
      return localVarFp
        .v2CustomersPrincipalSearchPost(terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Customer2Api - object-oriented interface
 * @export
 * @class Customer2Api
 * @extends {BaseAPI}
 */
export class Customer2Api extends BaseAPI {
  /**
   *
   * @summary Create customer with identified by token
   * @param {CreateAuthenticatedCustomerRequest} [createAuthenticatedCustomerRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersAuthenticatedPost(
    createAuthenticatedCustomerRequest?: CreateAuthenticatedCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersAuthenticatedPost(createAuthenticatedCustomerRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get customer employerCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersEmployercodeGet(options?: AxiosRequestConfig) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersEmployercodeGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get authorized customer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersGet(options?: AxiosRequestConfig) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get customer by id or userGuid
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersIdGet(id: string, options?: AxiosRequestConfig) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersIdGet(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get occupational data. Returns HTTP status 404, if the customer doesn\'t have it.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersOccupationalDataGet(options?: AxiosRequestConfig) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersOccupationalDataGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create customer
   * @param {CreateCustomerRequest} [createCustomerRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersPost(createCustomerRequest?: CreateCustomerRequest, options?: AxiosRequestConfig) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersPost(createCustomerRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get principal customer
   * @param {string} delegateToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersPrincipalGet(delegateToken: string, options?: AxiosRequestConfig) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersPrincipalGet(delegateToken, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create principal customer for delegation
   * @param {string} delegateToken
   * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersPrincipalPost(
    delegateToken: string,
    terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersPrincipalPost(
        delegateToken,
        terveystaloCustomerAPIAPIAbstractionsV2CustomerCreatePrincipalCustomerRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create or extend on behalf connection between delegate (Guerdian) and principal (Child) by one year.  Only delegateToken and onBehalfConnection properties matters at the moment.
   * @param {string} delegateToken
   * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersPrincipalPut(
    delegateToken: string,
    terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersPrincipalPut(
        delegateToken,
        terveystaloCustomerAPIAPIAbstractionsV2CustomerUpdatePrincipalCustomerRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search principal customer.
   * @param {TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest} [terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Customer2Api
   */
  public v2CustomersPrincipalSearchPost(
    terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest,
    options?: AxiosRequestConfig,
  ) {
    return Customer2ApiFp(this.configuration)
      .v2CustomersPrincipalSearchPost(terveystaloCustomerAPIAPIAbstractionsV2CustomerSearchPrincipalRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DelegateApi - axios parameter creator
 * @export
 */
export const DelegateApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create URL for external service that allows user to select principal  for delegation. That service returns code that is used as parameter  on POST /v2/delegate/token
     * @param {TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest} [terveystaloCustomerAPIControllersV2CreateRedirectURLRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2DelegateRedirectURLPost: async (
      terveystaloCustomerAPIControllersV2CreateRedirectURLRequest?: TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/delegate/redirectURL`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloCustomerAPIControllersV2CreateRedirectURLRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create token that can be used in subsequent requests when acting  of behalf of another person.
     * @param {TerveystaloCustomerAPIControllersV2CreateTokenRequest} [terveystaloCustomerAPIControllersV2CreateTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2DelegateTokenPost: async (
      terveystaloCustomerAPIControllersV2CreateTokenRequest?: TerveystaloCustomerAPIControllersV2CreateTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/delegate/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloCustomerAPIControllersV2CreateTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DelegateApi - functional programming interface
 * @export
 */
export const DelegateApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DelegateApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create URL for external service that allows user to select principal  for delegation. That service returns code that is used as parameter  on POST /v2/delegate/token
     * @param {TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest} [terveystaloCustomerAPIControllersV2CreateRedirectURLRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2DelegateRedirectURLPost(
      terveystaloCustomerAPIControllersV2CreateRedirectURLRequest?: TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloCustomerAPIControllersV2CreateRedirectURLResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2DelegateRedirectURLPost(
        terveystaloCustomerAPIControllersV2CreateRedirectURLRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create token that can be used in subsequent requests when acting  of behalf of another person.
     * @param {TerveystaloCustomerAPIControllersV2CreateTokenRequest} [terveystaloCustomerAPIControllersV2CreateTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2DelegateTokenPost(
      terveystaloCustomerAPIControllersV2CreateTokenRequest?: TerveystaloCustomerAPIControllersV2CreateTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerveystaloCustomerAPIControllersV2CreateTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2DelegateTokenPost(
        terveystaloCustomerAPIControllersV2CreateTokenRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DelegateApi - factory interface
 * @export
 */
export const DelegateApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DelegateApiFp(configuration);
  return {
    /**
     *
     * @summary Create URL for external service that allows user to select principal  for delegation. That service returns code that is used as parameter  on POST /v2/delegate/token
     * @param {TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest} [terveystaloCustomerAPIControllersV2CreateRedirectURLRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2DelegateRedirectURLPost(
      terveystaloCustomerAPIControllersV2CreateRedirectURLRequest?: TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest,
      options?: any,
    ): AxiosPromise<TerveystaloCustomerAPIControllersV2CreateRedirectURLResponse> {
      return localVarFp
        .v2DelegateRedirectURLPost(terveystaloCustomerAPIControllersV2CreateRedirectURLRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create token that can be used in subsequent requests when acting  of behalf of another person.
     * @param {TerveystaloCustomerAPIControllersV2CreateTokenRequest} [terveystaloCustomerAPIControllersV2CreateTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2DelegateTokenPost(
      terveystaloCustomerAPIControllersV2CreateTokenRequest?: TerveystaloCustomerAPIControllersV2CreateTokenRequest,
      options?: any,
    ): AxiosPromise<TerveystaloCustomerAPIControllersV2CreateTokenResponse> {
      return localVarFp
        .v2DelegateTokenPost(terveystaloCustomerAPIControllersV2CreateTokenRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * DelegateApi - object-oriented interface
 * @export
 * @class DelegateApi
 * @extends {BaseAPI}
 */
export class DelegateApi extends BaseAPI {
  /**
   *
   * @summary Create URL for external service that allows user to select principal  for delegation. That service returns code that is used as parameter  on POST /v2/delegate/token
   * @param {TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest} [terveystaloCustomerAPIControllersV2CreateRedirectURLRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DelegateApi
   */
  public v2DelegateRedirectURLPost(
    terveystaloCustomerAPIControllersV2CreateRedirectURLRequest?: TerveystaloCustomerAPIControllersV2CreateRedirectURLRequest,
    options?: AxiosRequestConfig,
  ) {
    return DelegateApiFp(this.configuration)
      .v2DelegateRedirectURLPost(terveystaloCustomerAPIControllersV2CreateRedirectURLRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create token that can be used in subsequent requests when acting  of behalf of another person.
   * @param {TerveystaloCustomerAPIControllersV2CreateTokenRequest} [terveystaloCustomerAPIControllersV2CreateTokenRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DelegateApi
   */
  public v2DelegateTokenPost(
    terveystaloCustomerAPIControllersV2CreateTokenRequest?: TerveystaloCustomerAPIControllersV2CreateTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return DelegateApiFp(this.configuration)
      .v2DelegateTokenPost(terveystaloCustomerAPIControllersV2CreateTokenRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HealthAndMonitoringApi - axios parameter creator
 * @export
 */
export const HealthAndMonitoringApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthAndMonitoringApi - functional programming interface
 * @export
 */
export const HealthAndMonitoringApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthAndMonitoringApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pingGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HealthAndMonitoringApi - factory interface
 * @export
 */
export const HealthAndMonitoringApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthAndMonitoringApiFp(configuration);
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet(options?: any): AxiosPromise<PingResult> {
      return localVarFp.pingGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HealthAndMonitoringApi - object-oriented interface
 * @export
 * @class HealthAndMonitoringApi
 * @extends {BaseAPI}
 */
export class HealthAndMonitoringApi extends BaseAPI {
  /**
   *
   * @summary Method to test service
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public pingGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .pingGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PersonalizationApi - axios parameter creator
 * @export
 */
export const PersonalizationApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return\'s customer self service allowed result from etydi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PersonalizationSelfServiceAllowedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/personalization/selfServiceAllowed`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PersonalizationApi - functional programming interface
 * @export
 */
export const PersonalizationApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = PersonalizationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Return\'s customer self service allowed result from etydi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2PersonalizationSelfServiceAllowedGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloCustomerAPIControllersV2SelfServiceAllowedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2PersonalizationSelfServiceAllowedGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PersonalizationApi - factory interface
 * @export
 */
export const PersonalizationApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PersonalizationApiFp(configuration);
  return {
    /**
     *
     * @summary Return\'s customer self service allowed result from etydi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PersonalizationSelfServiceAllowedGet(
      options?: any,
    ): AxiosPromise<TerveystaloCustomerAPIControllersV2SelfServiceAllowedResponse> {
      return localVarFp.v2PersonalizationSelfServiceAllowedGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * PersonalizationApi - object-oriented interface
 * @export
 * @class PersonalizationApi
 * @extends {BaseAPI}
 */
export class PersonalizationApi extends BaseAPI {
  /**
   *
   * @summary Return\'s customer self service allowed result from etydi
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonalizationApi
   */
  public v2PersonalizationSelfServiceAllowedGet(options?: AxiosRequestConfig) {
    return PersonalizationApiFp(this.configuration)
      .v2PersonalizationSelfServiceAllowedGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ReferralApi - axios parameter creator
 * @export
 */
export const ReferralApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Check if customer has valid referral by referral code
     * @param {string} referralCode
     * @param {string} [delegateToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersReferralReferralCodeGet: async (
      referralCode: string,
      delegateToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'referralCode' is not null or undefined
      assertParamExists('v2CustomersReferralReferralCodeGet', 'referralCode', referralCode);
      const localVarPath = `/v2/customers/referral/{referralCode}`.replace(
        `{${'referralCode'}}`,
        encodeURIComponent(String(referralCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (delegateToken !== undefined && delegateToken !== null) {
        localVarHeaderParameter['delegate-token'] = String(delegateToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReferralApi - functional programming interface
 * @export
 */
export const ReferralApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ReferralApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Check if customer has valid referral by referral code
     * @param {string} referralCode
     * @param {string} [delegateToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CustomersReferralReferralCodeGet(
      referralCode: string,
      delegateToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerveystaloCustomerAPIControllersV2HasReferralResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CustomersReferralReferralCodeGet(
        referralCode,
        delegateToken,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReferralApi - factory interface
 * @export
 */
export const ReferralApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ReferralApiFp(configuration);
  return {
    /**
     *
     * @summary Check if customer has valid referral by referral code
     * @param {string} referralCode
     * @param {string} [delegateToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CustomersReferralReferralCodeGet(
      referralCode: string,
      delegateToken?: string,
      options?: any,
    ): AxiosPromise<TerveystaloCustomerAPIControllersV2HasReferralResponse> {
      return localVarFp
        .v2CustomersReferralReferralCodeGet(referralCode, delegateToken, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ReferralApi - object-oriented interface
 * @export
 * @class ReferralApi
 * @extends {BaseAPI}
 */
export class ReferralApi extends BaseAPI {
  /**
   *
   * @summary Check if customer has valid referral by referral code
   * @param {string} referralCode
   * @param {string} [delegateToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferralApi
   */
  public v2CustomersReferralReferralCodeGet(
    referralCode: string,
    delegateToken?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReferralApiFp(this.configuration)
      .v2CustomersReferralReferralCodeGet(referralCode, delegateToken, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SelfServiceApi - axios parameter creator
 * @export
 */
export const SelfServiceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Save customer choice to approve or reject self service approvement.
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SelfserviceSetApprovementPost: async (body?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/selfservice/SetApprovement`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SelfServiceApi - functional programming interface
 * @export
 */
export const SelfServiceApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = SelfServiceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Save customer choice to approve or reject self service approvement.
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SelfserviceSetApprovementPost(
      body?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SelfserviceSetApprovementPost(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SelfServiceApi - factory interface
 * @export
 */
export const SelfServiceApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SelfServiceApiFp(configuration);
  return {
    /**
     *
     * @summary Save customer choice to approve or reject self service approvement.
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SelfserviceSetApprovementPost(body?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp.v2SelfserviceSetApprovementPost(body, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SelfServiceApi - object-oriented interface
 * @export
 * @class SelfServiceApi
 * @extends {BaseAPI}
 */
export class SelfServiceApi extends BaseAPI {
  /**
   *
   * @summary Save customer choice to approve or reject self service approvement.
   * @param {boolean} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SelfServiceApi
   */
  public v2SelfserviceSetApprovementPost(body?: boolean, options?: AxiosRequestConfig) {
    return SelfServiceApiFp(this.configuration)
      .v2SelfserviceSetApprovementPost(body, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Required by  https://github.com/terveystalo/handbook/tree/main/policies/api_responses#liveness-endpoint-mandatory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthLiveGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/health/live`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Required by  https://github.com/terveystalo/handbook/tree/main/policies/api_responses#liveness-endpoint-mandatory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthLiveGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthLiveGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SystemApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthGet(options?: any): AxiosPromise<void> {
      return localVarFp.healthGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Required by  https://github.com/terveystalo/handbook/tree/main/policies/api_responses#liveness-endpoint-mandatory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthLiveGet(options?: any): AxiosPromise<void> {
      return localVarFp.healthLiveGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public healthGet(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .healthGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Required by  https://github.com/terveystalo/handbook/tree/main/policies/api_responses#liveness-endpoint-mandatory
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public healthLiveGet(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .healthLiveGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WeakLoginApi - axios parameter creator
 * @export
 */
export const WeakLoginApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get token for authentication
     * @param {WeakLoginRequest} [weakLoginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2WeaktokenPost: async (
      weakLoginRequest?: WeakLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/weaktoken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(weakLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WeakLoginApi - functional programming interface
 * @export
 */
export const WeakLoginApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = WeakLoginApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get token for authentication
     * @param {WeakLoginRequest} [weakLoginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2WeaktokenPost(
      weakLoginRequest?: WeakLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeakLoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2WeaktokenPost(weakLoginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WeakLoginApi - factory interface
 * @export
 */
export const WeakLoginApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WeakLoginApiFp(configuration);
  return {
    /**
     *
     * @summary Get token for authentication
     * @param {WeakLoginRequest} [weakLoginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2WeaktokenPost(weakLoginRequest?: WeakLoginRequest, options?: any): AxiosPromise<WeakLoginResponse> {
      return localVarFp.v2WeaktokenPost(weakLoginRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * WeakLoginApi - object-oriented interface
 * @export
 * @class WeakLoginApi
 * @extends {BaseAPI}
 */
export class WeakLoginApi extends BaseAPI {
  /**
   *
   * @summary Get token for authentication
   * @param {WeakLoginRequest} [weakLoginRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeakLoginApi
   */
  public v2WeaktokenPost(weakLoginRequest?: WeakLoginRequest, options?: AxiosRequestConfig) {
    return WeakLoginApiFp(this.configuration)
      .v2WeaktokenPost(weakLoginRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
