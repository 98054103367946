import { getExpectedAuthState, WebAuthClient } from '@terveystalo/web-auth-onewelcome';
import { History } from 'history';
import isPlainObject from 'lodash/isPlainObject';
import { ConfigModelType } from 'model/config';
import { parse } from 'query-string';
import { Language } from 'translations/generated/translation-languages';
import { Analytics } from 'utils/analytics/analytics-utils';
import { delegateStateStorageKey } from 'utils/auth/delegate';
import { returnUrlBase } from 'utils/auth/helpers';
import { parseMobileAuthToken, parseMobileDelegateToken } from 'utils/react/mobileapp-helpers';
import { Dispatch } from 'utils/react/ui-context';
import { parseLangFromPath } from 'utils/react/url-state-utils';
import { routes } from 'utils/routes/routes';
import { getStorageObjectItem, removeStorageItem, setStorageObjectItem } from 'utils/storage/helpers';

type RestoreLocation = {
  pathname?: string;
  search?: string;
};
type RestoreLocationState = {
  prevRoute?: string;
};
type ReturnState = RestoreLocation & RestoreLocationState;

export type OneWelcomeClient = ReturnType<typeof createOneWelcomeClient>;

const callbackStateStorageKey = 'st-booking-cb-';

export const createOneWelcomeClient = (
  config: ConfigModelType['FrontendConfig'],
  history: History,
  dispatch: Dispatch,
  analytics: Analytics,
) => {
  // Initialize client
  let authClient: WebAuthClient;
  const lang = parseLangFromPath(history.location.pathname);

  // Catch the secure origin error on first load for our pipeline metrics automations to work
  // This try-catch can be removed if we implement deploying canary builds
  try {
    authClient = createAuthClientInstance(config, lang);
  } catch (err) {
    if (err instanceof Error && err.message.includes('oneWelcome-spa-js must run on a secure origin')) {
      return undefined;
    } else {
      throw err;
    }
  }

  const listenToAuthState = (updateTimes: (timeoutAt: Date | undefined, expiresAt: Date | undefined) => void) => {
    return authClient.listenToAuthState(newAuthState => {
      if (newAuthState.type === 'LoggedIn') {
        const { timeoutAt, expiresAt } = newAuthState;
        updateTimes(timeoutAt, expiresAt);
        // Track logged in status of user for Piwik
        analytics.trackUserIsLoggedIn(true);
      } else {
        updateTimes(undefined, undefined);
        // User is not logged in, set status to false
        analytics.trackUserIsLoggedIn(false);
      }
    });
  };

  const loginWithOneWelcome = async (
    lang: Language,
    restoreLocation?: RestoreLocation,
    restoreState?: RestoreLocationState,
  ) => {
    // State to restore after login
    const appState: ReturnState = {
      ...(restoreLocation || {
        pathname: history.location.pathname,
        search: history.location.search,
      }),
      prevRoute: restoreState && restoreState.prevRoute,
    };

    // Store appState to storage to preserve them through the redirect instead of using provided passing of appState
    // as there is a size limit using the appState of loginWithRedirect because the value is passed in the url
    setStorageObjectItem(callbackStateStorageKey, appState);

    const expectedAuthState = getExpectedAuthState();
    if (expectedAuthState.type === 'LoggedIn') {
      const user = await authClient.signinSilent();
      if (user && user.id_token && typeof user.id_token === 'string') {
        dispatch.updateToken({ accessToken: user.id_token, type: 'oneWelcome' });
      }
      return;
    }

    // Redirect user to authorize using Implicit Grant
    return authClient.signinRedirect({
      // Make sure we return to the current language root
      redirect_uri: `${returnUrlBase()}${routes.root[lang]}`,
      extraQueryParams: { max_age: 3600 }, // 1h in seconds. Session max length
    });
  };

  const refreshSession = async () => {
    const token = await authClient.signinSilent();
    if (token && typeof token === 'string') {
      dispatch.updateToken({ accessToken: token, type: 'oneWelcome' });
    }
  };

  const logoutFromOneWelcome = (lang: Language, reloginState?: ReturnState) => {
    if (reloginState) {
      setStorageObjectItem(callbackStateStorageKey, reloginState);
    }

    // Redirect user to logout from oneWelcome
    const baseUrl = returnUrlBase();
    return authClient.signoutRedirect({
      post_logout_redirect_uri: `${baseUrl}${routes.loggedOut[lang]}`,
      extraQueryParams: { client_id: config.ONEWELCOME_CLIENT_ID },
    });
  };

  const restoreLocationFromAppState = () => {
    // Try to restore data from storage
    const appState = getStorageObjectItem(callbackStateStorageKey);

    if (!!appState && isPlainObject(appState)) {
      const { pathname, search, prevRoute } = appState as Record<any, unknown>;
      // Redirect to the path the user came from
      history.replace({
        pathname: (typeof pathname === 'string' && pathname) || history.location.pathname,
        search: (typeof search === 'string' && search) || undefined,
        state: { prevRoute, arrivingFromAuth: true },
      });
    } else {
      // Set info to location state that returning from auth
      history.replace({
        pathname: history.location.pathname,
        state: { arrivingFromAuth: true },
      });
    }
  };

  // Handle all possible tokens and codes
  const handleRedirectCallback = async () => {
    const { code, embedInApp, delegation, state, error, error_description, relogin } = parse(history.location.search);

    const isBookingForOther = delegation === 'true';
    // On first load, check possible authentication states

    // Separate handling for first load when embedInApp, do not do "normal" auth redirect checks
    if (embedInApp === 'true') {
      const mobileToken = embedInApp ? parseMobileAuthToken(history) : null;
      if (mobileToken) {
        // Track loggedIn mobile user
        analytics.trackUserIsLoggedIn(true);
        dispatch.setToken({ ...mobileToken, type: 'oneWelcome' });
        if (isBookingForOther) {
          const mobileDelegateToken = parseMobileDelegateToken();
          if (mobileDelegateToken) {
            dispatch.setDelegateToken({ accessToken: mobileDelegateToken });
          } else {
            dispatch.delegateError({ type: 'token_invalid' });
          }
        }
      }
      return;
    }

    const isReturnFromSuomiFi = isBookingForOther && (code || error);
    const isReturnFromOneWelcome = !isBookingForOther && (code || error);

    if (isReturnFromSuomiFi) {
      // Try to restore delegate return data from storage
      const { state: storedState, registration, pathname, search } = getStorageObjectItem(delegateStateStorageKey);
      // Remove the code and state from the url after parsing them and direct the user where they came from
      history.replace({
        pathname: (typeof pathname === 'string' && pathname) || history.location.pathname,
        search: (typeof search === 'string' && search) || 'delegation=true',
        state: { arrivingFromAuth: true },
      });
      const newLang = parseLangFromPath(history.location.pathname);
      if (newLang !== lang) {
        try {
          await authClient.close();
          authClient = createAuthClientInstance(config, newLang);
        } catch {
          // no-op
        }
      }
      if (
        typeof code === 'string' &&
        !!state &&
        typeof state === 'string' &&
        state === storedState &&
        !!registration &&
        typeof registration === 'string'
      ) {
        dispatch.setDelegateRegistration(registration, code);
      } else {
        dispatch.delegateError({ type: 'other_token_error' });
      }
    }

    // Track auth error to not try to fetch a token then
    let authErr = false;
    if (isReturnFromOneWelcome) {
      dispatch.loadingAuthChange();
      try {
        await authClient.signinRedirectCallback();
      } catch (err) {
        // Make sure user loggedIn status is false in Piwik
        analytics.trackUserIsLoggedIn(false);
        // and send error
        analytics.sendStrongAuthError(error || 'invalid_token_or_state', error_description);
        dispatch.authError({ type: 'oneWelcome_error' });
        authErr = true;
      }
      restoreLocationFromAppState();
    }

    if (relogin !== undefined) {
      // Check if a relogin was requested
      restoreLocationFromAppState();
    }

    // Get a possible token
    if (!authErr) {
      try {
        const user = await authClient.signinSilent();
        const token = user?.id_token;
        if (token && typeof token === 'string') {
          // Track loggedIn user
          analytics.trackUserIsLoggedIn(true);
          dispatch.setToken({ accessToken: token, type: 'oneWelcome' });
        } else if (isReturnFromOneWelcome) {
          // Make sure user loggedIn status is false in Piwik
          analytics.trackUserIsLoggedIn(false);
          analytics.sendStrongAuthError(error || 'invalid_token_or_state', error_description);
          dispatch.authError({ type: 'oneWelcome_error' });
        }
      } catch (err) {
        if (isReturnFromOneWelcome) {
          // Make sure user loggedIn status is false in Piwik
          analytics.trackUserIsLoggedIn(false);
          analytics.sendStrongAuthError(error || 'invalid_token_or_state', error_description);
          dispatch.authError({ type: 'oneWelcome_error' });
        } else {
          // no-op
        }
      }
    }

    // Last, remove any stored states
    removeStorageItem(callbackStateStorageKey);
    removeStorageItem(delegateStateStorageKey);
  };

  // Handle redirect callback only once when app loads (i.e. user returns from auth redirect)
  handleRedirectCallback();

  return {
    loginWithOneWelcome,
    refreshSession,
    listenToAuthState,
    logoutFromOneWelcome,
  };
};

function createAuthClientInstance(config: ConfigModelType['FrontendConfig'], lang: Language) {
  const baseUrl = returnUrlBase();

  return new WebAuthClient({
    authority: config.ONEWELCOME_AUTHORITY,
    client_id: config.ONEWELCOME_CLIENT_ID,
    extraQueryParams: config.ONEWELCOME_USE_FAKEBANK ? { idp: 'tulip-fakebank' } : undefined,
    post_logout_redirect_uri: `${baseUrl}${routes.loggedOut[lang]}`,
    redirect_uri: `${baseUrl}${routes.root[lang]}`,
    silent_redirect_uri: `${baseUrl}/silent-signin-callback.html`,
    timeoutReturnTo: `${baseUrl}${routes.autoLoggedOut[lang]}`,
  });
}
