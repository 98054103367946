import {
  BookingErrorState,
  BookingResultTitleState,
  CancelledBookingState,
  MandatoryGuideState,
  VaccineGuideState,
} from 'reducers/notice';
import { defineActionCreators } from 'utils/redux/actions';

export default defineActionCreators({
  setBookingError(bookingError: BookingErrorState, bookingResultsTitle: BookingResultTitleState) {
    return { bookingError, bookingResultsTitle };
  },
  setCancelledBooking(cancelledBooking: CancelledBookingState) {
    return { cancelledBooking };
  },
  closeCriticalNotice() {
    return {};
  },
  closeCriticalNoticeDental() {
    return {};
  },
  closeInfoNotice() {
    return {};
  },
  closeInfoNoticeDental() {
    return {};
  },
  setMandatoryGuideState(guideState: MandatoryGuideState) {
    return { guideState };
  },
  setVaccineGuideState(guideState: VaccineGuideState) {
    return { guideState };
  },
});
