import { isError, parseErrorCode } from 'model/error';
import { useEffect } from 'react';
import { AuthToken } from 'reducers/auth';
import { removeStoredDelegate, useDelegateLogin } from 'utils/auth/delegate';
import { useReduxDispatch, useReduxState, useUiContext } from 'utils/react/ui-context';
import { isPartnerRoot } from 'utils/routes/routes';
import { deepGet } from 'utils/types/deepGetSet';

export function useLoginType(): AuthToken['type'] {
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const isPartner = isPartnerRoot(bookingRoot);

  return isPartner ? 'weak' : 'oneWelcome';
}

/**
 * Log out locally, i.e. remove all login info from runtime state
 * Enough for weak auth, but for OneWelcome also a redirect is needed: see LogoutButton
 */
export function useLocalLogout() {
  const dispatch = useReduxDispatch();
  const { getCurrentTime } = useUiContext();

  return (withReset?: boolean) => {
    removeStoredDelegate();
    dispatch.logout();
    dispatch.delegateExit();
    dispatch.clearAllApiData();
    if (withReset) {
      dispatch.setBookingError(null, null);
      dispatch.filtersReset(getCurrentTime());
    }
  };
}

export function useAutoLogin() {
  const auth = useReduxState(state => state.auth);
  const { lang } = useReduxState(state => state.location);
  const dispatch = useReduxDispatch();
  const logOut = useLocalLogout();
  const { apiClient } = useUiContext();
  const loginType = useLoginType();

  useEffect(() => {
    // Make sure login types are not mixed
    if ('token' in auth && auth.token.type !== loginType) {
      logOut();
    }

    // Do not use auto login on page load for other than oneWelcome
    if (loginType !== 'oneWelcome') {
      return;
    }

    if (auth.status === 'TOKEN_RETRIEVED') {
      dispatch.userDataLoading(auth.token);
      apiClient
        .getCustomer(lang, auth.token)
        .then(userData => {
          dispatch.login(userData, auth.token);
        })
        .catch(err => {
          const errorCode = parseErrorCode(err);
          const errorType = isError.AdaApiManagedError(err)
            ? `${deepGet(err, 'AdaApiManagedError', 'responseData', 'type')}`
            : undefined;
          if (
            errorCode === 404 ||
            errorType === 'Terveystalo.CustomerAPI.API.Abstractions.Exceptions.UserNotRegisteredAPIException'
          ) {
            // The user has not registered, set the data as base data for the registration form
            dispatch.newUser(null, auth.token);
          } else if (errorCode === 401 || errorCode === 403) {
            dispatch.authError({ type: 'token_invalid' });
          } else if (
            errorType ===
              'Terveystalo.CustomerAPI.API.Abstractions.Exceptions.CustomerHasBadCreditRecordAPIException' ||
            errorType === 'Terveystalo.CustomerAPI.API.Abstractions.Exceptions.CustomerDeniedWebReservationAPIException'
          ) {
            dispatch.authError({ type: 'online_booking_denied' });
          } else {
            dispatch.authError({ type: 'other' });
          }
        });
    }
  });

  useDelegateLogin();
}

export const returnUrlBase = () => {
  const { origin, href } = window.location;
  // The origin may be sometimes undefined, but href should be defined even then
  if (origin) {
    return origin;
  }
  const urlParts = href.split('//');
  const hostname = (urlParts[1] || '').split('/')[0];
  if (hostname) {
    return `${urlParts[0]}//${hostname}`;
  }
  // Fallback to prod origin just to be sure
  return 'https://ajanvaraus.terveystalo.com';
};

export const randomStateString = (length = 32) => {
  const cryptoObj = window.crypto || (window as any).msCrypto;
  if (!cryptoObj) {
    return null;
  }

  const bytes = new Uint8Array(length);
  const random = cryptoObj.getRandomValues(bytes);
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

  const result = [...new Array(length)].map((_, i) => charset[random[i] % charset.length]);
  return result.join('');
};
