/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 * SpecialistAPI
 * <p>Accept-Language header supported values: fi-FI (defaultt),sv-FI,en-FI</p><p>develop 0a27770d62d9c60980c22a305b6d6be585469a77 develop_20210907.1</p>
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface GetSpecialistsResponseItem
 */
export interface GetSpecialistsResponseItem {
  /**
   *
   * @type {string}
   * @memberof GetSpecialistsResponseItem
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof GetSpecialistsResponseItem
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetSpecialistsResponseItem
   */
  LastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetSpecialistsResponseItem
   */
  ImageUri?: string | null;
  /**
   *
   * @type {TerveystaloSpecialistAPIAbstractionsV1SpecialistGender}
   * @memberof GetSpecialistsResponseItem
   */
  Gender?: TerveystaloSpecialistAPIAbstractionsV1SpecialistGender;
  /**
   *
   * @type {TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32}
   * @memberof GetSpecialistsResponseItem
   */
  ChildAgeRange?: TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32;
  /**
   *
   * @type {TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32}
   * @memberof GetSpecialistsResponseItem
   */
  AdultAgeRange?: TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32;
  /**
   *
   * @type {Array<SpecialistLanguage>}
   * @memberof GetSpecialistsResponseItem
   */
  Languages?: Array<SpecialistLanguage> | null;
  /**
   *
   * @type {boolean}
   * @memberof GetSpecialistsResponseItem
   */
  SignLanguage?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetSpecialistsResponseItem
   */
  HiddenForSearch?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSpecialistsResponseItem
   */
  ClinicIds?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSpecialistsResponseItem
   */
  CompetenceIds?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSpecialistsResponseItem
   */
  ServiceIds?: Array<string> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetSpecialistsResponseItem
   */
  Title?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetSpecialistsResponseItem
   */
  Degree?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetSpecialistsResponseItem
   */
  Specialty?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetSpecialistsResponseItem
   */
  ReservationInstruction?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetSpecialistsResponseItem
   */
  ShortDescription?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetSpecialistsResponseItem
   */
  PreparationInstructions?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetSpecialistsResponseItem
   */
  Presentation?: Array<Localized> | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GetSpecialistsResponseItem
   */
  SubsystemIDs?: { [key: string]: string } | null;
  /**
   *
   * @type {Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatch>}
   * @memberof GetSpecialistsResponseItem
   */
  Matches?: Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatch> | null;
}
/**
 *
 * @export
 * @interface Localized
 */
export interface Localized {
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  TwoLetterISOLanguage?: string | null;
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  Value?: string | null;
}
/**
 *
 * @export
 * @interface PingResult
 */
export interface PingResult {
  /**
   *
   * @type {string}
   * @memberof PingResult
   */
  Message?: string | null;
}
/**
 *
 * @export
 * @interface SpecialistDetail
 */
export interface SpecialistDetail {
  /**
   *
   * @type {string}
   * @memberof SpecialistDetail
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof SpecialistDetail
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpecialistDetail
   */
  LastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpecialistDetail
   */
  ImageUri?: string | null;
  /**
   *
   * @type {TerveystaloSpecialistAPIAbstractionsV1SpecialistGender}
   * @memberof SpecialistDetail
   */
  Gender?: TerveystaloSpecialistAPIAbstractionsV1SpecialistGender;
  /**
   *
   * @type {TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32}
   * @memberof SpecialistDetail
   */
  ChildAgeRange?: TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32;
  /**
   *
   * @type {TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32}
   * @memberof SpecialistDetail
   */
  AdultAgeRange?: TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32;
  /**
   *
   * @type {Array<SpecialistLanguage>}
   * @memberof SpecialistDetail
   */
  Languages?: Array<SpecialistLanguage> | null;
  /**
   *
   * @type {boolean}
   * @memberof SpecialistDetail
   */
  SignLanguage?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpecialistDetail
   */
  HiddenForSearch?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof SpecialistDetail
   */
  ClinicIds?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SpecialistDetail
   */
  CompetenceIds?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SpecialistDetail
   */
  ServiceIds?: Array<string> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof SpecialistDetail
   */
  Title?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof SpecialistDetail
   */
  Degree?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof SpecialistDetail
   */
  Specialty?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof SpecialistDetail
   */
  ReservationInstruction?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof SpecialistDetail
   */
  ShortDescription?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof SpecialistDetail
   */
  PreparationInstructions?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof SpecialistDetail
   */
  Presentation?: Array<Localized> | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SpecialistDetail
   */
  SubsystemIDs?: { [key: string]: string } | null;
}
/**
 *
 * @export
 * @interface SpecialistLanguage
 */
export interface SpecialistLanguage {
  /**
   *
   * @type {string}
   * @memberof SpecialistLanguage
   */
  TwoLetterISOCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SpecialistLanguage
   */
  Native?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloBaseAPIAbstractionsSubsystem = {
  Osku: 'Osku',
  Assisdent: 'Assisdent',
  DynamicHealth: 'DynamicHealth',
} as const;

export type TerveystaloBaseAPIAbstractionsSubsystem = typeof TerveystaloBaseAPIAbstractionsSubsystem[keyof typeof TerveystaloBaseAPIAbstractionsSubsystem];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloSpecialistAPIAbstractionsV1SpecialistGender = {
  Male: 'Male',
  Female: 'Female',
} as const;

export type TerveystaloSpecialistAPIAbstractionsV1SpecialistGender = typeof TerveystaloSpecialistAPIAbstractionsV1SpecialistGender[keyof typeof TerveystaloSpecialistAPIAbstractionsV1SpecialistGender];

/**
 *
 * @export
 * @interface TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32
 */
export interface TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32 {
  /**
   *
   * @type {number}
   * @memberof TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32
   */
  Minimum?: number;
  /**
   *
   * @type {number}
   * @memberof TerveystaloSpecialistAPIAbstractionsV1SpecialistRange1SystemInt32
   */
  Maximum?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField = {
  FirstName: 'FirstName',
  LastName: 'LastName',
  Title: 'Title',
} as const;

export type TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField = typeof TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField[keyof typeof TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField];

/**
 *
 * @export
 * @interface TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatch
 */
export interface TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatch {
  /**
   *
   * @type {TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField}
   * @memberof TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatch
   */
  Field?: TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField;
  /**
   *
   * @type {TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatchType}
   * @memberof TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatch
   */
  Type?: TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatchType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatchType = {
  Start: 'Start',
  Other: 'Other',
} as const;

export type TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatchType = typeof TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatchType[keyof typeof TerveystaloSpecialistAPIAbstractionsV2SpecialistGetSpecialistsResponseItemMatchType];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloSpecialistServiceDomainMedicalField = {
  Medicine: 'Medicine',
  Dental: 'Dental',
} as const;

export type TerveystaloSpecialistServiceDomainMedicalField = typeof TerveystaloSpecialistServiceDomainMedicalField[keyof typeof TerveystaloSpecialistServiceDomainMedicalField];

/**
 * HealthAndMonitoringApi - axios parameter creator
 * @export
 */
export const HealthAndMonitoringApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthAndMonitoringApi - functional programming interface
 * @export
 */
export const HealthAndMonitoringApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthAndMonitoringApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pingGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HealthAndMonitoringApi - factory interface
 * @export
 */
export const HealthAndMonitoringApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthAndMonitoringApiFp(configuration);
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet(options?: any): AxiosPromise<PingResult> {
      return localVarFp.pingGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HealthAndMonitoringApi - object-oriented interface
 * @export
 * @class HealthAndMonitoringApi
 * @extends {BaseAPI}
 */
export class HealthAndMonitoringApi extends BaseAPI {
  /**
   *
   * @summary Method to test service
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public pingGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .pingGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SpecialistApi - axios parameter creator
 * @export
 */
export const SpecialistApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary List or search specialists. When searchText is given, results are sorted and annotated by fields that  match search. When used search with paging, only results in within current page are sorted.
     * @param {string} [searchText]
     * @param {Array<TerveystaloSpecialistServiceDomainMedicalField>} [medicalFields]
     * @param {Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField>} [searchFields]
     * @param {number} [skip]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsGet: async (
      searchText?: string,
      medicalFields?: Array<TerveystaloSpecialistServiceDomainMedicalField>,
      searchFields?: Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField>,
      skip?: number,
      take?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/specialists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (searchText !== undefined) {
        localVarQueryParameter['searchText'] = searchText;
      }

      if (medicalFields) {
        localVarQueryParameter['medicalFields'] = medicalFields;
      }

      if (searchFields) {
        localVarQueryParameter['searchFields'] = searchFields;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get specialistid with eConcept specialistId
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsGetSpecialistIdWitheConceptIdGet: async (
      id?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/specialists/GetSpecialistIdWitheConceptId`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get specialistId\'s by clinicId and serviceId
     * @param {string} [clinicId]
     * @param {string} [serviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsGetSpecialistsIdsByClinicIDServiceIdGet: async (
      clinicId?: string,
      serviceId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/specialists/GetSpecialistsIdsByClinicIDServiceId`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (clinicId !== undefined) {
        localVarQueryParameter['clinicId'] = clinicId;
      }

      if (serviceId !== undefined) {
        localVarQueryParameter['serviceId'] = serviceId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single specialist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('v2SpecialistsIdGet', 'id', id);
      const localVarPath = `/v2/specialists/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available languages for specialists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsLanguagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/specialists/languages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get specialist by subsystem ID
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsSubsystemSubsystemIDGet: async (
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'subsystem' is not null or undefined
      assertParamExists('v2SpecialistsSubsystemSubsystemIDGet', 'subsystem', subsystem);
      // verify required parameter 'subsystemID' is not null or undefined
      assertParamExists('v2SpecialistsSubsystemSubsystemIDGet', 'subsystemID', subsystemID);
      const localVarPath = `/v2/specialists/{subsystem}/{subsystemID}`
        .replace(`{${'subsystem'}}`, encodeURIComponent(String(subsystem)))
        .replace(`{${'subsystemID'}}`, encodeURIComponent(String(subsystemID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpecialistApi - functional programming interface
 * @export
 */
export const SpecialistApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = SpecialistApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List or search specialists. When searchText is given, results are sorted and annotated by fields that  match search. When used search with paging, only results in within current page are sorted.
     * @param {string} [searchText]
     * @param {Array<TerveystaloSpecialistServiceDomainMedicalField>} [medicalFields]
     * @param {Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField>} [searchFields]
     * @param {number} [skip]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SpecialistsGet(
      searchText?: string,
      medicalFields?: Array<TerveystaloSpecialistServiceDomainMedicalField>,
      searchFields?: Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField>,
      skip?: number,
      take?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSpecialistsResponseItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SpecialistsGet(
        searchText,
        medicalFields,
        searchFields,
        skip,
        take,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get specialistid with eConcept specialistId
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SpecialistsGetSpecialistIdWitheConceptIdGet(
      id?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SpecialistsGetSpecialistIdWitheConceptIdGet(
        id,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get specialistId\'s by clinicId and serviceId
     * @param {string} [clinicId]
     * @param {string} [serviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SpecialistsGetSpecialistsIdsByClinicIDServiceIdGet(
      clinicId?: string,
      serviceId?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SpecialistsGetSpecialistsIdsByClinicIDServiceIdGet(
        clinicId,
        serviceId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get single specialist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SpecialistsIdGet(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecialistDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SpecialistsIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get available languages for specialists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SpecialistsLanguagesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SpecialistsLanguagesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get specialist by subsystem ID
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SpecialistsSubsystemSubsystemIDGet(
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SpecialistsSubsystemSubsystemIDGet(
        subsystem,
        subsystemID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SpecialistApi - factory interface
 * @export
 */
export const SpecialistApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SpecialistApiFp(configuration);
  return {
    /**
     *
     * @summary List or search specialists. When searchText is given, results are sorted and annotated by fields that  match search. When used search with paging, only results in within current page are sorted.
     * @param {string} [searchText]
     * @param {Array<TerveystaloSpecialistServiceDomainMedicalField>} [medicalFields]
     * @param {Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField>} [searchFields]
     * @param {number} [skip]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsGet(
      searchText?: string,
      medicalFields?: Array<TerveystaloSpecialistServiceDomainMedicalField>,
      searchFields?: Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField>,
      skip?: number,
      take?: number,
      options?: any,
    ): AxiosPromise<Array<GetSpecialistsResponseItem>> {
      return localVarFp
        .v2SpecialistsGet(searchText, medicalFields, searchFields, skip, take, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get specialistid with eConcept specialistId
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsGetSpecialistIdWitheConceptIdGet(id?: number, options?: any): AxiosPromise<string> {
      return localVarFp
        .v2SpecialistsGetSpecialistIdWitheConceptIdGet(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get specialistId\'s by clinicId and serviceId
     * @param {string} [clinicId]
     * @param {string} [serviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsGetSpecialistsIdsByClinicIDServiceIdGet(
      clinicId?: string,
      serviceId?: string,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .v2SpecialistsGetSpecialistsIdsByClinicIDServiceIdGet(clinicId, serviceId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get single specialist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsIdGet(id: string, options?: any): AxiosPromise<SpecialistDetail> {
      return localVarFp.v2SpecialistsIdGet(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get available languages for specialists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsLanguagesGet(options?: any): AxiosPromise<Array<string>> {
      return localVarFp.v2SpecialistsLanguagesGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get specialist by subsystem ID
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SpecialistsSubsystemSubsystemIDGet(
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .v2SpecialistsSubsystemSubsystemIDGet(subsystem, subsystemID, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SpecialistApi - object-oriented interface
 * @export
 * @class SpecialistApi
 * @extends {BaseAPI}
 */
export class SpecialistApi extends BaseAPI {
  /**
   *
   * @summary List or search specialists. When searchText is given, results are sorted and annotated by fields that  match search. When used search with paging, only results in within current page are sorted.
   * @param {string} [searchText]
   * @param {Array<TerveystaloSpecialistServiceDomainMedicalField>} [medicalFields]
   * @param {Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField>} [searchFields]
   * @param {number} [skip]
   * @param {number} [take]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialistApi
   */
  public v2SpecialistsGet(
    searchText?: string,
    medicalFields?: Array<TerveystaloSpecialistServiceDomainMedicalField>,
    searchFields?: Array<TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField>,
    skip?: number,
    take?: number,
    options?: AxiosRequestConfig,
  ) {
    return SpecialistApiFp(this.configuration)
      .v2SpecialistsGet(searchText, medicalFields, searchFields, skip, take, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get specialistid with eConcept specialistId
   * @param {number} [id]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialistApi
   */
  public v2SpecialistsGetSpecialistIdWitheConceptIdGet(id?: number, options?: AxiosRequestConfig) {
    return SpecialistApiFp(this.configuration)
      .v2SpecialistsGetSpecialistIdWitheConceptIdGet(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get specialistId\'s by clinicId and serviceId
   * @param {string} [clinicId]
   * @param {string} [serviceId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialistApi
   */
  public v2SpecialistsGetSpecialistsIdsByClinicIDServiceIdGet(
    clinicId?: string,
    serviceId?: string,
    options?: AxiosRequestConfig,
  ) {
    return SpecialistApiFp(this.configuration)
      .v2SpecialistsGetSpecialistsIdsByClinicIDServiceIdGet(clinicId, serviceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get single specialist
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialistApi
   */
  public v2SpecialistsIdGet(id: string, options?: AxiosRequestConfig) {
    return SpecialistApiFp(this.configuration)
      .v2SpecialistsIdGet(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available languages for specialists
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialistApi
   */
  public v2SpecialistsLanguagesGet(options?: AxiosRequestConfig) {
    return SpecialistApiFp(this.configuration)
      .v2SpecialistsLanguagesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get specialist by subsystem ID
   * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
   * @param {string} subsystemID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialistApi
   */
  public v2SpecialistsSubsystemSubsystemIDGet(
    subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
    subsystemID: string,
    options?: AxiosRequestConfig,
  ) {
    return SpecialistApiFp(this.configuration)
      .v2SpecialistsSubsystemSubsystemIDGet(subsystem, subsystemID, options)
      .then(request => request(this.axios, this.basePath));
  }
}
