import noop from 'lodash/noop';
import { ConfigModelType } from 'model/config';

// Creates a bound console.log() callback, with a pre-attached label, so the output is easier to filter in the browser console.
// Binding has the nice side-effect of having working links to the actual site of the logging call in both browser & node console.
// The config is provided so that logging can be centrally enabled/disabled. Also, filtering based on label might be added via config later.
export function createLogger(config: ConfigModelType['FrontendConfig'], label = '') {
  if (!config.FEATURE_LOGGING) return noop;
  // eslint-disable-next-line no-console
  return label ? console.log.bind(console, `[${label}]`) : console.log.bind(console);
}

// Functions/components that support logging can accept an external logger using this interface
export type Logger = ReturnType<typeof createLogger>;

// This no-op logger can be used in places where it's more convenient to always have a logger (even if it does nothing)
// compared to having to if (log) log(...) all the time.
export const NO_LOGGING: Logger = noop;
