import mapValues from 'lodash/mapValues';
import { FC } from 'react';

// Infers the type wrapped by a Promise
export type PromiseOf<T> = T extends Promise<infer R> ? R : never;

// Modified version of Parameters; doing this programmatically is currently not QUITE possible in TS (but almost)
// https://github.com/microsoft/TypeScript/issues/26058
/* eslint-disable-next-line @typescript-eslint/ban-types */
export type ArgsOfSkip1<T extends Function> = T extends (a: any) => any
  ? []
  : T extends (a: any, b: infer B) => any
  ? [B]
  : T extends (a: any, b: infer B, c: infer C) => any
  ? [B, C]
  : T extends (a: any, b: infer B, c: infer C, d: infer D) => any
  ? [B, C, D]
  : T extends (a: any, b: infer B, c: infer C, d: infer D, e: infer E) => any
  ? [B, C, D, E]
  : never;

// Can be used to implement exhaustiveness checks in TS.
// Returns "any" for convenience.
export function assertExhausted(value: void): any {
  throw new Error(`Runtime behaviour doesn't match type definitions (value was "${value}")`);
}

// Simple helper for reducing repetition with complex type defs
export type OneOrArrayOf<T> = T | T[];

// Getting the EXACT key type of an arbitrary object in TS isn't straightforward.
// https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript
// This utility type exists to document places where we effectively need to cast to "any" to get the signature we want.
// It should be noted that it's always possible to avoid having to cast to any, but in cases where this utility is used,
// we make the conscious choice of risking the edge cases, in favor of GREATLY simplified code, with less pointless repetition.
// TL;DR: USE THIS ONLY WITH OBJECT LITERALS, AND YOU WON'T BE SURPRISED.
export type MappedObjectAny = any;

// Same as _.mapValues(), but preserves object keys more carefully.
// See MappedObjectResult for the known caveats.
export function mapValuesExactly<I extends Record<string, any>, O>(
  object: I,
  mapper: (value: I[keyof I], key: keyof I, collection: I) => O,
): { [key in keyof I]: O } {
  return mapValues(object, mapper) as MappedObjectAny;
}

// Similar helper (with similar caveats) as mapValuesExactly(), but for just obtaining the key type of an object
export function objectKeysExactly<T extends Record<string, any>>(object: T): Array<keyof T> {
  return Object.keys(object) as MappedObjectAny;
}

export type UiComponent = FC<{ className?: string } & any>;
