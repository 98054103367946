import * as t from 'io-ts';
import mapValues from 'lodash/mapValues';
import { MappedObjectAny } from 'utils/types/misc';

// Helper types for defining what goes in & comes out of defineModels()
type ModelSpecMap = { [key: string]: t.Props };
type ModelCategory<T extends ModelSpecMap> = { [K in keyof T]: t.TypeC<T[K]> & { name: keyof T } };

// Export helpers for typing a Model by its name, or just a Model in general
type ModelName<C extends ModelCategory<any>> = keyof C;
export type ModelType<C extends ModelCategory<any>> = { [key in ModelName<C>]: t.TypeOf<C[key]> };
export type AnyModel<C extends ModelCategory<any>> = C[ModelName<C>];

// Helper function for defining Models en masse, and attaching a name to them without repetition
export function defineModels<T extends ModelSpecMap>(specs: T): ModelCategory<T> {
  return mapValues(specs, (spec, name) => t.type(spec, name)) as MappedObjectAny;
}
