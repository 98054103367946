import React, { FC, Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'utils/react/ui-context';

type Props = {
  children?: React.ReactNode;
};

// Modified from https://reacttraining.com/react-router/web/guides/scroll-restoration
export const ScrollToTop: FC<Props> = ({ children }) => {
  const { action } = useHistory();
  const location = useLocation<{ preventScrollToTop?: boolean }>();
  const { pathname } = location;
  const [previousPath, setPreviousPath] = useState(pathname);

  const pathChanged = pathname !== previousPath;
  const isForward = action === 'PUSH' || action === 'REPLACE';

  // To prevent scroll when so stated
  const preventScroll = location && location.state && location.state.preventScrollToTop;

  const doScroll = pathChanged && isForward && !preventScroll;

  // Check if there is a need to do a scroll to top
  useEffect(() => {
    if (doScroll) {
      window.scrollTo(0, 0);
    }
  }, [doScroll]);

  // Update the previous path when the pathname changes
  useEffect(() => {
    setPreviousPath(pathname);
  }, [pathname]);

  return <Fragment>{children}</Fragment>;
};
