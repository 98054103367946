import { isError, parseErrorCode } from 'model/error';
import { useEffect } from 'react';
import { randomStateString, useLoginType } from 'utils/auth/helpers';
import { useReduxDispatch, useReduxState, useUiContext } from 'utils/react/ui-context';
import { getStorageItem, getStorageObjectItem, removeStorageItem, setStorageObjectItem } from 'utils/storage/helpers';
import { deepGet } from 'utils/types/deepGetSet';

const storageKey = 'st-booking-delegate';
export const delegateStateStorageKey = 'st-booking-delegate-cb';

export function removeStoredDelegate(preserveAge = false) {
  if (preserveAge) {
    const { age } = getStorageObjectItem(storageKey);
    storeDelegate(typeof age === 'number' ? age : undefined, undefined);
  } else {
    removeStorageItem(storageKey);
  }
}

export const generateDelegateAuthStateStr = (registration: string) => {
  const state = randomStateString();
  setStorageObjectItem(delegateStateStorageKey, {
    state,
    registration,
    pathname: window.location.pathname,
    search: window.location.search.slice(1) || undefined,
  });
  return state;
};

const storeDelegate = (age: number | undefined, token: string | undefined) => {
  setStorageObjectItem(storageKey, { age, token });
};

export const useDelegateLogin = () => {
  const auth = useReduxState(state => state.auth);
  const delegate = useReduxState(state => state.delegate);
  const { lang, embedInApp } = useReduxState(state => state.location);
  const dispatch = useReduxDispatch();
  const { apiClient, getCurrentTime } = useUiContext();
  const loginType = useLoginType();

  useEffect(() => {
    // Do not do delegate login when using weak auth
    if (loginType !== 'oneWelcome' && delegate.status !== 'NOTHING') {
      removeStoredDelegate();
      dispatch.delegateExit();
      return;
    }
    if (delegate.status === 'RETURNING' && auth.status === 'LOGGED_IN') {
      // Fetch token if returning from suomi.fi
      dispatch.delegateTokenLoading();
      apiClient
        .getDelegateToken(lang, auth.token, delegate.registration, delegate.code)
        .then(({ token, notRegistered, name, age }) => {
          if (notRegistered) {
            dispatch.newDelegateUser(name, { accessToken: token }, age);
            storeDelegate(age || delegate.age, undefined);
          } else {
            dispatch.setDelegateToken({ accessToken: token });
            storeDelegate(age || delegate.age, token);
          }
        })
        .catch(err => {
          const errorType = isError.AdaApiManagedError(err)
            ? `${deepGet(err, 'AdaApiManagedError', 'responseData', 'type')}`
            : undefined;
          if (errorType === 'Terveystalo.CustomerAPI.Controllers.V2.DelegationForbiddenAPIException') {
            dispatch.delegateError({ type: 'not_allowed' });
          }
          dispatch.delegateError({ type: 'other_token_error' });
        });
    } else if (delegate.status === 'DELEGATION') {
      // Check if there is login info stored for the delegate and use it if age matches
      const { token, age } = getStorageObjectItem(storageKey);
      if (typeof age === 'number' && (delegate.age === undefined || age === delegate.age)) {
        if (token && typeof token === 'string') {
          dispatch.setDelegateToken({ accessToken: token });
          storeDelegate(age, token);
        } else {
          dispatch.setDelegateAge(age);
          storeDelegate(delegate.age !== undefined ? delegate.age : age, undefined);
        }
      } else {
        // Store age and change delegate status to prevent a loop
        dispatch.setDelegateAge(delegate.age);
        storeDelegate(delegate.age, undefined);
      }
    } else if (delegate.status === 'TOKEN_RETRIEVED' && auth.status === 'LOGGED_IN') {
      // If there is token only, fetch delegate data from api
      dispatch.delegateUserDataLoading(delegate.token);
      apiClient
        .getDelegateCustomer(lang, auth.token, delegate.token)
        .then(customer => {
          const age = customer.age || delegate.age;
          dispatch.delegateLogin({ ...customer, age: age || null }, delegate.token, age);
          if (age !== delegate.age) {
            storeDelegate(age, delegate.token.accessToken);
          }
        })
        .catch(err => {
          const errorCode = parseErrorCode(err);
          if (errorCode === 401 || errorCode === 403) {
            dispatch.delegateError({ type: 'token_invalid' });
          } else {
            dispatch.delegateError({ type: 'other' });
          }
        });
    } else if (delegate.status === 'ERROR') {
      // If the token is faulty, make sure it is not stored
      if (getStorageItem(storageKey)) removeStoredDelegate(true);
    }
  }, [delegate, apiClient, auth, dispatch, embedInApp, getCurrentTime, lang, loginType]);
};

export const useDelegateChange = () => {
  const dispatch = useReduxDispatch();
  const delegate = useReduxState(state => state.delegate);

  return () => {
    removeStoredDelegate(true);
    if (delegate.status !== 'NOTHING') {
      dispatch.delegateChange();
    }
  };
};
