import {
  AppointmentTypesState,
  LocationState,
  RestrictionsState,
  SpecialistGenderState,
  SpecialistLanguageState,
  SpecialistOrServiceState,
  TimeOfDayState,
} from 'reducers/filters';
import { defineActionCreators } from 'utils/redux/actions';

export default defineActionCreators({
  setRestrictions(restrictions: RestrictionsState) {
    return { restrictions };
  },
  setRestrictionsAction(action: (prevState: RestrictionsState) => RestrictionsState) {
    return { action };
  },
  setPersonalizationConsent(personalizationConsent: boolean | undefined) {
    return { personalizationConsent };
  },
  filterByService(specialistOrService: SpecialistOrServiceState, withBack?: boolean, resetErrors = true) {
    return { specialistOrService, withBack, resetErrors };
  },
  filterByLocation(location: LocationState, resetErrors = true) {
    return { location, resetErrors };
  },
  filterByDate(date: number, resetErrors = true) {
    return { date, resetErrors };
  },
  filterByFromTimeOfDay(fromTimeOfDay: TimeOfDayState, resetErrors = true) {
    return { fromTimeOfDay, resetErrors };
  },
  filterByToTimeOfDay(toTimeOfDay: TimeOfDayState, resetErrors = true) {
    return { toTimeOfDay, resetErrors };
  },
  filterByAppointmentTypes(appointmentTypes: AppointmentTypesState, resetErrors = true) {
    return { appointmentTypes, resetErrors };
  },
  filterForCallRequest(callRequest: boolean, resetErrors = true) {
    return { callRequest, resetErrors };
  },
  filterBySpecialistGender(specialistGender: SpecialistGenderState, resetErrors = true) {
    return { specialistGender, resetErrors };
  },
  filterBySpecialistLanguage(specialistLanguage: SpecialistLanguageState, resetErrors = true) {
    return { specialistLanguage, resetErrors };
  },
  showMoreResults() {
    return {};
  },
  nextServiceIndex() {
    return {};
  },
  doAlternativeServicesSearch() {
    return {};
  },
  filtersReset(currentDate: number) {
    return { currentDate };
  },
});
