/** @jsxImportSource @emotion/react */
import React, { FC, Fragment } from 'react';
import { CriticalNotice } from 'ui/misc/CriticalNotice';
import { InfoNotice } from 'ui/misc/InfoNotice';
import { SymptomNotice } from 'ui/misc/SymptomNotice';
import { useReduxState } from 'utils/react/ui-context';

export const Notices: FC = () => {
  const embedInApp = useReduxState(state => state.location.embedInApp);
  const { vaccineGuideState } = useReduxState(state => state.notice);

  if (vaccineGuideState && vaccineGuideState.completed) {
    return <SymptomNotice />;
  }

  if (embedInApp) {
    return (
      <Fragment>
        <CriticalNotice />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <CriticalNotice />
      <InfoNotice />
    </Fragment>
  );
};
