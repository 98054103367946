import { ApiClient } from 'api/ApiClient';
import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import { getErrorAsString, isError } from 'model/error';
import { NO_ANALYTICS } from 'utils/analytics/analytics-utils';
import { NO_LOGGING } from 'utils/logging/logging-utils';
import { Dispatch } from 'utils/react/ui-context';
import { Store } from 'utils/redux/store';
import { deepGet } from 'utils/types/deepGetSet';
import { PromiseOf } from 'utils/types/misc';
import { loading, notAsked, RemoteData } from 'utils/types/remoteData';

export type ApiFetcher = ReturnType<typeof createApiFetcher>;

// A specification of which ApiClient method to invoke, and with which arguments
type FetchSpec = [keyof ApiClient, Parameters<ApiClient[keyof ApiClient]>];

// Additional options supported for fetches
type Options = { fetchDebounceTime?: number; fetchDebounceId?: string };

// Additional actions to perform after fetching
export type CallbackActions<M extends keyof ApiClient = keyof ApiClient> = {
  onSuccess?: (res: PromiseOf<ReturnType<ApiClient[M]>>) => void;
  onFail?: () => void;
};

// Invocations of ApiFetcher.fetch() return this type. Notably, because calling fetch()
// will always cause at least an attempt at fetching data, we won't ever return "not asked".
export type FetchReturnType<T> = Exclude<RemoteData<T>, { status: 'REMOTE_DATA_NOT_ASKED' }>;

// Creates a convenience wrapper around the given ApiClient.
// The resulting wrapper is designed to enable declarative data fetches from React/Redux.
export function createApiFetcher(
  apiClient: ApiClient,
  getState: Store['getState'],
  dispatch: Dispatch,
  log = NO_LOGGING,
  analytics = NO_ANALYTICS,
) {
  // By default, the fetcher doesn't maintain state of its own; that's outsourced to Redux.
  // However, while collecting fetch orders from UI components, we have to keep track of
  // things to do before the Redux store has had a chance to update. This object keeps track
  // of resources we are about to fetch, and are in the process of fetching. One reason for
  // doing this is to ensure we don't double-fetch resources that are requested by more than 1
  // component at the same time. Once the fetches complete, entries are removed from this
  // object, and it becomes empty again.
  const todo = {} as {
    [resourceId: string]: { fetch: FetchSpec; underway?: true; actions: CallbackActions } | undefined;
  };

  // By default, fetches are scheduled (more or less) immediately when asked. However, to make
  // it easier to implement features such as autocomplete boxes etc, we optionally support
  // specifying a debounce behaviour by the caller. In short, when you make multiple fetches with
  // the same fetchDebounceId, only the LAST of those fetches (within the debounce time window)
  // will actually be made.
  const externalDebounces = {} as {
    [fetchDebounceId: string]:
      | { resourceId: string; fetch: FetchSpec; actions: CallbackActions; callback: (() => void) & Cancelable }
      | undefined;
  };
  interface Cancelable {
    cancel(): void;
    flush(): void;
  }

  // Fetches are not triggered immediately when data is requested. This is to try to make sure
  // we know all data needs of the currently rendering UI before starting any fetches. Thus, the
  // actual method doing the fetches is invoked asynchronously.
  const performFetchesDebounced = debounce(performFetches, 1);

  // When running in the test suite, override any debounce values to make the tests go faster.
  const debounceOverride = process.env.NODE_ENV === 'test' ? 1 : undefined;

  return {
    fetch<M extends keyof ApiClient>(
      method: M,
      params: Parameters<ApiClient[M]>,
      options: Options = {},
      actions: CallbackActions<M> = {},
    ): FetchReturnType<PromiseOf<ReturnType<ApiClient[M]>>> {
      const resourceId = getResourceId(method, params);
      const result = getCurrent(resourceId); // check to see if Redux already knows about this resource
      if (result.status !== 'REMOTE_DATA_NOT_ASKED') {
        if (options.fetchDebounceId !== undefined) {
          debounceFetchCancel(options.fetchDebounceId); // we have something to give back already -> cancel debounced fetches, if any were set up
        }
        return result; // if we've already done something about this resource, just return our current knowledge of the state of it
      } else if (options.fetchDebounceId !== undefined) {
        debounceFetch(
          resourceId,
          [method, params],
          { fetchDebounceId: '', fetchDebounceTime: 500, ...options },
          actions,
        ); // we were asked to debounce the fetch -> give it the special treatment it needs
      } else {
        scheduleFetch(resourceId, [method, params], actions); // no special treatment needed -> schedule the requested fetch immediately
      }
      return loading; // we just scheduled a fetch (debounced or not) -> from the user's PoV, we started loading data
    },
    clear<M extends keyof ApiClient>(method: M, params: Parameters<ApiClient[M]>) {
      const resourceId = getResourceId(method, params);
      const result = getCurrent(resourceId); // check to see if Redux knows about this resource
      if (result.status !== 'REMOTE_DATA_NOT_ASKED') {
        delete todo[resourceId]; // clear a possible request that is not in the redux store yet
        dispatch.clearData(resourceId); // clear the result from the reduct store
      }
    },
    getResourceId,
    invokeApiClient,
  };

  // An ID that uniquely identifies this data fetch, for e.g. resource deduplication
  function getResourceId<M extends keyof ApiClient>(method: M, params: Parameters<ApiClient[M]>) {
    return `${method}:${JSON.stringify(params)}`;
  }

  // Returns the current Redux state for the given resourceId; defaults to "not asked"
  function getCurrent(resourceId: string): RemoteData<any> {
    const rawResult = deepGet(getState().apiData, 'data', resourceId);
    return rawResult || notAsked;
  }

  // Performs the necessary work to debounce the given fetch.
  // After the debounce time window expires, delegates a call to scheduleFetch() as if it had been called directly originally.
  function debounceFetch(
    resourceId: string,
    fetch: FetchSpec,
    options: Options & { fetchDebounceId: string },
    actions: CallbackActions,
  ) {
    const { fetchDebounceId, fetchDebounceTime } = options;
    const existingWork = externalDebounces[fetchDebounceId];
    if (existingWork) {
      // There's already a debounce-entry for this fetchDebounceId -> update its data & reset the debounce time window
      existingWork.resourceId = resourceId;
      existingWork.fetch = fetch;
      existingWork.actions = actions;
      existingWork.callback();
    } else {
      // This is the first time this fetchDebounceId has been requested -> set it up
      const callback = debounce(() => {
        const work = externalDebounces[fetchDebounceId];
        if (!work) return; // this is mostly to satisfy TS; we've verified the existence of the key further up, but better safe than sorry!
        scheduleFetch(work.resourceId, work.fetch, work.actions);
        delete externalDebounces[fetchDebounceId];
      }, debounceOverride || fetchDebounceTime);
      externalDebounces[fetchDebounceId] = {
        resourceId,
        fetch,
        actions,
        callback,
      };
      callback(); // start the debounce timer initially
    }
  }

  // If there's a debounced fetch with this ID, cancel it; if not, ignore the call
  function debounceFetchCancel(fetchDebounceId: string) {
    const existingWork = externalDebounces[fetchDebounceId];
    if (!existingWork) return;
    existingWork.callback.cancel(); // make sure the debounced function doesn't trigger
    delete externalDebounces[fetchDebounceId]; // remove its entry in our bookkeeping
  }

  // Makes a note that the given resourceId with its fetch specification needs to be fetched soon (but not immediately!)
  function scheduleFetch(resourceId: string, fetch: FetchSpec, actions: CallbackActions) {
    if (todo[resourceId]) return; // a fetch has already been scheduled (or is underway) for this resource -> it's already being handled -> no need to do anything
    const result = getCurrent(resourceId);
    if (result.status === 'REMOTE_DATA_SUCCESS') return; // we already have this data -> no need to do anything
    todo[resourceId] = { fetch, actions }; // add note about this fetch
    performFetchesDebounced(); // ...and ensure someone will get back to it soon
  }

  // Looks at the resources we've earmarked for fetching before, and fetches them, dispatching actions to indicate progress
  function performFetches() {
    const batchStartMs = performance ? performance.now() : null;
    const batched: string[] = [];
    let pendingBatched: string[] = [];
    forEach(todo, (item, resourceId) => {
      if (!item) return; // this is mostly a TS technicality; when reading nonexistent keys from 'todo' you theoretically could get undefined, though forEach() ensures that won't happen
      if (item.underway) return; // this fetch is already underway -> let's not re-fetch until the ongoing one has finished (either with success or failure)
      item.underway = true;
      batched.push(item.fetch[0]);
      pendingBatched.push(resourceId);
      const fetchStartMs = performance ? performance.now() : null;
      Promise.resolve()
        .then(() => dispatch.fetchStarted(resourceId))
        .then(() => invokeApiClient(item.fetch))
        .then(
          res => {
            dispatch.fetchSucceeded(resourceId, res);
            if (fetchStartMs !== null) {
              analytics.sendFetchDetails(item.fetch[0], resourceId, performance.now() - fetchStartMs, {
                type: 'success',
                resultCount: Array.isArray(res) ? res.length : undefined,
              });
            }
            if (item.actions.onSuccess) item.actions.onSuccess(res);
          },
          err => {
            const errorCode = isError.AdaApiManagedError(err)
              ? err.AdaApiManagedError.apiErrorCode
              : err.response && typeof err.response.status === 'number'
              ? err.response.status
              : null;
            dispatch.fetchFailed(resourceId, err.message, errorCode, err.response?.headers?.errorid);
            if (fetchStartMs !== null) {
              analytics.sendFetchDetails(item.fetch[0], resourceId, performance.now() - fetchStartMs, {
                type: 'failure',
                errorMessage: getErrorAsString(err),
              });
            }
            if (item.actions.onFail) item.actions.onFail();
          },
        )
        .finally(() => {
          delete todo[resourceId]; // remove the note about the fetch, so it can be re-fetched in the future, if someone so chooses
          pendingBatched = pendingBatched.filter(id => id !== resourceId);
          if (pendingBatched.length === 0 && batchStartMs !== null)
            analytics.sendFetchBatchMetrics(batched, performance.now() - batchStartMs);
        });
    });
    log(`Batched fetches for ${batched.length} resources`, batched);
  }

  // This is the end of the road: where our TS-fu ends and where we need to resort to "as any".
  // We'll assume that because everything outside this method is well-typed that we're off the hook.
  // There's also runtime validation to make sure no-one's invoking any illegal methods on the API client.
  function invokeApiClient(fetch: FetchSpec): Promise<PromiseOf<ReturnType<ApiClient[keyof ApiClient]>>> {
    const [method, params] = fetch;
    if (apiClient.hasOwnProperty(method)) {
      log(`Fetching with method "${method}" with params`, params);
      return (apiClient as any)[method].apply(null, params);
    } else {
      throw new Error(`Unknown method "${method}" specified for ApiClient`);
    }
  }
}
