/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useTranslations } from 'utils/react/ui-context';
import { BookingRoot } from 'utils/routes/routes';
import { getBookingRootTitle } from 'utils/routes/texts';
import { classNames } from 'utils/styles/helpers';

type Props = {
  titleOverride?: string;
  bookingRoot: BookingRoot | undefined;
};

export const HeaderBanner: FC<Props> = ({ bookingRoot, titleOverride }) => {
  const t = useTranslations();

  const bannerText = titleOverride || (bookingRoot ? getBookingRootTitle(bookingRoot, t) : undefined);

  if (!bannerText) {
    return null;
  }

  return (
    <header className={classNames('bg-matisse-blue-15', 'py-0.5', 'px-1')}>
      <div className={classNames('text-matisse-blue')}>{bannerText}</div>
    </header>
  );
};
