import { InsuranceCompanies } from 'utils/partnerAndInsurance/types';
import { useEnvironment, useTranslations } from 'utils/react/ui-context';

export const useOwnInformationUrl = () => {
  const t = useTranslations();
  const env = useEnvironment();

  return env === 'prod'
    ? t.ext_link_manage_own_information
    : env === 'stg'
    ? t.ext_link_manage_own_information_stg
    : t.ext_link_manage_own_information_test;
};

export const useTtComServicesUrl = () => {
  const t = useTranslations();
  const env = useEnvironment();

  return env === 'prod'
    ? t.ext_link_tt_com_services
    : env === 'stg'
    ? t.ext_link_tt_com_services_stg
    : t.ext_link_tt_com_services_test;
};

export const useTtComHome = () => {
  const t = useTranslations();
  const env = useEnvironment();

  return env === 'prod'
    ? t.ext_link_tt_com_home
    : env === 'stg'
    ? t.ext_link_tt_com_home_stg
    : t.ext_link_tt_com_home_test;
};

export const useHtiaUrl = () => {
  const t = useTranslations();
  const env = useEnvironment();

  return env === 'prod'
    ? t.ext_link_htia_new_symptom
    : env === 'stg'
    ? t.ext_link_htia_new_symptom_stg
    : t.ext_link_htia_new_symptom_test;
};

export const useTtComBookingUrl = () => {
  const t = useTranslations();
  const env = useEnvironment();

  return (webCode: string) => {
    return env === 'prod'
      ? t.ext_link_tt_com_appointment + webCode
      : env === 'stg'
      ? t.ext_link_tt_com_appointment_stg + webCode
      : t.ext_link_tt_com_appointment_test + webCode;
  };
};

export const useOBOBookingInfoUrl = () => {
  const t = useTranslations();
  return t.ext_link_obo_booking_info;
};

/* 
  Logic to return desired external url for insurance companies sites for different cases when user is sent there
  after either one said not to have permissions or backend request returned no valid permissions
*/
export const useInsuranceCompanyRedirUrl = (
  companyName: InsuranceCompanies | null,
  isOnBehalfOf: boolean,
  noResultsCase: boolean,
) => {
  const env = useEnvironment();
  if (!!isOnBehalfOf && !!noResultsCase) {
    switch (companyName) {
      case 'LähiTapiolaIndividual':
        return env === 'prod'
          ? 'https://asiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=w&lang=fi&data=tt&redirectUrl=https%3A%2F%2Fajanvaraus.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?delegation=true&icid=5&cancelUrl=https%3A%2F%2Fajanvaraus.terveystalo.com/fi/ajanvaraus/vakuutus/?delegation=true&icid=19&errorUrl=https%3A%2F%2Fajanvaraus.terveystalo.com/fi/ajanvaraus/vakuutus/?delegation=true&icid=19'
          : env === 'stg'
          ? 'https://uatasiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=W&lang=fi&data=dummy-use-custom-here&redirectUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?delegation=true&icid=5&cancelUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com/fi/ajanvaraus/vakuutus/?delegation=true&icid=19&errorUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com/fi/ajanvaraus/vakuutus/?delegation=true&icid=19'
          : 'https://uatasiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=W&lang=fi&data=dummy-use-custom-here&redirectUrl=https:%2F%2Fajanvaraus.test.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?icid=5&cancelUrl=https:%2F%2Fajanvaraus.test.terveystalo.com/fi/ajanvaraus/vakuutus/?delegation=true&icid=19&errorUrl=https:%2F%2Fajanvaraus.test.terveystalo.com/fi/ajanvaraus/vakuutus/?delegation=true&icid=19';
    }
  }
  if (!!noResultsCase) {
    switch (companyName) {
      case 'LähiTapiolaIndividual':
        return env === 'prod'
          ? 'https://asiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=w&lang=fi&data=tt&redirectUrl=https%3A%2F%2Fajanvaraus.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?icid=5&cancelUrl=https%3A%2F%2Fajanvaraus.terveystalo.com/fi/ajanvaraus/vakuutus/?icid=19&errorUrl=https%3A%2F%2Fajanvaraus.terveystalo.com/fi/ajanvaraus/vakuutus/?icid=19'
          : env === 'stg'
          ? 'https://uatasiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=W&lang=fi&data=dummy-use-custom-here&redirectUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?icid=5&cancelUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com/fi/ajanvaraus/vakuutus/?icid=19&errorUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com/fi/ajanvaraus/vakuutus/?icid=19 '
          : 'https://uatasiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=W&lang=fi&data=dummy-use-custom-here&redirectUrl=https:%2F%2Fajanvaraus.test.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?icid=5&cancelUrl=https:%2F%2Fajanvaraus.test.terveystalo.com/fi/ajanvaraus/vakuutus/?icid=19&errorUrl=https:%2F%2Fajanvaraus.test.terveystalo.com/fi/ajanvaraus/vakuutus/?icid=19';
    }
  }
  if (!!isOnBehalfOf) {
    switch (companyName) {
      case 'LähiTapiolaIndividual':
        return env === 'prod'
          ? 'https://asiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=w&lang=fi&data=tt&redirectUrl=https%3A%2F%2Fajanvaraus.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?delegation=true&icid=5&cancelUrl=https%3A%2F%2Fajanvaraus.terveystalo.com&errorUrl=https%3A%2F%2Fajanvaraus.terveystalo.com '
          : env === 'stg'
          ? 'https://uatasiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=W&lang=fi&data=dummy-use-custom-here&redirectUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?delegation=true&icid=5&cancelUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com&errorUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com'
          : 'https://uatasiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=W&lang=fi&data=dummy-use-custom-here&redirectUrl=https:%2F%2Fajanvaraus.test.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?delegation=true&icid=5&cancelUrl=https:%2F%2Fajanvaraus.test.terveystalo.com&errorUrl=https:%2F%2Fajanvaraus.test.terveystalo.com';
    }
  }

  switch (companyName) {
    case 'LähiTapiolaIndividual':
      return env === 'prod'
        ? 'https://asiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=w&lang=fi&data=tt&redirectUrl=https%3A%2F%2Fajanvaraus.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?icid=5&cancelUrl=https%3A%2F%2Fajanvaraus.terveystalo.com&errorUrl=https%3A%2F%2Fajanvaraus.terveystalo.com'
        : env === 'stg'
        ? 'https://uatasiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=W&lang=fi&data=dummy-use-custom-here&redirectUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?icid=5&cancelUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com&errorUrl=https:%2F%2Fajanvaraus.stg.terveystalo.com'
        : 'https://uatasiointi.lahitapiola.fi/hccompensationpermit/#/?vendor=1093863-3&channel=W&lang=fi&data=dummy-use-custom-here&redirectUrl=https:%2F%2Fajanvaraus.test.terveystalo.com/fi/ajanvaraus/vakuutus/suorakorvaus/?icid=5&cancelUrl=https:%2F%2Fajanvaraus.test.terveystalo.com&errorUrl=https:%2F%2Fajanvaraus.test.terveystalo.com';
  }
  return undefined;
};
