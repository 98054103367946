import { regularAppointmentTypes } from 'model/api';
import { parse } from 'query-string';
import { LocationState, MultipleClinics, SpecialistOrServiceState } from 'reducers/filters';
import { useIsBookingForChildren } from 'utils/booking/helpers';
import { useApiData, useConfig, useReduxState, useTranslations } from 'utils/react/ui-context';
import { useRespiratorySymptoms } from 'utils/symptoms/helpers';
import { assertExhausted } from 'utils/types/misc';
import { notAsked } from 'utils/types/remoteData';

// Helper objects
const loadingSpecialistOrService = { type: 'loading' as const, name: '', isDental: false };
const noSpecialistOrService = { type: 'none' as const, name: '', isDental: false };

// Helper for getting/fetching the language-specific name and other additional data of the selected specialist/service filter value
export const useSpecialistOrServiceFilter = ():
  | {
      type: Exclude<SpecialistOrServiceState, null>['type'];
      id: string;
      name: string;
      chatId?: number | null;
      isDental: boolean;
      clinicIds?: string[];
    }
  | typeof loadingSpecialistOrService
  | typeof noSpecialistOrService => {
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const specialistOrService = useReduxState(state => state.filters.specialistOrServiceFilter);
  const restrictions = useReduxState(state => state.filters.restrictions);
  const config = useConfig();
  const medicalType = bookingRoot === 'dental' ? 'dental' : 'all';
  const findServiceFilter = useFindServiceFilter(medicalType);
  const findSpecialistFilter = useFindSpecialistFilter(medicalType);
  const bookingData = useMoveBooking();
  const respiratorySymptoms = useRespiratorySymptoms();
  const isBookingForChildren = useIsBookingForChildren();

  // Use filters from booking to move if nothing else is explicitly selected
  if (!specialistOrService && bookingData) {
    if (bookingData.status === 'REMOTE_DATA_LOADING') {
      return loadingSpecialistOrService;
    }
    if (bookingData.status === 'REMOTE_DATA_SUCCESS') {
      const booking = bookingData.data;
      // Use specialist for search for new appointments and fall back to service if specialist is not found
      const specialistFilter = booking.specialist.specialistId
        ? findSpecialistFilter(booking.specialist.specialistId)
        : noSpecialistOrService;
      if (specialistFilter) {
        return specialistFilter;
      }
      const serviceFilter =
        'service' in booking && booking.service ? findServiceFilter(booking.service.serviceId) : null;
      if (serviceFilter) {
        return serviceFilter;
      }
    }
  }

  // Check if a default should be used
  if (!specialistOrService) {
    // Do not use defaults when there are restrictions in place
    if (restrictions && (restrictions.serviceId || restrictions.specialistId)) {
      return noSpecialistOrService;
    }

    // Use respiratory default service for users with symptoms in non-dental booking
    if (bookingRoot !== 'dental' && respiratorySymptoms === 'symptoms') {
      return findServiceFilter(config.CORONA_SERVICES_MAP.corona_doctor);
    }

    const defaultService =
      bookingRoot === 'occupationalHealthcare'
        ? null
        : bookingRoot === 'dental'
        ? config.DENTAL_DEFAULT_SERVICE_ID
        : isBookingForChildren
        ? config.CHILD_DEFAULT_SERVICE_IDS[0]
        : config.DEFAULT_SERVICE_ID;
    return !!defaultService ? findServiceFilter(defaultService) : noSpecialistOrService;
  }

  if (specialistOrService.type === 'service') {
    return findServiceFilter(specialistOrService.serviceId);
  }

  if (specialistOrService.type === 'specialist') {
    return findSpecialistFilter(specialistOrService.specialistId);
  }

  return noSpecialistOrService;
};

const useFindServiceFilter = (medicalType: 'all' | 'dental') => {
  const getService = useApiData('getService');
  const getPopularServices = useApiData('getPopularServices');

  return (serviceId: string) => {
    const serviceData = getService([serviceId]);
    if (serviceData.status === 'REMOTE_DATA_LOADING') {
      return loadingSpecialistOrService;
    }
    if (serviceData.status === 'REMOTE_DATA_SUCCESS') {
      const service = serviceData.data;
      if (!service.bookingEnabled || (medicalType === 'dental' && !service.isDental)) {
        return noSpecialistOrService;
      }
      // Check if service is one of imaging subservices, if yes, select the main service instead
      if (service && service.referralRequired) {
        const popularServices = getPopularServices([]);
        if (popularServices.status === 'REMOTE_DATA_LOADING') {
          return loadingSpecialistOrService;
        }
        const imagingCategory =
          popularServices.status === 'REMOTE_DATA_SUCCESS'
            ? popularServices.data.imaging.find(d => service && d.subServiceIds.includes(service.serviceId))
            : undefined;
        if (imagingCategory) {
          const imagingServiceData = getService([imagingCategory.mainServiceId]);
          if (imagingServiceData.status === 'REMOTE_DATA_LOADING') {
            return loadingSpecialistOrService;
          } else if (imagingServiceData.status === 'REMOTE_DATA_SUCCESS' && imagingServiceData.data.bookingEnabled) {
            const imagingService = imagingServiceData.data;
            return {
              type: 'service' as const,
              id: imagingService.serviceId,
              name: imagingService.serviceName,
              chatId: imagingService.chatId,
              isDental: imagingService.isDental,
            };
          }
          return noSpecialistOrService;
        }
      }
      return {
        type: 'service' as const,
        id: service.serviceId,
        name: service.serviceName,
        chatId: service.chatId,
        isDental: service.isDental,
      };
    }
    return noSpecialistOrService;
  };
};

const useFindSpecialistFilter = (medicalType: 'all' | 'dental') => {
  const getSpecialist = useApiData('getSpecialist');

  return (specialistId: string) => {
    const specialistData = getSpecialist([specialistId]);
    if (specialistData.status === 'REMOTE_DATA_LOADING') {
      return loadingSpecialistOrService;
    }
    const matchedData =
      specialistData.status === 'REMOTE_DATA_SUCCESS' &&
      (medicalType === 'all' || (medicalType === 'dental' && specialistData.data.isDental))
        ? specialistData.data
        : undefined;
    return matchedData
      ? {
          type: 'specialist' as const,
          id: matchedData.specialistId,
          name: `${matchedData.firstName} ${matchedData.lastName}`,
          isDental: matchedData.isDental,
          clinicIds: matchedData.clinicIds,
        }
      : noSpecialistOrService;
  };
};

// Helper objects
const loadingLocation = { type: 'loading' as const, name: '', clinicIds: [] as string[], isDental: undefined };
const noLocation = { type: 'none' as const, name: '', clinicIds: [] as string[], isDental: undefined };

// For getting the fetched / lang-specific values of the selected location filter
export const useLocationFilter = ():
  | {
      type: Exclude<LocationState, null>['type'];
      subType?: MultipleClinics['subType'];
      id?: string;
      name: string;
      clinicIds: string[];
      isDental: boolean | undefined;
    }
  | typeof loadingLocation
  | typeof noLocation => {
  const t = useTranslations();
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const auth = useReduxState(state => state.auth);
  const locationFilter = useReduxState(state => state.filters.locationFilter);
  const restrictions = useReduxState(state => state.filters.restrictions);
  const specialistOrService = useSpecialistOrServiceFilter();
  const config = useConfig();
  const getAreas = useApiData('getAreas');
  const getCities = useApiData('getCities');
  const getClinic = useApiData('getClinic');
  const getClinicId = useApiData('getClinicId');
  const getOccupationalData = useApiData('getOccupationalData');
  const bookingData = useMoveBooking();
  const isBookingForChildren = useIsBookingForChildren();

  // Use filters from booking to move if nothing else is explicitly selected
  if (!locationFilter && bookingData) {
    if (bookingData.status === 'REMOTE_DATA_LOADING') {
      return loadingLocation;
    }
    if (bookingData.status === 'REMOTE_DATA_SUCCESS' && 'clinic' in bookingData.data) {
      const booking = bookingData.data;
      if (booking.appointmentType === 'phone' || booking.appointmentType === 'video') {
        return { type: 'remote', name: t.remote_appointment, clinicIds: [], isDental: false };
      } else {
        return {
          type: 'clinic',
          id: booking.clinic.clinicId,
          name: booking.clinic.name,
          clinicIds: [booking.clinic.clinicId],
          isDental: undefined,
        };
      }
    }
  }

  if (!locationFilter) {
    // Do not use defaults when there are restrictions in place
    if (restrictions && (restrictions.clinicIds || restrictions.cityId || restrictions.areaId)) {
      return noLocation;
    }

    // Show specialist's clinics if nothing specific was selected
    if (specialistOrService && specialistOrService.type === 'specialist') {
      return {
        type: 'multiple-clinics',
        name: t.specialists_clinics,
        subType: 'specialists-clinics',
        clinicIds: [], // search for all the specialist's appointments regardless of clinic
        isDental: undefined,
      };
    }

    // Check if a default location should be used, first special cases
    if (bookingRoot === 'occupationalHealthcare' && auth.status === 'LOGGED_IN') {
      const occupationalData = getOccupationalData([auth.token]);
      const clinicCode =
        occupationalData.status === 'REMOTE_DATA_SUCCESS' ? occupationalData.data.mainClinicCode : undefined;
      const clinicIdData = clinicCode ? getClinicId([clinicCode]) : notAsked;
      const clinicData = clinicIdData.status === 'REMOTE_DATA_SUCCESS' ? getClinic([clinicIdData.data]) : notAsked;
      if (
        occupationalData.status === 'REMOTE_DATA_LOADING' ||
        clinicIdData.status === 'REMOTE_DATA_LOADING' ||
        clinicData.status === 'REMOTE_DATA_LOADING'
      ) {
        return loadingLocation;
      }
      if (clinicData.status === 'REMOTE_DATA_SUCCESS') {
        return {
          type: 'clinic',
          id: clinicData.data.clinicId,
          name: clinicData.data.name,
          clinicIds: [clinicData.data.clinicId],
          isDental: clinicData.data.isDental,
        };
      }
    }
    if (isBookingForChildren) {
      const areaData = getAreas([]);
      if (areaData.status === 'REMOTE_DATA_LOADING') {
        return loadingLocation;
      }
      const area =
        areaData.status === 'REMOTE_DATA_SUCCESS'
          ? areaData.data.find(a => a.areaId === config.CHILD_DEFAULT_AREA_ID)
          : undefined;
      if (area) {
        return { type: 'area', id: area.areaId, name: area.name, clinicIds: area.clinicIds, isDental: false };
      }
      // If areas failed to load or the given id was not found, fall back to normal default
    }

    const cityData = getCities([]);
    if (cityData.status === 'REMOTE_DATA_LOADING') {
      return loadingLocation;
    }
    const defaultCity =
      cityData.status === 'REMOTE_DATA_SUCCESS'
        ? cityData.data.find(c => c.cityId === config.DEFAULT_CITY_ID)
        : undefined;
    const defaultCityFilter = defaultCity
      ? {
          type: 'city' as const,
          id: defaultCity.cityId,
          name: defaultCity.name,
          clinicIds: defaultCity.clinicIds,
          isDental: false,
        }
      : noLocation;
    return defaultCityFilter;
  }

  switch (locationFilter.type) {
    case 'area':
      const areaData = getAreas([]);
      if (areaData.status === 'REMOTE_DATA_LOADING') {
        return loadingLocation;
      }
      if (areaData.status === 'REMOTE_DATA_SUCCESS') {
        const area = areaData.data.find(a => a.areaId === locationFilter.areaId);
        return area
          ? { type: 'area', id: area.areaId, name: area.name, clinicIds: area.clinicIds, isDental: false }
          : noLocation;
      }
      return noLocation;
    case 'city':
      const cityData = getCities([]);
      if (cityData.status === 'REMOTE_DATA_LOADING') {
        return loadingLocation;
      }
      if (cityData.status === 'REMOTE_DATA_SUCCESS') {
        const city = cityData.data.find(c => c.cityId === locationFilter.cityId);
        return city
          ? { type: 'city', id: city.cityId, name: city.name, clinicIds: city.clinicIds, isDental: false }
          : noLocation;
      }
      return noLocation;
    case 'clinic':
      const clinicData = getClinic([locationFilter.clinicId]);
      if (clinicData.status === 'REMOTE_DATA_LOADING') {
        return loadingLocation;
      }
      if (clinicData.status === 'REMOTE_DATA_SUCCESS') {
        return {
          type: 'clinic',
          id: clinicData.data.clinicId,
          name: clinicData.data.name,
          clinicIds: [clinicData.data.clinicId],
          isDental: clinicData.data.isDental,
        };
      }
      return noLocation;
    case 'multiple-clinics':
      let name = '';
      switch (locationFilter.subType) {
        case 'specialists-clinics':
          name = t.specialists_clinics;
          break;
        case 'nearest-clinics':
          name = t.nearest_clinics;
          break;
        case 'other-clinics':
          name = t.multiple_clinics;
          break;
        default:
          assertExhausted(locationFilter.subType);
          return noLocation;
      }
      return {
        type: 'multiple-clinics',
        name,
        subType: locationFilter.subType,
        clinicIds: locationFilter.clinicIds,
        isDental: undefined,
      };
    case 'remote':
      return { type: 'remote', name: t.remote_appointment, clinicIds: [], isDental: false };
    default:
      assertExhausted(locationFilter);
      return noLocation;
  }
};

export function useMoveBooking() {
  const { moveBookingWebCode } = useReduxState(state => state.location);
  const auth = useReduxState(state => state.auth);
  const getBooking = useApiData('getBookingWithCode');

  if (!moveBookingWebCode || auth.status !== 'LOGGED_IN') {
    return null;
  }

  return getBooking([{ bookingCode: moveBookingWebCode, customerId: auth.user.customerId }, auth.token]);
}

export function parseBookingParamsFromString(params: string | null) {
  const parsedParams = params ? parse(params) : null;

  if (!parsedParams) {
    return null;
  }

  const getSingleParam = (name: string) => {
    const val = parsedParams[name];
    return !val ? undefined : typeof val === 'string' ? val : val[0];
  };
  const getArrayParam = (name: string) => {
    const val = parsedParams[name];
    return !val ? undefined : typeof val === 'string' ? [val] : !val.length ? undefined : val;
  };

  const specialistId = getSingleParam('specialistId');
  const serviceId = getSingleParam('serviceId');
  const clinicIds = getArrayParam('clinicId');
  const cityId = getSingleParam('cityId');
  const areaId = getSingleParam('areaId');
  const types = getArrayParam('type');
  const validTypes = types ? regularAppointmentTypes.filter(t => types.includes(t)) : undefined;
  const appointmentTypes = validTypes && validTypes.length ? validTypes : undefined;

  if (!specialistId && !serviceId && !clinicIds && !cityId && !areaId && !appointmentTypes) {
    return null;
  }

  return { specialistId, serviceId, clinicIds, cityId, areaId, appointmentTypes };
}

export function useValidateBookingParams(params: string | null) {
  const getSpecialist = useApiData('getSpecialist');
  const getService = useApiData('getService');
  const getClinic = useApiData('getClinic');
  const getCities = useApiData('getCities');
  const getAreas = useApiData('getAreas');

  const bookingParams = parseBookingParamsFromString(params);

  const specialistData =
    bookingParams && bookingParams.specialistId ? getSpecialist([bookingParams.specialistId]) : notAsked;
  const serviceData = bookingParams && bookingParams.serviceId ? getService([bookingParams.serviceId]) : notAsked;
  const noSpecialistOrService = bookingParams && !bookingParams.specialistId && !bookingParams.serviceId;

  const clinicsData =
    bookingParams && bookingParams.clinicIds ? bookingParams.clinicIds.map(cId => getClinic([cId])) : [];
  const allCitiesData = bookingParams && bookingParams.cityId ? getCities([]) : notAsked;
  const cityData =
    allCitiesData.status === 'REMOTE_DATA_SUCCESS'
      ? { ...allCitiesData, data: allCitiesData.data.find(d => bookingParams && d.cityId === bookingParams.cityId) }
      : allCitiesData;
  const allAreasData = bookingParams && bookingParams.areaId ? getAreas([]) : notAsked;
  const areaData =
    allAreasData.status === 'REMOTE_DATA_SUCCESS'
      ? { ...allAreasData, data: allAreasData.data.find(d => bookingParams && d.areaId === bookingParams.areaId) }
      : allAreasData;
  const paramsData = [specialistData, serviceData, ...clinicsData, cityData, areaData];
  const loading = paramsData.some(d => d.status === 'REMOTE_DATA_LOADING');
  const isInvalid =
    (params && !bookingParams) ||
    paramsData.some(d => d.status === 'REMOTE_DATA_FAILURE') ||
    noSpecialistOrService ||
    (cityData.status === 'REMOTE_DATA_SUCCESS' && !cityData.data) ||
    (areaData.status === 'REMOTE_DATA_SUCCESS' && !areaData.data) ||
    (bookingParams && bookingParams.appointmentTypes && !bookingParams.appointmentTypes.length);

  return {
    bookingParams,
    loading,
    isInvalid,
  };
}

export function useHideRemoteOptions() {
  const { bookingRoot = 'privateCustomer', embedInApp } = useReduxState(state => state.location);
  const delegate = useReduxState(state => state.delegate);

  if (bookingRoot === 'dental') {
    // Dental does not have appointmentTypes
    return true;
  }

  if (delegate.status === 'NOTHING') {
    // No restrictions when booking for self
    return false;
  }

  // Remote appointments are only available legal guardians in delegate booking
  // When booking in app embed the user is already set as legal guardian
  if (embedInApp) {
    return false;
  }

  // Only under 12-year-olds can be automatically set as legal guardians
  return delegate.age === undefined || delegate.age >= 12;
}
