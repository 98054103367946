import React, { ReactNode } from 'react';
import { ErrorPageContent } from 'ui/error-pages/ErrorPageContent';
import { Analytics } from 'utils/analytics/analytics-utils';

type Props = {
  analytics: Analytics;
  children: ReactNode;
};

// Component to catch for example bundle chunk load errors and display an error to the user
// From https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.props.analytics.sendAppError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPageContent />;
    }

    return this.props.children;
  }
}
