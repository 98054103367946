/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { RestrictionsState } from 'reducers/filters';
import { ErrorPageContent } from 'ui/error-pages/ErrorPageContent';
import { RedirectionForm } from 'ui/redirection-form/RedirectionForm';
import { LoadingPageContent } from 'ui/utils/LoadingPageContent';
import { CoronaVaccineGuideWrapper } from 'ui/vaccine-guide/CoronaVaccineGuideWrapper';
import { useApiData, useTranslations, useUiContext } from 'utils/react/ui-context';
import { assertExhausted } from 'utils/types/misc';

export const CoronaVaccineGuideContent: FC<{
  openParamName: string;
  openParamBasis: string;
  noTracking?: boolean;
  onClose: (choiceTexts: string[], restrictions: RestrictionsState) => void;
}> = ({ openParamName, openParamBasis, noTracking, onClose }) => {
  const t = useTranslations();
  const getCoronaVaccineGuideForm = useApiData('getCoronaVaccineGuideForm');
  const analytics = useUiContext().analytics;

  const guideContent = getCoronaVaccineGuideForm([]);

  switch (guideContent.status) {
    case 'REMOTE_DATA_LOADING':
      return (
        <CoronaVaccineGuideWrapper>
          <LoadingPageContent translations={t} />
        </CoronaVaccineGuideWrapper>
      );
    case 'REMOTE_DATA_FAILURE':
      return <ErrorPageContent message={t.corona_vaccination_load_failed} />;
    case 'REMOTE_DATA_SUCCESS':
      return (
        <CoronaVaccineGuideWrapper>
          <RedirectionForm
            redirectionForm={guideContent.data}
            openParamName={openParamName}
            openParamBasis={openParamBasis}
            showChoicesSummary={true}
            onClose={onClose}
            onGoToStart={() => {
              // No additional actions required when the user returns to the start of the form
            }}
            sendAnalytics={
              noTracking
                ? () => {
                    // No op when no tracking parameter is set
                  }
                : p => analytics.trackCoronaVaccineGuide(guideContent.data.formId, p)
            }
          />
        </CoronaVaccineGuideWrapper>
      );
    default:
      assertExhausted(guideContent);
      return null;
  }
};
