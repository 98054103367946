import { useConfig, useTranslations } from 'utils/react/ui-context';

export const useChatUrl = () => {
  const t = useTranslations();
  const { CHAT_URL_BASE } = useConfig();

  return (chatId: number | null, chatTopicId: number | string | null) =>
    chatId === null
      ? chatTopicId === null
        ? `${CHAT_URL_BASE}${t.chat_path}`
        : `${CHAT_URL_BASE}${t.chat_topic_path}${chatTopicId}/`
      : `${CHAT_URL_BASE}${t.chat_queue_path}${chatId}/`;
};
