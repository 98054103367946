import {
  AppointmentApiFactory,
  CallRequestApiFactory,
  HealthAndMonitoringApiFactory,
  InsuranceApiFactory,
  TerveystaloAppointmentAPIAPIAbstractionAPICustomerType,
  TerveystaloAppointmentAPIAPIAbstractionAppointmentType,
  TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
  TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem,
  TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem,
  TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem,
  TerveystaloAppointmentAPIAPIAbstractionV1RequestGender,
  TerveystaloAppointmentAPICoreDomainCustomerType,
  TerveystaloAppointmentAPICoreDomainPartnerType,
  TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus,
  TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
} from 'api/generated/AppointmentApi/api';
import { AreaApiFactory, CityApiFactory, ClinicApiFactory } from 'api/generated/ClinicApi2/api';
import {
  Customer2ApiFactory,
  CustomerResponse,
  DelegateApiFactory,
  PersonalizationApiFactory,
  ReferralApiFactory,
  SelfServiceApiFactory,
  WeakLoginApiFactory,
} from 'api/generated/CustomerApi2/api';
import {
  AppointmentPriceApiFactory,
  PriceApiFactory,
  Localized as PriceApiLocalized,
  TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType,
  TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse,
  TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse,
} from 'api/generated/PriceApi/api';
import { DefaultApiFactory as RedirectionFormApiFactory } from 'api/generated/RedirectionFormApi/api';
import {
  Competence2ApiFactory,
  Localized,
  Service2ApiFactory,
  TerveystaloServiceAPIAbstractionsMedicalField,
} from 'api/generated/ServiceApi2/api';
import {
  SpecialistApiFactory,
  TerveystaloBaseAPIAbstractionsSubsystem,
  TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField,
} from 'api/generated/SpecialistApi2/api';
import axios, { AxiosResponse } from 'axios';
import { formatISO } from 'date-fns';
import compact from 'lodash/compact';
import get from 'lodash/get';
import {
  ApiErrorStatus,
  ApiModel,
  ApiModelType,
  AppointmentType,
  CustomerType,
  regularAppointmentTypes,
} from 'model/api';
import { ConfigModelType } from 'model/config';
import { createError } from 'model/error';
import { AuthToken } from 'reducers/auth';
import { DelegateToken } from 'reducers/delegate';
import { SpecialistGender } from 'reducers/filters';
import { Language } from 'translations/generated/translation-languages';
import { BookingFormData } from 'ui/booking/BookingForm';
import { WeakLoginData } from 'ui/login/Login';
import { RegisterData } from 'ui/login/RegisterForm';
import { InsuranceDetails } from 'ui/partner/InsuranceDetailsForm';
import { MigriBookingDetails } from 'ui/partner/MigriBookingDetailsForm';
import { PublicInvoicingDetails } from 'ui/partner/PublicInvoicingDetailsForm';
import { Analytics } from 'utils/analytics/analytics-utils';
import { CallRequestQuery } from 'utils/appointments/call-requests';
import { AppointmentQuery } from 'utils/appointments/helpers';
import { priceToEurosString } from 'utils/currency/format';
import { dateISOString } from 'utils/datetime/format';
import { createLogger } from 'utils/logging/logging-utils';
import { BookingRoot } from 'utils/routes/routes';
import { RespiratorySymptoms } from 'utils/symptoms/helpers';
import {
  defined,
  definedNotNull,
  guardedModelArrayConversion,
  guardedModelArrayConversionMultiType,
  guardedModelConversion,
  guardedStringArrayConversion,
  guardedStringConversion,
} from 'utils/types/api';
import { assertExhausted } from 'utils/types/misc';
import { cleanUpEmail, cleanUpPhoneNumber } from 'utils/validation/helpers';

// Creates low-level bindings to the ADA APIs.
export function createAdaApiClient(config: ConfigModelType['FrontendConfig'], axiosImpl = axios, analytics: Analytics) {
  const log = createLogger(config, 'AdaApiClient');
  // Dummy for openAPI typing
  const isJsonMime = () => {
    return true;
  };
  const commonConfig = {
    apiKey: config.ADA_API_KEY,
    isJsonMime,
  };
  const authConfig = (accessToken?: string) => ({
    ...commonConfig,
    apiKey: (header: string) => (header === 'Ocp-Apim-Subscription-Key' ? commonConfig.apiKey : accessToken || ''),
  });
  const api = {
    ServiceApi: Service2ApiFactory(commonConfig, config.API_ENDPOINT_SERVICEAPI_V2, axiosImpl),
    CompetenceApi: Competence2ApiFactory(commonConfig, config.API_ENDPOINT_SERVICEAPI_V2, axiosImpl),
    SpecialistApi: SpecialistApiFactory(commonConfig, config.API_ENDPOINT_SPECIALISTAPI_V2, axiosImpl),
    CustomerApi: (accessToken?: string) =>
      Customer2ApiFactory(authConfig(accessToken), config.API_ENDPOINT_CUSTOMERAPI_V2, axiosImpl),
    WeakLoginApi: WeakLoginApiFactory(commonConfig, config.API_ENDPOINT_CUSTOMERAPI_V2, axiosImpl),
    DelegateApi: (accessToken?: string) =>
      DelegateApiFactory(authConfig(accessToken), config.API_ENDPOINT_CUSTOMERAPI_V2, axiosImpl),
    ReferralApi: (accessToken?: string) =>
      ReferralApiFactory(authConfig(accessToken), config.API_ENDPOINT_CUSTOMERAPI_V2, axiosImpl),
    PersonalizationApi: (accessToken?: string) =>
      PersonalizationApiFactory(authConfig(accessToken), config.API_ENDPOINT_CUSTOMERAPI_V2, axiosImpl),
    SelfServiceApi: (accessToken?: string) =>
      SelfServiceApiFactory(authConfig(accessToken), config.API_ENDPOINT_CUSTOMERAPI_V2, axiosImpl),
    AppointmentApi: (accessToken?: string) =>
      AppointmentApiFactory(authConfig(accessToken), config.API_ENDPOINT_APPOINTMENTAPI, axiosImpl),
    CallRequestApi: (accessToken?: string) =>
      CallRequestApiFactory(authConfig(accessToken), config.API_ENDPOINT_APPOINTMENTAPI, axiosImpl),
    InsuranceApi: (accessToken?: string) =>
      InsuranceApiFactory(authConfig(accessToken), config.API_ENDPOINT_APPOINTMENTAPI, axiosImpl),
    AppointmentApiHealth: HealthAndMonitoringApiFactory(commonConfig, config.API_ENDPOINT_APPOINTMENTAPI, axiosImpl),
    ClinicApi2: ClinicApiFactory(commonConfig, config.API_ENDPOINT_CLINICAPI_V2, axiosImpl),
    AreaApi2: AreaApiFactory(commonConfig, config.API_ENDPOINT_CLINICAPI_V2, axiosImpl),
    CityApi2: CityApiFactory(commonConfig, config.API_ENDPOINT_CLINICAPI_V2, axiosImpl),
    RedirectionFormApi: RedirectionFormApiFactory(commonConfig, config.API_ENDPOINT_REDIRECTIONFORMAPI, axiosImpl),
    PriceApi: PriceApiFactory(commonConfig, config.API_ENDPOINT_PRICEAPI, axiosImpl),
    AppointmentPriceApi: AppointmentPriceApiFactory(commonConfig, config.API_ENDPOINT_PRICEAPI, axiosImpl),
  };
  const options = (lang: Language) => ({
    headers: {
      'Accept-Language': `${lang}-FI`,
    },
  });
  return {
    async searchServices(lang: Language, searchText: string, filter: MedicalType) {
      return api.ServiceApi.v2ServicesGet(searchText, ownToApiMedicalTypes(filter), options(lang)).then(
        res =>
          guardedModelArrayConversion(analytics, log, res.data, ApiModel.Service, data => ({
            serviceId: defined(data.Id, 'serviceId'),
            serviceName: localized(lang, data.Name, 'serviceName'),
            chatId: data.ChatId || null,
            isDental:
              !data.SubsystemIDs ||
              !data.SubsystemIDs.length ||
              data.SubsystemIDs.some(d => d.Subsystem === TerveystaloBaseAPIAbstractionsSubsystem.Assisdent),
            referralRequired: !!data.LoginRequired,
            bookingEnabled: !!data.BookingEnabled,
          })),
        refineApiError,
      );
    },
    async getService(lang: Language, serviceId: string) {
      return api.ServiceApi.v2ServicesIdGet(serviceId, options(lang)).then(
        guardedModelConversion(analytics, log, ApiModel.ServiceExtended, ({ data }) => ({
          serviceId,
          serviceName: localized(lang, data.Name, 'serviceName'),
          serviceDescription: localized(lang, data.Description, 'serviceDescription'),
          serviceBookingInstruction: localized(lang, data.BookingInstruction, 'bookingInstruction'),
          preparationInfo: localized(lang, data.PreparingInformation, 'preparationInfo'),
          priceDisclaimer: data.PriceDisclaimer ? localized(lang, data.PriceDisclaimer, 'priceDisclaimer') : null,
          chatId: data.ChatId || null,
          isDental:
            !data.SubsystemIDs ||
            !data.SubsystemIDs.length ||
            data.SubsystemIDs.some(d => d.Subsystem === TerveystaloBaseAPIAbstractionsSubsystem.Assisdent),
          referralRequired: !!data.LoginRequired,
          bookingEnabled: !!data.BookingEnabled,
        })),
        refineApiError,
      );
    },
    async getServices(lang: Language, filter: MedicalType) {
      return api.ServiceApi.v2ServicesGet(undefined, ownToApiMedicalTypes(filter), options(lang)).then(
        res =>
          guardedModelArrayConversion(analytics, log, res.data, ApiModel.Service, data => ({
            serviceId: defined(data.Id, 'serviceId'),
            serviceName: localized(lang, data.Name, 'serviceName'),
            chatId: data.ChatId || null,
            isDental:
              !data.SubsystemIDs ||
              !data.SubsystemIDs.length ||
              data.SubsystemIDs.some(d => d.Subsystem === TerveystaloBaseAPIAbstractionsSubsystem.Assisdent),
            referralRequired: !!data.LoginRequired,
            bookingEnabled: !!data.BookingEnabled,
          })),
        refineApiError,
      );
    },
    async getCompetence(lang: Language, competenceId: string) {
      return api.CompetenceApi.v2CompetencesIdGet(competenceId, options(lang)).then(
        guardedModelConversion(analytics, log, ApiModel.Competence, ({ data }) => ({
          competenceId,
          competenceName: localized(lang, data.Name, 'competenceName'),
        })),
        refineApiError,
      );
    },
    async searchSpecialists(
      lang: Language,
      searchText: string,
      filter: MedicalType,
      searchField: SpecialistSearchField,
    ) {
      return api.SpecialistApi.v2SpecialistsGet(
        searchText,
        ownToApiMedicalTypes(filter),
        ownToApiSpecialistSearchFields(searchField),
        undefined,
        undefined,
        options(lang),
      ).then(
        res =>
          guardedModelArrayConversion(analytics, log, res.data, ApiModel.Specialist, data => ({
            specialistId: defined(data.Id, 'specialistId'),
            firstName: definedNotNull(data.FirstName, 'firstName'),
            lastName: definedNotNull(data.LastName, 'lastName'),
            imageUri: data.ImageUri || null,
            specialistTitle: localized(lang, data.Title, 'specialistTitle'),
            isDental: !data.SubsystemIDs || !!data.SubsystemIDs.Assisdent,
          })),
        refineApiError,
      );
    },
    async getSpecialist(lang: Language, specialistId: string) {
      return api.SpecialistApi.v2SpecialistsIdGet(specialistId, options(lang)).then(
        guardedModelConversion(analytics, log, ApiModel.SpecialistExtended, ({ data }) => ({
          specialistId: defined(data.Id, 'specialistId'),
          firstName: definedNotNull(data.FirstName, 'firstName'),
          lastName: definedNotNull(data.LastName, 'lastName'),
          imageUri: data.ImageUri || null,
          specialistTitle: localized(lang, data.Title, 'specialistTitle'),
          languages: data.Languages
            ? data.Languages.map(l => ({
                code: definedNotNull(l.TwoLetterISOCode, 'languageCode'),
                native: !!l.Native,
              }))
            : [],
          clinicIds: data.ClinicIds || [],
          serviceIds: data.ServiceIds || [],
          competenceIds: data.CompetenceIds || [],
          presentation: localized(lang, data.Presentation, 'presentation'),
          shortDescription: localized(lang, data.ShortDescription, 'shortDescription'),
          bookingInstruction: localized(lang, data.ReservationInstruction, 'bookingInstruction'),
          preparationInstruction: localized(lang, data.PreparationInstructions, 'preparationInstruction'),
          isDental: !data.SubsystemIDs || !!data.SubsystemIDs.Assisdent,
        })),
        refineApiError,
      );
    },
    async getSpecialistLanguages(_: Language) {
      return api.SpecialistApi.v2SpecialistsLanguagesGet().then(
        res => guardedStringArrayConversion(analytics, log, res.data, data => data),
        refineApiError,
      );
    },
    async getCustomer(_lang: Language, token: AuthToken) {
      return api
        .CustomerApi(token.accessToken)
        .v2CustomersGet()
        .then(
          guardedModelConversion(analytics, log, ApiModel.User, ({ data }: AxiosResponse<CustomerResponse>) => ({
            customerId: definedNotNull(data.Id, 'customerId'),
            age: definedNotNull(data.Age, 'age'),
            firstName: definedNotNull(data.FirstName, 'firstName'),
            lastName: definedNotNull(data.LastName, 'lastName'),
            occupational: data.Occupational || false,
            email: cleanUpEmail(data.EmailAddress || ''),
            phone: cleanUpPhoneNumber(data.PhoneNumber || ''),
          })),
          refineApiError,
        );
    },
    async getDelegateCustomer(_lang: Language, token: AuthToken, delegateToken: DelegateToken) {
      return api
        .CustomerApi(token.accessToken)
        .v2CustomersPrincipalGet(delegateToken.accessToken)
        .then(
          guardedModelConversion(
            analytics,
            log,
            ApiModel.DelegateUser,
            ({ data }: AxiosResponse<CustomerResponse>) => ({
              customerId: definedNotNull(data.Id, 'customerId'),
              age: definedNotNull(data.Age, 'age'),
              firstName: definedNotNull(data.FirstName, 'firstName'),
              lastName: definedNotNull(data.LastName, 'lastName'),
              occupational: data.Occupational || false,
              onBehalfConnection: !!data.ValidOnBehalfConnection,
              email: cleanUpEmail(data.EmailAddress || ''),
              phone: cleanUpPhoneNumber(data.PhoneNumber || ''),
            }),
          ),
          refineApiError,
        );
    },
    async getDelegateUrl(lang: Language, authToken: AuthToken, redirectUri: string) {
      return api
        .DelegateApi(authToken.accessToken)
        .v2DelegateRedirectURLPost(
          {
            RedirectUri: redirectUri,
          },
          options(lang),
        )
        .then(
          guardedModelConversion(analytics, log, ApiModel.DelegateInfo, ({ data }) => ({
            url: definedNotNull(data.Url, 'delegateUrl'),
            registration: definedNotNull(data.Registration, 'delegateRegistration'),
          })),
          refineApiError,
        );
    },
    async getDelegateToken(_lang: Language, authToken: AuthToken, registration: string, code: string) {
      return api
        .DelegateApi(authToken.accessToken)
        .v2DelegateTokenPost({
          Registration: registration,
          Code: code,
        })
        .then(
          guardedModelConversion(analytics, log, ApiModel.DelegateTokenData, ({ data }) => ({
            token: definedNotNull(data.Token, 'delegateToken'),
            notRegistered: !data.PrincipalCustomerExists,
            name: data.Name || '',
            age: data.Age,
          })),
          refineApiError,
        );
    },
    /* This is used only with the weak login */
    async getCustomerToken(_lang: Language, query: WeakLoginData) {
      return api.WeakLoginApi.v2WeaktokenPost({
        PersonalIdentityCode: query.ssn,
      }).then(
        guardedStringConversion(analytics, log, ({ data }) => definedNotNull(data.BearerToken, 'token')),
        refineApiError,
      );
    },
    async createCustomer(_lang: Language, query: RegisterData) {
      return api
        .CustomerApi()
        .v2CustomersPost({
          EmailAddress: query.email,
          FirstName: query.firstName,
          LastName: query.lastName,
          MobilePhoneNumber: query.phone,
          PersonalIdentityCode: query.ssn,
          PostCode: query.postCode,
          PostOffice: query.city,
          StreetAddress: query.address,
        })
        .then(
          guardedStringConversion(analytics, log, ({ data }) => data),
          refineApiError,
        );
    },
    async createCustomerWithTokenOW(_lang: Language, query: Omit<RegisterData, 'ssn'>, token: AuthToken) {
      return api
        .CustomerApi(token.accessToken)
        .v2CustomersAuthenticatedPost({
          EmailAddress: query.email,
          FirstName: query.firstName,
          LastName: query.lastName,
          MobilePhoneNumber: query.phone,
          PostCode: query.postCode,
          PostOffice: query.city,
          StreetAddress: query.address,
        })
        .then(
          guardedStringConversion(analytics, log, ({ data }) => data),
          refineApiError,
        );
    },
    async createDelegateCustomer(
      _lang: Language,
      query: Omit<RegisterData, 'ssn'>,
      authToken: AuthToken,
      delegateToken: DelegateToken,
    ) {
      return api
        .CustomerApi(authToken.accessToken)
        .v2CustomersPrincipalPost(delegateToken.accessToken, {
          EmailAddress: query.email,
          FirstName: query.firstName,
          LastName: query.lastName,
          MobilePhoneNumber: query.phone,
          PostCode: query.postCode,
          PostOffice: query.city,
          StreetAddress: query.address,
        })
        .then(returnNothing, refineApiError);
    },
    async searchPrincipalCustomer(_lang: Language, personalIdentityCode: string, authToken: AuthToken) {
      return api
        .CustomerApi(authToken.accessToken)
        .v2CustomersPrincipalSearchPost({
          PersonalIdentityCode: personalIdentityCode,
        })
        .then(
          guardedModelConversion(
            analytics,
            log,
            ApiModel.DelegateUser,
            ({ data }: AxiosResponse<CustomerResponse>) => ({
              customerId: definedNotNull(data.Id, 'customerId'),
              age: defined(data.Age, 'age'),
              firstName: definedNotNull(data.FirstName, 'firstName'),
              lastName: definedNotNull(data.LastName, 'lastName'),
              occupational: data.Occupational || false,
              onBehalfConnection: !!data.ValidOnBehalfConnection,
              email: cleanUpEmail(data.EmailAddress || ''),
              phone: cleanUpPhoneNumber(data.PhoneNumber || ''),
            }),
          ),
          refineApiError,
        );
    },
    async createDelegateGuardian(authToken: AuthToken, delegateToken: DelegateToken) {
      return api
        .CustomerApi(authToken.accessToken)
        .v2CustomersPrincipalPut(delegateToken.accessToken, {
          OnBehalfConnection: true,
        })
        .then(returnNothing, refineApiError);
    },
    async getReferralStatus(
      _lang: Language,
      token: AuthToken,
      referralCode: string,
      delegateToken: DelegateToken | undefined,
    ) {
      return api
        .ReferralApi(token.accessToken)
        .v2CustomersReferralReferralCodeGet(referralCode, delegateToken ? delegateToken.accessToken : undefined)
        .then(
          guardedModelConversion(analytics, log, ApiModel.ReferralStatus, ({ data }) => ({
            referralCode,
            hasReferral: !!data.HasReferral,
          })),
          refineApiError,
        );
    },
    async getOccupationalSelfService(_lang: Language, token: AuthToken) {
      return api
        .PersonalizationApi(token.accessToken)
        .v2PersonalizationSelfServiceAllowedGet()
        .then(({ data }) => !!data.IsSelfServiceAllowed, refineApiError);
    },
    async postOccupationalPersonalizedBookingConsentLog(_lang: Language, token: AuthToken, consent: boolean) {
      return api
        .SelfServiceApi(token.accessToken)
        .v2SelfserviceSetApprovementPost(consent)
        .then(returnNothing, refineApiError);
    },
    async getPrivateAppointments(lang: Language, query: AppointmentQuery) {
      const payload = {
        From: ownToApiDate(query.date),
        Days: query.dayCount,
        SpecialistIDs: query.specialistIds,
        ClinicIDs: query.clinicIds,
        ServiceID: query.serviceId,
        AppointmentTypes: query.appointmentTypes ? query.appointmentTypes.map(ownToApiAppointmentType) : undefined,
        FromTimeSpan: query.fromTimeOfDay || undefined,
        ToTimeSpan: query.toTimeOfDay || undefined,
        SpecialistTwoLetterISOLanguage: query.specialistLanguage || undefined,
        Gender: query.specialistGender ? ownToApiGender(query.specialistGender) : undefined,
        MinimumCount: query.minCount,
        MaximumLookaheadDays: query.maxExtraDays,
        CustomerAge: query.customerAge,
        CustomerType: ownToApiCustomerType(query.customerType),
        InfectionRisk: ownToApiRespiratorySymptoms(query.respiratorySymptoms),
        DurationInMinutes: query.durationInMinutes,
        Take: query.take,
        Skip: query.skip,
      };
      return api
        .AppointmentApi()
        .appointmentAvailablePrivateSearchPost(payload, options(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.AppointmentsResult, ({ data }) => ({
            occupationalRestrictions: null,
            availableAppointments: guardedModelArrayConversion(analytics, log, data, ApiModel.Appointment, d => ({
              appointmentId: defined(d.Id, 'appointmentId'),
              dateTime: apiToOwnDateTime(d.DateTime),
              clinic: apiToOwnAppointmentClinic(lang, d.Clinic),
              hideClinicDetails: !!d.HideClinicDetails,
              specialist: apiToOwnAppointmentSpecialist(lang, d.Specialist),
              duration: defined(d.DurationInMinutes, 'duration'),
              appointmentTypes: apiToOwnAppointmentTypes(d.AppointmentTypes),
              flags: d.Flags ? Array.from(d.Flags) : null,
              service: apiToOwnService(lang, d.Service),
              services: d.Services ? compact(d.Services.map(s => apiToOwnService(lang, s))) : [],
            })),
          })),
          refineApiError,
        );
    },
    async getAppointments(lang: Language, query: AppointmentQuery, token: AuthToken | 'non-occupational') {
      const payload = {
        From: ownToApiDate(query.date),
        Days: query.dayCount,
        SpecialistIDs: query.specialistIds,
        ClinicIDs: query.clinicIds,
        ServiceID: query.serviceId,
        AppointmentTypes: query.appointmentTypes ? query.appointmentTypes.map(ownToApiAppointmentType) : undefined,
        FromTimeSpan: query.fromTimeOfDay || undefined,
        ToTimeSpan: query.toTimeOfDay || undefined,
        SpecialistTwoLetterISOLanguage: query.specialistLanguage || undefined,
        Gender: query.specialistGender ? ownToApiGender(query.specialistGender) : undefined,
        MinimumCount: query.minCount,
        MaximumLookaheadDays: query.maxExtraDays,
        CustomerAge: query.customerAge,
        CustomerType: ownToApiCustomerType(query.customerType),
        DisableRevenueManagement: config.FEATURE_DISABLE_RM || undefined,
        InfectionRisk: ownToApiRespiratorySymptoms(query.respiratorySymptoms),
        DurationInMinutes: query.durationInMinutes,
        Take: query.take,
        Skip: query.skip,
      };
      if (query.customerType === 'occupational' && token !== 'non-occupational') {
        return api
          .AppointmentApi(token.accessToken)
          .appointmentAvailableSearchOccupationalPost(payload, options(lang))
          .then(
            guardedModelConversion(analytics, log, ApiModel.AppointmentsResult, ({ data }) => ({
              occupationalRestrictions: data.OccupationalRules
                ? {
                    specialistIds: Array.from(data.OccupationalRules.SpecialistIds ?? []) || null, // TODO Safe casting
                    clinics: data.OccupationalRules.Clinics
                      ? Array.from(data.OccupationalRules.Clinics).map(c => ({
                          // TODO Safe casting
                          id: defined(c.Id, 'occupationalRulesClinicId'),
                          home: !!c.Home,
                        }))
                      : null,
                  }
                : null,
              availableAppointments: guardedModelArrayConversion(
                analytics,
                log,
                definedNotNull(data.AvailableAppointments, 'availableAppointments'),
                ApiModel.Appointment,
                d => ({
                  appointmentId: defined(d.Id, 'appointmentId'),
                  dateTime: apiToOwnDateTime(d.DateTime),
                  clinic: apiToOwnAppointmentClinic(lang, d.Clinic),
                  hideClinicDetails: !!d.HideClinicDetails,
                  flags: d.Flags ? Array.from(d.Flags) : null,
                  specialist: apiToOwnAppointmentSpecialist(lang, d.Specialist),
                  duration: defined(d.DurationInMinutes, 'duration'),
                  appointmentTypes: apiToOwnAppointmentTypes(d.AppointmentTypes),
                  service: apiToOwnService(lang, d.Service),
                  services: d.Services ? compact(d.Services.map(s => apiToOwnService(lang, s))) : [],
                }),
              ),
            })),
            refineApiError,
          );
      }
      return api
        .AppointmentApi()
        .appointmentAvailableSearchPost(payload, options(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.AppointmentsResult, ({ data }) => ({
            occupationalRestrictions: null,
            availableAppointments: guardedModelArrayConversion(analytics, log, data, ApiModel.Appointment, d => ({
              appointmentId: defined(d.Id, 'appointmentId'),
              dateTime: apiToOwnDateTime(d.DateTime),
              clinic: apiToOwnAppointmentClinic(lang, d.Clinic),
              hideClinicDetails: !!d.HideClinicDetails,
              specialist: apiToOwnAppointmentSpecialist(lang, d.Specialist),
              duration: defined(d.DurationInMinutes, 'duration'),
              appointmentTypes: apiToOwnAppointmentTypes(d.AppointmentTypes),
              flags: d.Flags ? Array.from(d.Flags) : null,
              service: apiToOwnService(lang, d.Service),
              services: d.Services ? compact(d.Services.map(s => apiToOwnService(lang, s))) : [],
            })),
          })),
          refineApiError,
        );
    },
    async getAlternativeAppointments(lang: Language, query: AppointmentQuery) {
      return api
        .AppointmentApi()
        .appointmentAvailableAlternativePost(
          {
            From: ownToApiDate(query.date),
            Days: query.dayCount,
            SpecialistIDs: query.specialistIds,
            ClinicIDs: query.clinicIds,
            ServiceID: query.serviceId,
            AppointmentTypes: query.appointmentTypes ? query.appointmentTypes.map(ownToApiAppointmentType) : undefined,
            FromTimeSpan: query.fromTimeOfDay || undefined,
            ToTimeSpan: query.toTimeOfDay || undefined,
            SpecialistTwoLetterISOLanguage: query.specialistLanguage || undefined,
            Gender: query.specialistGender ? ownToApiGender(query.specialistGender) : undefined,
            MinimumCount: query.minCount,
            MaximumLookaheadDays: query.maxExtraDays,
            CustomerAge: query.customerAge,
            CustomerType: ownToApiCustomerType(query.customerType),
            InfectionRisk: ownToApiRespiratorySymptoms(query.respiratorySymptoms),
          },
          options(lang),
        )
        .then(
          res =>
            guardedModelArrayConversion(analytics, log, res.data, ApiModel.AlternativeAppointments, data => {
              const Clinic = defined(data.Clinic, 'clinic');
              return {
                appointments: definedNotNull(data.Appointments, 'appointments').map(a => ({
                  appointmentId: defined(a.Id, 'appointmentId'),
                  dateTime: apiToOwnDateTime(a.DateTime),
                  specialist: apiToOwnAppointmentSpecialist(lang, a.Specialist),
                  duration: defined(a.DurationInMinutes, 'duration'),
                  appointmentType: 'clinic', // always a clinic appointment in nearby appointments
                  service: apiToOwnService(lang, a.Service),
                })),
                clinic: {
                  clinicId: defined(Clinic.Id, 'clinicId'),
                  name: localized(lang, Clinic.Name, 'clinicName'),
                },
              };
            }),
          refineApiError,
        );
    },
    async getAvailableCallRequests(lang: Language, query: CallRequestQuery) {
      return api
        .CallRequestApi()
        .callrequestAvailableSearchGet(
          query.specialistId,
          dateISOString(query.date),
          query.dayCount,
          query.startTime,
          config.FEATURE_DISABLE_RM || undefined,
          options(lang),
        )
        .then(
          res =>
            guardedModelArrayConversion(
              analytics,
              log,
              definedNotNull(res.data.Slots, 'slots'),
              ApiModel.CallRequestSlot,
              data => ({
                slotId: definedNotNull(data.Id, 'id'),
                dateTime: apiToOwnDateTime(data.IntervalStartDateTime),
                slotLength: definedNotNull(data.IntervalDurationInMinutes, 'intervalDuration'),
                duration: 5,
                specialistId: query.specialistId,
              }),
            ),
          refineApiError,
        );
    },
    async getAppointmentDays(_lang: Language, query: AppointmentQuery) {
      return api
        .AppointmentApi()
        .appointmentAvailableSearchDaysPost({
          From: ownToApiDate(query.date),
          Days: query.dayCount,
          SpecialistIDs: query.specialistIds,
          ClinicIDs: query.clinicIds,
          ServiceID: query.serviceId,
          AppointmentTypes: query.appointmentTypes ? query.appointmentTypes.map(ownToApiAppointmentType) : undefined,
          FromTimeSpan: query.fromTimeOfDay || undefined,
          ToTimeSpan: query.toTimeOfDay || undefined,
          CustomerType: ownToApiCustomerType(query.customerType),
          CustomerAge: query.customerAge,
          InfectionRisk: ownToApiRespiratorySymptoms(query.respiratorySymptoms),
          SpecialistTwoLetterIsoLanguage: query.specialistLanguage || undefined,
          Gender: query.specialistGender ? ownToApiGender(query.specialistGender) : undefined,
        })
        .then(
          res =>
            guardedModelArrayConversion(analytics, log, res.data, ApiModel.AppointmentDay, data => ({
              date: apiToOwnDateTime(data.Date),
              appointmentCount: defined(data.AppointmentCount, 'appointmentCount'),
            })),
          refineApiError,
        );
    },
    async getAvailableCallRequestDays(lang: Language, query: CallRequestQuery) {
      return api
        .CallRequestApi()
        .callrequestAvailableSearchDaysGet(
          query.specialistId,
          dateISOString(query.date),
          query.dayCount,
          '08:00:00',
          config.FEATURE_DISABLE_RM || undefined,
          options(lang),
        )
        .then(
          res =>
            guardedModelArrayConversion(analytics, log, res.data, ApiModel.AppointmentDay, data => ({
              date: apiToOwnDateTime(data.Date),
              appointmentCount: defined(data.CallRequestCount, 'callRequestCount'),
            })),
          refineApiError,
        );
    },
    async getAppointment(lang: Language, appointmentId: string, respiratorySymptoms: RespiratorySymptoms) {
      return api
        .AppointmentApi()
        .appointmentAvailableIdIdGet(appointmentId, ownToApiRespiratorySymptoms(respiratorySymptoms), options(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.Appointment, ({ data }) => ({
            appointmentId: defined(data.Id, 'appointmentId'),
            dateTime: apiToOwnDateTime(data.DateTime),
            clinic: apiToOwnAppointmentClinic(lang, data.Clinic),
            hideClinicDetails: !!data.HideClinicDetails,
            specialist: apiToOwnAppointmentSpecialist(lang, data.Specialist),
            duration: defined(data.DurationInMinutes, 'duration'),
            appointmentTypes: apiToOwnAppointmentTypes(data.AppointmentTypes),
            flags: data.Flags ? Array.from(data.Flags) : null,
            service: apiToOwnService(lang, data.Service),
            services: data.Services ? compact(data.Services.map(s => apiToOwnService(lang, s))) : [],
          })),
          refineApiError,
        );
    },
    async getCallRequest(lang: Language, slotId: string) {
      return api
        .CallRequestApi()
        .callrequestAvailableIdGet(slotId, options(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.CallRequestSlot, ({ data }) => ({
            slotId,
            dateTime: apiToOwnDateTime(data.IntervalStartDateTime),
            slotLength: definedNotNull(data.IntervalDurationInMinutes, 'intervalDuration'),
            duration: 5,
            specialistId: definedNotNull(data.SpecialistId, 'specialistId'),
          })),
          refineApiError,
        );
    },
    async isAppointmentAvailable(_: Language, appointmentId: string) {
      return api
        .AppointmentApi()
        .appointmentIsappointmentavailableGet(appointmentId)
        .then(
          guardedModelConversion(analytics, log, ApiModel.AppointmentAvailable, ({ data }) => ({
            isAvailable: defined(data.AppointmentAvailable, 'appointmentAvailable'),
          })),
          refineApiError,
        );
    },
    async createTemporaryBooking(
      _: Language,
      query: {
        appointmentId: string;
        serviceId?: string;
        customerId: string;
        appointmentType: AppointmentType;
        customerType: CustomerType;
        delegatePatientName?: string;
        respiratorySymptoms: RespiratorySymptoms;
        principalCustomerId?: string;
        selfTriageId?: string;
      },
      token: AuthToken,
      delegateToken: DelegateToken | undefined,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationTemporaryCreatePost({
          AppointmentId: query.appointmentId,
          CustomerId: query.customerId,
          ServiceId: query.serviceId,
          AppointmentType: ownToApiAppointmentType(query.appointmentType),
          CustomerType: ownToApiCustomerType(query.customerType),
          DelegateToken: delegateToken ? delegateToken.accessToken : undefined,
          PrincipalCustomerId: query.principalCustomerId,
          InfectionRisk: ownToApiRespiratorySymptoms(query.respiratorySymptoms),
          SelfTriageId: query.selfTriageId,
        })
        .then(
          guardedStringConversion(analytics, log, ({ data }) => defined(data.Id, 'bookingId')),
          refineApiError,
        );
    },
    async createCallRequestTemporaryBooking(
      _: Language,
      query: {
        slotId: string;
        delegatePatientName?: string;
        principalCustomerId?: string;
      },
      token: AuthToken,
      delegateToken: DelegateToken | undefined,
      customerType: CustomerType | null,
    ) {
      return api
        .CallRequestApi(token.accessToken)
        .callrequestReservationTemporaryCreatePost({
          AppointmentId: query.slotId,
          DelegateToken: delegateToken ? delegateToken.accessToken : undefined,
          PrincipalCustomerId: query.principalCustomerId,
          CustomerType: customerType ? ownToApiCustomerType(customerType) : undefined,
        })
        .then(
          guardedStringConversion(analytics, log, ({ data }) => defined(data.ReservationId, 'bookingId')),
          refineApiError,
        );
    },
    async getTemporaryBooking(
      lang: Language,
      query: {
        bookingId: string;
        customerId: string;
      },
      token: AuthToken,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationTemporaryGet(query.bookingId, query.customerId, options(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.TemporaryBooking, ({ data }) => ({
            bookingId: defined(data.Id, 'bookingId'),
            expiresIn: defined(data.TemporaryReservationExpiresInSeconds, 'expiresIn'),
            dateTime: apiToOwnDateTime(data.DateTime),
            clinic: apiToOwnAppointmentClinic(lang, data.Clinic),
            hideClinicDetails: !!data.HideClinicDetails,
            specialist: apiToOwnAppointmentSpecialist(lang, data.Specialist, data.FallbackSpecialistName),
            duration: defined(data.DurationInMinutes, 'duration'),
            appointmentType: apiToOwnAppointmentType(data.AppointmentType),
            service: apiToOwnService(lang, data.Service),
            delegatePatientName: data.PrincipalName || undefined,
            principalCustomerId: data.PrincipalCustomerId || undefined,
            respiratorySymptoms: apiToOwnRespiratorySymptoms(data.InfectionRisk),
          })),
          refineApiError,
        );
    },
    async getCallRequestTemporaryBooking(lang: Language, bookingId: string, token: AuthToken) {
      return api
        .CallRequestApi(token.accessToken)
        .callrequestReservationTemporaryReservationIdGet(bookingId, options(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.CallRequestTemporaryBooking, ({ data }) => ({
            bookingId,
            expiresIn: defined(data.TemporaryReservationExpiresInSeconds, 'expiresIn'),
            dateTime: apiToOwnDateTime(data.IntervalStartDateTime),
            slotLength: definedNotNull(data.IntervalDurationInMinutes, 'intervalDuration'),
            duration: 5,
            specialistId: definedNotNull(data.SpecialistId, 'specialistId'),
            principalCustomerId: data.PrincipalCustomerId || undefined,
            delegatePatientName: data.PrincipalName || undefined,
          })),
          refineApiError,
        );
    },
    async confirmBooking(
      lang: Language,
      query: BookingFormData & {
        bookingId: string;
        customerId: string;
        customerType: CustomerType;
        appointmentType: AppointmentType;
        estimatedPrice: number | undefined;
        visibleForGuardian?: boolean;
        principalCustomerId?: string;
        directCompensationPermitId?: string;
      },
      token: AuthToken,
      delegateToken: DelegateToken | undefined,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationTemporaryConfirmPost(
          {
            AppointmentId: query.bookingId,
            CustomerId: query.customerId,
            Reason: query.reason,
            GiftCardNumber: query.giftCardCode,
            EmailAddress: query.email,
            SMSNumber: query.sms,
            DentalPhoneNumber: query.dentalPhone,
            PhoneNumberToCall: query.phone,
            AppointmentType: ownToApiAppointmentType(query.appointmentType),
            CustomerType: ownToApiCustomerType(query.customerType),
            BookingOnlyAtReception: query.bookingOnlyAtReception,
            OutsourcedService: query.outsourcedService,
            DelegateToken: delegateToken ? delegateToken.accessToken : undefined,
            EstimatedPrice: query.estimatedPrice ? priceToEurosString(query.estimatedPrice) : undefined,
            VisibleForGuardian: query.visibleForGuardian ?? true,
            ReminderPhoneNumber:
              !query.visibleForGuardian && query.visibleForGuardian !== undefined
                ? query.phone ?? undefined
                : undefined,
            PrincipalCustomerId: query.principalCustomerId,
            InsuranceCompany: query.insuranceCompany,
            PartnerCompany: query.partnerCompany,
            PartnerType: query.partnerType ? ownToApiPartnerType(query.partnerType) : undefined,
            DirectCompensationPermitId: query.directCompensationPermitId,
          },
          options(lang),
        )
        .then(returnNothing, refineApiError);
    },
    async confirmCallRequestBooking(
      lang: Language,
      query: BookingFormData & {
        bookingId: string;
        customerType: CustomerType;
        delegatePatientName?: string;
        estimatedPrice: number | undefined;
        visibleForGuardian?: boolean;
      },
      token: AuthToken,
      delegateToken: DelegateToken | undefined,
    ) {
      return api
        .CallRequestApi(token.accessToken)
        .callrequestReservationTemporaryConfirmPost(
          {
            ReservationId: query.bookingId,
            EmailAddress: query.email,
            SmsNumber: query.sms,
            PhoneNumberToCall: query.phone,
            CustomerType: ownToApiCustomerType(query.customerType),
            DelegateToken: delegateToken ? delegateToken.accessToken : undefined,
            PrincipalName: delegateToken ? undefined : query.delegatePatientName,
            EstimatedPrice: query.estimatedPrice ? priceToEurosString(query.estimatedPrice) : undefined,
            VisibleForGuardian: query.visibleForGuardian ?? true,
            ReminderPhoneNumber:
              !query.visibleForGuardian && query.visibleForGuardian !== undefined
                ? query.phone ?? undefined
                : undefined,
          },
          options(lang),
        )
        .then(returnNothing, refineApiError);
    },
    async getBooking(
      lang: Language,
      query: {
        bookingId: string;
        customerId: string;
      },
      token: AuthToken,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationConfirmedGet(query.bookingId, query.customerId, options(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.Booking, ({ data }) => ({
            bookingId: defined(data.Id, 'bookingId'),
            bookingCode: definedNotNull(data.WebCode, 'bookingCode'),
            dateTime: apiToOwnDateTime(data.DateTime),
            clinic: apiToOwnAppointmentClinic(lang, data.Clinic),
            hideClinicDetails: !!data.HideClinicDetails,
            specialist: apiToOwnAppointmentSpecialist(lang, data.Specialist, data.FallbackSpecialistName),
            duration: defined(data.DurationInMinutes, 'duration'),
            appointmentType: apiToOwnAppointmentType(data.AppointmentType),
            optionalAppointmentTypes: apiToOwnAppointmentTypes(data.OptionalAppointmentTypes, true),
            customerType: apiToOwnCustomerType(data.CustomerType),
            service: apiToOwnService(lang, data.Service),
            delegatePatientName: data.PrincipalName || undefined,
            respiratorySymptoms: apiToOwnRespiratorySymptoms(data.InfectionRisk),
            workTimeType: data.WorkTimeType || undefined,
            visibleForGuardian: data.VisibleForGuardian,
          })),
          refineApiError,
        );
    },
    async getCallRequestBooking(lang: Language, bookingId: string, token: AuthToken) {
      return api
        .CallRequestApi(token.accessToken)
        .callrequestReservationConfirmedReservationIdGet(bookingId, options(lang))
        .then(
          guardedModelConversion(analytics, log, ApiModel.CallRequestBooking, ({ data }) => ({
            bookingId,
            bookingCode: undefined, // not available in this api data
            dateTime: apiToOwnDateTime(data.IntervalStartDateTime),
            slotLength: definedNotNull(data.IntervalDurationInMinutes, 'intervalDuration'),
            duration: 5,
            specialistId: definedNotNull(data.SpecialistId, 'specialistId'),
            delegatePatientName: data.PrincipalName || undefined,
          })),
          refineApiError,
        );
    },
    async getBookingWithCode(
      lang: Language,
      query: {
        bookingCode: string;
        customerId: string;
      },
      token: AuthToken,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationConfirmedWebcodeGet(query.customerId, query.bookingCode, options(lang))
        .then(
          res =>
            res.data.AppointmentType === 'CallRequest'
              ? guardedModelConversion<typeof ApiModel.CallRequestOwnBooking, typeof res>(
                  analytics,
                  log,
                  ApiModel.CallRequestOwnBooking,
                  ({ data }) => ({
                    bookingId: defined(data.Id, 'bookingId'),
                    bookingCode: definedNotNull(data.WebCode, 'bookingCode'),
                    dateTime: setCallRequesTime(apiToOwnDateTime(data.DateTime)),
                    slotLength: 12 * 60, // Hardcoded 12 hours because not available in this data
                    duration: 5,
                    specialist: apiToOwnSpecialistBase(lang, data.Specialist), // Do not allow minimal specialists for call requests
                    customerType: apiToOwnCustomerType(data.CustomerType),
                    delegatePatientName: data.PrincipalName || undefined,
                    visibleForGuardian: data.VisibleForGuardian,
                  }),
                )(res)
              : guardedModelConversion<typeof ApiModel.Booking, typeof res>(
                  analytics,
                  log,
                  ApiModel.Booking,
                  ({ data }) => ({
                    bookingId: defined(data.Id, 'bookingId'),
                    bookingCode: definedNotNull(data.WebCode, 'bookingCode'),
                    dateTime: apiToOwnDateTime(data.DateTime),
                    clinic: apiToOwnAppointmentClinic(lang, data.Clinic),
                    hideClinicDetails: !!data.HideClinicDetails,
                    specialist: apiToOwnAppointmentSpecialist(lang, data.Specialist, data.FallbackSpecialistName),
                    duration: defined(data.DurationInMinutes, 'duration'),
                    appointmentType: apiToOwnAppointmentType(data.AppointmentType),
                    optionalAppointmentTypes: apiToOwnAppointmentTypes(data.OptionalAppointmentTypes, true),
                    customerType: apiToOwnCustomerType(data.CustomerType),
                    service: apiToOwnService(lang, data.Service),
                    delegatePatientName: data.PrincipalName || undefined,
                    respiratorySymptoms: apiToOwnRespiratorySymptoms(data.InfectionRisk),
                    workTimeType: data.WorkTimeType || undefined,
                    visibleForGuardian: data.VisibleForGuardian,
                  }),
                )(res),
          refineApiError,
        );
    },
    async getAllOwnBookings(lang: Language, token: AuthToken) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationConfirmedAllGet(options(lang))
        .then(
          res =>
            guardedModelArrayConversionMultiType<
              ApiModelType['Booking'] | ApiModelType['CallRequestOwnBooking'],
              typeof res.data[number]
            >(res.data, ['Booking', 'CallRequestOwnBooking'], d =>
              d.AppointmentType === 'CallRequest'
                ? guardedModelConversion(analytics, log, ApiModel.CallRequestOwnBooking, b => ({
                    bookingId: defined(b.Id, 'bookingId'),
                    bookingCode: definedNotNull(b.WebCode, 'bookingCode'),
                    dateTime: setCallRequesTime(apiToOwnDateTime(b.DateTime)),
                    slotLength: 12 * 60, // Hardcoded 12 hours because not available in this data
                    duration: 5,
                    specialist: apiToOwnSpecialistBase(lang, b.Specialist), // Do not allow minimal specialists for call requests
                    customerType: apiToOwnCustomerType(b.CustomerType),
                    delegatePatientName: b.PrincipalName || undefined,
                  }))
                : guardedModelConversion(analytics, log, ApiModel.Booking, b => ({
                    bookingId: defined(b.Id, 'bookingId'),
                    bookingCode: definedNotNull(b.WebCode, 'bookingCode'),
                    dateTime: apiToOwnDateTime(b.DateTime),
                    clinic: apiToOwnAppointmentClinic(lang, b.Clinic),
                    hideClinicDetails: !!b.HideClinicDetails,
                    specialist: apiToOwnAppointmentSpecialist(lang, b.Specialist, b.FallbackSpecialistName),
                    duration: defined(b.DurationInMinutes, 'duration'),
                    appointmentType: apiToOwnAppointmentType(b.AppointmentType),
                    optionalAppointmentTypes: apiToOwnAppointmentTypes(b.OptionalAppointmentTypes, true),
                    customerType: apiToOwnCustomerType(b.CustomerType),
                    service: apiToOwnService(lang, b.Service),
                    delegatePatientName: b.PrincipalName || undefined,
                    respiratorySymptoms: apiToOwnRespiratorySymptoms(b.InfectionRisk),
                    workTimeType: b.WorkTimeType || undefined,
                    visibleForGuardian: b.VisibleForGuardian,
                  })),
            ),
          refineApiError,
        );
    },
    async editBooking(
      lang: Language,
      query: BookingFormData & {
        bookingId: string;
        customerId: string;
        customerType: CustomerType | null;
        appointmentType: AppointmentType;
        estimatedPrice: number | undefined;
        visibleForGuardian?: boolean;
      },
      token: AuthToken,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationConfirmedUpdatePut(
          {
            /* Notification: PartnerBooking users are no allowed to update booked appointments, only book a new appointments */
            AppointmentId: query.bookingId,
            CustomerId: query.customerId,
            Reason: query.reason,
            GiftCardNumber: query.giftCardCode,
            EmailAddress: query.email,
            SMSNumber: query.sms,
            PhoneNumberToCall: query.phone,
            AppointmentType: ownToApiAppointmentType(query.appointmentType),
            CustomerType: query.customerType ? ownToApiCustomerType(query.customerType) : undefined,
            DelegateToken: undefined, // editing not available with delegate token,
            EstimatedPrice: query.estimatedPrice ? priceToEurosString(query.estimatedPrice) : undefined,
            VisibleForGuardian: query.visibleForGuardian ?? true,
            ReminderPhoneNumber:
              !query.visibleForGuardian && query.visibleForGuardian !== undefined
                ? query.phone ?? undefined
                : undefined,
          },
          options(lang),
        )
        .then(returnNothing, refineApiError);
    },
    async moveDentalBooking(
      lang: Language,
      webCodeToMove: string,
      query: BookingFormData & {
        bookingId: string;
        serviceId: string;
        estimatedPrice: number | undefined;
      },
      token: AuthToken,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .webCodeMovePut(
          webCodeToMove,
          {
            TemporaryReservationId: query.bookingId,
            ServiceId: query.serviceId,
            Reason: query.reason,
            EmailAddress: query.email,
            SmsNumber: query.sms,
            EstimatedPrice: query.estimatedPrice ? priceToEurosString(query.estimatedPrice) : undefined,
          },
          options(lang),
        )
        .then(returnNothing, refineApiError);
    },
    async cancelTemporaryBooking(
      _: Language,
      query: {
        bookingId: string;
        customerId: string;
        reason?: string;
      },
      token: AuthToken,
      delegateToken: DelegateToken | undefined,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationTemporaryCancelPost({
          AppointmentId: query.bookingId,
          CustomerId: query.customerId,
          Reason: query.reason,
          DelegateToken: delegateToken ? delegateToken.accessToken : undefined,
        })
        .then(returnNothing, refineApiError);
    },
    async cancelBooking(
      lang: Language,
      query: {
        bookingId: string;
        customerId: string;
        reason?: string;
      },
      token: AuthToken,
      delegateToken: DelegateToken | undefined,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationConfirmedCancelPost(
          {
            AppointmentId: query.bookingId,
            CustomerId: query.customerId,
            Reason: query.reason,
            DelegateToken: delegateToken ? delegateToken.accessToken : undefined,
          },
          options(lang),
        )
        .then(returnNothing, refineApiError);
    },
    async getAreas(lang: Language) {
      return api.AreaApi2.v2AreasGet(true, true, options(lang)).then(
        res =>
          guardedModelArrayConversion(analytics, log, res.data, ApiModel.Area, data => ({
            areaId: defined(data.Id, 'areaId'),
            name: localized(lang, data.Name, 'areaName'),
            clinicIds: definedNotNull(data.ClinicIDs, 'clinicIds'),
          })),
        refineApiError,
      );
    },
    async getCities(lang: Language) {
      return api.CityApi2.v2CitiesGet(true, true, options(lang)).then(
        res =>
          guardedModelArrayConversion(analytics, log, res.data, ApiModel.City, data => ({
            cityId: defined(data.Id, 'cityId'),
            name: localized(lang, data.Name, 'cityName'),
            clinicIds: definedNotNull(data.ClinicIDs, 'clinicIds'),
          })),
        refineApiError,
      );
    },
    async searchClinics(lang: Language, searchText: string, filter: MedicalType) {
      return api.ClinicApi2.v2ClinicsSearchPost(
        {
          Query: searchText,
          BookingEnabled: true,
          MedicalFields: ownToApiMedicalTypes(filter),
        },
        options(lang),
      ).then(
        res =>
          guardedModelArrayConversion(analytics, log, res.data, ApiModel.ClinicBase, data => ({
            clinicId: defined(data.Id, 'clinicId'),
            name: localized(lang, data.Name, 'clinicName'),
          })),
        refineApiError,
      );
    },
    async getClinicsInArea(lang: Language, areaId: string, filter: MedicalType) {
      return api.ClinicApi2.v2ClinicsPost(
        {
          AreaID: areaId,
          BookingEnabled: true,
          MedicalFields: ownToApiMedicalTypes(filter),
        },
        options(lang),
      ).then(
        res =>
          guardedModelArrayConversion(analytics, log, res.data, ApiModel.ClinicBase, data => ({
            clinicId: defined(data.Id, 'clinicId'),
            name: localized(lang, data.Name, 'clinicName'),
          })),
        refineApiError,
      );
    },
    async getNearestClinics(
      _lang: Language,
      query: {
        latitude: number;
        longitude: number;
        take?: number;
      },
      filter: MedicalType,
    ) {
      return api.ClinicApi2.v2ClinicsNearestPost({
        Longitude: query.longitude,
        Latitude: query.latitude,
        Take: query.take,
        BookingEnabled: true,
        MedicalFields: ownToApiMedicalTypes(filter),
      }).then(res => guardedStringArrayConversion(analytics, log, res.data, data => data), refineApiError);
    },
    async getClinic(lang: Language, clinicId: string) {
      return api.ClinicApi2.v2ClinicsIdGet(clinicId, options(lang)).then(
        guardedModelConversion(analytics, log, ApiModel.ClinicExtended, ({ data }) => ({
          clinicId,
          name: localized(lang, data.Name, 'clinicName'),
          address: localized(lang, data.Address, 'address'),
          city: localized(lang, data.City, 'city'),
          postCode: postCodeFromNumber(defined(data.PostCode, 'postCode')),
          checkInInstructions: localized(lang, data.CheckInInstructions, 'checkInInstructions'),
          arrivalInstructions: localized(lang, data.ArrivalInstructions, 'arrivalInstructions'),
          phoneReservation: data.PhoneReservation || '',
          fax: data.Fax || '',
          entryInstructions: data.EntryInstructions ? localized(lang, data.EntryInstructions, 'entryInstructions') : '',
          latitude: data.GeoLocation && data.GeoLocation.Latitude ? data.GeoLocation.Latitude : null,
          longitude: data.GeoLocation && data.GeoLocation.Longitude ? data.GeoLocation.Longitude : null,
          isDental:
            !data.SubsystemIds ||
            !data.SubsystemIds.length ||
            data.SubsystemIds.some(d => d.Subsystem === TerveystaloBaseAPIAbstractionsSubsystem.Assisdent),
        })),
        refineApiError,
      );
    },
    async getClinicId(_lang: Language, clinicCode: string) {
      return api.ClinicApi2.v2ClinicsSubsystemSubsystemIDGet(
        TerveystaloBaseAPIAbstractionsSubsystem.DynamicHealth,
        clinicCode,
      ).then(
        guardedStringConversion(analytics, log, ({ data }) => data),
        refineApiError,
      );
    },
    async getVisitedSpecialists(lang: Language, dayRange: number, token: AuthToken) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentPastSpecialistAllGet(dayRange)
        .then(
          res =>
            guardedModelArrayConversion(analytics, log, res.data, ApiModel.VisitedSpecialist, data => {
              const Specialist = defined(data.Specialist, 'specialist');
              return {
                specialist: {
                  specialistId: definedNotNull(Specialist.Id, 'specialistId'),
                  firstName: definedNotNull(Specialist.FirstName, 'firstName'),
                  lastName: definedNotNull(Specialist.LastName, 'lastName'),
                  specialistTitle: localized(lang, Specialist.Title, 'specialistTitle'),
                },
                lastVisitCustomerType: apiToOwnCustomerType(data.CustomerType),
                lastVisitDate: apiToOwnDateTime(data.Datetime),
              };
            }),
          refineApiError,
        );
    },
    async getAppointmentApiHealth() {
      return api.AppointmentApiHealth.healthGet().then(
        guardedModelConversion(analytics, log, ApiModel.ApiHealth, ({ data }) => ({
          system: defined(data.System, 'system'),
          dental: get(data.Features, 'Dental', null),
        })),
        refineApiError,
      );
    },
    async sendInsuranceForm(
      query: InsuranceDetails & {
        bookingRoot: BookingRoot;
        bookingId: string;
        customerId: string;
      },
      token: AuthToken,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationSendinsuranceformPost({
          ReservationId: query.bookingId,
          CustomerId: query.customerId,
          InsuranceOrganization: ownToApiInsuranceCompanyText(query.bookingRoot),
          DirectInvoicingServices: query.directInvoicingServices,
          InvoicingMaxAmount: query.maximumCost,
          PatientDeductibleAmount: query.deductible,
          ValidityPeriod: formatISO(query.validUntil),
          SiiDirectCompensationNoted: query.kelaCompensatable,
          InjuryType: query.typeOfInjury,
          IssueNumber: query.issueNumber,
          AccidentDate: query.injuryDate ? formatISO(query.injuryDate) : undefined,
          Company: query.employer,
          Symptom: query.symptom,
          AppointerName: query.bookerName,
          OtherInformation: query.notes,
          CustomerServiceInfo: query.customerServiceInfo,
        })
        .then(returnNothing, refineApiError);
    },
    async sendPublicInvoicingForm(
      query: PublicInvoicingDetails & {
        bookingRoot: BookingRoot;
        bookingId: string;
        customerId: string;
      },
      token: AuthToken,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationSenddirectinvoiceformPost({
          ReservationId: query.bookingId,
          CustomerId: query.customerId,
          DirectInvoicingServices: query.directInvoicingServices,
          ValidityPeriod: formatISO(query.validUntil),
          PayerNumber: query.payerNumber,
          AppointerName: query.bookerName,
          AppointerEmail: query.bookerEmail,
          OtherInformation: query.notes,
        })
        .then(returnNothing, refineApiError);
    },
    async sendMigriInvoicingForm(
      query: MigriBookingDetails & {
        bookingId: string;
        customerId: string;
      },
      token: AuthToken,
    ) {
      return api
        .AppointmentApi(token.accessToken)
        .appointmentReservationSendmigriformPost({
          ReservationId: query.bookingId,
          CustomerId: query.customerId,
          AllowedServices: query.allowedServices,
          PatientUmaNumber: query.patientUmaNumber,
          BookerName: query.bookerName,
          BookerEmail: query.bookerEmail,
          ReceptionCenterName: query.receptionCenterName,
          ReceptionCenterCustomerNumber: query.receptionCenterCustomerNumber,
          ReceptionCenterAdress: query.receptionCenterAddress,
          ReceptionCenterPostalCode: query.receptionCenterPostCode,
          ReceptionCenterCity: query.receptionCenterCity,
          ReceptionCenterNursePhoneNum: query.receptionCenterNursePhoneNum,
          InterpreterDetails: query.interpreterDetails,
          Notes: query.notes ?? 'Ei lisätietoja', // Note: This could allowed to be undefined so that no dummy message is needed. Bad req 400 with empty string
        })
        .then(returnNothing, refineApiError);
    },
    async getCoronaVaccineGuideForm(lang: Language) {
      return api.RedirectionFormApi.getApiV1Coronavaccineform(lang).then(
        guardedModelConversion(analytics, log, ApiModel.RedirectionForm, ({ data }: any) =>
          data && 'result' in data && typeof data.result === 'object'
            ? {
                ...data.result,
                coronaRestrictions: null,
                influenzaVaccineParams: null,
                skips: {
                  services: [],
                  specialists: [],
                },
              }
            : data,
        ),
        refineApiError,
      );
    },
    async getSpecialistPrices(lang: Language, specialistId: string) {
      return api.PriceApi.specialistSpecialistIdPricesGet(specialistId, options(lang)).then(
        res =>
          guardedModelArrayConversion(
            analytics,
            log,
            definedNotNull(res.data.PriceItems, 'priceItems'),
            ApiModel.SpecialistPriceItem,
            data => ({
              itemName: stringLocalized(lang, data.Name, 'itemName'),
              price: defined(data.Price, 'price'),
              priceType: defined(data.PriceType, 'priceType'),
              deductiblePrice: data.DeductiblePrice !== undefined ? data.DeductiblePrice : null,
              serviceId: data.PriceType === 'Service' ? definedNotNull(data.ServiceId, 'serviceId') : null,
              priceCodeMap: apiToOwnPriceCodeMap(data.PriceCodeMap),
            }),
          ),
        refineApiError,
      );
    },
    async getAppointmentPriceFrom(
      lang: Language,
      query: {
        durationInMinutes: number;
        datetime: string;
        appointmentType: AppointmentType;
        serviceId: string | undefined;
        specialistId: string | undefined;
      },
    ) {
      return api.AppointmentPriceApi.appointmentPriceFromGet(
        query.durationInMinutes,
        query.datetime,
        ownToPriceApiAppointmentType(query.appointmentType),
        query.serviceId,
        query.specialistId,
      ).then(
        guardedModelConversion(analytics, log, ApiModel.AppointmentPriceEstimate, ({ data }) => ({
          price: data.price
            ? {
                code: data.price.Code || null,
                estimatedPrice: defined(data.price.Price, 'price'),
                deductiblePrice: data.price.DeductiblePrice || null,
                fixedPrice: !!data.price.FixedPrice,
                name: stringLocalized(lang, data.price.Name, 'appointmentPriceName'),
                info: stringLocalized(lang, data.price.Info, 'appointmentPriceInfo'),
              }
            : undefined,
          isFixedPriceService: !!data.FixedPriceService,
        })),
        refineApiError,
      );
    },
    async getDirectCompensationPermits(lang: Language, authToken: AuthToken, insuranceCompany: string) {
      return api
        .InsuranceApi(authToken.accessToken)
        .v1InsuranceInsuranceCompanyDirectCompensationPermitsGet(
          ownToApiInsuranceCompany(insuranceCompany),
          undefined,
          lang,
        )
        .then(
          res =>
            res.data.Status === 'Ok'
              ? guardedModelArrayConversion(
                  analytics,
                  log,
                  definedNotNull(res.data.Permits, 'permits'),
                  ApiModel.DirectCompensationPermit,
                  data => ({
                    id: data.Id,
                    validFrom: data.ValidFrom ? new Date(data.ValidFrom).toLocaleDateString('fi-FI') : null,
                    validTo: data.ValidTo ? new Date(data.ValidTo).toLocaleDateString('fi-FI') : null,
                    description: data.Description,
                    errorStatus: undefined,
                  }),
                )
              : [
                  {
                    id: 'error',
                    validFrom: '',
                    validTo: '',
                    description: '',
                    errorStatus:
                      res.data.Status === 'NoPolicy'
                        ? ('noPolicy' as ApiErrorStatus)
                        : ('userNotFound' as ApiErrorStatus),
                  },
                ],
          refineApiError,
        );
    },
    async getDelegateDirectCompensationPermits(
      lang: Language,
      authToken: AuthToken,
      insuranceCompany: string,
      delegateToken: string,
    ) {
      return api
        .InsuranceApi(authToken.accessToken)
        .v1InsuranceInsuranceCompanyDirectCompensationPermitsGet(
          ownToApiInsuranceCompany(insuranceCompany),
          delegateToken,
          lang,
        )
        .then(
          res =>
            res.data.Status === 'Ok'
              ? guardedModelArrayConversion(
                  analytics,
                  log,
                  definedNotNull(res.data.Permits, 'permits'),
                  ApiModel.DirectCompensationPermit,
                  data => ({
                    id: data.Id,
                    validFrom: data.ValidFrom ? new Date(data.ValidFrom).toLocaleDateString('fi-FI') : null,
                    validTo: data.ValidTo ? new Date(data.ValidTo).toLocaleDateString('fi-FI') : null,
                    description: data.Description,
                    errorStatus: undefined,
                  }),
                )
              : [
                  {
                    id: 'error',
                    validFrom: '',
                    validTo: '',
                    description: '',
                    errorStatus:
                      res.data.Status === 'NoPolicy'
                        ? ('noPolicy' as ApiErrorStatus)
                        : ('userNotFound' as ApiErrorStatus),
                  },
                ],
          refineApiError,
        );
    },
    async postDirectCompensationChat(lang: Language, authToken: AuthToken, insuranceCompany: string, icid: string) {
      return api
        .InsuranceApi(authToken.accessToken)
        .v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost(
          ownToApiInsuranceCompany(insuranceCompany),
          '', // In this case backend expects to receive empty-string delegateToken apiToOwnChatResponseStatus
          { DirectCompensationPermitId: icid },
        )
        .then(res => apiToOwnChatResponseStatus(res.data.Status), refineApiError);
    },
    async postDelegateDirectCompensationChat(
      lang: Language,
      authToken: AuthToken,
      insuranceCompany: string,
      icid: string,
      delegateToken: string,
    ) {
      return api
        .InsuranceApi(authToken.accessToken)
        .v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost(
          ownToApiInsuranceCompany(insuranceCompany),
          delegateToken,
          { DirectCompensationPermitId: icid },
        )
        .then(res => apiToOwnChatResponseStatus(res.data.Status), refineApiError);
    },
  };
}

function returnNothing() {
  // Nothing useful was returned as a reponse from the api => do nothing
}

// Helper to fix post codes from api
function postCodeFromNumber(postCode: number) {
  const postCodeStr = postCode.toString();
  const addZeroCount = Math.max(0, 5 - postCodeStr.length);
  return `${'0'.repeat(addZeroCount)}${postCodeStr}`;
}

// Reusable mappings between our own model and the api model
function ownToApiGender(type: SpecialistGender) {
  switch (type) {
    case 'male':
      return TerveystaloAppointmentAPIAPIAbstractionV1RequestGender.Male;
    case 'female':
      return TerveystaloAppointmentAPIAPIAbstractionV1RequestGender.Female;
    default:
      return assertExhausted(type);
  }
}

function ownToApiDate(from: number | Date) {
  const date = new Date(from).getTime();
  const dateParts = dateISOString(date).split('-');
  return {
    Year: parseInt(dateParts[0], 10),
    Month: parseInt(dateParts[1], 10),
    Day: parseInt(dateParts[2], 10),
  };
}

function ownToApiAppointmentType(type: AppointmentType) {
  switch (type) {
    case 'clinic':
      return TerveystaloAppointmentAPIAPIAbstractionAppointmentType.Reception;
    case 'phone':
      return TerveystaloAppointmentAPIAPIAbstractionAppointmentType.PhoneReception;
    case 'video':
      return TerveystaloAppointmentAPIAPIAbstractionAppointmentType.RemoteReception;
    default:
      return assertExhausted(type);
  }
}

function ownToPriceApiAppointmentType(type: AppointmentType) {
  switch (type) {
    case 'clinic':
      return TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType.Reception;
    case 'phone':
      return TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType.Phone;
    case 'video':
      return TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType.Remote;
    default:
      return assertExhausted(type);
  }
}

function ownToApiCustomerType(type: CustomerType) {
  switch (type) {
    case 'private':
      return TerveystaloAppointmentAPIAPIAbstractionAPICustomerType.Private;
    case 'occupational':
      return TerveystaloAppointmentAPIAPIAbstractionAPICustomerType.Occupational;
    case 'insurance':
      return TerveystaloAppointmentAPIAPIAbstractionAPICustomerType.Insurance;
    case 'municipality':
      return TerveystaloAppointmentAPIAPIAbstractionAPICustomerType.Municipality;
    default:
      return assertExhausted(type);
  }
}

type MedicalType = 'all' | 'medicine' | 'dental';
function ownToApiMedicalTypes(type: MedicalType) {
  switch (type) {
    case 'all':
      return undefined;
    case 'medicine':
      return [TerveystaloServiceAPIAbstractionsMedicalField.Medicine];
    case 'dental':
      return [TerveystaloServiceAPIAbstractionsMedicalField.Dental];
    default:
      return assertExhausted(type);
  }
}

export type SpecialistSearchField = 'name' | 'title';
function ownToApiSpecialistSearchFields(type: SpecialistSearchField) {
  switch (type) {
    case 'name':
      return [
        TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField.FirstName,
        TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField.LastName,
      ];
    case 'title':
      return [TerveystaloSpecialistAPIAbstractionsV2SpecialistApiSpecialistSearchField.Title];
    default:
      return assertExhausted(type);
  }
}

function ownToApiInsuranceCompanyText(root: BookingRoot) {
  switch (root) {
    case 'privateCustomer':
    case 'privateCustomerV2':
    case 'occupationalHealthcare':
    case 'dental':
    case 'insurance':
    case 'voucher':
    case 'publicPartner':
    case 'htaTeam':
    case 'migri':
      return '';
    case 'terveysmestari':
      return 'Terveysmestari';
    case 'fenniahoitaja':
      return 'FenniaHoitaja';
    case 'terveyshelppi':
      return 'TerveysHelppi';
    default:
      return assertExhausted(root);
  }
}

function ownToApiRespiratorySymptoms(respiratorySymptoms: RespiratorySymptoms) {
  switch (respiratorySymptoms) {
    case 'symptoms':
      return TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk.Respiratory;
    case 'no-symptoms':
      return TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk.None;
    case undefined:
      return undefined;
    default:
      return assertExhausted(respiratorySymptoms);
  }
}

function ownToApiInsuranceCompany(insuranceCompany: string) {
  switch (insuranceCompany) {
    case 'stub':
      return TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany.Stub;
    case 'LähiTapiola':
      return TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany.LahiTapiola;
    case undefined:
      return undefined;
    default:
      return assertExhausted();
  }
}

function ownToApiPartnerType(partnerType: 'insurance' | 'other') {
  switch (partnerType) {
    case 'insurance':
      return TerveystaloAppointmentAPICoreDomainPartnerType.InsuranceCompany;
    case 'other':
      return TerveystaloAppointmentAPICoreDomainPartnerType.Other;
    case undefined:
      return undefined;
    default:
      return assertExhausted();
  }
}

function apiToOwnAppointmentType(type: TerveystaloAppointmentAPIAPIAbstractionAppointmentType | undefined) {
  const appointmentType = defined(type, 'appointmentType');
  switch (appointmentType) {
    case TerveystaloAppointmentAPIAPIAbstractionAppointmentType.Reception:
      return 'clinic';
    case TerveystaloAppointmentAPIAPIAbstractionAppointmentType.PhoneReception:
      return 'phone';
    case TerveystaloAppointmentAPIAPIAbstractionAppointmentType.RemoteReception:
      return 'video';
    case TerveystaloAppointmentAPIAPIAbstractionAppointmentType.ChatReception:
      throw new Error('Type "ChatReception" should never be used.');
    case TerveystaloAppointmentAPIAPIAbstractionAppointmentType.CallRequest:
      return undefined;
    default:
      return assertExhausted(appointmentType);
  }
}

function apiToOwnAppointmentTypes(
  types: TerveystaloAppointmentAPIAPIAbstractionAppointmentType[] | null | undefined,
  optional = false,
): AppointmentType[] {
  const theirTypes = optional ? types || [] : definedNotNull(types, 'appointmentTypes');
  const ownTypes = theirTypes.map(apiToOwnAppointmentType);
  // Make sure the types are in certain order
  return regularAppointmentTypes.filter(t => ownTypes.includes(t));
}

function priceApiToOwnAppointmentType(type: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType | undefined) {
  const appointmentType = defined(type, 'appointmentType');
  switch (appointmentType) {
    case TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType.Reception:
      return 'clinic';
    case TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType.Phone:
      return 'phone';
    case TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType.Remote:
      return 'video';
    default:
      return assertExhausted(appointmentType);
  }
}

function apiToOwnTimeOfDay(type: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse | undefined) {
  const timeOfDay = defined(type, 'timeOfDay');
  switch (timeOfDay) {
    case TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse.Weekday:
      return 'weekday';
    case TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse.Saturday:
      return 'saturday';
    case TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse.Sunday:
      return 'sunday';
    case TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse.Night:
      return 'night';
    default:
      return assertExhausted(timeOfDay);
  }
}

function apiToOwnCustomerType(type: TerveystaloAppointmentAPICoreDomainCustomerType | undefined) {
  switch (type) {
    case TerveystaloAppointmentAPICoreDomainCustomerType.Private:
      return 'private';
    case TerveystaloAppointmentAPICoreDomainCustomerType.Occupational:
      return 'occupational';
    case TerveystaloAppointmentAPICoreDomainCustomerType.Insurance:
      return 'insurance';
    case TerveystaloAppointmentAPICoreDomainCustomerType.Municipality:
      return 'municipality';
    case undefined:
      return null;
    default:
      return null;
  }
}

function apiToOwnSpecialistBase(
  lang: Language,
  data: TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem | undefined,
): ApiModelType['SpecialistBase'] {
  const Specialist = defined(data, 'specialist');

  return {
    specialistId: definedNotNull(Specialist.Id, 'specialistId'),
    firstName: definedNotNull(Specialist.FirstName, 'firstName'),
    lastName: definedNotNull(Specialist.LastName, 'lastName'),
    specialistTitle: localized(lang, Specialist.Title, 'specialistTitle'),
  };
}

function apiToOwnAppointmentSpecialist(
  lang: Language,
  data: TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem | undefined,
  fallbackName?: string | null,
): ApiModelType['SpecialistBase'] | ApiModelType['SpecialistMinimal'] {
  if (!data && !!fallbackName) {
    return { specialistId: null, fullName: fallbackName };
  }

  const Specialist = defined(data, 'specialist');
  if (!Specialist.Id) {
    return { specialistId: null, fullName: definedNotNull(Specialist.FullName, 'fullName') };
  }

  return apiToOwnSpecialistBase(lang, data);
}

function apiToOwnAppointmentClinic(
  lang: Language,
  data: TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem | undefined,
): ApiModelType['Clinic'] {
  const Clinic = defined(data, 'clinic');
  return {
    clinicId: defined(Clinic.Id, 'clinicId'),
    name: localized(lang, Clinic.Name, 'name'),
    address: localized(lang, Clinic.StreetAddress, 'address'),
    city: localized(lang, Clinic.CityName, 'city'),
    postCode: definedNotNull(Clinic.PostCode, 'postCode'),
    checkInInstructions: localized(lang, Clinic.CheckInInstructions, 'checkInInstructions'),
    arrivalInstructions: localized(lang, Clinic.ArrivalInstructions, 'arrivalInstructions'),
  };
}

function apiToOwnService(
  lang: Language,
  Service: TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem | undefined,
): ApiModelType['ServiceBase'] | null {
  return Service
    ? {
        serviceId: defined(Service.Id, 'serviceId'),
        serviceName: localized(lang, Service.Name, 'serviceName'),
      }
    : null;
}

// The dates come as type Date from the api model, but actually they are date strings
// e.g. "2019-10-15T10:45:00+03:00"
function apiToOwnDateTime(data: Date | string | undefined): string {
  // To take advantage of the time string being in Finland time zone, we don't do new Date(data).toISOString()
  return defined(data, 'dateTime').toString();
}

// Helper function to fix call request datetime coming from api, hardcode to 8 am given (Finnish time)
function setCallRequesTime(date: string) {
  // e.g. 2021-11-18T23:40:00+02:00 => 2021-11-18T08:00:00+02:00
  return date.replace(/T\d{2}:\d{2}:\d{2}/, 'T08:00:00');
}

function apiToOwnRespiratorySymptoms(
  infectionRisk: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk | undefined,
): RespiratorySymptoms {
  switch (infectionRisk) {
    case TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk.Respiratory:
      return 'symptoms';
    case TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk.None:
      return 'no-symptoms';
    case undefined:
      return undefined;
    default:
      return assertExhausted(infectionRisk);
  }
}

function apiToOwnPriceCodeMap(
  PriceCodeMap: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse | undefined,
): ApiModelType['SpecialistPriceItem']['priceCodeMap'] | undefined {
  return PriceCodeMap
    ? {
        durationInMinutes: defined(PriceCodeMap.DurationInMinutes, 'durationInMinutes'),
        specialistType: defined(PriceCodeMap.SpecialistType, 'specialistType'),
        timeOfDay: apiToOwnTimeOfDay(PriceCodeMap.TimeOfDay),
        appointmentType: priceApiToOwnAppointmentType(PriceCodeMap.AppointmentType),
      }
    : undefined;
}

function apiToOwnChatResponseStatus(
  status: TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus | undefined,
) {
  switch (status) {
    case TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus.Ok:
      return 'Ok';
    case TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus.Error:
    case undefined:
    default:
      return 'Error';
  }
}

// Returns a rejected Promise, with additional details about an ADA API error, if possible
function refineApiError(err: any) {
  if (err && err.response && err.response.status < 500) {
    // The API handled the request properly, but still returned an error -> treat it as "managed" error
    const res: AxiosResponse = err.response;
    return Promise.reject(
      createError.AdaApiManagedError('API request failed', {
        apiErrorCode: res.status,
        requestMethod: res.config.method || 'UNKNOWN',
        requestUrl: res.config.url || 'UNKNOWN',
        responseData: res.data,
      }),
    );
  } else {
    // We don't know anything particular about this Error -> just pass it on
    return Promise.reject(err);
  }
}

// Determines the correct localized string from an API response
export function localized(lang: Language, string: Localized[] | null | undefined, name: string): string {
  if (Array.isArray(string)) {
    const result = string.find(val => val.TwoLetterISOLanguage === lang);
    if (result && result.Value) return result.Value;
    return ''; // no localized value for the lang we want -> treat as empty string
  } else {
    throw new Error(
      `Couldn't determine localized value in API response for lang "${lang}" from value: ${JSON.stringify(
        string,
      )} (${name})`,
    );
  }
}

export function stringLocalized(lang: Language, string: PriceApiLocalized[] | null | undefined, name: string): string {
  if (Array.isArray(string)) {
    const result = string.find(val => val.TwoLetterISOLanguage === lang);
    if (result && result.Value) return result.Value;
    return ''; // no localized value for the lang we want -> treat as empty string
  } else {
    throw new Error(
      `Couldn't determine localized value in API response for lang "${lang}" from value: ${JSON.stringify(
        string,
      )} (${name})`,
    );
  }
}
