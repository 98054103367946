import { actions } from 'actions';
import { bindActionCreators, Store as ReduxStore } from 'redux';
import { NO_LOGGING } from 'utils/logging/logging-utils';

export type Action = ActionUnionFrom<typeof actions>;

type valueof<T> = T[keyof T];
type ActionCreatorMap = { [key: string]: (...args: any[]) => Record<string, any> };
type ActionCreatorMapWithType<T extends { [key: string]: (...args: any) => any }> = {
  [K in keyof T]: ActionCreatorWithType<T[K], K>;
};
type ActionUnionFrom<T extends ActionCreatorMap> = valueof<{ [P in keyof T]: ReturnType<T[P]> }>; // returns the union of all possible return types from the given action creator map
type ActionCreatorWithType<A, T> = A extends (...args: any) => infer R
  ? (...args: Parameters<A>) => Readonly<R & { type: T }>
  : never;

// Updates the given map of action creators so that their return value (i.e. the action object)
// contains a "type" key with the type of each action, as determined by their key in the given map.
export function defineActionCreators<T extends ActionCreatorMap>(map: T): ActionCreatorMapWithType<T> {
  return Object.keys(map)
    .map(type => ({
      [type]: (...args: any[]) => ({ type, ...map[type].apply(null, args) }),
    }))
    .reduce((memo, next) => ({ ...memo, ...next }), {} as any);
}

// Simple wrapper around Redux's bindActionCreators(), but with support for optionally logging dispatches
export function createBoundDispatcher(reduxStore: ReduxStore, log = NO_LOGGING): typeof actions {
  return bindActionCreators(actions, action => {
    log('Dispatching action', action);
    return reduxStore.dispatch(action);
  });
}
