/** @jsxImportSource @emotion/react */
import { CheckboxItem, FormFieldLabel, RadioButtonItem, Select } from '@terveystalo/design-system-react-components';
import get from 'lodash/get';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { AppointmentType, regularAppointmentTypes } from 'model/api';
import React, { FC, Fragment } from 'react';
import { initState } from 'reducers/filters';
import { format24to12 } from 'utils/appointments/helpers';
import { useHideRemoteOptions } from 'utils/filters/helpers';
import { useApiData, useTranslations } from 'utils/react/ui-context';
import { classNames } from 'utils/styles/helpers';
import { notAsked } from 'utils/types/remoteData';

type Filters = typeof initState;
type Props = {
  filters: Pick<
    Filters,
    'fromTimeOfDay' | 'toTimeOfDay' | 'appointmentTypes' | 'specialistGender' | 'specialistLanguage' | 'restrictions'
  >;
  isOpen: boolean;
  onChange: {
    fromTimeOfDay: (v: Filters['fromTimeOfDay']) => void;
    toTimeOfDay: (v: Filters['toTimeOfDay']) => void;
    appointmentTypes: (v: Filters['appointmentTypes']) => void;
    specialistGender: (v: Filters['specialistGender']) => void;
    specialistLanguage: (v: Filters['specialistLanguage']) => void;
  };
  grayInputs?: boolean;
};

export const AdditionalFiltersContent: FC<Props> = ({ filters, isOpen, onChange, grayInputs }) => {
  const { fromTimeOfDay, toTimeOfDay, appointmentTypes, specialistGender, specialistLanguage, restrictions } = filters;

  const t = useTranslations();
  const lang = 'fi';
  const getLanguages = useApiData('getSpecialistLanguages');

  const appointmentLabels = {
    clinic: t.appointment_at_the_clinic,
    phone: t.phone_appointment,
    video: t.video_appointment,
  };
  const getNewAppointmentTypes = (value: AppointmentType) => {
    if (!appointmentTypes) {
      return regularAppointmentTypes.filter(v => v !== value);
    }
    return appointmentTypes.includes(value) ? appointmentTypes.filter(v => v !== value) : [...appointmentTypes, value];
  };

  const genderOptions = [
    { value: '' as const, label: t.no_significance },
    { value: 'female' as const, label: t.female },
    { value: 'male' as const, label: t.male },
  ];
  const languagesData = isOpen ? getLanguages([]) : notAsked;
  const hideRemoteOptions = useHideRemoteOptions();

  return (
    <Fragment>
      <div className={classNames('m:mb-1', 'm:border-b-xxs', 'border-zodiac-black-22')}>
        <div className={classNames('flex', 'items-end')}>
          <Select
            label={t.at_the_earliest_at}
            name="fromTime"
            value={fromTimeOfDay || fromTimeOfDayOptions(lang)[0].value}
            options={fromTimeOfDayOptions(lang).filter(o => !toTimeOfDay || toTimeOfDay >= o.value)}
            onChange={e => onChange.fromTimeOfDay(e.target.value)}
            grayBackground={grayInputs}
            className={classNames('flex-grow')}
          />
          <Select
            label={t.at_the_latest_at}
            name="toTime"
            value={toTimeOfDay || toTimeOfDayOptions(lang)[toTimeOfDayOptions(lang).length - 1].value}
            options={toTimeOfDayOptions(lang).filter(o => !fromTimeOfDay || fromTimeOfDay <= o.value)}
            onChange={e => onChange.toTimeOfDay(e.target.value)}
            grayBackground={grayInputs}
            className={classNames('flex-grow', 'ml-1')}
          />
        </div>
        {!hideRemoteOptions && (
          <FilterWrapper fieldId="types-select" label={t.type_of_appointment}>
            {regularAppointmentTypes.map(opt => (
              <CheckboxItem
                key={`type-${opt}`}
                name="appointment-types"
                label={appointmentLabels[opt]}
                checked={appointmentTypes ? appointmentTypes.includes(opt) : true}
                onChange={() => {
                  onChange.appointmentTypes(getNewAppointmentTypes(opt));
                }}
                grayBackground={grayInputs}
                disabled={
                  (!!restrictions && !!restrictions.appointmentTypes && !restrictions.appointmentTypes.includes(opt)) ||
                  (appointmentTypes && appointmentTypes.length === 1 && appointmentTypes.includes(opt)) ||
                  false
                }
              />
            ))}
          </FilterWrapper>
        )}
      </div>
      {isOpen && (
        <div className={classNames('m:mt-1.5', 'm:mb-1', 'm:border-b-xxs', 'border-zodiac-black-22')}>
          <FilterWrapper fieldId="specialist-gender-select" label={t.specialists_gender}>
            {genderOptions.map(opt => (
              <RadioButtonItem
                key={`gender-${opt.value}`}
                name="specialist-gender"
                label={opt.label}
                checked={specialistGender ? specialistGender === opt.value : opt.value === ''}
                onChange={() => onChange.specialistGender(opt.value || null)}
                grayBackground={grayInputs}
              />
            ))}
          </FilterWrapper>
          <Select
            label={t.specialists_language}
            name="lang"
            value={specialistLanguage || ''}
            options={[
              { value: '', label: t.all_languages },
              ...(languagesData.status === 'REMOTE_DATA_SUCCESS'
                ? sortBy(
                    languagesData.data.map(v => ({ value: v, label: get(t, `lang_${v}`, v) ?? 'undefined' })),
                    'label',
                  )
                : []),
            ]}
            onChange={e => onChange.specialistLanguage(e.target.value || null)}
            grayBackground={grayInputs}
          />
        </div>
      )}
    </Fragment>
  );
};

const FilterWrapper: FC<{ fieldId: string; label: string; noMargin?: boolean; children?: React.ReactNode }> = ({
  fieldId,
  label,
  noMargin,
  children,
}) => (
  <fieldset className={classNames(!noMargin && 'mb-1')} id={fieldId}>
    <FormFieldLabel fieldId={fieldId} as="legend" label={label} />
    {children}
  </fieldset>
);

// Time strings to choose from
export const fromTimeOfDayOptions = (lang = 'fi') =>
  range(0, 24, 0.5).map(v => {
    const str = `${v < 10 ? '0' : ''}${Math.floor(v)}:${v % 1 === 0 ? '0' : '3'}0`;
    return { value: str, label: lang == 'en' ? format24to12(str) : str.replace(':', '.') };
  });

export const toTimeOfDayOptions = (lang = 'fi') => [
  ...fromTimeOfDayOptions(lang).slice(1),
  { value: '23:59', label: lang == 'en' ? format24to12('24:00') : '24.00' },
];
