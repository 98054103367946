import { History } from 'history';
import { ConfigModelType } from 'model/config';
import { Analytics } from 'utils/analytics/analytics-utils';
import { addScript } from 'utils/dom/helpers';

export function initializePlugins(config: ConfigModelType['FrontendConfig'], history: History, analytics: Analytics) {
  if (config.FEATURE_CUSTOMER_SERVICE_CHAT) {
    // Do not show the customer service chat when site is embedded in the mobile app
    if (!history.location.search.match('embedInApp=true')) {
      const paramsInput = document.createElement('input');
      paramsInput.id = 'NinchatBoxParameters';
      paramsInput.type = 'hidden';
      paramsInput.value = JSON.stringify({
        lang: 'fi-FI',
        requireInfo: '0',
        offerMode: 'active',
        queueAddr: 'chat_bot_queue@terveystalo.com',
        allocTimeout: '20000',
      });
      const body = document.body || document.getElementsByTagName('body')[0];
      if (body) {
        body.appendChild(paramsInput);
        addScript('https://ninchat.com/customer/terveystalo/tap/init-booking-customer-service.js', [
          { key: 'id', value: 'NinchatBoxInit' },
        ]);

        // Detect when chat is loaded and change the z-index to a better one
        try {
          const observer = new MutationObserver(mutations =>
            mutations.forEach(mutation => {
              if (typeof mutation.addedNodes.forEach === 'function') {
                return mutation.addedNodes.forEach(n => {
                  if (n instanceof HTMLElement && n.id === 'ninchat-iframe') {
                    n.style.zIndex = '8'; // On top of main content and feedback bubble, but under our modals
                    observer.disconnect();
                  }
                });
              }
            }),
          );
          observer.observe(body, { childList: true });
        } catch (e) {
          // eslint-disable-next-line
          // @ts-ignore: e.message was used here 2yr already when newest version of ts started alerting
          analytics.sendCustomerServiceChatObserverFailed((e && e.message) || `${e}`);
        }
      }
    }
  }
}
