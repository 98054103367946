/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { BookingRoot, getPartnerName, isPartnerRoot } from 'utils/routes/routes';
import { classNames } from 'utils/styles/helpers';
import { assertExhausted } from 'utils/types/misc';

export const PartnerHeaderDetail: FC<{ bookingRoot: BookingRoot }> = ({ bookingRoot }) => {
  if (!isPartnerRoot(bookingRoot) || bookingRoot === 'htaTeam') {
    return null;
  }

  let name = '';
  let logoSrc: any;
  switch (bookingRoot) {
    case 'privateCustomer':
    case 'privateCustomerV2':
    case 'occupationalHealthcare':
    case 'dental':
    case 'insurance':
    case 'voucher':
    case 'migri':
      break;
    case 'terveysmestari':
      name = 'Terveysmestari';
      logoSrc = require('assets/pohjola-logo.jpg');
      break;
    case 'fenniahoitaja':
      name = 'FenniaHoitaja';
      logoSrc = require('assets/fennia-logo.jpg');
      break;
    case 'terveyshelppi':
      name = 'TerveysHelppi';
      logoSrc = require('assets/lahitapiola-logo.jpg');
      break;
    case 'publicPartner':
      name = 'Julkiset palvelut';
      break;
    default:
      assertExhausted(bookingRoot);
  }
  const logoSrcStr = typeof logoSrc === 'string' ? logoSrc : !!logoSrc ? logoSrc.default : undefined;

  return (
    <div className={classNames('flex', 'items-center', 'bg-white', !!logoSrcStr && 'px-1', 's:px-1.5')}>
      {!!logoSrcStr && (
        <img className={classNames('flex-shrink-0', 'h-2.5')} alt="" src={logoSrcStr} aria-hidden="true" />
      )}
      <span className={classNames('hidden', 's:block', !!logoSrcStr && 'ml-0.75', 'text-zodiac-black-65')}>
        <span className={classNames('block')}>{name}</span>{' '}
        <span className={classNames('block', 'whitespace-nowrap')}>{getPartnerName(bookingRoot)}</span>
      </span>
    </div>
  );
};
