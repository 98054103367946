import { TranslationMap } from 'translations';
import fiTranslations from 'translations/generated/translations-fi';
import { Action } from 'utils/redux/actions';

export const initState: { fi: TranslationMap; sv?: TranslationMap | 'loading'; en?: TranslationMap | 'loading' } = {
  fi: fiTranslations,
  sv: undefined,
  en: undefined,
};

export default function reducer(state = initState, action: Action) {
  switch (action.type) {
    case 'setTranslations':
      return { ...state, [action.lang]: action.translations };
    default:
      return state;
  }
}
