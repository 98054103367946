import mapValues from 'lodash/mapValues';
import { ConfigModelType } from 'model/config';
import { parse } from 'query-string';
import React, { useContext, useMemo } from 'react';
import { ConfigContext } from 'utils/config/ConfigContextProvider';
import { createLogger } from 'utils/logging/logging-utils';
import { getStorageItem, setStorageItem } from 'utils/storage/helpers';
import { assertExhausted } from 'utils/types/misc';

type GroupDefinition = {
  groupNumber: number;
  division: '50-50' | '90-10';
  strPos?: number; // Client string position to use, defaults to groupNumber - 1
};

// Define current A/B testing groups (name and division)
// You can control test start and end dates with the config (env) variable AB_TEST_DATES
type GroupName = 'feedbackType' | 'occupationalLander' | 'priceLocation' | 'occupationalLoginHelper'; // All group names in use, use short names to not reach analytics max length (255)
const groupDefinitions: Record<GroupName, GroupDefinition> = {
  // For example: exampleChange: { groupNumber: 1, division: '50-50' },
  feedbackType: { groupNumber: 2, division: '50-50' },
  occupationalLander: { groupNumber: 3, division: '90-10' },
  priceLocation: { groupNumber: 4, division: '50-50' },
  occupationalLoginHelper: { groupNumber: 5, division: '50-50' },
};

const storageKey = 'st-booking-abt';
export type AbTesting = {
  getGroupLetter: (groupName: GroupName) => string | null;
  groups: {
    feedbackType: string | null;
    occupationalLander: string | null;
    priceLocation: string | null;
    occupationalLoginHelper: string | null;
  };
};

export const AbTestingContextProvider = ({
  nonRandom = false,
  children,
}: {
  nonRandom?: boolean;
  children?: React.ReactNode;
}) => {
  const config = useContext(ConfigContext);
  const log = createLogger(config, 'A/B testing');

  const abTesting = useMemo(() => {
    // When initialized, load (or create and store) a random string for setting A/B-testing groups for this browser
    const storedVal = getStorageItem(storageKey, localStorage);
    const isValidVal = !!storedVal && /^[0-9]{8}$/.test(storedVal);
    let clientStr: string;
    if (!!storedVal && isValidVal) {
      clientStr = storedVal;
      log('Found client string', clientStr);
    } else {
      // Generate a new client string of 8 digits (0-9)
      const newVal = [...new Array(8)].map(() => `${Math.random() * 10}`.slice(0, 1)).join('');
      setStorageItem(storageKey, newVal, localStorage);
      clientStr = newVal;
      log('Created client string', clientStr);
    }

    // Possibility to override groups with query string, e.g. ab=A1&ab=B2
    const { ab } = parse(window.location.search);
    const overrides = !ab ? [] : typeof ab === 'string' ? [ab] : ab;

    const groups = mapValues(groupDefinitions, (d, k) => setGroup(k, d));
    log('Current groups', groupDefinitions, groups);

    return {
      getGroupLetter, // For code implementation
      groups, // For analytics
    };

    // Helper for setting groups with different divisions: should return always same group for same clientStr and definition
    function setGroup(groupName: string, definition: GroupDefinition) {
      const { groupNumber, division, strPos = groupNumber - 1 } = definition;
      const digit = clientStr.slice(strPos, strPos + 1);

      const override = overrides.find(o => o.slice(1) === `${groupNumber}`);
      if (override) {
        return override;
      }

      // Check if the test is active
      const isActive = isActiveTestDate(config.AB_TEST_DATES[groupName]) && !nonRandom;

      if (!isActive) {
        return null;
      }

      let letter = ''; // Use A for base/control case
      switch (division) {
        case '50-50':
          // Two even-sized groups: A and B
          letter = digit < '5' ? 'A' : 'B';
          break;
        case '90-10':
          // Remember to add this back to examples when not used
          letter = digit === '0' ? 'B' : 'A';
          break;
        // Other examples:
        // case '95-5':
        //   // Select two digits to get a value divisible by 20
        //   const from100 = parseInt(clientStr.slice(strPos, strPos + 2), 10);
        //   letter = from100 < 5 ? 'B' : 'A';
        //   break;
        // case '1/3':
        //   // Split as evenly as possbile into three: A, B and C, select from three digits to get more even groups
        //   const from1000 = parseInt(clientStr.slice(strPos, strPos + 3), 10);
        //   letter = from1000 < 334 ? 'A' : from1000 < 667 ? 'B' : 'C';
        //   break;
        default:
          assertExhausted();
      }
      return `${letter}${groupNumber}`;
    }

    /**
     *
     * @param groupName
     * @returns group letter: 'A' | 'B' | ... or null if the test is not active, A is used for base case
     */
    function getGroupLetter(groupName: GroupName) {
      const group = groups[groupName];
      return !group ? null : group.slice(0, 1);
    }
  }, [config.AB_TEST_DATES, log, nonRandom]);

  return <AbTestingContext.Provider value={abTesting}>{children}</AbTestingContext.Provider>;
};

export function initializeAbTesting(config: ConfigModelType['FrontendConfig'], nonRandom = false) {
  const log = createLogger(config, 'A/B testing');

  // When initialized, load (or create and store) a random string for setting A/B-testing groups for this browser
  const storedVal = getStorageItem(storageKey, localStorage);
  const isValidVal = !!storedVal && /^[0-9]{8}$/.test(storedVal);
  let clientStr: string;
  if (!!storedVal && isValidVal) {
    clientStr = storedVal;
    log('Found client string', clientStr);
  } else {
    // Generate a new client string of 8 digits (0-9)
    const newVal = [...new Array(8)].map(() => `${Math.random() * 10}`.slice(0, 1)).join('');
    setStorageItem(storageKey, newVal, localStorage);
    clientStr = newVal;
    log('Created client string', clientStr);
  }

  // Possibility to override groups with query string, e.g. ab=A1&ab=B2
  const { ab } = parse(window.location.search);
  const overrides = !ab ? [] : typeof ab === 'string' ? [ab] : ab;

  const groups = mapValues(groupDefinitions, (d, k) => setGroup(k, d));
  log('Current groups', groupDefinitions, groups);

  return {
    getGroupLetter, // For code implementation
    groups, // For analytics
  };

  // Helper for setting groups with different divisions: should return always same group for same clientStr and definition
  function setGroup(groupName: string, definition: GroupDefinition) {
    const { groupNumber, division, strPos = groupNumber - 1 } = definition;
    const digit = clientStr.slice(strPos, strPos + 1);

    const override = overrides.find(o => o.slice(1) === `${groupNumber}`);
    if (override) {
      return override;
    }

    // Check if the test is active
    const isActive = isActiveTestDate(config.AB_TEST_DATES[groupName]) && !nonRandom;

    if (!isActive) {
      return null;
    }

    let letter = ''; // Use A for base/control case
    switch (division) {
      case '50-50':
        // Two even-sized groups: A and B
        letter = digit < '5' ? 'A' : 'B';
        break;
      case '90-10':
        // Remember to add this back to examples when not used
        letter = digit === '0' ? 'B' : 'A';
        break;
      // Other examples:
      // case '95-5':
      //   // Select two digits to get a value divisible by 20
      //   const from100 = parseInt(clientStr.slice(strPos, strPos + 2), 10);
      //   letter = from100 < 5 ? 'B' : 'A';
      //   break;
      // case '1/3':
      //   // Split as evenly as possbile into three: A, B and C, select from three digits to get more even groups
      //   const from1000 = parseInt(clientStr.slice(strPos, strPos + 3), 10);
      //   letter = from1000 < 334 ? 'A' : from1000 < 667 ? 'B' : 'C';
      //   break;
      default:
        assertExhausted();
    }
    return `${letter}${groupNumber}`;
  }

  /**
   *
   * @param groupName
   * @returns group letter: 'A' | 'B' | ... or null if the test is not active, A is used for base case
   */
  function getGroupLetter(groupName: GroupName) {
    const group = groups[groupName];
    return !group ? null : group.slice(0, 1);
  }
}

export function isActiveTestDate(str: string | undefined, compareDate?: Date) {
  const parts = !!str ? str.split(';') : [];
  const now = compareDate || new Date();
  const startDate = parts.length > 0 ? new Date(parts[0]) : undefined;
  const endDate = parts.length > 1 ? new Date(parts[1]) : undefined;

  const isAfterStart = !startDate || isNaN(startDate.getTime()) || startDate <= now;
  const isBeforeEnd = !endDate || isNaN(endDate.getTime()) || endDate > now;

  return isAfterStart && isBeforeEnd;
}

export const AbTestingContext = React.createContext<AbTesting>({
  getGroupLetter: () => null,
  groups: {
    feedbackType: null,
    occupationalLander: null,
    priceLocation: null,
    occupationalLoginHelper: null,
  },
});
