import { ApiClient } from 'api/ApiClient';
import { History, Location } from 'history';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import startsWith from 'lodash/startsWith';
import without from 'lodash/without';
import xor from 'lodash/xor';
import { regularAppointmentTypes } from 'model/api';
import { ConfigModelType } from 'model/config';
import { parse, ParsedQuery, stringify } from 'query-string';
import { useContext } from 'react';
import { initState as filtersInitState } from 'reducers/filters';
import { Language, languages } from 'translations/generated/translation-languages';
import { fromTimeOfDayOptions, toTimeOfDayOptions } from 'ui/search-filters/AdditionalFiltersContent';
import { AnalyticsContext } from 'utils/analytics/AnalyticsContextProvider';
import { getCustomerTypeParam } from 'utils/booking/helpers';
import { dateISOString } from 'utils/datetime/format';
import { isPast, TimeSource } from 'utils/datetime/helpers';
import {
  getInsuranceCompanyFromIdNumber,
  getInsuranceCompanyIdFromInsuranceCompany,
} from 'utils/partnerAndInsurance/helpers';
import { Dispatch } from 'utils/react/ui-context';
import { Store } from 'utils/redux/store';
import { allBookingRoots, routes } from 'utils/routes/routes';
import { assertExhausted, objectKeysExactly } from 'utils/types/misc';

type HistoryWithState = History<
  { initialRedirect?: boolean; arrivingFromAuth?: boolean; fromSync?: boolean } | null | undefined
>;

export const StartUrlStateSync = (
  history: HistoryWithState,
  store: Store,
  dispatch: Dispatch,
  config: ConfigModelType['FrontendConfig'],
  getCurrentTime: TimeSource,
  apiClient: ApiClient,
) => {
  // Make sure the url language and payment type root are reflected in our redux state
  // on the first page load
  SetLanguageFromPath(history.location.pathname, dispatch);
  syncPathBookingRootToState(history.location, store, dispatch);
  // and on following route changes
  history.listen(location => syncPathBookingRootToState(location, store, dispatch));

  // On the initial page load, sync query string / default filters to filter state
  syncQueryStringToState(history, config, store, dispatch, getCurrentTime, apiClient, true);
  // Reflect our redux state search filters to query strings
  store.subscribe(debounce(() => syncStateToQueryString(history, store, getCurrentTime), 100));

  history.listen(location => {
    if (
      history.action === 'POP' || // If the user goes back/forward, sync query string changes to state
      (location.state && (location.state.initialRedirect || location.state.arrivingFromAuth))
    ) {
      syncQueryStringToState(history, config, store, dispatch, getCurrentTime, apiClient);
    } else if (!location.state || !location.state.fromSync) {
      // If the location was changed by syncing state to query string, do not sync again
      // When the user navigates by clicking/selecting something, sync the filter state to query string
      syncStateToQueryString(history, store, getCurrentTime, true);
    }
  });
};

export const parseLangFromPath = (pathname: string): Language => {
  const pathParts = pathname.split('/');
  const langPart = pathParts.length > 1 && (pathParts[1] as Language);
  return langPart && languages.includes(langPart) ? langPart : 'fi';
};

// Helper to set language from url to state for easier access
// Sets also html document values until each language has it's own index file
const SetLanguageFromPath = (pathname: string, dispatch: Dispatch) => {
  const lang = parseLangFromPath(pathname);
  const { changeAnalyticsLang } = useContext(AnalyticsContext);
  dispatch.changeLang(lang);
  // Change document language for browser translations
  document.documentElement.lang = lang;
  // Change meta description language
  let descriptionEl = document.querySelector('meta[name=description]');
  if (!descriptionEl) {
    descriptionEl = document.createElement('meta');
    descriptionEl.setAttribute('name', 'description');
    document.head.appendChild(descriptionEl);
  }
  const metaDescriptions = {
    fi:
      'Varaa aika kätevästi netissä. Verkkoajanvaraus on auki 24h vuorokaudessa. Voit varata ajan vastaanotolle tai nettilääkärille mihin vuorokaudenaikaan tahansa.',
    sv:
      'Boka ditt möte enkelt online. Onlinebokning är öppen 24 timmar om dygnet. Du kan boka en tid eller en online-läkartid när som helst på dagen.',
    en:
      'Book your appointment easily online. Online booking open 24 hours a day. You can book an appointment or book time for an online doctor at any time of the day.',
  };
  descriptionEl.setAttribute('content', metaDescriptions[lang]);
  changeAnalyticsLang(lang);
};

const syncPathBookingRootToState = (location: Location, store: Store, dispatch: Dispatch) => {
  const lang = parseLangFromPath(location.pathname);
  const { bookingRoot } = store.getState().location;
  const pathParts = location.pathname.split('/');
  const rootPart = pathParts.length > 2 && pathParts[3];
  const parsedRoot =
    (rootPart && allBookingRoots.find(r => routes[r].root[lang].split('/')[3] === rootPart)) || undefined;
  if (parsedRoot !== bookingRoot) {
    dispatch.changeBookingRoot(parsedRoot);
  }
};

const setFiltersFromParsedQuery = (
  params: ParsedQuery,
  store: Store,
  dispatch: Dispatch,
  getCurrentTime: TimeSource,
  config: ConfigModelType['FrontendConfig'],
) => {
  const {
    specialistId,
    serviceId,
    clinicId,
    type,
    callRequest,
    cityId,
    areaId,
    date,
    fromTime,
    toTime,
    specialistGender,
    specialistLanguage,
    delegation,
    changeCode,
  } = params;

  const { filters, delegate, location } = store.getState();
  const currentFilters = prepareFiltersForQueryString(filters, getCurrentTime);

  if (delegation === 'true' && delegate.status === 'NOTHING') {
    dispatch.initDelegation(undefined);
  }

  // Handle arriving to booking with a changeCode (= already booked appointment to change)
  if (changeCode && typeof changeCode === 'string' && location.moveBookingWebCode !== changeCode) {
    dispatch.setMoveBookingWebCode(changeCode);
  }

  // If both specialistId and serviceId are present, specialist takes precedence
  if (specialistId && typeof specialistId === 'string') {
    if (currentFilters.specialistId !== specialistId) {
      // Select the specialist (no details available yet)
      dispatch.filterByService({ specialistId, type: 'specialist' });
    }
  } else if (serviceId && typeof serviceId === 'string') {
    if (currentFilters.serviceId !== serviceId) {
      dispatch.filterByService({ serviceId, type: 'service' });
    }
  } else if (!!filters.specialistOrServiceFilter) {
    dispatch.filterByService(null);
  }

  const queryAppointmentTypes = type ? (typeof type === 'string' ? [type] : type) : [];
  const selectedAppointmentTypes = regularAppointmentTypes.filter(at => queryAppointmentTypes.includes(at));
  if (type) {
    if (selectedAppointmentTypes.length) {
      const isDifferentTypes =
        xor(selectedAppointmentTypes, currentFilters.type || regularAppointmentTypes).length !== 0;
      if (isDifferentTypes) {
        dispatch.filterByAppointmentTypes(selectedAppointmentTypes);
      }
    } else if (!!filters.appointmentTypes) {
      dispatch.filterByAppointmentTypes(null);
    }
  }

  if (callRequest === null && !filters.callRequest) {
    dispatch.filterForCallRequest(true);
  } else if (callRequest === undefined && filters.callRequest) {
    dispatch.filterForCallRequest(false);
  }

  // Clinic takes precedence over the others, city takes precedence over area
  if (clinicId) {
    if (currentFilters.clinicId !== clinicId) {
      const clinicIds = typeof clinicId === 'string' ? [clinicId] : clinicId;
      if (clinicIds.length === 1) {
        if (clinicIds[0] === 'null') {
          // Only allow remote appointments when not booking for other
          if (delegation !== 'true') {
            dispatch.filterByLocation({ type: 'remote' });
          }
        } else {
          dispatch.filterByLocation({ clinicId: clinicIds[0], type: 'clinic' });
        }
      } else {
        dispatch.filterByLocation({
          clinicIds,
          type: 'multiple-clinics',
          subType:
            filters.locationFilter && filters.locationFilter.type === 'multiple-clinics'
              ? filters.locationFilter.subType
              : 'other-clinics',
        });
      }
    }
  } else if (cityId && typeof cityId === 'string') {
    if (currentFilters.cityId !== cityId) {
      dispatch.filterByLocation({ cityId, type: 'city' });
    }
  } else if (areaId && typeof areaId === 'string') {
    if (currentFilters.areaId !== areaId) {
      dispatch.filterByLocation({ areaId, type: 'area' });
    }
  } else if (!!filters.locationFilter) {
    dispatch.filterByLocation(null);
  }

  if (date && typeof date === 'string' && !!new Date(date).getTime()) {
    if (dateISOString(date) !== dateISOString(filters.date) && !isPast(date, getCurrentTime)) {
      dispatch.filterByDate(new Date(date).getTime());
    }
  } else if (dateISOString(filters.date) !== dateISOString(getCurrentTime())) {
    dispatch.filterByDate(getCurrentTime());
  }

  if (fromTime && typeof fromTime === 'string' && fromTimeOfDayOptions().some(o => o.value === fromTime)) {
    if (fromTime !== currentFilters.fromTime) {
      dispatch.filterByFromTimeOfDay(fromTime);
    }
  } else if (!!filters.fromTimeOfDay) {
    dispatch.filterByFromTimeOfDay(null);
  }
  if (toTime && typeof toTime === 'string' && toTimeOfDayOptions().some(o => o.value === toTime)) {
    if (toTime !== currentFilters.toTime) {
      dispatch.filterByToTimeOfDay(toTime);
    }
  } else if (!!filters.toTimeOfDay) {
    dispatch.filterByToTimeOfDay(null);
  }

  if (specialistGender && (specialistGender === 'male' || specialistGender === 'female')) {
    if (specialistGender !== currentFilters.specialistGender) {
      dispatch.filterBySpecialistGender(specialistGender);
    }
  } else if (!!filters.specialistGender) {
    dispatch.filterBySpecialistGender(null);
  }

  if (specialistLanguage && typeof specialistLanguage === 'string') {
    if (specialistLanguage !== currentFilters.specialistLanguage) {
      dispatch.filterBySpecialistLanguage(specialistLanguage);
    }
  } else if (!!filters.specialistLanguage) {
    dispatch.filterBySpecialistLanguage(null);
  }

  // If booking a certain service, set also restrictions
  if (
    serviceId &&
    typeof serviceId === 'string' &&
    !!config.CORONAVACCINE_BOOSTER_SERVICE_IDS &&
    config.CORONAVACCINE_BOOSTER_SERVICE_IDS.includes(serviceId)
  ) {
    const locationRestriction = clinicId
      ? { clinicIds: typeof clinicId === 'string' ? [clinicId] : clinicId }
      : cityId && typeof cityId === 'string'
      ? { cityId }
      : areaId && typeof areaId === 'string'
      ? { areaId }
      : { clinicIds: [] }; // Set some location restriction to prevent booking if entering with invalid params
    dispatch.setRestrictions({ instructions: null, serviceId, ...locationRestriction });
  }
};

const syncQueryStringToState = (
  history: HistoryWithState,
  config: ConfigModelType['FrontendConfig'],
  store: Store,
  dispatch: Dispatch,
  getCurrentTime: TimeSource,
  apiClient: ApiClient,
  firstLoad = false,
) => {
  const { pathname, search, state } = history.location;
  const location = store.getState().location;

  // special handling for mobile app embed, only switch towards embed to ensure it stays set on navigation
  const { embedInApp } = parse(search);
  if (embedInApp === 'true' && !location.embedInApp) {
    dispatch.changeEmbedInApp(true);
    // Set a meta tag to prevent zooming
    let viewportEl = document.querySelector('meta[name=viewport]');
    if (!viewportEl) {
      viewportEl = document.createElement('meta');
      viewportEl.setAttribute('name', 'viewport');
      document.head.appendChild(viewportEl);
    }
    viewportEl.setAttribute(
      'content',
      'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no',
    );
  }

  // Sync the query string to state only on pages related to making bookings
  if (currentPageType(pathname, location.lang) === 'other') return;

  // Make sure customerTypeAddition is up-to-date
  if (location.bookingRoot === 'htaTeam') {
    const customerTypeParam = getCustomerTypeParam();
    if (customerTypeParam !== location.customerTypeAddition) {
      dispatch.changeCustomerTypeAddition(customerTypeParam);
    }
  }

  // Dispatch selected insurance company from url to redux state
  const { icid } = parse(search);
  if (icid && typeof icid === 'string') {
    dispatch.changeSelectedInsuranceCompany(getInsuranceCompanyFromIdNumber(icid));
  }

  if (firstLoad && !(state && 'arrivingFromAuth' in state)) {
    // Extract all known old booking parameters from query string
    // as case insensitive, while keeping other params case sensitive
    const { clinic, city, area, service, specialty, specialist, paymentinformation } = parse(search.toLowerCase());
    const oldParams = [
      'Clinic',
      'City',
      'Area',
      'Service',
      'Specialty',
      'Specialist',
      'Step',
      'PaymentInformation',
      'AppointmentTime',
      'StartTime',
      'EndTime',
      'Appointment',
      'epslanguage',
      'id',
    ];
    const rest = parse(
      search
        .replace(/^\?/, '')
        .split('&')
        .filter(s => !oldParams.some(p => startsWith(s.toLowerCase(), `${p.toLowerCase()}=`)))
        .join('&'),
    );

    // Remove old params from url and change payment type if not matching
    let newPath = pathname;
    switch (paymentinformation) {
      case '4':
        if (location.bookingRoot !== 'privateCustomer' && location.bookingRoot !== 'dental') {
          newPath = routes.privateCustomer.root[location.lang];
        }
        break;
      case '5':
        if (location.bookingRoot !== 'occupationalHealthcare') {
          newPath = routes.occupationalHealthcare.root[location.lang];
        }
        break;
      case '6':
        if (location.bookingRoot !== 'insurance') {
          newPath = routes.insurance.root[location.lang];
        }
        break;
      case '7':
        if (location.bookingRoot !== 'voucher') {
          newPath = routes.voucher.root[location.lang];
        }
        break;
    }
    history.replace({
      pathname: newPath,
      search: stringify(rest),
      state: { ...(state || {}), fromSync: true },
    });

    // Find old parameters feasible for new booking filters
    const hasOldParams = [clinic, city, area, service, specialty, specialist].some(
      k => typeof k === 'string' && !!parseInt(k, 10),
    );

    // Fetch ids for given old params
    if (hasOldParams) {
      apiClient
        .getNewFromOldBookingParams(stringify({ clinic, city, area, service, specialty, specialist }))
        .then(p => setFiltersFromParsedQuery({ ...p, ...rest }, store, dispatch, getCurrentTime, config));
      // If setting from old parameters, return to skip the rest of the steps on the top level
      // as they should be done only after the above fetch
      return;
    }
  }

  const parsed = parse(search);
  setFiltersFromParsedQuery(parsed, store, dispatch, getCurrentTime, config);
};

const syncStateToQueryString = (history: History, store: Store, getCurrentTime: TimeSource, replace = false) => {
  const { pathname, search } = history.location;
  const { filters, location, delegate } = store.getState();

  // Sync the state to query string only on pages related to making bookings
  const pageType = currentPageType(pathname, location.lang);
  if (pageType === 'other') return;

  const prevQueryParams = parse(search);

  // Better ensuring not accidentally ending up without embedInApp set when it should be (e.g. after a refresh)
  let embedInAppParam: 'true' | undefined;
  if (location.embedInApp && prevQueryParams.embedInApp !== 'true') {
    embedInAppParam = 'true';
  }

  let customerTypeParam: string | null | undefined = null;
  if (location.bookingRoot === 'htaTeam' && location.customerTypeAddition !== prevQueryParams.customerType) {
    customerTypeParam = location.customerTypeAddition;
  }

  let delegationParam: string | null | undefined = null;
  if (delegate.status !== 'NOTHING' && prevQueryParams.delegation !== 'true') {
    delegationParam = 'true';
  } else if (delegate.status === 'NOTHING' && prevQueryParams.delegation === 'true') {
    delegationParam = undefined;
  }

  let changeCodeParam: string | undefined;
  if (location.moveBookingWebCode !== prevQueryParams.changeCode) {
    changeCodeParam = location.moveBookingWebCode;
  }

  let selectedInsuranceCompanyParam: string | undefined;
  if (getInsuranceCompanyIdFromInsuranceCompany(location.selectedInsuranceCompany) !== prevQueryParams.icid) {
    selectedInsuranceCompanyParam = getInsuranceCompanyIdFromInsuranceCompany(location.selectedInsuranceCompany);
  }

  // Check if there is a filter in state with a different value than in the query string
  const newFilters = prepareFiltersForQueryString(filters, getCurrentTime);

  const prevTypeFilter = prevQueryParams.type
    ? typeof prevQueryParams.type === 'string'
      ? [prevQueryParams.type]
      : prevQueryParams.type
    : undefined;
  const filtersDiffer =
    objectKeysExactly(newFilters).some(k => k !== 'type' && !isEqual(newFilters[k], prevQueryParams[k])) ||
    !isEqual(newFilters.type, prevTypeFilter);

  if (
    filtersDiffer ||
    selectedInsuranceCompanyParam !== null ||
    delegationParam !== null ||
    !!embedInAppParam ||
    !!changeCodeParam ||
    customerTypeParam !== null
  ) {
    // Find non-filter query params and append them to the new filters
    const otherQueryParams = pick(prevQueryParams, without(Object.keys(prevQueryParams), ...Object.keys(newFilters)));
    const queryString = stringify({
      ...newFilters,
      ...otherQueryParams,
      delegation: delegationParam === null ? prevQueryParams.delegation : delegationParam,
      embedInApp: embedInAppParam || prevQueryParams.embedInApp,
      changeCode: changeCodeParam || prevQueryParams.changeCode,
      customerType: customerTypeParam || prevQueryParams.customerType,
      icid: selectedInsuranceCompanyParam || prevQueryParams.icid,
    });
    // If from history change and not redux filter dispatch, or when not on search page, replace history instead of push
    const notRootPage = pageType !== 'search' && pageType !== 'frontpage';

    // Use replace on change to call request booking from service params
    const changedToCallRequest = newFilters.callRequest === null && prevQueryParams.serviceId;

    if (replace || notRootPage || !filtersDiffer || changedToCallRequest) {
      history.replace({
        search: queryString,
        state: {
          fromSync: true,
        },
      });
    } else {
      history.push({
        search: queryString,
        state: {
          fromSync: true,
        },
      });
    }
  }
};

const currentPageType = (pathname: string, lang: Language) => {
  // Remove trailing slash from routes for more robust matching
  const removeTrailingSlash = (s: string) => s.replace(/\/$/, '');
  const currentPath = removeTrailingSlash(pathname);
  const rootPath = removeTrailingSlash(routes.root[lang]);
  const bookingRootPaths = allBookingRoots.map(r => removeTrailingSlash(routes[r].root[lang]));
  const loginPath = removeTrailingSlash(routes.logIn[lang]);
  if (currentPath === rootPath || currentPath === '') {
    return 'frontpage';
  }
  if (currentPath === loginPath) {
    return 'login';
  }
  if (bookingRootPaths.includes(currentPath)) {
    return 'search';
  }
  if (bookingRootPaths.some(p => currentPath.includes(p))) {
    return 'booking';
  }
  return 'other';
};

const prepareFiltersForQueryString = (filters: typeof filtersInitState, getCurrentTime: TimeSource) => {
  const {
    specialistOrServiceFilter: specialistOrService,
    locationFilter,
    date,
    appointmentTypes,
    callRequest,
    fromTimeOfDay,
    toTimeOfDay,
    specialistGender,
    specialistLanguage,
  } = filters;
  const dateStr = dateISOString(date);
  let locationParams = {};
  if (locationFilter) {
    switch (locationFilter.type) {
      case 'area':
        locationParams = { areaId: locationFilter.areaId };
        break;
      case 'city':
        locationParams = { cityId: locationFilter.cityId };
        break;
      case 'clinic':
        locationParams = { clinicId: locationFilter.clinicId };
        break;
      case 'multiple-clinics':
        locationParams = { clinicId: locationFilter.clinicIds };
        break;
      case 'remote':
        locationParams = { clinicId: 'null' };
        break;
      default:
        assertExhausted(locationFilter);
        break;
    }
  }
  return {
    specialistId:
      specialistOrService && specialistOrService.type === 'specialist' ? specialistOrService.specialistId : undefined,
    serviceId:
      specialistOrService && specialistOrService.type === 'service' ? specialistOrService.serviceId : undefined,
    areaId: undefined,
    cityId: undefined,
    clinicId: undefined,
    ...locationParams,
    type:
      !appointmentTypes || xor(appointmentTypes, regularAppointmentTypes).length === 0 ? undefined : appointmentTypes,
    callRequest: callRequest ? null : undefined,
    date: dateStr !== dateISOString(getCurrentTime()) ? dateStr : undefined,
    fromTime: fromTimeOfDay || undefined,
    toTime: toTimeOfDay || undefined,
    specialistGender: specialistGender || undefined,
    specialistLanguage: specialistLanguage || undefined,
  };
};

// Returns the second last part from the pathname, used to parse id from path for direct compensation preview
export const parseClaimIdFromPath = (pathname: string): string | null => {
  const pathParts = pathname.split('/');
  return pathParts[pathParts.length - 2];
};
