import { LocationDescriptorObject } from 'history';
import { ConfigModelType } from 'model/config';
import { baseRoute, routes } from 'utils/routes/routes';

type RedirectRoute = {
  from: string;
  to: LocationDescriptorObject;
};

// Plain language routes mapped to other existing routes e.g. private customer page with certain search parameters
export const extraRedirectRoutes: (config: ConfigModelType['FrontendConfig']) => RedirectRoute[] = config => [
  {
    from: `${baseRoute.fi}/videovastaanotot`,
    to: { pathname: `${routes.root.fi}`, search: 'type=video' },
  },
  {
    from: `${baseRoute.sv}/video-mottagninger`,
    to: { pathname: `${routes.root.sv}`, search: 'type=video' },
  },
  {
    from: `${baseRoute.en}/video-appointments`,
    to: { pathname: `${routes.root.en}`, search: 'type=video' },
  },
  {
    from: `${baseRoute.fi}/punkkirokote`,
    to: { pathname: `${routes.root.fi}`, search: 'serviceId=1e66e8b6-939c-4c1f-92c0-386f72120540' },
  },
  {
    from: `${baseRoute.sv}/fästingvaccination`,
    to: { pathname: `${routes.root.sv}`, search: 'serviceId=1e66e8b6-939c-4c1f-92c0-386f72120540' },
  },
  {
    from: `${baseRoute.en}/tbe-vaccination`,
    to: { pathname: `${routes.root.en}`, search: 'serviceId=1e66e8b6-939c-4c1f-92c0-386f72120540' },
  },
  {
    from: `${baseRoute.fi}/koronarokotus`,
    to: { pathname: `${routes.root.fi}`, search: `serviceId=${config.CORONAVACCINE_SERVICE_ID}` },
  },
  {
    from: `${baseRoute.sv}/koronavaccination`,
    to: { pathname: `${routes.root.sv}`, search: `serviceId=${config.CORONAVACCINE_SERVICE_ID}` },
  },
  {
    from: `${baseRoute.en}/corona-vaccination`,
    to: { pathname: `${routes.root.en}`, search: `serviceId=${config.CORONAVACCINE_SERVICE_ID}` },
  },
  {
    from: `${baseRoute.fi}/aske/koronarokotus`,
    to: {
      pathname: `${routes.root.fi}`,
      search: `serviceId=${config.CORONAVACCINE_SERVICE_ID}&vaccineGuideOpen=CM&tracking=false`,
    },
  },
  // Old booking site MyAppointments to make the links to there work after the domain name change
  {
    from: '/MyAppointments/',
    to: { pathname: routes.myAppointments.fi },
  },
  {
    from: '/fi/MyAppointments/',
    to: { pathname: routes.myAppointments.fi },
  },
  {
    from: '/sv/MyAppointments/',
    to: { pathname: routes.myAppointments.sv },
  },
  {
    from: '/en/MyAppointments/',
    to: { pathname: routes.myAppointments.en },
  },
];
