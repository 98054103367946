/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Notice } from 'ui/misc/Notice';
import { useTranslations } from 'utils/react/ui-context';

export const SymptomNotice: FC = () => {
  const t = useTranslations();

  return <Notice variant="warning" icon="Info" text={t.corona_vaccination_notice_text} />;
};
