/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { focusVisible } from '@terveystalo/design-system-react-components';
import { theme } from '@terveystalo/design-system-theme';

export const onNotSmall = `@media screen and (min-width: ${theme.media.breakpoint.s})`;
export const onLarge = `@media screen and (min-width: ${theme.media.breakpoint.m})`;
export const shadowSmall = `${theme.shadow.offset.card.x} ${theme.shadow.offset.card.y} ${theme.shadow.blur.card} ${theme.shadow.color.card}`;

// Placeholder for a possible future helper class for tailwind className checking
export const classNames = (...args: Array<string | boolean | undefined | null>) => {
  const strs = args.filter(arg => typeof arg === 'string' && !!arg) as string[]; // ignore falsy values
  return strs.join(' ');
};

export const maxWidthContainer = {
  className: 'w-full max-w-7xl px-1 mx-auto',
};

export const cardClassNames = classNames('bg-white', 'rounded-m', 'shadow-card');
export const cardOnNotSmallClassNames = classNames('s:bg-white', 's:rounded-m', 's:shadow-card');

export const cardInteractionStyles = {
  css: css`
    -webkit-tap-highlight-color: transparent;
    ${theme.media.device.supportsHover} {
      &:hover {
        box-shadow: ${shadowSmall}, inset 0 0 0 ${theme.border.width.xxs} ${theme.color.skyIsBlue};
      }
    }
    ${focusVisible(
      `
        outline: 0;
        box-shadow: ${shadowSmall}, inset 0 0 0 ${theme.border.width.xs} ${theme.color.zodiacBlack};
      `,
      `
        box-shadow: ${shadowSmall}, inset 0 0 0 ${theme.border.width.xs} ${theme.color.skyIsBlue};
      `,
    )}
    &:active {
      box-shadow: ${shadowSmall}, inset 0 0 0 ${theme.border.width.xs} ${theme.color.skyIsBlue};
    }
  `,
};

export const cardBlockStyles = {
  className: classNames('flex-grow', 's:w-1/3', 'p-0.5', 'border-b-xxs', 'border-zodiac-black-04', 's:border-none'),
  css: css`
    &:last-of-type {
      border-bottom: 0;
    }
  `,
};
export const cardBlockStylesCentered = {
  className: classNames('mx-auto', 'text-center', cardBlockStyles.className),
  css: cardBlockStyles.css,
};
export const cardBlockTitleStyles = {
  className: classNames('text-zodiac-black', 'pb-1', 'pt-0.5', 'text-style-subtitle-s'),
};

export const cardBlockWrapperClassNames = classNames(
  'p-0.5',
  'flex',
  'flex-col',
  'items-center',
  'overflow-hidden',
  'w-full',
);

// Helpers for overriding DS component styles, especially Button and TextField
export const smallerButtonTextCss = css`
  font-size: ${theme.typography.fontSize.subtitle.s};
  line-height: ${theme.typography.lineHeight.subtitle.s};
`;
export const noBottomMargin = css`
  margin-bottom: 0;
`;
