import { AppointmentType } from 'model/api';
import { Action } from 'utils/redux/actions';

type SpecialistWithType = {
  type: 'specialist';
  specialistId: string;
};
type ServiceWithType = { type: 'service'; serviceId: string };
export type SpecialistOrServiceState = SpecialistWithType | ServiceWithType | null;

type AreaWithType = { type: 'area'; areaId: string; clinicIds?: string[] };
type CityWithType = { type: 'city'; cityId: string; clinicIds?: string[] };
type ClinicWithType = {
  type: 'clinic';
  clinicId: string;
};
// For a miscellaneous set of multiple clinics, e.g. from using user's location
export type MultipleClinics = {
  type: 'multiple-clinics';
  subType: 'specialists-clinics' | 'nearest-clinics' | 'other-clinics';
  clinicIds: string[];
};
type Remote = { type: 'remote' };
export type LocationState = AreaWithType | CityWithType | ClinicWithType | MultipleClinics | Remote | null;

export type AppointmentTypesState = AppointmentType[] | null;
export type TimeOfDayState = string | null;
export type SpecialistGender = 'male' | 'female';
export type SpecialistGenderState = SpecialistGender | null;
export type SpecialistLanguageState = string | null;

export type RestrictionsState = {
  specialistId?: string;
  serviceId?: string;
  clinicIds?: string[];
  cityId?: string;
  areaId?: string;
  appointmentTypes?: AppointmentType[];
  instructions: string | null;
} | null;

export const initState: {
  specialistOrServiceFilter: SpecialistOrServiceState;
  withBackToPreviousService: boolean;
  locationFilter: LocationState;
  date: number;
  appointmentTypes: AppointmentTypesState;
  callRequest: boolean;
  fromTimeOfDay: TimeOfDayState; // times starting at/after this time of day, e.g. "11:30"
  toTimeOfDay: TimeOfDayState; // times starting before/at this time of day, e.g. "23:59:59"
  specialistGender: SpecialistGenderState;
  specialistLanguage: SpecialistLanguageState; // two-letter language code
  restrictions: RestrictionsState;
  showMoreCount: number;
  serviceIndex: number;
  alternativeServicesSearch: boolean;
  personalizationConsent: boolean | undefined;
} = {
  specialistOrServiceFilter: null,
  withBackToPreviousService: false,
  locationFilter: null,
  date: Date.now(),
  appointmentTypes: null,
  callRequest: false,
  fromTimeOfDay: null,
  toTimeOfDay: null,
  specialistGender: null,
  specialistLanguage: null,
  restrictions: null,
  showMoreCount: 0,
  serviceIndex: 0,
  alternativeServicesSearch: false,
  personalizationConsent: undefined,
};

export default function reducer(state = initState, action: Action) {
  const { restrictions } = state;
  // Whenever a filtering action is dispatched, reset show more and service index states
  const baseState = { ...state, showMoreCount: 0, serviceIndex: 0, alternativeServicesSearch: false };
  switch (action.type) {
    case 'setRestrictions':
      return { ...baseState, restrictions: action.restrictions };
    case 'setRestrictionsAction':
      return {
        ...baseState,
        restrictions: action.action(
          restrictions || {
            instructions: null,
          },
        ),
      };
    case 'setPersonalizationConsent':
      return { ...baseState, personalizationConsent: action.personalizationConsent };
    case 'filterByService':
      if (
        !action.withBack &&
        action.specialistOrService &&
        restrictions &&
        (restrictions.serviceId || restrictions.specialistId)
      ) {
        const allowed =
          action.specialistOrService.type === 'specialist'
            ? action.specialistOrService.specialistId === restrictions.specialistId
            : action.specialistOrService.serviceId === restrictions.serviceId;
        if (!allowed) {
          return state;
        }
      }
      return {
        ...baseState,
        specialistOrServiceFilter: action.specialistOrService,
        withBackToPreviousService: !!action.withBack,
      };
    case 'filterByLocation':
      if (action.location && restrictions && (restrictions.clinicIds || restrictions.cityId || restrictions.areaId)) {
        let allowed = false;
        switch (action.location.type) {
          case 'area':
            allowed = action.location.areaId === restrictions.areaId;
            break;
          case 'city':
            allowed = action.location.cityId === restrictions.cityId;
            break;
          case 'clinic':
            allowed = (restrictions.clinicIds || []).includes(action.location.clinicId);
            break;
          case 'multiple-clinics':
            const allowedClinics = action.location.clinicIds.filter(c => (restrictions.clinicIds || []).includes(c));
            if (allowedClinics.length) {
              return { ...baseState, locationFilter: { ...action.location, clinicIds: allowedClinics } };
            }
        }
        if (!allowed) {
          return state;
        }
      }
      return { ...baseState, locationFilter: action.location };
    case 'filterByDate':
      return { ...baseState, date: action.date };
    case 'filterByFromTimeOfDay':
      return { ...baseState, fromTimeOfDay: action.fromTimeOfDay };
    case 'filterByToTimeOfDay':
      return { ...baseState, toTimeOfDay: action.toTimeOfDay };
    case 'filterByAppointmentTypes':
      if (action.appointmentTypes && restrictions && restrictions.appointmentTypes) {
        const allowedSubset = action.appointmentTypes.filter(t => (restrictions.appointmentTypes || []).includes(t));
        if (!allowedSubset.length) {
          return state;
        }
        return { ...baseState, appointmentTypes: allowedSubset };
      }
      return { ...baseState, appointmentTypes: action.appointmentTypes };
    case 'filterForCallRequest':
      return { ...baseState, callRequest: action.callRequest };
    case 'filterBySpecialistGender':
      return { ...baseState, specialistGender: action.specialistGender };
    case 'filterBySpecialistLanguage':
      return { ...baseState, specialistLanguage: action.specialistLanguage };
    case 'showMoreResults':
      return { ...state, showMoreCount: state.showMoreCount + 1 };
    case 'nextServiceIndex':
      return { ...baseState, serviceIndex: state.serviceIndex + 1 };
    case 'doAlternativeServicesSearch':
      return { ...baseState, alternativeServicesSearch: true };
    case 'filtersReset':
      return { ...state, ...initState, date: action.currentDate };
    default:
      return state;
  }
}
