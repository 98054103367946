import { Language } from 'translations/generated/translation-languages';
import { routes } from 'utils/routes/routes';
import { objectKeysExactly } from 'utils/types/misc';

// All route params regex for matching them from the url with capture groups
const routeStrRegex = '([a-zA-Z0-9-_.]+)';
// The length of params list is the maximum amount of parameters for any route
const routeRegexParams = [routeStrRegex, routeStrRegex, routeStrRegex] as const;
function isTupleMax3(x: any): x is [string, string, string] {
  return Array.isArray(x) && x.length <= 3;
}

// Finds route set for all languages matching the given pathname and language
export const routeMatcher = (pathname: string, lang: Language) => {
  // If a match is not found, use the root route for the language change links
  let currentLangRoute = routes.root;

  // Use find to stop the loop when a match is found
  objectKeysExactly(routes).find(key => {
    // The root only redirects, so ignore it
    if (key === 'root') {
      return false;
    }
    const route = routes[key];
    if (typeof route === 'function') {
      const routeStr = route(routeStrRegex)[lang];
      // Convert the whole url to a regex matcher
      const matcher = routeStr.replace(/\//g, '\\/');
      const matched = pathname.match(matcher);
      if (matched) {
        // Get the params from the capture groups to give back to the route function
        const matchedParams = matched.slice(1);
        if (matchedParams.length >= 1) {
          currentLangRoute = route(matchedParams[0]);
          return true;
        }
      }
    } else if ('fi' in route) {
      if (pathname === route[lang]) {
        currentLangRoute = route;
        return true;
      }
    } else {
      return !!objectKeysExactly(route).find(subkey => {
        const subroute = route[subkey];
        if (typeof subroute === 'function') {
          const routeStr = subroute(...routeRegexParams)[lang];
          // Convert the whole url to a regex matcher
          const matcher = routeStr.replace(/\//g, '\\/');
          const matched = pathname.match(matcher);
          if (matched) {
            // Get the params from the capture groups to give back to the route function
            const matchedParams = matched.slice(1);
            if (isTupleMax3(matchedParams)) {
              currentLangRoute = subroute(...matchedParams);
              return true;
            }
          }
        } else {
          if (pathname === subroute[lang]) {
            currentLangRoute = subroute;
            return true;
          }
        }
        return false;
      });
    }
    return false;
  });

  return currentLangRoute;
};
