import { Language } from 'translations/generated/translation-languages';
import { FromDate, isToday, isTomorrow, TimeSource } from 'utils/datetime/helpers';

// Helpers for formatting dates and times. Uses always Helsinki timezone and finnish formats
const formatDateTime = (from: FromDate, options: Intl.DateTimeFormatOptions, lang: Language = 'fi') => {
  const date = new Date(from);
  let localeStr;
  if (lang == 'en') localeStr = 'en-US';
  else localeStr = 'fi-FI';
  if (!date.getTime()) return '';
  try {
    return new Intl.DateTimeFormat(localeStr, { timeZone: 'Europe/Helsinki', ...options }).format(date);
  } catch (e) {
    return new Intl.DateTimeFormat(localeStr, options).format(date);
  }
};

// Date in Finnish format, e.g. 1.12. or 1.12.2019
export const dateString = (from: FromDate, withYear = false) =>
  formatDateTime(from, { day: 'numeric', month: 'numeric', year: withYear ? 'numeric' : undefined });

// Time in Finnish format, e.g. 09.00
export const timeString = (from: FromDate, lang?: Language) =>
  formatDateTime(from, { hour: '2-digit', minute: '2-digit', hour12: lang === 'en' }, lang);

export const timeRange = (from: FromDate, lengthMinutes: number, withMinutes = false) => {
  const startTime = new Date(from);
  const endTime = new Date(startTime);
  endTime.setMinutes(endTime.getMinutes() + lengthMinutes);
  const showMinutes = withMinutes || startTime.getMinutes() !== 0;
  return `${formatDateTime(startTime, {
    hour: 'numeric',
    minute: showMinutes ? '2-digit' : undefined,
  })}–${formatDateTime(endTime, { hour: 'numeric', minute: showMinutes ? '2-digit' : undefined })}`;
};

// Weekday string, e.g. maanantai
const weekdayString = (from: FromDate, lang: Language) => formatDateTime(from, { weekday: 'long' }, lang);

export const fullDateString = (
  from: FromDate,
  lang: Language,
  t: { today: string },
  getCurrentTime: TimeSource,
  withYear = false,
) =>
  `${weekdayString(from, lang)} ${dateString(from, withYear)}${isToday(from, getCurrentTime) ? ` (${t.today})` : ''}`;

export const fullDateTimeString = (
  from: FromDate,
  lang: Language,
  t: { today: string; at_as_in_time: string },
  getCurrentTime: TimeSource,
) => `${fullDateString(from, lang, t, getCurrentTime, true)} ${t.at_as_in_time} ${timeString(from, lang)}`;

export const onDateString = (
  from: FromDate,
  lang: Language,
  t: { today: string; tomorrow: string },
  getCurrentTime: TimeSource,
  withYear = false,
) =>
  `${
    isToday(from, getCurrentTime) ? t.today : isTomorrow(from, getCurrentTime) ? t.tomorrow : weekdayString(from, lang)
  } ${dateString(from, withYear)}`;

export const shortOnDateString = (from: FromDate, t: { today: string; tomorrow: string }, getCurrentTime: TimeSource) =>
  `${
    isToday(from, getCurrentTime) ? t.today : isTomorrow(from, getCurrentTime) ? t.tomorrow : dateString(from)
  } ${timeString(from)}`;

// For displaying a ISO-like date in Helsinki timezone, e.g. 2019-12-25
export const dateISOString = (from: FromDate) => {
  const dStr = formatDateTime(from, { day: '2-digit', month: '2-digit', year: 'numeric' });
  return `${dStr.slice(6)}-${dStr.slice(3, 5)}-${dStr.slice(0, 2)}`;
};

// Calendar date picker helpers
export const monthYearString = (from: FromDate, lang: Language) =>
  formatDateTime(from, { month: 'long', year: 'numeric' }, lang);
export const shortWeekdays = (lang: Language) => {
  // Generate 7 days starting from a monday
  return [...new Array(7)].map((_, i) => {
    const date = new Date('2020-01-06T12:00:00Z');
    date.setDate(date.getDate() + i);
    return formatDateTime(date, { weekday: 'short' }, lang);
  });
};

// Helper for creating dates for stories
export function mockDateTimeStr(hour: number, addMs?: number, from?: number) {
  const date = from ? new Date(from) : new Date();
  const offset = date.getTimezoneOffset() / 60;
  const isoDate = new Date(date.setHours(hour - offset, 0, 0, 0) + (addMs || 0)).toISOString();
  return isoDate.slice(0, 19) + '+03:00';
}
