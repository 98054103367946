import axios from 'axios';
import { ConfigModelType } from 'model/config';
import { Language } from 'translations/generated/translation-languages';
import { BookingRoot } from 'utils/routes/routes';
import { assertExhausted } from 'utils/types/misc';

// Creates low-level bindings to the Insurance company permission API.
export function createPartnerPermissionApiClient(config: ConfigModelType['FrontendConfig'], axiosImpl = axios) {
  const baseUrl = config.API_ENDPOINT_INSURANCECOMPANYAPI;
  const options = { headers: { 'Ocp-Apim-Subscription-Key': config.ADA_API_KEY } };
  return {
    getPermission(_lang: Language, bookingRoot: BookingRoot) {
      if (baseUrl === '') return Promise.resolve(null); // For tests and for disabling the endpoint
      return axiosImpl.get(`${baseUrl}/get?name=${permissionName(bookingRoot)}`, options).then(() => null);
    },
  };
}

function permissionName(bookingRoot: BookingRoot) {
  switch (bookingRoot) {
    case 'privateCustomer':
    case 'privateCustomerV2':
    case 'occupationalHealthcare':
    case 'dental':
    case 'insurance':
    case 'voucher':
    case 'htaTeam':
      return '';
    case 'migri':
      return 'vypmig';
    case 'terveysmestari':
      return 'vypvtm';
    case 'fenniahoitaja':
      return 'vyffh';
    case 'terveyshelppi':
      return 'vyltth';
    case 'publicPartner':
      return 'julkk';
    default:
      assertExhausted(bookingRoot);
      return '';
  }
}
