/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 * ServiceAPI
 * Accept-Language header supported values: fi-FI (defaultt),sv-FI,en-FI
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface CompetenceItemSubsystemID
 */
export interface CompetenceItemSubsystemID {
  /**
   *
   * @type {string}
   * @memberof CompetenceItemSubsystemID
   */
  Id?: string | null;
  /**
   *
   * @type {TerveystaloBaseAPIAbstractionsSubsystem}
   * @memberof CompetenceItemSubsystemID
   */
  Subsystem?: TerveystaloBaseAPIAbstractionsSubsystem;
}
/**
 *
 * @export
 * @interface CompetenceSubsystemID
 */
export interface CompetenceSubsystemID {
  /**
   *
   * @type {string}
   * @memberof CompetenceSubsystemID
   */
  Id?: string | null;
  /**
   *
   * @type {TerveystaloBaseAPIAbstractionsSubsystem}
   * @memberof CompetenceSubsystemID
   */
  Subsystem?: TerveystaloBaseAPIAbstractionsSubsystem;
}
/**
 *
 * @export
 * @interface GetCompetenceResponse
 */
export interface GetCompetenceResponse {
  /**
   *
   * @type {TerveystaloServiceAPIAbstractionsCompetenceType}
   * @memberof GetCompetenceResponse
   */
  CompetenceType?: TerveystaloServiceAPIAbstractionsCompetenceType;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetCompetenceResponse
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetCompetenceResponse
   */
  Description?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetCompetenceResponse
   */
  Keywords?: Array<Localized> | null;
  /**
   *
   * @type {Array<CompetenceSubsystemID>}
   * @memberof GetCompetenceResponse
   */
  SubsystemIDs?: Array<CompetenceSubsystemID> | null;
}
/**
 *
 * @export
 * @interface GetCompetencesResponseItem
 */
export interface GetCompetencesResponseItem {
  /**
   *
   * @type {string}
   * @memberof GetCompetencesResponseItem
   */
  Id?: string;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetCompetencesResponseItem
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {TerveystaloServiceAPIAbstractionsCompetenceType}
   * @memberof GetCompetencesResponseItem
   */
  CompetenceType?: TerveystaloServiceAPIAbstractionsCompetenceType;
  /**
   *
   * @type {Array<CompetenceItemSubsystemID>}
   * @memberof GetCompetencesResponseItem
   */
  SubsystemIDs?: Array<CompetenceItemSubsystemID> | null;
}
/**
 *
 * @export
 * @interface GetServiceResponse
 */
export interface GetServiceResponse {
  /**
   *
   * @type {number}
   * @memberof GetServiceResponse
   */
  Popularity?: number;
  /**
   *
   * @type {number}
   * @memberof GetServiceResponse
   */
  ChatId?: number | null;
  /**
   *
   * @type {ServiceType}
   * @memberof GetServiceResponse
   */
  ServiceContentType?: ServiceType;
  /**
   *
   * @type {string}
   * @memberof GetServiceResponse
   */
  AlternativeServiceId?: string | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetServiceResponse
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetServiceResponse
   */
  Description?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetServiceResponse
   */
  BookingInstruction?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetServiceResponse
   */
  Keywords?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetServiceResponse
   */
  PreparingInformation?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetServiceResponse
   */
  PrerequisiteURL?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetServiceResponse
   */
  PriceDisclaimer?: Array<Localized> | null;
  /**
   *
   * @type {Array<OrderedService>}
   * @memberof GetServiceResponse
   */
  ChildServices?: Array<OrderedService> | null;
  /**
   *
   * @type {Array<OrderedService>}
   * @memberof GetServiceResponse
   */
  MasterServices?: Array<OrderedService> | null;
  /**
   *
   * @type {boolean}
   * @memberof GetServiceResponse
   */
  BookingEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetServiceResponse
   */
  LoginRequired?: boolean;
  /**
   *
   * @type {Array<ServiceSubsystemID>}
   * @memberof GetServiceResponse
   */
  SubsystemIDs?: Array<ServiceSubsystemID> | null;
  /**
   *
   * @type {string}
   * @memberof GetServiceResponse
   */
  PictureUriRectangle?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetServiceResponse
   */
  PictureUriSquare?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetServiceResponse
   */
  VideoUri?: string | null;
}
/**
 *
 * @export
 * @interface GetServicesResponseItem
 */
export interface GetServicesResponseItem {
  /**
   *
   * @type {string}
   * @memberof GetServicesResponseItem
   */
  Id?: string;
  /**
   *
   * @type {number}
   * @memberof GetServicesResponseItem
   */
  ChatId?: number | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof GetServicesResponseItem
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {number}
   * @memberof GetServicesResponseItem
   */
  Popularity?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetServicesResponseItem
   */
  BookingEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetServicesResponseItem
   */
  LoginRequired?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetServicesResponseItem
   */
  PictureUriRectangle?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetServicesResponseItem
   */
  PictureUriSquare?: string | null;
  /**
   *
   * @type {Array<ServiceItemSubsystemID>}
   * @memberof GetServicesResponseItem
   */
  SubsystemIDs?: Array<ServiceItemSubsystemID> | null;
}
/**
 *
 * @export
 * @interface Localized
 */
export interface Localized {
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  TwoLetterISOLanguage?: string | null;
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  Value?: string | null;
}
/**
 *
 * @export
 * @interface OrderedService
 */
export interface OrderedService {
  /**
   *
   * @type {string}
   * @memberof OrderedService
   */
  ServiceID?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrderedService
   */
  Favorite?: boolean;
  /**
   *
   * @type {number}
   * @memberof OrderedService
   */
  Priority?: number;
}
/**
 *
 * @export
 * @interface PingResult
 */
export interface PingResult {
  /**
   *
   * @type {string}
   * @memberof PingResult
   */
  Message?: string | null;
}
/**
 *
 * @export
 * @interface ServiceItemSubsystemID
 */
export interface ServiceItemSubsystemID {
  /**
   *
   * @type {string}
   * @memberof ServiceItemSubsystemID
   */
  Id?: string | null;
  /**
   *
   * @type {TerveystaloBaseAPIAbstractionsSubsystem}
   * @memberof ServiceItemSubsystemID
   */
  Subsystem?: TerveystaloBaseAPIAbstractionsSubsystem;
}
/**
 *
 * @export
 * @interface ServiceSubsystemID
 */
export interface ServiceSubsystemID {
  /**
   *
   * @type {string}
   * @memberof ServiceSubsystemID
   */
  ID?: string | null;
  /**
   *
   * @type {TerveystaloBaseAPIAbstractionsSubsystem}
   * @memberof ServiceSubsystemID
   */
  Subsystem?: TerveystaloBaseAPIAbstractionsSubsystem;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ServiceType = {
  Treatment: 'Treatment',
  Compilation: 'Compilation',
} as const;

export type ServiceType = typeof ServiceType[keyof typeof ServiceType];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloBaseAPIAbstractionsSubsystem = {
  Osku: 'Osku',
  Assisdent: 'Assisdent',
  DynamicHealth: 'DynamicHealth',
} as const;

export type TerveystaloBaseAPIAbstractionsSubsystem = typeof TerveystaloBaseAPIAbstractionsSubsystem[keyof typeof TerveystaloBaseAPIAbstractionsSubsystem];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloServiceAPIAbstractionsCompetenceType = {
  Specialty: 'Specialty',
  Specializing: 'Specializing',
  DoctorQualification: 'DoctorQualification',
  Qualification: 'Qualification',
} as const;

export type TerveystaloServiceAPIAbstractionsCompetenceType = typeof TerveystaloServiceAPIAbstractionsCompetenceType[keyof typeof TerveystaloServiceAPIAbstractionsCompetenceType];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloServiceAPIAbstractionsMedicalField = {
  Medicine: 'Medicine',
  Dental: 'Dental',
} as const;

export type TerveystaloServiceAPIAbstractionsMedicalField = typeof TerveystaloServiceAPIAbstractionsMedicalField[keyof typeof TerveystaloServiceAPIAbstractionsMedicalField];

/**
 * Competence2Api - axios parameter creator
 * @export
 */
export const Competence2ApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get list of competences
     * @param {string} [searchText]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CompetencesGet: async (searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/competences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (searchText !== undefined) {
        localVarQueryParameter['searchText'] = searchText;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get competence by global competence id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CompetencesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('v2CompetencesIdGet', 'id', id);
      const localVarPath = `/v2/competences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get global competence id by subsystem key
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CompetencesSubsystemSubsystemIDGet: async (
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'subsystem' is not null or undefined
      assertParamExists('v2CompetencesSubsystemSubsystemIDGet', 'subsystem', subsystem);
      // verify required parameter 'subsystemID' is not null or undefined
      assertParamExists('v2CompetencesSubsystemSubsystemIDGet', 'subsystemID', subsystemID);
      const localVarPath = `/v2/competences/{subsystem}/{subsystemID}`
        .replace(`{${'subsystem'}}`, encodeURIComponent(String(subsystem)))
        .replace(`{${'subsystemID'}}`, encodeURIComponent(String(subsystemID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * Competence2Api - functional programming interface
 * @export
 */
export const Competence2ApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = Competence2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get list of competences
     * @param {string} [searchText]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CompetencesGet(
      searchText?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCompetencesResponseItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CompetencesGet(searchText, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get competence by global competence id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CompetencesIdGet(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompetenceResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CompetencesIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get global competence id by subsystem key
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CompetencesSubsystemSubsystemIDGet(
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CompetencesSubsystemSubsystemIDGet(
        subsystem,
        subsystemID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * Competence2Api - factory interface
 * @export
 */
export const Competence2ApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = Competence2ApiFp(configuration);
  return {
    /**
     *
     * @summary Get list of competences
     * @param {string} [searchText]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CompetencesGet(searchText?: string, options?: any): AxiosPromise<Array<GetCompetencesResponseItem>> {
      return localVarFp.v2CompetencesGet(searchText, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get competence by global competence id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CompetencesIdGet(id: string, options?: any): AxiosPromise<GetCompetenceResponse> {
      return localVarFp.v2CompetencesIdGet(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get global competence id by subsystem key
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CompetencesSubsystemSubsystemIDGet(
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .v2CompetencesSubsystemSubsystemIDGet(subsystem, subsystemID, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Competence2Api - object-oriented interface
 * @export
 * @class Competence2Api
 * @extends {BaseAPI}
 */
export class Competence2Api extends BaseAPI {
  /**
   *
   * @summary Get list of competences
   * @param {string} [searchText]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Competence2Api
   */
  public v2CompetencesGet(searchText?: string, options?: AxiosRequestConfig) {
    return Competence2ApiFp(this.configuration)
      .v2CompetencesGet(searchText, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get competence by global competence id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Competence2Api
   */
  public v2CompetencesIdGet(id: string, options?: AxiosRequestConfig) {
    return Competence2ApiFp(this.configuration)
      .v2CompetencesIdGet(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get global competence id by subsystem key
   * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
   * @param {string} subsystemID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Competence2Api
   */
  public v2CompetencesSubsystemSubsystemIDGet(
    subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
    subsystemID: string,
    options?: AxiosRequestConfig,
  ) {
    return Competence2ApiFp(this.configuration)
      .v2CompetencesSubsystemSubsystemIDGet(subsystem, subsystemID, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HealthAndMonitoringApi - axios parameter creator
 * @export
 */
export const HealthAndMonitoringApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthAndMonitoringApi - functional programming interface
 * @export
 */
export const HealthAndMonitoringApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthAndMonitoringApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pingGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HealthAndMonitoringApi - factory interface
 * @export
 */
export const HealthAndMonitoringApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthAndMonitoringApiFp(configuration);
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet(options?: any): AxiosPromise<PingResult> {
      return localVarFp.pingGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HealthAndMonitoringApi - object-oriented interface
 * @export
 * @class HealthAndMonitoringApi
 * @extends {BaseAPI}
 */
export class HealthAndMonitoringApi extends BaseAPI {
  /**
   *
   * @summary Method to test service
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public pingGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .pingGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * Service2Api - axios parameter creator
 * @export
 */
export const Service2ApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get list of services
     * @param {string} [searchText]
     * @param {Array<TerveystaloServiceAPIAbstractionsMedicalField>} [medicalFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ServicesGet: async (
      searchText?: string,
      medicalFields?: Array<TerveystaloServiceAPIAbstractionsMedicalField>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/services`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (searchText !== undefined) {
        localVarQueryParameter['searchText'] = searchText;
      }

      if (medicalFields) {
        localVarQueryParameter['medicalFields'] = medicalFields;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get service by global service id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ServicesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('v2ServicesIdGet', 'id', id);
      const localVarPath = `/v2/services/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get global service id by subsystem key
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ServicesSubsystemSubsystemIDGet: async (
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'subsystem' is not null or undefined
      assertParamExists('v2ServicesSubsystemSubsystemIDGet', 'subsystem', subsystem);
      // verify required parameter 'subsystemID' is not null or undefined
      assertParamExists('v2ServicesSubsystemSubsystemIDGet', 'subsystemID', subsystemID);
      const localVarPath = `/v2/services/{subsystem}/{subsystemID}`
        .replace(`{${'subsystem'}}`, encodeURIComponent(String(subsystem)))
        .replace(`{${'subsystemID'}}`, encodeURIComponent(String(subsystemID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * Service2Api - functional programming interface
 * @export
 */
export const Service2ApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = Service2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get list of services
     * @param {string} [searchText]
     * @param {Array<TerveystaloServiceAPIAbstractionsMedicalField>} [medicalFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2ServicesGet(
      searchText?: string,
      medicalFields?: Array<TerveystaloServiceAPIAbstractionsMedicalField>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetServicesResponseItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2ServicesGet(searchText, medicalFields, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get service by global service id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2ServicesIdGet(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetServiceResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2ServicesIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get global service id by subsystem key
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2ServicesSubsystemSubsystemIDGet(
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2ServicesSubsystemSubsystemIDGet(
        subsystem,
        subsystemID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * Service2Api - factory interface
 * @export
 */
export const Service2ApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = Service2ApiFp(configuration);
  return {
    /**
     *
     * @summary Get list of services
     * @param {string} [searchText]
     * @param {Array<TerveystaloServiceAPIAbstractionsMedicalField>} [medicalFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ServicesGet(
      searchText?: string,
      medicalFields?: Array<TerveystaloServiceAPIAbstractionsMedicalField>,
      options?: any,
    ): AxiosPromise<Array<GetServicesResponseItem>> {
      return localVarFp.v2ServicesGet(searchText, medicalFields, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get service by global service id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ServicesIdGet(id: string, options?: any): AxiosPromise<GetServiceResponse> {
      return localVarFp.v2ServicesIdGet(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get global service id by subsystem key
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ServicesSubsystemSubsystemIDGet(
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .v2ServicesSubsystemSubsystemIDGet(subsystem, subsystemID, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Service2Api - object-oriented interface
 * @export
 * @class Service2Api
 * @extends {BaseAPI}
 */
export class Service2Api extends BaseAPI {
  /**
   *
   * @summary Get list of services
   * @param {string} [searchText]
   * @param {Array<TerveystaloServiceAPIAbstractionsMedicalField>} [medicalFields]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Service2Api
   */
  public v2ServicesGet(
    searchText?: string,
    medicalFields?: Array<TerveystaloServiceAPIAbstractionsMedicalField>,
    options?: AxiosRequestConfig,
  ) {
    return Service2ApiFp(this.configuration)
      .v2ServicesGet(searchText, medicalFields, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get service by global service id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Service2Api
   */
  public v2ServicesIdGet(id: string, options?: AxiosRequestConfig) {
    return Service2ApiFp(this.configuration)
      .v2ServicesIdGet(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get global service id by subsystem key
   * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
   * @param {string} subsystemID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Service2Api
   */
  public v2ServicesSubsystemSubsystemIDGet(
    subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
    subsystemID: string,
    options?: AxiosRequestConfig,
  ) {
    return Service2ApiFp(this.configuration)
      .v2ServicesSubsystemSubsystemIDGet(subsystem, subsystemID, options)
      .then(request => request(this.axios, this.basePath));
  }
}
