/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Alert, focusBoxShadow, Icon, IconName } from '@terveystalo/design-system-react-components';
import { theme } from '@terveystalo/design-system-theme';
import React, { FC } from 'react';
import { ExternalLink } from 'ui/utils/LinkHelper';
import { classNames } from 'utils/styles/helpers';

type Props = {
  variant: 'info' | 'warning' | 'error';
  icon?: IconName;
  text: string;
  href?: string;
  className?: string;
  close?: {
    closed: boolean;
    onClose: () => void;
    buttonLabel: string;
  };
};

export const Notice: FC<Props> = ({ variant, icon, text, href, close, className }) => {
  if (close && close.closed) {
    return null;
  }

  const darkColor = variant === 'error' ? 'coralRed150' : variant === 'warning' ? 'cadmiumYellow150' : 'matisseBlue';

  return (
    <Alert
      childAs="div"
      icon={icon}
      variant={variant}
      className={className}
      css={css`
        /* override some styles to better look like a banner and have bigger clickable areas */
        padding-left: 0;
        padding-right: 0;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;

        > img {
          margin-left: ${theme.spacing['1']};
        }

        > div {
          flex-grow: 1;
        }
      `}
    >
      <div className={classNames('px-1', icon && '-ml-1', 'flex', 'items-start')}>
        <div className={classNames('mr-0.75', 'flex-grow')}>
          {href ? (
            <ExternalLink href={href} className={classNames('inline-block', 'hover:text-zodiac-black', focusBoxShadow)}>
              <span>{text}</span> <Icon name="ArrowRight" className={classNames('inline-block')} color={darkColor} />
            </ExternalLink>
          ) : (
            text
          )}
        </div>
        {close && (
          <button
            className={classNames(
              'flex-shrink-0',
              'flex',
              'justify-center',
              'items-center',
              'p-0.25',
              '-m-0.25',
              focusBoxShadow,
            )}
            css={css`
              &:hover,
              &:active {
                img {
                  transform: scale(1.33);
                }
              }
            `}
            onClick={close.onClose}
            aria-label={close.buttonLabel}
          >
            <Icon name="ClearFilled" color={darkColor} />
          </button>
        )}
      </div>
    </Alert>
  );
};
