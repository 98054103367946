import { Language } from 'translations/generated/translation-languages';
import { assertExhausted, objectKeysExactly } from 'utils/types/misc';

export type LangRoute = { [key in Language]: string };

const rootRoute: LangRoute = { fi: '/fi', sv: '/sv', en: '/en' };
export const baseRoute: LangRoute = {
  fi: `${rootRoute.fi}/ajanvaraus`,
  sv: `${rootRoute.sv}/tidbokning`,
  en: `${rootRoute.en}/booking`,
};

type BookingSubRoutes = {
  bookingSummary: (bookingId: string) => LangRoute;
  bookingConfirm: (bookingId: string) => LangRoute;
  bookingPatientWithService: (appointmentId: string, appointmentType: string, serviceId: string) => LangRoute;
  bookingPatient: (appointmentId: string, appointmentType: string) => LangRoute;
  appointmentPreviewWithService: (appointmentId: string, serviceId: string) => LangRoute;
  appointmentPreview: (appointmentId: string) => LangRoute;

  privateCustomerHidden: LangRoute;
  directCompensations: LangRoute;
  directCompensationPreview: (claimId: string) => LangRoute;
  root: LangRoute;
};

const normalBookingRootRoutes = {
  privateCustomer: {
    fi: `${baseRoute.fi}/yksityisasiakas`,
    sv: `${baseRoute.sv}/privatkund`,
    en: `${baseRoute.en}/private-customer`,
  },
  privateCustomerV2: {
    fi: `${baseRoute.fi}/yksityisasiakas-hidden`,
    sv: `${baseRoute.sv}/privatkund-hidden`,
    en: `${baseRoute.en}/private-customer-hidden`,
  },
  occupationalHealthcare: {
    fi: `${baseRoute.fi}/tyoterveysajat`,
    sv: `${baseRoute.sv}/foretagshalsovardskund`,
    en: `${baseRoute.en}/occupational-healthcare`,
  },
  dental: {
    fi: `${baseRoute.fi}/hammashoito`,
    sv: `${baseRoute.sv}/tandvard`,
    en: `${baseRoute.en}/dental-care`,
  },
  insurance: {
    fi: `${baseRoute.fi}/vakuutus`,
    sv: `${baseRoute.sv}/forsakring`,
    en: `${baseRoute.en}/insurance`,
  },
  voucher: {
    fi: `${baseRoute.fi}/palveluseteli`,
    sv: `${baseRoute.sv}/servicesedel`,
    en: `${baseRoute.en}/service-voucher`,
  },
};
const insurancePartnerBookingRootRoutes = {
  terveysmestari: {
    fi: `${baseRoute.fi}/terveysmestari`,
    sv: `${baseRoute.sv}/terveysmestari`,
    en: `${baseRoute.en}/terveysmestari`,
  },
  fenniahoitaja: {
    fi: `${baseRoute.fi}/fenniahoitaja`,
    sv: `${baseRoute.sv}/fenniahoitaja`,
    en: `${baseRoute.en}/fenniahoitaja`,
  },
  terveyshelppi: {
    fi: `${baseRoute.fi}/terveyshelppi`,
    sv: `${baseRoute.sv}/terveyshelppi`,
    en: `${baseRoute.en}/terveyshelppi`,
  },
};
export const publicPartnerBookingRoot = 'publicPartner' as const;
const publicPartnerRootRoute = {
  fi: `${baseRoute.fi}/julkisenkumppani`,
  sv: `${baseRoute.sv}/julkisenkumppani`,
  en: `${baseRoute.en}/julkisenkumppani`,
};
export const htaTeamBookingRoot = 'htaTeam' as const;
const htaTeamRootRoute = {
  fi: `${baseRoute.fi}/hta-tiimi`,
  sv: `${baseRoute.sv}/hta-team`,
  en: `${baseRoute.en}/hta-team`,
};
export const migriBookingRoot = 'migri' as const;
const migriRootRoute = {
  fi: `${baseRoute.fi}/migri`,
  sv: `${baseRoute.sv}/migri`,
  en: `${baseRoute.en}/migri`,
};

const bookingRootRoutes = {
  ...normalBookingRootRoutes,
  ...insurancePartnerBookingRootRoutes,
  [publicPartnerBookingRoot]: publicPartnerRootRoute,
  [htaTeamBookingRoot]: htaTeamRootRoute,
  [migriBookingRoot]: migriRootRoute,
};

export const normalBookingRoots = objectKeysExactly(normalBookingRootRoutes);
type NormalBookingRoot = typeof normalBookingRoots[number];
export const insurancePartnerBookingRoots = objectKeysExactly(insurancePartnerBookingRootRoutes);
export const allBookingRoots = [
  ...normalBookingRoots,
  ...insurancePartnerBookingRoots,
  publicPartnerBookingRoot,
  htaTeamBookingRoot,
  migriBookingRoot,
];
export type InsurancePartnerBookingRoot = typeof insurancePartnerBookingRoots[number];
export type PublicPartnerBookingRoot = typeof publicPartnerBookingRoot;
export type HtaTeamBookingRoot = typeof htaTeamBookingRoot;
export type MigriBookingRoot = typeof migriBookingRoot;
export type BookingRoot =
  | NormalBookingRoot
  | InsurancePartnerBookingRoot
  | PublicPartnerBookingRoot
  | HtaTeamBookingRoot
  | MigriBookingRoot;
export const isInsurancePartnerRoot = (r: BookingRoot | undefined) =>
  !!r && insurancePartnerBookingRoots.includes(r as any);
export const isPublicPartnerRoot = (r: BookingRoot | undefined) => r === publicPartnerBookingRoot;
export const isMigriBookingRoot = (r: BookingRoot | undefined) => r === migriBookingRoot;
/* 
  Collection of all partner booking roots to give boolean "is partner booking root"
*/
export const isPartnerRoot = (r: BookingRoot | undefined) =>
  isInsurancePartnerRoot(r) || isPublicPartnerRoot(r) || r === htaTeamBookingRoot || r === migriBookingRoot;
export const getPartnerName = (r: BookingRoot | undefined): string | undefined => {
  switch (r) {
    case 'privateCustomer':
    case 'privateCustomerV2':
    case 'occupationalHealthcare':
    case 'dental':
    case 'insurance':
    case 'voucher':
    case 'publicPartner':
    case 'htaTeam':
    case 'migri':
      return;
    case 'terveysmestari':
      return 'Pohjola Vakuutus';
    case 'fenniahoitaja':
      return 'Fennia';
    case 'terveyshelppi':
      return 'LähiTapiola';
    default:
      assertExhausted(r);
  }
};

type NormalBookingRoutes = { [key in NormalBookingRoot]: BookingSubRoutes };
type InsurancePartnerBookingRoutes = {
  [key in InsurancePartnerBookingRoot]: BookingSubRoutes & { insuranceDetails: (bookingId: string) => LangRoute };
};
type PublicPartnerBookingRoutes = {
  [publicPartnerBookingRoot]: BookingSubRoutes & { invoicingDetails: (bookingId: string) => LangRoute };
};
type HtaTeamBookingRoutes = { [htaTeamBookingRoot]: BookingSubRoutes };
type MigriBookingRoutes = {
  [migriBookingRoot]: BookingSubRoutes & { invoicingDetails: (bookingId: string) => LangRoute };
};
type BookingRoutes = NormalBookingRoutes &
  InsurancePartnerBookingRoutes &
  PublicPartnerBookingRoutes &
  HtaTeamBookingRoutes &
  MigriBookingRoutes;
type Routes = {
  root: LangRoute;
  myAppointments: LangRoute;
  logIn: LangRoute;
  loggedOut: LangRoute;
  autoLoggedOut: LangRoute;
  bookingCancelled: LangRoute;
  occupationalTeam: LangRoute;
  myAppointmentSummary: (bookingCode: string) => LangRoute;
  myAppointmentEdit: (bookingCode: string) => LangRoute;
} & BookingRoutes;

const bookingSubRoutesForRoot = (bookingRootRoute: LangRoute): BookingSubRoutes => ({
  // Important: make sure the route parameters appear in the urls in the given order
  // and similar functions with more params have to be first for the route backwards matcher to work
  bookingSummary: bookingId => ({
    fi: `${bookingRootRoute.fi}/kiitos-varauksestasi/${bookingId}/`,
    sv: `${bookingRootRoute.sv}/tack-for-din-bokning/${bookingId}/`,
    en: `${bookingRootRoute.en}/thank-you-for-your-booking/${bookingId}/`,
  }),
  bookingConfirm: bookingId => ({
    fi: `${bookingRootRoute.fi}/vahvista/${bookingId}/`,
    sv: `${bookingRootRoute.sv}/bekräfta/${bookingId}/`,
    en: `${bookingRootRoute.en}/confirm/${bookingId}/`,
  }),
  bookingPatientWithService: (appointmentId, appointmentType, serviceId) => ({
    fi: `${bookingRootRoute.fi}/potilas/${appointmentId}/${appointmentType}/${serviceId}/`,
    sv: `${bookingRootRoute.sv}/patient/${appointmentId}/${appointmentType}/${serviceId}/`,
    en: `${bookingRootRoute.en}/patient/${appointmentId}/${appointmentType}/${serviceId}/`,
  }),
  bookingPatient: (appointmentId, appointmentType) => ({
    fi: `${bookingRootRoute.fi}/potilas/${appointmentId}/${appointmentType}/`,
    sv: `${bookingRootRoute.sv}/patient/${appointmentId}/${appointmentType}/`,
    en: `${bookingRootRoute.en}/patient/${appointmentId}/${appointmentType}/`,
  }),
  appointmentPreviewWithService: (appointmentId, serviceId) => ({
    fi: `${bookingRootRoute.fi}/varaa/${appointmentId}/${serviceId}/`,
    sv: `${bookingRootRoute.sv}/boka/${appointmentId}/${serviceId}/`,
    en: `${bookingRootRoute.en}/book/${appointmentId}/${serviceId}/`,
  }),
  appointmentPreview: appointmentId => ({
    fi: `${bookingRootRoute.fi}/varaa/${appointmentId}/`,
    sv: `${bookingRootRoute.sv}/boka/${appointmentId}/`,
    en: `${bookingRootRoute.en}/book/${appointmentId}/`,
  }),
  root: {
    fi: `${bookingRootRoute.fi}/`,
    sv: `${bookingRootRoute.sv}/`,
    en: `${bookingRootRoute.en}/`,
  },
  privateCustomerHidden: {
    fi: `${baseRoute.fi}/yksityisasiakas-hidden/`,
    sv: `${baseRoute.sv}/privatkund-hidden/`,
    en: `${baseRoute.en}/private-customer-hidden/`,
  },
  directCompensations: {
    fi: `${bookingRootRoute.fi}/suorakorvaus/`,
    sv: `${bookingRootRoute.sv}/suorakorvaus/`,
    en: `${bookingRootRoute.en}/direct-compensation/`,
  },
  directCompensationPreview: claimId => ({
    fi: `${bookingRootRoute.fi}/suorakorvaus/${claimId}/`,
    sv: `${bookingRootRoute.sv}/boka/${claimId}/`,
    en: `${bookingRootRoute.en}/direct-compensation/${claimId}/`,
  }),
});

export const routes: Routes = {
  root: { fi: `${rootRoute.fi}/`, sv: `${rootRoute.sv}/`, en: `${rootRoute.en}/` },
  myAppointmentEdit: bookingCode => ({
    fi: `${baseRoute.fi}/omat-varaukseni/${bookingCode}/muokkaa/`,
    sv: `${baseRoute.sv}/egna-bokningar/${bookingCode}/andra/`,
    en: `${baseRoute.en}/my-appointments/${bookingCode}/modify/`,
  }),
  myAppointmentSummary: bookingCode => ({
    fi: `${baseRoute.fi}/omat-varaukseni/${bookingCode}/`,
    sv: `${baseRoute.sv}/egna-bokningar/${bookingCode}/`,
    en: `${baseRoute.en}/my-appointments/${bookingCode}/`,
  }),
  myAppointments: {
    fi: `${baseRoute.fi}/omat-varaukseni/`,
    sv: `${baseRoute.sv}/egna-bokningar/`,
    en: `${baseRoute.en}/my-appointments/`,
  },
  bookingCancelled: {
    fi: `${baseRoute.fi}/varaus-peruttu/`,
    sv: `${baseRoute.sv}/mottagningstid-avbokad/`,
    en: `${baseRoute.en}/appointment-cancelled/`,
  },
  loggedOut: {
    fi: `${baseRoute.fi}/kirjauduit-ulos/`,
    sv: `${baseRoute.sv}/du-loggades-ut/`,
    en: `${baseRoute.en}/you-logged-out/`,
  },
  autoLoggedOut: {
    fi: `${baseRoute.fi}/sinut-kirjattiin-ulos/`,
    sv: `${baseRoute.sv}/du-ar-utloggad/`,
    en: `${baseRoute.en}/you-were-logged-out/`,
  },
  logIn: {
    fi: `${baseRoute.fi}/kirjaudu-sisaan/`,
    sv: `${baseRoute.sv}/logga-in/`,
    en: `${baseRoute.en}/log-in/`,
  },
  ...normalBookingRoots.reduce<NormalBookingRoutes>((rs, r) => {
    rs[r] = bookingSubRoutesForRoot(normalBookingRootRoutes[r]);
    return rs;
  }, {} as any),
  ...insurancePartnerBookingRoots.reduce<InsurancePartnerBookingRoutes>((rs, r) => {
    rs[r] = {
      ...bookingSubRoutesForRoot(insurancePartnerBookingRootRoutes[r]),
      insuranceDetails: bookingId => ({
        fi: `${insurancePartnerBookingRootRoutes[r].fi}/vakuutuksen-tiedot/${bookingId}/`,
        sv: `${insurancePartnerBookingRootRoutes[r].sv}/vakuutuksen-tiedot/${bookingId}/`,
        en: `${insurancePartnerBookingRootRoutes[r].en}/vakuutuksen-tiedot/${bookingId}/`,
      }),
    };
    return rs;
  }, {} as any),
  [publicPartnerBookingRoot]: {
    ...bookingSubRoutesForRoot(publicPartnerRootRoute),
    invoicingDetails: bookingId => ({
      fi: `${publicPartnerRootRoute.fi}/laskutuksen-tiedot/${bookingId}/`,
      sv: `${publicPartnerRootRoute.sv}/laskutuksen-tiedot/${bookingId}/`,
      en: `${publicPartnerRootRoute.en}/laskutuksen-tiedot/${bookingId}/`,
    }),
  },
  [htaTeamBookingRoot]: bookingSubRoutesForRoot(htaTeamRootRoute),
  occupationalTeam: {
    fi: `${bookingRootRoutes.occupationalHealthcare.fi}/tyoterveystiimi/`,
    sv: `${bookingRootRoutes.occupationalHealthcare.sv}/foretagshalsovardsteam/`,
    en: `${bookingRootRoutes.occupationalHealthcare.en}/occupational-healthcare-team/`,
  },
  [migriBookingRoot]: {
    ...bookingSubRoutesForRoot(migriRootRoute),
    invoicingDetails: bookingId => ({
      fi: `${migriRootRoute.fi}/lisätiedot/${bookingId}/`,
      sv: `${migriRootRoute.sv}/lisätiedot/${bookingId}/`,
      en: `${migriRootRoute.en}/lisätiedot/${bookingId}/`,
    }),
  },
};
