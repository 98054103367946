import { defineActionCreators } from 'utils/redux/actions';

export default defineActionCreators({
  setGlobalNotesOpen(isOpen: boolean) {
    return { isOpen };
  },
  changeGlobalNotesText(text: string) {
    return { text };
  },
  resizeGlobalNotes(size: { width?: number; height?: number }) {
    return { size };
  },
});
