/* eslint-disable import/order */ // because the polyfills need to be the first imports
import { ApiClientContextProvider } from 'api/ApiClient';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/promise/finally';
import 'core-js/features/string/includes';
import 'core-js/features/string/repeat';
import 'core-js/features/symbol';
import 'core-js/features/symbol/iterator';
/* eslint-enable import/order */

import 'index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from 'ui/App';
import { AbTestingContextProvider } from 'utils/analytics/ab-testing';
import { AnalyticsContextProvider } from 'utils/analytics/AnalyticsContextProvider';
import { ConfigContextProvider } from 'utils/config/ConfigContextProvider';
import { UiContextProvider } from 'utils/react/ui-context';
import { RedirectionService } from 'utils/RedirectionService';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) since we are using TypeScript
Promise.resolve().then(() => {
  root.render(
    <ConfigContextProvider>
      <RedirectionService />
      <AbTestingContextProvider>
        <AnalyticsContextProvider>
          <ApiClientContextProvider>
            <UiContextProvider>
              <App />
            </UiContextProvider>
          </ApiClientContextProvider>
        </AnalyticsContextProvider>
      </AbTestingContextProvider>
    </ConfigContextProvider>,
  );
});
