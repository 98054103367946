import apiData from 'actions/apiData';
import auth from 'actions/auth';
import delegate from 'actions/delegate';
import filters from 'actions/filters';
import globalNotes from 'actions/globalNotes';
import location from 'actions/location';
import notice from 'actions/notice';
import screenSize from 'actions/screenSize';
import translations from 'actions/translations';

export const actions = {
  ...location,
  ...filters,
  ...apiData,
  ...auth,
  ...notice,
  ...screenSize,
  ...translations,
  ...delegate,
  ...globalNotes,
};
