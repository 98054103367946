import range from 'lodash/range';

export type TimeSource = typeof Date.now;

export type FromDate = Date | number | string;

const createDate = (from: FromDate) => {
  const date = new Date(from);
  if (isNaN(date.getTime())) {
    return undefined;
  }
  return date;
};

export const isSameDay = (from1: FromDate, from2: FromDate) => {
  const date1 = createDate(from1);
  const date2 = createDate(from2);
  if (!date1 || !date2) {
    return false;
  }
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const isToday = (from: FromDate, getCurrentTime: TimeSource) => isSameDay(from, new Date(getCurrentTime()));

export const isTomorrow = (from: FromDate, getCurrentTime: TimeSource) => {
  const date = new Date(getCurrentTime());
  date.setDate(date.getDate() + 1);
  return isSameDay(from, date);
};

export const isSameMonth = (from1: FromDate, from2: FromDate) => {
  const date1 = createDate(from1);
  const date2 = createDate(from2);
  if (!date1 || !date2) {
    return false;
  }
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
};

export const startOfDay = (from: FromDate) => {
  const date = createDate(from);
  if (!date) {
    return undefined;
  }
  date.setHours(0, 0, 0, 0);
  return date;
};

export const isCurrentMonth = (from: FromDate, getCurrentTime: TimeSource) =>
  isSameMonth(from, new Date(getCurrentTime()));

export const startOfMonth = (from: FromDate) => {
  const date = createDate(from);
  if (!date) {
    return undefined;
  }
  date.setDate(1);
  return date;
};

export const daysRemainingInMonth = (from: FromDate, getCurrentTime: TimeSource) => {
  const date = createDate(from);
  if (!date) {
    return 0;
  }
  const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  // If the given date is in current month, subtract number of past days excluding today
  if (isSameMonth(date, new Date(getCurrentTime()))) {
    return daysInMonth - date.getDate() + 1;
  }
  return daysInMonth;
};

export const calendarMonthDates = (from: Date) => {
  const date = new Date(from);
  // Find the previous monday
  const start = date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
  // Form dates for six weeks
  return range(0, 6).map(i =>
    range(0, 7).map(j => {
      const cur = new Date(start);
      cur.setDate(cur.getDate() + (i * 7 + j));
      return cur;
    }),
  );
};

export const secondsTo = (to: FromDate, getCurrentTime: TimeSource) => {
  const date = createDate(to);
  if (!date) {
    return 0;
  }
  const now = getCurrentTime();
  return Math.floor((date.getTime() - now) / 1000);
};

// Check if the given date is in the past
export const isPast = (from: FromDate, getCurrentTime: TimeSource, includeToday?: boolean) => {
  const date = createDate(from);
  if (!date) {
    return false;
  }
  if (isToday(date, getCurrentTime)) {
    return !!includeToday;
  }
  return date.getTime() <= getCurrentTime();
};

// Check if the given date is in the future
export const isFuture = (from: FromDate, getCurrentTime: TimeSource, includeToday?: boolean) => {
  const date = createDate(from);
  if (!date) {
    return false;
  }
  if (isToday(date, getCurrentTime)) {
    return !!includeToday;
  }
  return date.getTime() >= getCurrentTime();
};

export const daysInFuture = (date: FromDate, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
