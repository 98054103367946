import { CallbackActions } from 'api/ApiFetcher';
import sortBy from 'lodash/sortBy';
import { ApiModelType, CustomerType, regularAppointmentTypes } from 'model/api';
import {
  AppointmentTypesState,
  SpecialistGenderState,
  SpecialistLanguageState,
  TimeOfDayState,
} from 'reducers/filters';
import { useCustomerType } from 'utils/booking/helpers';
import { useHideRemoteOptions, useLocationFilter, useSpecialistOrServiceFilter } from 'utils/filters/helpers';
import { useApiData, useReduxState } from 'utils/react/ui-context';
import { RespiratorySymptoms, useRespiratorySymptoms } from 'utils/symptoms/helpers';
import { notAsked, RemoteData } from 'utils/types/remoteData';

export type AppointmentStatus = 'chosen' | 'booked' | 'moved';

export type AppointmentQuery = {
  date: number;
  dayCount: number; // number of days to include in the search, >= 1
  clinicIds: string[];
  customerType: CustomerType;
  // Optional values
  specialistIds?: string[];
  serviceId?: string;
  serviceName?: string;
  appointmentTypes: AppointmentTypesState;
  fromTimeOfDay: TimeOfDayState;
  toTimeOfDay: TimeOfDayState;
  specialistGender: SpecialistGenderState;
  specialistLanguage: SpecialistLanguageState; // two-letter lang code, e.g. "fi"
  minCount: number; // minimum number of appointment results, integer
  maxExtraDays?: number; // maximum number of days to add if otherwise not enough results (by minCount), default 14(?)
  customerAge?: number;
  respiratorySymptoms: RespiratorySymptoms;
  durationInMinutes?: number;
  take?: number;
  skip?: number;
};
export type AppointmentQueryWithStatus =
  | { status: 'query'; query: AppointmentQuery; alternativeServiceId?: string }
  | { status: 'loading' }
  | { status: 'no-filter' };

export const useAppointmentQuery = (overrides: Partial<AppointmentQuery> = {}): AppointmentQueryWithStatus => {
  const auth = useReduxState(state => state.auth);
  const customerType = useCustomerType();
  const filters = useReduxState(state => state.filters);
  const delegate = useReduxState(state => state.delegate);
  const {
    appointmentTypes,
    date,
    fromTimeOfDay,
    toTimeOfDay,
    specialistGender,
    specialistLanguage,
    serviceIndex,
  } = filters;

  const specialistOrService = useSpecialistOrServiceFilter();
  const locationFilter = useLocationFilter();

  const hideRemoteOptions = useHideRemoteOptions();
  const respiratorySymptoms = useRespiratorySymptoms();

  const getPopularServices = useApiData('getPopularServices');

  if (specialistOrService.type === 'loading' || locationFilter.type === 'loading') {
    return { status: 'loading' };
  }

  if (specialistOrService.type === 'none' || locationFilter.type === 'none') {
    return { status: 'no-filter' };
  }

  const query = {
    date,
    dayCount: 1,
    clinicIds: locationFilter.clinicIds,
    specialistIds: specialistOrService.type === 'specialist' ? [specialistOrService.id] : undefined,
    serviceId: specialistOrService.type === 'service' ? specialistOrService.id : undefined,
    appointmentTypes:
      locationFilter.type !== 'remote' && !hideRemoteOptions
        ? appointmentTypes
        : (appointmentTypes || regularAppointmentTypes)
            .filter(a => locationFilter.type !== 'remote' || a !== 'clinic')
            .filter(a => a === 'clinic' || !hideRemoteOptions),
    fromTimeOfDay,
    toTimeOfDay,
    specialistGender,
    specialistLanguage,
    minCount: 10,
    maxExtraDays: 14,
    customerAge:
      delegate.status !== 'NOTHING'
        ? delegate.age
        : auth.status === 'LOGGED_IN' && auth.user.age !== null
        ? auth.user.age
        : undefined, // check for null specifically as age can be 0
    customerType,
    respiratorySymptoms,
    ...overrides,
  };

  // Handle alternative service override
  const popularServices = getPopularServices([]);
  const mainServiceId = query.serviceId;
  if (mainServiceId && popularServices.status === 'REMOTE_DATA_LOADING') {
    return { status: 'loading' };
  }
  const alternativeServices =
    mainServiceId && popularServices.status === 'REMOTE_DATA_SUCCESS'
      ? popularServices.data.alternative.find(s => s.mainServiceId === mainServiceId && s.searchType === 'sequential')
      : undefined;
  const serviceIds = alternativeServices ? alternativeServices.serviceIds : [];
  const serviceId = serviceIds.length
    ? serviceIds[serviceIds.length > serviceIndex ? serviceIndex : serviceIds.length - 1]
    : mainServiceId;

  return {
    status: 'query',
    query: { ...query, serviceId },
    alternativeServiceId: serviceId !== mainServiceId ? serviceId : undefined,
  };
};

export const useGetAppointments = (overrideOptions?: { fetchDebounceTime?: number }) => {
  const auth = useReduxState(state => state.auth);
  const getAppointments = useApiData('getAppointments');

  const options = overrideOptions || { fetchDebounceTime: 1000 }; // Limit appointments re-fetching interval on params change

  return (query: AppointmentQueryWithStatus, actions?: CallbackActions<'getAppointments'>) => {
    if (query.status !== 'query') {
      return notAsked;
    }

    if (query.query.customerType !== 'occupational') {
      return getAppointments([query.query, 'non-occupational'], options, actions);
    }

    if (auth.status === 'LOGGED_IN') {
      return getAppointments([query.query, auth.token], options, actions);
    }

    return notAsked;
  };
};

export const useSearchAlternativeServiceIds = (query: AppointmentQueryWithStatus) => {
  const alternativeServicesSearch = useReduxState(state => state.filters.alternativeServicesSearch);
  const getPopularServices = useApiData('getPopularServices');
  const popularServices = getPopularServices([]);

  if (
    !alternativeServicesSearch ||
    query.status !== 'query' ||
    !query.query.serviceId ||
    popularServices.status !== 'REMOTE_DATA_SUCCESS'
  ) {
    return undefined;
  }

  const alternativeServices = popularServices.data.alternative.find(
    s =>
      s.mainServiceId === query.query.serviceId &&
      (s.searchType === 'simultaneous' ||
        (s.searchType === 'simultaneousOccupational' && query.query.customerType === 'occupational')),
  );
  // Set the main service as the first service for the laternatives to include the main results among the alternative
  return alternativeServices ? [query.query.serviceId, ...alternativeServices.serviceIds] : undefined;
};

export const useGetAppointmentsWithMultipleServices = () => {
  const getAppointments = useGetAppointments({});

  return (
    query: AppointmentQueryWithStatus,
    serviceIds: string[],
  ): RemoteData<ApiModelType['AppointmentsResult'] & { appointmentToServiceMap: Map<string, string | undefined> }> => {
    if (query.status !== 'query') {
      return notAsked;
    }

    const appointmentToServiceMap = new Map<string, string | undefined>();
    const appointmentsDatas = serviceIds.map(serviceId => ({
      serviceId,
      fetch: getAppointments({ ...query, query: { ...query.query, serviceId } }),
    }));

    // Show loading as long as at least one is loading
    if (appointmentsDatas.some(d => d.fetch.status === 'REMOTE_DATA_LOADING')) {
      return { status: 'REMOTE_DATA_LOADING' };
    }

    // Show failure only if all fail
    const failure = appointmentsDatas.find(d => d.fetch.status === 'REMOTE_DATA_FAILURE');
    if (
      failure &&
      failure.fetch.status === 'REMOTE_DATA_FAILURE' &&
      !appointmentsDatas.some(d => d.fetch.status === 'REMOTE_DATA_SUCCESS')
    ) {
      return failure.fetch;
    }

    const mainServiceId = query.query.serviceId;

    // Combine all succeeded appointments into one list ordered by date, storing service information
    const appointments = sortBy(
      appointmentsDatas.reduce<Array<ApiModelType['Appointment']>>((list, d) => {
        if (d.fetch.status === 'REMOTE_DATA_SUCCESS') {
          d.fetch.data.availableAppointments.forEach(a => {
            if (!appointmentToServiceMap.has(a.appointmentId)) {
              // Create a map to send proper alternative service analytics
              appointmentToServiceMap.set(a.appointmentId, d.serviceId === mainServiceId ? undefined : d.serviceId);
              list.push(a);
            }
          });
        }
        return list;
      }, []),
      'dateTime',
    );

    return {
      status: 'REMOTE_DATA_SUCCESS',
      data: { availableAppointments: appointments, occupationalRestrictions: null, appointmentToServiceMap },
    };
  };
};

export const useGetAppointmentDays = (overrideOptions?: { fetchDebounceTime?: number }) => {
  const getAppointmentDays = useApiData('getAppointmentDays');

  const options = overrideOptions || { fetchDebounceTime: 1000 }; // Limit appointments re-fetching interval on params change

  return (query: AppointmentQueryWithStatus) => {
    if (query.status !== 'query') {
      return notAsked;
    }

    return getAppointmentDays([query.query], options);
  };
};

export const useGetAppointmentDaysWithMultipleServices = () => {
  const getAppointmentDays = useGetAppointmentDays({});

  return (query: AppointmentQueryWithStatus, serviceIds: string[]): ReturnType<typeof getAppointmentDays> => {
    if (query.status !== 'query') {
      return notAsked;
    }

    const appointmentDaysDatas = serviceIds.map(serviceId =>
      getAppointmentDays({ ...query, query: { ...query.query, serviceId } }),
    );

    // Show loading as long as at least one is loading
    if (appointmentDaysDatas.some(d => d.status === 'REMOTE_DATA_LOADING')) {
      return { status: 'REMOTE_DATA_LOADING' };
    }

    // Show failure only if all fail
    const failure = appointmentDaysDatas.find(d => d.status === 'REMOTE_DATA_FAILURE');
    if (
      failure &&
      failure.status === 'REMOTE_DATA_FAILURE' &&
      !appointmentDaysDatas.some(d => d.status === 'REMOTE_DATA_SUCCESS')
    ) {
      return failure;
    }

    // Combine all succeeded appointment days into one ordered list of dates
    // Note that the appointmentCounts are not added as the values are not used
    const dates = new Set<string>();
    const appointmentDays = sortBy(
      appointmentDaysDatas.reduce<Array<ApiModelType['AppointmentDay']>>((list, d) => {
        if (d.status === 'REMOTE_DATA_SUCCESS') {
          d.data.forEach(a => {
            if (!dates.has(a.date)) {
              dates.add(a.date);
              list.push(a);
            }
          });
        }
        return list;
      }, []),
      'date',
    );

    return {
      status: 'REMOTE_DATA_SUCCESS',
      data: appointmentDays,
    };
  };
};

// Formatter from 24 to 12 hour clock
export const format24to12 = (time: string) => {
  let hours = parseInt(time.split(':')[0], 10);
  const minutes = time.split(':')[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${minutes} ${ampm}`;
};
