type NotAsked = {
  status: 'REMOTE_DATA_NOT_ASKED';
};

// Helper object for not asked data.
// Returning this one (instead of creating a new one every time) makes us more friendly towards identity-check-based memoization.
export const notAsked: NotAsked = {
  status: 'REMOTE_DATA_NOT_ASKED',
};

type Loading = {
  status: 'REMOTE_DATA_LOADING';
};

// Same as "notAsked" above.
export const loading: Loading = {
  status: 'REMOTE_DATA_LOADING',
};

type Success<Data> = {
  status: 'REMOTE_DATA_SUCCESS';
  data: Data;
};

type ErrorFetching = {
  status: 'REMOTE_DATA_FAILURE';
  error: string;
  errorCode?: number;
  errorId?: string;
};

export type RemoteData<Data> = NotAsked | Loading | Success<Data> | ErrorFetching;
