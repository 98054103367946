import { Address, AutocompleteApiFactory, Namedetails } from 'api/generated/GeocodingApi/api';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import { ApiModel } from 'model/api';
import { ConfigModelType } from 'model/config';
import { Language } from 'translations/generated/translation-languages';
import { Analytics } from 'utils/analytics/analytics-utils';
import { createLogger, Logger } from 'utils/logging/logging-utils';
import { defined, guardedModelArrayConversion } from 'utils/types/api';

// Creates low-level bindings to the Geocoding API.
export function createGeocodingApiClient(
  config: ConfigModelType['FrontendConfig'],
  axiosImpl = axios,
  analytics: Analytics,
) {
  const log = createLogger(config, 'GeocodingApiClient');
  const api = {
    GeocodingApi: AutocompleteApiFactory({ apiKey: config.GEOCODING_API_KEY }, undefined, axiosImpl),
  };
  return {
    searchGeoLocations(lang: Language, searchText: string) {
      // Define the types of results we want: places such as cities, towns, suburbs and different kinds of addresses, syntax: 'class:type'
      const tags =
        'place:city,place:town,place:suburb,place:village,place:hamlet,place:island,place:islet,place:house,place:square,highway:residential,highway:unclassified,highway:primary,highway:secondary,highway:tertiary';
      analytics.sendGeoLocationSearch(lang, searchText);
      return api.GeocodingApi.autocomplete(searchText, 1, 10, undefined, undefined, 'fi', lang, tags).then(res =>
        convertDataToGeoLocations(analytics, log, res.data),
      );
    },
  };
}

// Exported conversion function for easier testing the filtering and name formatting
export function convertDataToGeoLocations(analytics: Analytics, log: Logger, locations: any[]) {
  const converted = guardedModelArrayConversion(analytics, log, locations, ApiModel.GeoLocation, data => ({
    name: defined(geoLocationName(defined(data.address, 'address'), data.class, data.type), 'name'),
    latitude: parseFloat(defined(data.lat, 'latitude')),
    longitude: parseFloat(defined(data.lon, 'longitude')),
  }));
  // Filter our those that would have a same display name
  return uniqBy(converted, 'name');
}

// Special result display name formatting
function geoLocationName(address: Address & Namedetails, category?: string, type?: string) {
  const { name, road, house_number, city } = address;
  if (!name) return;
  if (category === 'place') {
    switch (type) {
      case 'house':
        return house_number && name === road ? `${name} ${house_number}${city ? `, ${city}` : ''}` : undefined;
      case 'city':
      case 'town':
        return name;
      default:
        return !city ? name : `${name}, ${city}`;
    }
  }
  return !city ? name : `${name}, ${city}`;
}
