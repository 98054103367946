import isPlainObject from 'lodash/isPlainObject';
import { useEffect, useRef } from 'react';
import { useUiContext } from 'utils/react/ui-context';

function useCheckCookiesEnabled() {
  const analytics = useUiContext().analytics;
  const checked = useRef(false);

  useEffect(() => {
    if (checked.current === false) {
      checked.current = true;
      const cookieName = 'test';
      const cookieVal = 'testval';
      document.cookie = `${cookieName}=${cookieVal};SameSite=Strict;`;
      if (document.cookie.indexOf(`${cookieName}=${cookieVal}`) === -1) {
        analytics.sendStorageNotEnabled('cookies');
      }
      document.cookie = `${cookieName}=;SameSite=Strict;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
  }, [checked, analytics]);
}

function useCheckSessionStorageEnabled() {
  const analytics = useUiContext().analytics;
  const checked = useRef(false);

  useEffect(() => {
    if (checked.current === false) {
      checked.current = true;
      try {
        const storageKey = 'test';
        const storageVal = 'testval';
        sessionStorage.setItem(storageKey, storageVal);
        if (sessionStorage.getItem(storageKey) !== storageVal) {
          throw new Error('Stored value not found');
        }
        sessionStorage.removeItem(storageKey);
      } catch (e) {
        analytics.sendStorageNotEnabled('sessionStorage');
      }
    }
  }, [checked, analytics]);
}

export function useCheckStorageEnabled() {
  useCheckCookiesEnabled();
  useCheckSessionStorageEnabled();
}

export function getCookie(cname: string) {
  const name = `${cname}=`;
  const cookieParts = decodeURIComponent(document.cookie).split(';');

  for (let c of cookieParts) {
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

// Browser storage helpers
export function getStorageItem(key: string, storage: Storage = sessionStorage): string | null {
  try {
    return storage.getItem(key);
  } catch (e) {
    return null;
  }
}

export function setStorageItem(key: string, value: string, storage: Storage = sessionStorage) {
  try {
    storage.setItem(key, value);
  } catch (e) {
    // no-op
  }
}

export function removeStorageItem(key: string, storage: Storage = sessionStorage) {
  try {
    storage.removeItem(key);
  } catch (e) {
    // no-op
  }
}

// Helpers for storing object values
export function getStorageObjectItem(key: string, storage: Storage = sessionStorage): Record<any, unknown> {
  try {
    const item = getStorageItem(key, storage);
    if (!item) {
      return {};
    }
    const parsed = JSON.parse(item);
    if (!!parsed && isPlainObject(parsed)) {
      return parsed;
    }
    return {};
  } catch (e) {
    return {};
  }
}

export function setStorageObjectItem(key: string, value: Record<string, any>, storage: Storage = sessionStorage) {
  setStorageItem(key, JSON.stringify(value), storage);
}
