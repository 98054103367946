import { actions } from 'actions';
import { ApiClient, ApiClientContext } from 'api/ApiClient';
import { ApiFetcher, CallbackActions, createApiFetcher } from 'api/ApiFetcher';
import { AxiosStatic } from 'axios';
import { createBrowserHistory, History } from 'history';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import { ConfigModelType } from 'model/config';
import { getErrorAsString, parseErrorCode } from 'model/error';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { State } from 'reducers';
import { Store as ReduxStore } from 'redux';
import { TranslationMap } from 'translations';
import { languages } from 'translations/generated/translation-languages';
import { AbTesting, AbTestingContext } from 'utils/analytics/ab-testing';
import { Analytics } from 'utils/analytics/analytics-utils';
import { AnalyticsContext } from 'utils/analytics/AnalyticsContextProvider';
import { createOneWelcomeClient, OneWelcomeClient } from 'utils/auth/oneWelcome';
import { ConfigContext } from 'utils/config/ConfigContextProvider';
import { TimeSource } from 'utils/datetime/helpers';
import { createLogger, Logger } from 'utils/logging/logging-utils';
import { initializePlugins } from 'utils/plugins/plugin-utils';
import { StartUrlStateSync } from 'utils/react/url-state-utils';
import { createBoundDispatcher } from 'utils/redux/actions';
import { createStore, Store } from 'utils/redux/store';
import { LangRoute } from 'utils/routes/routes';
import { deepGet } from 'utils/types/deepGetSet';
import { ArgsOfSkip1, assertExhausted, PromiseOf } from 'utils/types/misc';
import { RemoteData } from 'utils/types/remoteData';
import { v4 as uuidv4 } from 'uuid';

export type Dispatch = typeof actions;

export type UiContext = Readonly<{
  config: ConfigModelType['FrontendConfig'];
  getCurrentTime: TimeSource;
  subscribe: Store['subscribe'];
  getState: Store['getState'];
  dispatch: Dispatch;
  history: History;
  apiClient: ApiClient;
  apiFetcher: ApiFetcher;
  reduxStore: ReduxStore;
  abTesting: AbTesting;
  analytics: Analytics;
  oneWelcomeClient: OneWelcomeClient;
}>;

export type UiContextOverrides = Partial<UiContext & { log: Logger; initialState: State; axios: AxiosStatic }>;

export function CreateUiContext(overrides: UiContextOverrides = {}): UiContext {
  const config = useContext(ConfigContext);
  const abTesting = useContext(AbTestingContext);
  const { analytics } = useContext(AnalyticsContext);
  const apiClient = useContext(ApiClientContext);

  const log = overrides.log || createLogger(config, 'UiContext');
  const reduxStore = overrides.reduxStore || createStore(overrides.initialState);
  const dispatch = overrides.dispatch || createBoundDispatcher(reduxStore);
  const history = overrides.history || createBrowserHistory();
  const getCurrentTime = overrides.getCurrentTime || Date.now;

  const apiFetcher =
    overrides.apiFetcher ||
    createApiFetcher(
      apiClient,
      reduxStore.getState.bind(reduxStore),
      dispatch,
      createLogger(config, 'ApiFetcher'),
      analytics,
    );
  const oneWelcomeClient = overrides.oneWelcomeClient || createOneWelcomeClient(config, history, dispatch, analytics);
  initializePlugins(config, history, analytics);
  // Dispatch actions for screen resizes
  window.addEventListener('resize', () => dispatch.changeScreenSize(window.innerWidth, window.innerHeight));
  // Make sure that the state params always match the url
  // eslint-disable-next-line
  // @ts-ignore: history was used here 2yr already when newest version of ts started alerting
  StartUrlStateSync(history, reduxStore, dispatch, config, getCurrentTime, apiClient);
  // Announce readiness
  log('Started', config);
  return {
    config,
    getCurrentTime,
    subscribe: reduxStore.subscribe.bind(reduxStore),
    getState: reduxStore.getState.bind(reduxStore),
    dispatch,
    history,
    apiClient,
    apiFetcher,
    reduxStore,
    abTesting,
    analytics,
    oneWelcomeClient,
  };
}

// This React Context is INTENTIONALLY not exported from this module.
// The idea being that instead of using <UiContext> and its various companions,
// all call sites should either go through the useUiContext() hook, or the
// <UiContextProvider> component.
const UiContextBase = React.createContext<UiContext | null>(null);

type Props = {
  children?: React.ReactNode;
};

export const UiContextProvider: React.FC<Props> = ({ children }) => {
  const uiContext = CreateUiContext({});
  return (
    <ReduxProvider store={uiContext.reduxStore}>
      <UiContextBase.Provider value={uiContext}>
        {children}
        {/* the children can be either a full <App> instance, or an individual component */}
      </UiContextBase.Provider>
    </ReduxProvider>
  );
};

export function useUiContext(): UiContext {
  const ctx = useContext(UiContextBase);
  if (!ctx) throw new Error('This component cannot be rendered without UiContext being available');
  return ctx;
}

// As per the recommendation in the react-redux docs themselves, we create a wrapper for useSelector()
// whose only job is to make it aware of our Store shape, without having to repeat it at every call site.
export function useReduxState<Selection>(
  selector: (state: State) => Selection,
  equalityFn?: (left: Selection, right: Selection) => boolean, // see https://react-redux.js.org/next/api/hooks#equality-comparisons-and-updates
): Selection {
  return useSelector<State, Selection>(selector, equalityFn);
}

export function useReduxDispatch() {
  return useUiContext().dispatch;
}

export function useHistory() {
  return useUiContext().history;
}

export function useConfig() {
  return useUiContext().config;
}

const environments = ['test', 'stg', 'prod'] as const;
export type Environment = typeof environments[number];
export function useEnvironment() {
  const { ENV } = useConfig();
  return environments.includes(ENV as any) ? (ENV as Environment) : 'prod';
}

export function useTranslations(): TranslationMap {
  const lang = useReduxState(state => state.location.lang);
  const translations = useReduxState(state => state.translations);
  const dispatch = useReduxDispatch();

  if (lang === 'fi') {
    return translations[lang];
  }

  const otherLangTranslations = translations[lang];
  if (!otherLangTranslations) {
    dispatch.setTranslations(lang, 'loading');
    // Separate imports per language for better type safety
    if (lang === 'sv') {
      import('translations/generated/translations-sv')
        .then(module => {
          dispatch.setTranslations(lang, module.default);
        })
        .catch(() => {
          // Enable retrying the loading
          dispatch.setTranslations(lang, undefined);
        });
    } else {
      import('translations/generated/translations-en')
        .then(module => {
          dispatch.setTranslations(lang, module.default);
        })
        .catch(() => {
          // Enable retrying the loading
          dispatch.setTranslations(lang, undefined);
        });
    }
  }
  if (!otherLangTranslations || otherLangTranslations === 'loading') {
    // Use empty translations for all keys while loading
    return mapValues(translations.fi, () => '');
  }
  return otherLangTranslations;
}

export function useTranslatedRoute() {
  const lang = useReduxState(state => state.location.lang);
  return (langRoute: LangRoute) => langRoute[lang];
}

// Implements a wrapper around ApiFetcher.fetch(), to make it more convenient to consume from React components.
// One of the major responsibilities of this hook is ensuring the component gets notified (i.e. re-rendered) whenever the data
// it's displaying changes.
export function useApiData<M extends keyof ApiClient>(method: M) {
  const lang = useReduxState(state => state.location.lang); // always make ApiClient requests using the selected language
  const [uiComponentId] = useState(uuidv4()); // in case we're using debounced fetches, generate a unique ID for this component so it doesn't interfere with requests from other components
  const { apiFetcher } = useUiContext();
  const subscriptions = useRef<{ [id: string]: null }>({}); // for the duration of the lifetime of the React component, keep track of the API data resources it's interested in, so we know when it needs to re-render
  noop(
    // When a component uses this hook, it creates an implicit dependency on the API data in the Redux store, even if it doesn't explicitly subscribe to Redux updates.
    // We don't actually need to DO anything with this data, though, because the requested data is returned to the caller below; hence the noop().
    useReduxState(
      state => mapValues(subscriptions.current, (_, key) => deepGet(state.apiData.data, key, 'status')), // instead of selecting the entire API response, we simply check the "status" of each resource we're interested in
      isEqual, // useReduxState would normally use === equality checks, but because we know the data is small and shallow, we can afford a comparison with _.isEqual
    ),
  );
  return (
    // These values are NOT bound when the hook is called, but only when the resulting "getter function" is invoked
    params: ArgsOfSkip1<ApiClient[M]>,
    options: { fetchDebounceTime?: number } = {},
    actions: CallbackActions<M> = {},
  ) => {
    const _options = options.fetchDebounceTime
      ? { fetchDebounceId: uiComponentId, fetchDebounceTime: options.fetchDebounceTime } // use the uiComponentId ONLY if debouncing has been requested by the React component
      : {};
    const _params = [lang, ...params] as any; // unfortunately the generics don't quite work for this internal invocation, but we're well-typed externally, so should be fine
    const resourceId = apiFetcher.getResourceId(method, _params);
    subscriptions.current[resourceId] = null; // mark this resource as being interesting to this component; the value doesn't really matter, it's the presence of the key in the map
    return apiFetcher.fetch(method, _params, _options, actions);
  };
}

// Implements a thin wrapper around ApiFetcher.clear, to make it more convenient to consume from React components
export function useClearApiData<M extends keyof ApiClient>(method: M) {
  const { apiFetcher } = useUiContext();
  return (
    // Values not to bind when the hook is called
    params: ArgsOfSkip1<ApiClient[M]>,
  ) => {
    // Clear chosen data for all languages
    languages.map(lang => apiFetcher.clear(method, [lang, ...params] as any));
  };
}

// Implements a similar helper than useApiData but does not persist the data in redux
// causing a separate fetch for each use of this hook
export function useApiDataNoPersist<M extends keyof ApiClient>(
  method: M,
  params: ArgsOfSkip1<ApiClient[M]> | null,
  onError: (errStr: string) => void,
) {
  const lang = useReduxState(state => state.location.lang);
  const [fetchedData, setFetchedData] = useState<RemoteData<PromiseOf<ReturnType<ApiClient[M]>>>>({
    status: 'REMOTE_DATA_NOT_ASKED',
  });
  const { apiFetcher } = useUiContext();

  useEffect(() => {
    if (params && fetchedData.status === 'REMOTE_DATA_NOT_ASKED') {
      setFetchedData({ status: 'REMOTE_DATA_LOADING' });
      const _params = [lang, ...params] as any; // unfortunately the generics don't quite work for this internal invocation, but we're well-typed externally, so should be fine
      apiFetcher
        .invokeApiClient([method, _params])
        .then(data => setFetchedData({ status: 'REMOTE_DATA_SUCCESS', data: data as any }))
        .catch(err => {
          onError(getErrorAsString(err));
          setFetchedData({ status: 'REMOTE_DATA_FAILURE', error: err.message, errorCode: parseErrorCode(err) });
        });
    }
  }, [fetchedData.status, method, lang, params, apiFetcher, onError]);

  return fetchedData;
}

export const useDocumentTitle = (titleKey?: keyof TranslationMap, withRoot?: boolean) => {
  const t = useTranslations();
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const { analytics } = useUiContext();
  useEffect(() => {
    const currentPart = titleKey ? `${t[titleKey]} · ` : '';
    let rootPart = '';
    if (withRoot && !!bookingRoot) {
      switch (bookingRoot) {
        case 'privateCustomer':
        case 'privateCustomerV2':
          rootPart = t.private_customer;
          break;
        case 'occupationalHealthcare':
          rootPart = t.occupational_healthcare;
          break;
        case 'dental':
          rootPart = t.dental_care;
          break;
        case 'insurance':
          rootPart = t.insurance;
          break;
        case 'voucher':
          rootPart = t.voucher_from_municipality;
          break;
        case 'terveysmestari':
          rootPart = 'Terveysmestari';
          break;
        case 'fenniahoitaja':
          rootPart = 'FenniaHoitaja';
          break;
        case 'terveyshelppi':
          rootPart = 'TerveysHelppi';
          break;
        case 'publicPartner':
          rootPart = 'Julkiset palvelut';
          break;
        case 'htaTeam':
          rootPart = t.hta;
          break;
        case 'migri':
          rootPart = 'Migri';
          break;
        default:
          assertExhausted(bookingRoot);
      }
      rootPart = `${rootPart} · `;
    }
    const fullTitle = `${currentPart}${rootPart}${t.booking} · Terveystalo`;
    if (document.title !== fullTitle) {
      document.title = fullTitle;
      analytics.trackPageView();
    }
  }, [t, titleKey, withRoot, bookingRoot, analytics]);
};
