import apiData, { initState as apiDataInitState } from 'reducers/apiData';
import auth, { initState as authInitState } from 'reducers/auth';
import delegate, { initState as delegateInitState } from 'reducers/delegate';
import filters, { initState as filtersInitState } from 'reducers/filters';
import globalNotes, { initState as globalNotesInitState } from 'reducers/globalNotes';
import location, { initState as locationInitState } from 'reducers/location';
import notice, { initState as noticeInitState } from 'reducers/notice';
import screenSize, { initState as screenSizeInitState } from 'reducers/screenSize';
import translations, { initState as translationsInitState } from 'reducers/translations';
import { Action } from 'utils/redux/actions';
import { mapValuesExactly } from 'utils/types/misc';

export type State = {
  filters: typeof filtersInitState;
  location: typeof locationInitState;
  apiData: typeof apiDataInitState;
  auth: typeof authInitState;
  notice: typeof noticeInitState;
  screenSize: typeof screenSizeInitState;
  delegate: typeof delegateInitState;
  translations: typeof translationsInitState;
  globalNotes: typeof globalNotesInitState;
};

// Own types for reducers to support passing the whole state
type Reducer<S, G> = (state: S | undefined, action: Action, globalState?: G) => S;
type ReducersMapObject<S> = { [K in keyof S]: Reducer<S[K], S> };

function combineReducers<S>(reducers: ReducersMapObject<S>) {
  return (state: S | undefined, action: Action) => {
    let hasChanged = !state;
    const newState = mapValuesExactly(reducers, (reducer, key) => {
      const partialState = state ? state[key] : undefined;
      const newPartialState = reducer(partialState, action, state);
      hasChanged = hasChanged || newPartialState !== partialState;
      return newPartialState;
    });
    return hasChanged || !state ? (newState as S) : state;
  };
}

export default combineReducers({
  location,
  filters,
  apiData,
  auth,
  notice,
  screenSize,
  delegate,
  globalNotes,
  translations,
});
