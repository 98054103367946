/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { focusVisible } from '@terveystalo/design-system-react-components';
import { FeedbackDialog } from '@terveystalo/design-system-react-organisms';
import { theme } from '@terveystalo/design-system-theme';
import isEqual from 'lodash/isEqual';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useFeedbackTags } from 'utils/feedback/helpers';
import { useModalOpenState } from 'utils/react/modal-helpers';
import { useConfig, useReduxState, useTranslations } from 'utils/react/ui-context';
import { classNames } from 'utils/styles/helpers';

const floatingShadowRotated = '-5px 0 10px 0 rgba(110, 118, 132, 0.3)';

export const FeedbackBubble: FC = () => {
  const t = useTranslations();
  const { lang } = useReduxState(state => state.location);
  const [isOpen, setIsOpen] = useModalOpenState('feedback');
  const getTags = useFeedbackTags('spontaneous');
  const [tags, setTags] = useState(getTags());
  const config = useConfig();

  // Make sure the tags are up to date when the dialog is opened
  useEffect(() => {
    const newTags = getTags();
    if (!isEqual(newTags, tags)) {
      setTags(newTags);
    }
  }, [isOpen, getTags, tags]);

  return (
    <Fragment>
      <div
        className={classNames('fixed', 'right-0')}
        css={css`
          z-index: 1;
          top: 50%;
          transform: rotate(270deg);
        `}
      >
        <button
          onClick={() => setIsOpen(true)}
          className={classNames(
            'absolute',
            'block',
            'bg-matisse-blue',
            'rounded-t-l',
            'text-white',
            'text-body-m',
            'font-bold',
            'cursor-pointer',
            'border-xxs',
            'bottom-0',
          )}
          css={css`
            left: -3rem; /* approx the width of the feedback bubble to center better */
            padding: 0.25rem 1rem; /* smaller height */
            box-shadow: ${floatingShadowRotated};
            border-color: ${theme.color.matisseBlue125};
            border-bottom: 0;
            &:hover,
            &:active {
              background-color: ${theme.color.skyIsBlue};
            }
            ${focusVisible(
              `
                outline: 0;
                box-shadow: 0 0 0 ${theme.border.width.xs} ${theme.color.white}, 0 0 0 ${theme.border.width.s} ${theme.color.zodiacBlack};
              `,
              `
                box-shadow: none;
              `,
            )}
          `}
        >
          {t.feedback}
        </button>
      </div>
      <FeedbackDialog
        id="open-feedback-modal"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        type="ces"
        language={lang}
        title={t.give_feedback_about_booking}
        tags={tags}
        apiAuth={config.FEEDBACK_API_AUTH.ces}
      />
    </Fragment>
  );
};
