/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';
import { TextWithPhoneNumber } from 'ui/utils/TextWithPhoneNumber';
import { classNames } from 'utils/styles/helpers';

export const RedirectionFormRichText: FC<{ text: string | null; className?: string }> = ({ text, className }) => (
  <p
    className={className}
    css={css`
      white-space: pre-wrap; /* render line breaks */
    `}
  >
    {text
      ? text.split(/\*\*/).map((t, i) =>
          i % 2 === 0 ? (
            <TextWithPhoneNumber text={t} key={`${t}-${i}`} />
          ) : (
            <strong key={`${t}-${i}`} className={classNames('font-bold')}>
              <TextWithPhoneNumber text={t} />
            </strong>
          ),
        )
      : null}
  </p>
);
