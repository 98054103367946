import { ApiModelType } from 'model/api';
import { Action } from 'utils/redux/actions';

export type DelegateToken = { accessToken: string; expiresIn?: number };
export type DelegateError = {
  type: 'token_invalid' | 'not_allowed' | 'other_token_error' | 'online_booking_denied' | 'other';
};
export type NotRegisteredDelegate = { status: 'USER_NOT_REGISTERED'; name: string; token: DelegateToken; age?: number };
export type LoggedInDelegate = {
  status: 'LOGGED_IN';
  user: ApiModelType['DelegateUser'];
  token: DelegateToken;
  age?: number;
};

export const initState:
  | { status: 'NOTHING'; age: undefined }
  | { status: 'RETURNING'; registration: string; code: string; age: undefined }
  | { status: 'DELEGATION'; age?: number }
  | { status: 'AGE_SET'; age?: number }
  | { status: 'LOADING_TOKEN'; age?: number }
  | { status: 'ERROR'; error: DelegateError; age?: number }
  | { status: 'TOKEN_RETRIEVED'; token: DelegateToken; age?: number }
  | { status: 'LOADING_USER_DATA'; token: DelegateToken; age?: number }
  | NotRegisteredDelegate
  | LoggedInDelegate = { status: 'NOTHING', age: undefined };

export default function reducer(state = initState, action: Action) {
  switch (action.type) {
    case 'initDelegation':
      return { status: 'DELEGATION' as const, age: action.age };
    case 'setDelegateRegistration':
      return { status: 'RETURNING' as const, registration: action.registration, code: action.code, age: undefined };
    case 'setDelegateAge':
      return { status: 'AGE_SET' as const, age: action.age };
    case 'delegateTokenLoading':
      return { status: 'LOADING_TOKEN' as const, age: state.age };
    case 'delegateError':
      return { status: 'ERROR' as const, error: action.error, age: state.age };
    case 'setDelegateToken':
      return { status: 'TOKEN_RETRIEVED' as const, token: action.token, age: state.age };
    case 'delegateUserDataLoading':
      return { status: 'LOADING_USER_DATA' as const, token: action.token, age: state.age };
    case 'newDelegateUser':
      return {
        status: 'USER_NOT_REGISTERED' as const,
        name: action.name,
        token: action.token,
        age: action.age || state.age,
      };
    case 'delegateLogin':
      return { status: 'LOGGED_IN' as const, user: action.user, token: action.token, age: action.age };
    case 'delegateChange':
      return { status: 'AGE_SET' as const, age: undefined };
    case 'delegateExit':
      return { status: 'NOTHING' as const, age: undefined };
    default:
      return state;
  }
}
