/** @jsxImportSource @emotion/react */
import { Button } from '@terveystalo/design-system-react-components';
import React, { FC } from 'react';
import { useLocalLogout, useLoginType } from 'utils/auth/helpers';
import {
  useReduxDispatch,
  useReduxState,
  useTranslatedRoute,
  useTranslations,
  useUiContext,
} from 'utils/react/ui-context';
import { routes } from 'utils/routes/routes';
import { classNames } from 'utils/styles/helpers';

export const useLogoutButtonClick = (relogin?: boolean) => {
  const troute = useTranslatedRoute();
  const logOut = useLocalLogout();
  const { bookingRoot, lang } = useReduxState(state => state.location);
  const dispatch = useReduxDispatch();
  const loginType = useLoginType();
  const { history, oneWelcomeClient } = useUiContext();

  return () => {
    logOut(true);
    if (loginType === 'oneWelcome') {
      if (!oneWelcomeClient) {
        throw new Error('No authClient where expected!');
      }
      dispatch.loadingAuthChange();
      const reloginState = relogin
        ? {
            pathname: troute(routes.logIn),
            search: history.location.search,
            prevRoute: history.location.pathname,
          }
        : undefined;
      oneWelcomeClient.logoutFromOneWelcome(lang, reloginState);
    } else {
      history.push(troute(bookingRoot ? routes[bookingRoot].root : routes.loggedOut));
    }
  };
};

export const LogoutButton: FC<{ className?: string; variant?: string }> = ({ className, variant }) => {
  const t = useTranslations();
  const onClick = useLogoutButtonClick();

  return (
    <Button
      as="button"
      variant={variant === 'primary' ? 'primary' : 'secondary'}
      onClick={onClick}
      className={classNames('w-full', 's:w-auto', className)}
    >
      {t.log_out}
    </Button>
  );
};
