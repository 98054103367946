import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApiClient } from 'api/ApiClient';
import { History } from 'history';
import { ConfigModelType } from 'model/config';
import { createLogger } from 'utils/logging/logging-utils';
import { BookingRoot } from 'utils/routes/routes';

export function createApplicationInsightsClient(config: ConfigModelType['FrontendConfig'], history: History) {
  if (!config.APPLICATION_INSIGHTS_KEY) {
    throw new Error('Cannot instantiate Application Insights without APPLICATION_INSIGHTS_KEY set');
  }
  const log = createLogger(config, 'ApplicationInsights');

  // Add Azure Application Insights SDK
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: config.APPLICATION_INSIGHTS_KEY,
      extensions: [reactPlugin],
      extensionConfig: { [reactPlugin.identifier]: { history } },
    },
  });
  appInsights.loadAppInsights();

  // Methods for which to gather additional insights data, such as the post body
  const additionalFetchAnalytics = !!config.ADDITIONAL_FETCH_ANALYTICS
    ? config.ADDITIONAL_FETCH_ANALYTICS.split(',')
    : [];

  const sendFetchDetails = (
    method: keyof ApiClient,
    query: string,
    durationMs: number,
    resultDetails: { type: 'success'; resultCount?: number } | { type: 'failure'; errorMessage: string },
  ) => {
    // Send details only from selected methods, as base info is automatically sent for all requests
    if (additionalFetchAnalytics.includes(method)) {
      if (config.FEATURE_LOGGING) log('Sending fetch details', method);
      appInsights.trackEvent({
        name: method,
        properties: {
          query,
          ...resultDetails,
        },
        measurements: {
          [`Fetch "${method}" duration (ms)`]: durationMs,
        },
      });
    }
  };

  let initialDataLoadSent = false;
  const sendInitialDataLoaded = (pageTimeMs: number, bookingRoot: BookingRoot) => {
    if (!initialDataLoadSent) {
      initialDataLoadSent = true;
      if (config.FEATURE_LOGGING) log('Sending initial data loaded durations');
      appInsights.trackEvent({
        name: 'initialDataLoaded',
        properties: {
          bookingRoot,
        },
        measurements: {
          'Initial time to results on page (ms)': pageTimeMs,
          'Initial time to results on site (ms)': performance.now(),
        },
      });
    }
  };

  const sendFetchBatchMetrics = (requestNames: string[], totalDurationMs: number) => {
    if (config.FEATURE_LOGGING) log('Sending fetch batch metrics');
    appInsights.trackEvent({
      name: 'fetchBatchMetrics',
      properties: {
        requestNames,
      },
      measurements: {
        'Fetch batch number of requests': requestNames.length,
        'Fetch batch total duration (ms)': totalDurationMs,
      },
    });
  };

  const sendDataConversionError = (model: string, error: any) => {
    if (config.FEATURE_LOGGING) log('Sending data conversion errors', model, error);
    appInsights.trackEvent({
      name: 'apiDataConversionError',
      properties: {
        model,
        error,
      },
      measurements: {},
    });
  };

  const sendStrongAuthError = (error: string | string[], description?: string | string[] | null) => {
    if (config.FEATURE_LOGGING) log('Sending strong auth error', error, description);
    appInsights.trackEvent({
      name: 'strongAuthError',
      properties: {
        error: Array.isArray(error) ? error.join(',') : error,
        errorDescription: Array.isArray(description) ? description.join(',') : description,
      },
    });
  };

  const sendGeoLocationSearch = (language: string, searchString: string) => {
    if (config.FEATURE_LOGGING) log('Sending geolocation search data', language, searchString);
    appInsights.trackEvent({
      name: 'geoLocationSearch',
      properties: {
        language,
        searchString,
      },
    });
  };

  const sendInvalidRedirectionFormParams = (resultId: number, resultBookingParams: string) => {
    if (config.FEATURE_LOGGING) log('Sending invalid redirection form params data', resultId, resultBookingParams);
    appInsights.trackEvent({
      name: 'invalidRedirectionFormParams',
      properties: {
        resultId,
        resultBookingParams,
      },
    });
  };

  const sendBookingFailureDetails = (appointmentOrBookingId: string, bookingError: string) => {
    if (config.FEATURE_LOGGING) log('Sending booking failure details', appointmentOrBookingId, bookingError);
    appInsights.trackEvent({
      name: 'bookingFailure',
      properties: {
        appointmentOrBookingId,
        bookingError,
      },
    });
  };

  const sendStorageNotEnabled = (type: 'cookies' | 'sessionStorage') => {
    if (config.FEATURE_LOGGING) log(`Sending ${type} not enabled info`);
    appInsights.trackEvent({
      name: `${type}NotEnabled`,
      properties: {},
    });
  };

  // to prevent multiple sends per session
  let allowSendReservationDenied = true;
  const sendOccupationalWebReservationDeniedFound = (employerCode: string) => {
    if (allowSendReservationDenied) {
      if (config.FEATURE_LOGGING) log('Sending company id of user who has occupational online resevation denied');
      appInsights.trackEvent({
        name: 'OccupationalWebReservationDeniedFound',
        properties: { employerCode },
      });
      allowSendReservationDenied = false;
    }
  };

  const sendMaintenanceBreakPageShown = (type: string, data?: Record<string, any>) => {
    if (config.FEATURE_LOGGING) log('Sending maintenance break page shown info', type);
    appInsights.trackEvent({
      name: 'maintenanceBreakPageShown',
      properties: {
        maintenanceBreakType: type,
        maintenanceBreakData: JSON.stringify(data),
      },
    });
  };

  const sendCustomerServiceChatObserverFailed = (error: string) => {
    if (config.FEATURE_LOGGING) log('Sending customer service chat observer info');
    appInsights.trackEvent({
      name: 'customerServiceChatObserverFailed',
      properties: {
        customerServiceChatObserverError: error,
      },
    });
  };

  const sendAppError = (error: any, errorInfo: any) => {
    if (config.FEATURE_LOGGING) log('Sending error catch info');
    appInsights.trackEvent({
      name: 'appErrorBoundaryCatch',
      properties: {
        appError: error && 'message' in error ? error.message : 'Error',
        appErrorInfo: typeof errorInfo === 'object' ? JSON.stringify(errorInfo) : '',
      },
    });
  };

  return {
    sendFetchDetails,
    sendInitialDataLoaded,
    sendFetchBatchMetrics,
    sendDataConversionError,
    sendStrongAuthError,
    sendGeoLocationSearch,
    sendInvalidRedirectionFormParams,
    sendBookingFailureDetails,
    sendStorageNotEnabled,
    sendOccupationalWebReservationDeniedFound,
    sendMaintenanceBreakPageShown,
    sendCustomerServiceChatObserverFailed,
    sendAppError,
  };
}
