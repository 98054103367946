/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 * Ajanvaraus UX API
 * Helper API for handling secrets and complex aggregation of api calls. Only for use by the Ajanvaraus SPA front.
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @enum {string}
 */

export const ButtonType = {
  Link: 'link',
  Chat: 'chat',
  Booking: 'booking',
} as const;

export type ButtonType = typeof ButtonType[keyof typeof ButtonType];

/**
 *
 * @export
 * @interface ContractService
 */
export interface ContractService {
  /**
   *
   * @type {string}
   * @memberof ContractService
   */
  serviceId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ContractService
   */
  included?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ContractService
   */
  paymentCommitmentRequired?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ContractService
   */
  directAllowed?: boolean | null;
  /**
   *
   * @type {Array<SpecialistReferralType>}
   * @memberof ContractService
   */
  acceptedSpecialistReferrals?: Array<SpecialistReferralType> | null;
  /**
   *
   * @type {Array<ServicePermissionType>}
   * @memberof ContractService
   */
  acceptedPermissions?: Array<ServicePermissionType> | null;
}
/**
 *
 * @export
 * @interface CustomError
 */
export interface CustomError {
  /**
   *
   * @type {string}
   * @memberof CustomError
   */
  stack?: string;
  /**
   *
   * @type {string}
   * @memberof CustomError
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CustomError
   */
  message: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const FokusServiceCategory = {
  Fysio: 'fysio',
  Urology: 'urology',
  Gynaecology: 'gynaecology',
  Tule: 'tule',
  MieliLinkBanner: 'mieli-link-banner',
  Laboratory: 'laboratory',
  Migrane: 'migrane',
} as const;

export type FokusServiceCategory = typeof FokusServiceCategory[keyof typeof FokusServiceCategory];

/**
 *
 * @export
 * @interface FormButton
 */
export interface FormButton {
  /**
   *
   * @type {ButtonType}
   * @memberof FormButton
   */
  type: ButtonType;
  /**
   *
   * @type {string}
   * @memberof FormButton
   */
  text: string | null;
  /**
   *
   * @type {string}
   * @memberof FormButton
   */
  defaultText: string;
  /**
   *
   * @type {string}
   * @memberof FormButton
   */
  url: string | null;
  /**
   *
   * @type {string}
   * @memberof FormButton
   */
  defaultUrl: string | null;
  /**
   *
   * @type {number}
   * @memberof FormButton
   */
  chatId: number | null;
  /**
   *
   * @type {string}
   * @memberof FormButton
   */
  params: string | null;
}
/**
 *
 * @export
 * @interface FormQuestion
 */
export interface FormQuestion {
  /**
   *
   * @type {string}
   * @memberof FormQuestion
   */
  kind: FormQuestionKindEnum;
  /**
   *
   * @type {number}
   * @memberof FormQuestion
   */
  questionId: number;
  /**
   *
   * @type {string}
   * @memberof FormQuestion
   */
  text: string | null;
  /**
   *
   * @type {string}
   * @memberof FormQuestion
   */
  defaultText: string;
  /**
   *
   * @type {boolean}
   * @memberof FormQuestion
   */
  disabled: boolean;
  /**
   *
   * @type {string}
   * @memberof FormQuestion
   */
  bookingParams: string | null;
  /**
   *
   * @type {Array<FormQuestionChildrenInner>}
   * @memberof FormQuestion
   */
  children: Array<FormQuestionChildrenInner>;
}

export const FormQuestionKindEnum = {
  Question: 'question',
} as const;

export type FormQuestionKindEnum = typeof FormQuestionKindEnum[keyof typeof FormQuestionKindEnum];

/**
 *
 * @export
 * @interface FormQuestionChildrenInner
 */
export interface FormQuestionChildrenInner {
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  kind: FormQuestionChildrenInnerKindEnum;
  /**
   *
   * @type {number}
   * @memberof FormQuestionChildrenInner
   */
  questionId: number;
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  text: string | null;
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  defaultText: string | null;
  /**
   *
   * @type {boolean}
   * @memberof FormQuestionChildrenInner
   */
  disabled: boolean;
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  bookingParams: string | null;
  /**
   *
   * @type {Array<FormQuestionChildrenInner>}
   * @memberof FormQuestionChildrenInner
   */
  children: Array<FormQuestionChildrenInner>;
  /**
   *
   * @type {number}
   * @memberof FormQuestionChildrenInner
   */
  resultId: number;
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  appText: string | null;
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  defaultAppText: string | null;
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  disabledText: string | null;
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  defaultDisabledText: string | null;
  /**
   *
   * @type {string}
   * @memberof FormQuestionChildrenInner
   */
  titleReplacement: string | null;
  /**
   *
   * @type {FormResultButton}
   * @memberof FormQuestionChildrenInner
   */
  button: FormResultButton | null;
}

export const FormQuestionChildrenInnerKindEnum = {
  Result: 'result',
} as const;

export type FormQuestionChildrenInnerKindEnum = typeof FormQuestionChildrenInnerKindEnum[keyof typeof FormQuestionChildrenInnerKindEnum];

/**
 *
 * @export
 * @interface FormResult
 */
export interface FormResult {
  /**
   *
   * @type {string}
   * @memberof FormResult
   */
  kind: FormResultKindEnum;
  /**
   *
   * @type {number}
   * @memberof FormResult
   */
  resultId: number;
  /**
   *
   * @type {string}
   * @memberof FormResult
   */
  text: string | null;
  /**
   *
   * @type {string}
   * @memberof FormResult
   */
  defaultText: string | null;
  /**
   *
   * @type {string}
   * @memberof FormResult
   */
  appText: string | null;
  /**
   *
   * @type {string}
   * @memberof FormResult
   */
  defaultAppText: string | null;
  /**
   *
   * @type {boolean}
   * @memberof FormResult
   */
  disabled: boolean;
  /**
   *
   * @type {string}
   * @memberof FormResult
   */
  disabledText: string | null;
  /**
   *
   * @type {string}
   * @memberof FormResult
   */
  defaultDisabledText: string | null;
  /**
   *
   * @type {string}
   * @memberof FormResult
   */
  titleReplacement: string | null;
  /**
   *
   * @type {FormResultButton}
   * @memberof FormResult
   */
  button: FormResultButton | null;
}

export const FormResultKindEnum = {
  Result: 'result',
} as const;

export type FormResultKindEnum = typeof FormResultKindEnum[keyof typeof FormResultKindEnum];

/**
 *
 * @export
 * @interface FormResultButton
 */
export interface FormResultButton {
  /**
   *
   * @type {ButtonType}
   * @memberof FormResultButton
   */
  type: ButtonType;
  /**
   *
   * @type {string}
   * @memberof FormResultButton
   */
  text: string | null;
  /**
   *
   * @type {string}
   * @memberof FormResultButton
   */
  defaultText: string;
  /**
   *
   * @type {string}
   * @memberof FormResultButton
   */
  url: string | null;
  /**
   *
   * @type {string}
   * @memberof FormResultButton
   */
  defaultUrl: string | null;
  /**
   *
   * @type {number}
   * @memberof FormResultButton
   */
  chatId: number | null;
  /**
   *
   * @type {string}
   * @memberof FormResultButton
   */
  params: string | null;
}
/**
 *
 * @export
 * @interface GiftCardValidateRequest
 */
export interface GiftCardValidateRequest {
  /**
   *
   * @type {string}
   * @memberof GiftCardValidateRequest
   */
  serviceId: string;
  /**
   *
   * @type {string}
   * @memberof GiftCardValidateRequest
   */
  code: string;
}
/**
 *
 * @export
 * @interface GiftCardValidateResponse
 */
export interface GiftCardValidateResponse {
  /**
   *
   * @type {string}
   * @memberof GiftCardValidateResponse
   */
  code: string;
  /**
   *
   * @type {GiftCardValidateStatus}
   * @memberof GiftCardValidateResponse
   */
  status: GiftCardValidateStatus;
  /**
   *
   * @type {string}
   * @memberof GiftCardValidateResponse
   */
  validForServiceId: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const GiftCardValidateStatus = {
  Ok: 'ok',
  Expired: 'expired',
  NotFound: 'notFound',
  NotForGivenServiceId: 'notForGivenServiceId',
  AlreadyUsed: 'alreadyUsed',
  NotAProductGiftCard: 'notAProductGiftCard',
  NotValidForCurrentUser: 'notValidForCurrentUser',
  NotValid: 'notValid',
  Throttling: 'throttling',
} as const;

export type GiftCardValidateStatus = typeof GiftCardValidateStatus[keyof typeof GiftCardValidateStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const Language = {
  Fi: 'fi',
  Sv: 'sv',
  En: 'en',
} as const;

export type Language = typeof Language[keyof typeof Language];

/**
 *
 * @export
 * @interface LocalizedStr
 */
export interface LocalizedStr {
  /**
   *
   * @type {string}
   * @memberof LocalizedStr
   */
  TwoLetterISOLanguage?: string | null;
  /**
   *
   * @type {string}
   * @memberof LocalizedStr
   */
  Value?: string | null;
}
/**
 *
 * @export
 * @interface OccupationalData
 */
export interface OccupationalData {
  /**
   *
   * @type {string}
   * @memberof OccupationalData
   */
  EmployerCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof OccupationalData
   */
  CompanyName?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OccupationalData
   */
  ResponsibleDoctorDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OccupationalData
   */
  ResponsibleNurseDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OccupationalData
   */
  ResponsiblePhysiotherapistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OccupationalData
   */
  ResponsiblePsychologistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OccupationalData
   */
  ResponsibleGeneralDoctorDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OccupationalData
   */
  ResponsibleNutritionistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OccupationalData
   */
  ResponsibleSocialWorkerDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof OccupationalData
   */
  PeriodicHealthExaminationClinic?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OccupationalData
   */
  WebReservationDeniedByEmployer?: boolean;
  /**
   *
   * @type {string}
   * @memberof OccupationalData
   */
  Contract?: string | null;
  /**
   *
   * @type {string}
   * @memberof OccupationalData
   */
  ActionPlan?: string | null;
  /**
   *
   * @type {string}
   * @memberof OccupationalData
   */
  BookingInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof OccupationalData
   */
  OtherInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof OccupationalData
   */
  BookingInfoOccupational?: string | null;
  /**
   *
   * @type {Array<TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic>}
   * @memberof OccupationalData
   */
  Clinics?: Array<TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic> | null;
  /**
   *
   * @type {{ [key: string]: TerveystaloCustomerAPIAPIAbstractionsV2CustomerAPIServiceAndSpecialtyPermission; }}
   * @memberof OccupationalData
   */
  ServicePermissions?: {
    [key: string]: TerveystaloCustomerAPIAPIAbstractionsV2CustomerAPIServiceAndSpecialtyPermission;
  } | null;
  /**
   *
   * @type {{ [key: string]: TerveystaloCustomerAPIAPIAbstractionsV2CustomerAPIServiceAndSpecialtyPermission; }}
   * @memberof OccupationalData
   */
  SpecialtyPermissions?: {
    [key: string]: TerveystaloCustomerAPIAPIAbstractionsV2CustomerAPIServiceAndSpecialtyPermission;
  } | null;
  /**
   *
   * @type {number}
   * @memberof OccupationalData
   */
  RedirectionFormID?: number | null;
  /**
   *
   * @type {TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic}
   * @memberof OccupationalData
   */
  ResponsibleClinic?: TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic;
  /**
   *
   * @type {boolean}
   * @memberof OccupationalData
   */
  HasPersonalizedBooking?: boolean;
  /**
   *
   * @type {RedirectionForm}
   * @memberof OccupationalData
   */
  redirectionForm?: RedirectionForm;
  /**
   *
   * @type {boolean}
   * @memberof OccupationalData
   */
  selfTriageVisible: boolean;
  /**
   *
   * @type {SkipLists}
   * @memberof OccupationalData
   */
  selfTriageSkips: SkipLists;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickContractServicesOrOfficeHoursOnly
 */
export interface PickContractServicesOrOfficeHoursOnly {
  /**
   *
   * @type {Array<ContractService>}
   * @memberof PickContractServicesOrOfficeHoursOnly
   */
  services?: Array<ContractService> | null;
  /**
   *
   * @type {boolean}
   * @memberof PickContractServicesOrOfficeHoursOnly
   */
  officeHoursOnly?: boolean | null;
}
/**
 *
 * @export
 * @interface PopularServices
 */
export interface PopularServices {
  /**
   *
   * @type {Array<PopularServicesAlternativeInner>}
   * @memberof PopularServices
   */
  alternative: Array<PopularServicesAlternativeInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServices
   */
  rehab: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServices
   */
  rela: Array<string>;
  /**
   *
   * @type {Array<PopularServicesFokusInner>}
   * @memberof PopularServices
   */
  fokus: Array<PopularServicesFokusInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServices
   */
  hidden: Array<string>;
  /**
   *
   * @type {Array<PopularServicesImagingInner>}
   * @memberof PopularServices
   */
  imaging: Array<PopularServicesImagingInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServices
   */
  sort: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServices
   */
  child: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServices
   */
  dental: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServices
   */
  occupational: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServices
   */
  primary: Array<string>;
}
/**
 *
 * @export
 * @interface PopularServicesAlternativeInner
 */
export interface PopularServicesAlternativeInner {
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServicesAlternativeInner
   */
  serviceIds: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PopularServicesAlternativeInner
   */
  searchType: PopularServicesAlternativeInnerSearchTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PopularServicesAlternativeInner
   */
  mainServiceId: string;
}

export const PopularServicesAlternativeInnerSearchTypeEnum = {
  Sequential: 'sequential',
  Simultaneous: 'simultaneous',
  SimultaneousOccupational: 'simultaneousOccupational',
} as const;

export type PopularServicesAlternativeInnerSearchTypeEnum = typeof PopularServicesAlternativeInnerSearchTypeEnum[keyof typeof PopularServicesAlternativeInnerSearchTypeEnum];

/**
 *
 * @export
 * @interface PopularServicesFokusInner
 */
export interface PopularServicesFokusInner {
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServicesFokusInner
   */
  serviceIds: Array<string>;
  /**
   *
   * @type {FokusServiceCategory}
   * @memberof PopularServicesFokusInner
   */
  serviceCategory: FokusServiceCategory;
  /**
   *
   * @type {string}
   * @memberof PopularServicesFokusInner
   */
  mainServiceId: string;
}
/**
 *
 * @export
 * @interface PopularServicesImagingInner
 */
export interface PopularServicesImagingInner {
  /**
   *
   * @type {Array<string>}
   * @memberof PopularServicesImagingInner
   */
  subServiceIds: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PopularServicesImagingInner
   */
  referralCode: string;
  /**
   *
   * @type {string}
   * @memberof PopularServicesImagingInner
   */
  mainServiceId: string;
}
/**
 *
 * @export
 * @interface RedirectionForm
 */
export interface RedirectionForm {
  /**
   *
   * @type {number}
   * @memberof RedirectionForm
   */
  formId: number;
  /**
   *
   * @type {string}
   * @memberof RedirectionForm
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof RedirectionForm
   */
  text: string | null;
  /**
   *
   * @type {string}
   * @memberof RedirectionForm
   */
  defaultText: string;
  /**
   *
   * @type {boolean}
   * @memberof RedirectionForm
   */
  disabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectionForm
   */
  deleted: boolean;
  /**
   *
   * @type {string}
   * @memberof RedirectionForm
   */
  defaultName: string;
  /**
   *
   * @type {Array<FormQuestionChildrenInner>}
   * @memberof RedirectionForm
   */
  children: Array<FormQuestionChildrenInner>;
  /**
   *
   * @type {string}
   * @memberof RedirectionForm
   */
  customerServiceNumber: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectionForm
   */
  coronaRestrictions: Array<string> | null;
  /**
   *
   * @type {RedirectionFormInfluenzaVaccineParams}
   * @memberof RedirectionForm
   */
  influenzaVaccineParams: RedirectionFormInfluenzaVaccineParams | null;
  /**
   *
   * @type {SkipLists}
   * @memberof RedirectionForm
   */
  skips: SkipLists;
}
/**
 *
 * @export
 * @interface RedirectionFormInfluenzaVaccineParams
 */
export interface RedirectionFormInfluenzaVaccineParams {
  /**
   *
   * @type {string}
   * @memberof RedirectionFormInfluenzaVaccineParams
   */
  specialistId?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectionFormInfluenzaVaccineParams
   */
  serviceId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ServicePermissionType = {
  KeyAccountPhysician: 'KeyAccountPhysician',
  CoordinatingOccupationalPhysician: 'CoordinatingOccupationalPhysician',
  NamedOccupationalHealthPhysician: 'NamedOccupationalHealthPhysician',
  Employer: 'Employer',
} as const;

export type ServicePermissionType = typeof ServicePermissionType[keyof typeof ServicePermissionType];

/**
 *
 * @export
 * @interface SkipLists
 */
export interface SkipLists {
  /**
   *
   * @type {Array<string>}
   * @memberof SkipLists
   */
  services: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SkipLists
   */
  specialists: Array<string>;
}
/**
 *
 * @export
 * @interface SpecialistData
 */
export interface SpecialistData {
  /**
   *
   * @type {string}
   * @memberof SpecialistData
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof SpecialistData
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpecialistData
   */
  LastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpecialistData
   */
  ImageUri?: string | null;
  /**
   *
   * @type {Array<LocalizedStr>}
   * @memberof SpecialistData
   */
  Title?: Array<LocalizedStr> | null;
  /**
   *
   * @type {SpecialistDataSubsystemIDs}
   * @memberof SpecialistData
   */
  SubsystemIDs?: SpecialistDataSubsystemIDs | null;
}
/**
 *
 * @export
 * @interface SpecialistDataSubsystemIDs
 */
export interface SpecialistDataSubsystemIDs {
  /**
   *
   * @type {string}
   * @memberof SpecialistDataSubsystemIDs
   */
  Osku?: string;
  /**
   *
   * @type {string}
   * @memberof SpecialistDataSubsystemIDs
   */
  Assisdent?: string;
  /**
   *
   * @type {string}
   * @memberof SpecialistDataSubsystemIDs
   */
  DynamicHealth?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const SpecialistReferralType = {
  GeneralPractitioner: 'GeneralPractitioner',
  OccupationalHealthPhysician: 'OccupationalHealthPhysician',
  NamedOccupationalHealthPhysician: 'NamedOccupationalHealthPhysician',
  OccupationalHealthNurse: 'OccupationalHealthNurse',
  OccupationalPhysicalTherapist: 'OccupationalPhysicalTherapist',
  CoordinatingOccupationalPhysician: 'CoordinatingOccupationalPhysician',
  KeyAccountPhysician: 'KeyAccountPhysician',
  NamedOccupationalHealthNurse: 'NamedOccupationalHealthNurse',
  NamedOccupationalPhysicalTherapist: 'NamedOccupationalPhysicalTherapist',
} as const;

export type SpecialistReferralType = typeof SpecialistReferralType[keyof typeof SpecialistReferralType];

/**
 *
 * @export
 * @interface SubSystemIds
 */
export interface SubSystemIds {
  /**
   *
   * @type {string}
   * @memberof SubSystemIds
   */
  Osku?: string;
  /**
   *
   * @type {string}
   * @memberof SubSystemIds
   */
  Assisdent?: string;
  /**
   *
   * @type {string}
   * @memberof SubSystemIds
   */
  DynamicHealth?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloCustomerAPIAPIAbstractionsV2CustomerAPIServiceAndSpecialtyPermission = {
  All: 'All',
  NotAllowed: 'NotAllowed',
  OnlyCompanySpecialistsAndClinics: 'OnlyCompanySpecialistsAndClinics',
  OnlyOwnSpecialistsAndClinics: 'OnlyOwnSpecialistsAndClinics',
} as const;

export type TerveystaloCustomerAPIAPIAbstractionsV2CustomerAPIServiceAndSpecialtyPermission = typeof TerveystaloCustomerAPIAPIAbstractionsV2CustomerAPIServiceAndSpecialtyPermission[keyof typeof TerveystaloCustomerAPIAPIAbstractionsV2CustomerAPIServiceAndSpecialtyPermission];

/**
 *
 * @export
 * @interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
 */
export interface TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic {
  /**
   *
   * @type {string}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  DynamicHealthCode?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsibleDoctorDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsibleNurseDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsiblePhysiotherapistDynamicHealthCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloCustomerAPIAPIAbstractionsV2CustomerOccupationalDataResponseClinic
   */
  ResponsiblePsychologistDynamicHealthCodes?: Array<string> | null;
}
/**
 *
 * @export
 * @interface UnauthorizedError
 */
export interface UnauthorizedError {
  /**
   *
   * @type {string}
   * @memberof UnauthorizedError
   */
  stack?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHtaTeamForm: async (lang?: Language, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/hta-team-form`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOccupationalContract: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/occupational-contract`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPIKey required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOccupationalData: async (lang?: Language, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/occupational-data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPIKey required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularServices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/popular-services`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dhCodes
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecialistsWithDhCodes: async (
      dhCodes: string,
      lang?: Language,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dhCodes' is not null or undefined
      assertParamExists('getSpecialistsWithDhCodes', 'dhCodes', dhCodes);
      const localVarPath = `/specialists-with-dh-codes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dhCodes !== undefined) {
        localVarQueryParameter['dhCodes'] = dhCodes;
      }

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} params URIComponent-encoded string of query parameters, supported: Clinic, City, Area, Service, Specialty, Specialist, e.g. Area%3D1%26Clinic%3D77%26Specialty%3D475
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transformOldToNewFilterParams: async (params: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'params' is not null or undefined
      assertParamExists('transformOldToNewFilterParams', 'params', params);
      const localVarPath = `/transform-old-params`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (params !== undefined) {
        localVarQueryParameter['params'] = params;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GiftCardValidateRequest} giftCardValidateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateGiftCard: async (
      giftCardValidateRequest: GiftCardValidateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'giftCardValidateRequest' is not null or undefined
      assertParamExists('validateGiftCard', 'giftCardValidateRequest', giftCardValidateRequest);
      const localVarPath = `/validate-gift-card`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPIKey required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        giftCardValidateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHtaTeamForm(
      lang?: Language,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectionForm>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHtaTeamForm(lang, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOccupationalContract(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickContractServicesOrOfficeHoursOnly>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOccupationalContract(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOccupationalData(
      lang?: Language,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OccupationalData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOccupationalData(lang, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPopularServices(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopularServices>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPopularServices(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dhCodes
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSpecialistsWithDhCodes(
      dhCodes: string,
      lang?: Language,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialistData>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialistsWithDhCodes(dhCodes, lang, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} params URIComponent-encoded string of query parameters, supported: Clinic, City, Area, Service, Specialty, Specialist, e.g. Area%3D1%26Clinic%3D77%26Specialty%3D475
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transformOldToNewFilterParams(
      params: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transformOldToNewFilterParams(params, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GiftCardValidateRequest} giftCardValidateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateGiftCard(
      giftCardValidateRequest: GiftCardValidateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardValidateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateGiftCard(giftCardValidateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHtaTeamForm(lang?: Language, options?: any): AxiosPromise<RedirectionForm> {
      return localVarFp.getHtaTeamForm(lang, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOccupationalContract(options?: any): AxiosPromise<PickContractServicesOrOfficeHoursOnly> {
      return localVarFp.getOccupationalContract(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOccupationalData(lang?: Language, options?: any): AxiosPromise<OccupationalData> {
      return localVarFp.getOccupationalData(lang, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularServices(options?: any): AxiosPromise<PopularServices> {
      return localVarFp.getPopularServices(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} dhCodes
     * @param {Language} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecialistsWithDhCodes(dhCodes: string, lang?: Language, options?: any): AxiosPromise<Array<SpecialistData>> {
      return localVarFp.getSpecialistsWithDhCodes(dhCodes, lang, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} params URIComponent-encoded string of query parameters, supported: Clinic, City, Area, Service, Specialty, Specialist, e.g. Area%3D1%26Clinic%3D77%26Specialty%3D475
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transformOldToNewFilterParams(params: string, options?: any): AxiosPromise<{ [key: string]: string }> {
      return localVarFp.transformOldToNewFilterParams(params, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GiftCardValidateRequest} giftCardValidateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateGiftCard(
      giftCardValidateRequest: GiftCardValidateRequest,
      options?: any,
    ): AxiosPromise<GiftCardValidateResponse> {
      return localVarFp.validateGiftCard(giftCardValidateRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {Language} [lang]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getHtaTeamForm(lang?: Language, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getHtaTeamForm(lang, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getOccupationalContract(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getOccupationalContract(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Language} [lang]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getOccupationalData(lang?: Language, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getOccupationalData(lang, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPopularServices(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPopularServices(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dhCodes
   * @param {Language} [lang]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getSpecialistsWithDhCodes(dhCodes: string, lang?: Language, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getSpecialistsWithDhCodes(dhCodes, lang, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} params URIComponent-encoded string of query parameters, supported: Clinic, City, Area, Service, Specialty, Specialist, e.g. Area%3D1%26Clinic%3D77%26Specialty%3D475
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transformOldToNewFilterParams(params: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .transformOldToNewFilterParams(params, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GiftCardValidateRequest} giftCardValidateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public validateGiftCard(giftCardValidateRequest: GiftCardValidateRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .validateGiftCard(giftCardValidateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
