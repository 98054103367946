import { CustomerType } from 'model/api';
import { Language } from 'translations/generated/translation-languages';
import { DirectCompensationItemData, InsuranceCompanies } from 'utils/partnerAndInsurance/types';
import { defineActionCreators } from 'utils/redux/actions';
import { BookingRoot } from 'utils/routes/routes';

export default defineActionCreators({
  changeLang(lang: Language, resetErrors = true) {
    return { lang, resetErrors };
  },
  changeBookingRoot(bookingRoot: BookingRoot | undefined) {
    return { bookingRoot };
  },
  changeCustomerTypeAddition(customerTypeAddition: CustomerType | undefined) {
    return { customerTypeAddition };
  },
  changeEmbedInApp(embedInApp: boolean) {
    return { embedInApp };
  },
  setMoveBookingWebCode(moveBookingWebCode: string | undefined) {
    return { moveBookingWebCode };
  },
  changeSelectedInsuranceCompany(selectedInsuranceCompany: InsuranceCompanies | undefined) {
    return { selectedInsuranceCompany };
  },
  changeSelectedDirectCompensation(selectedDirectCompensation: DirectCompensationItemData | undefined) {
    return { selectedDirectCompensation };
  },
  changeAgreementDirectCompensationDataFetch(agreementDirectCompensationDataFetch: boolean) {
    return { agreementDirectCompensationDataFetch };
  },
});
