import { ApiModelType } from 'model/api';
import { AuthError, AuthToken } from 'reducers/auth';
import { defineActionCreators } from 'utils/redux/actions';

export default defineActionCreators({
  authError(error: AuthError) {
    return { error };
  },
  setToken(token: AuthToken) {
    return { token };
  },
  updateToken(token: AuthToken) {
    return { token };
  },
  userDataLoading(token: AuthToken) {
    return { token };
  },
  newUser(data: ApiModelType['NotRegisteredUser'] | null, token: AuthToken) {
    return { data, token };
  },
  login(user: ApiModelType['User'], token: AuthToken) {
    return { user, token };
  },
  logout() {
    return {};
  },
  loadingAuthChange() {
    return {};
  },
});
