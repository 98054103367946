import { Link as DSLink } from '@terveystalo/design-system-react-components';
import React, { AnchorHTMLAttributes, FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { classNames } from 'utils/styles/helpers';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * Anchor (a) tag with link styling
 * to be used for non-internal addresses that do not need to open in a new tab, such as phone number links
 */
export const StyledLink: FC<AnchorProps> = props => <DSLink {...props} />;

/**
 * Properties to use when a link is supposed to open in a new tab
 */
const externalLinkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

/**
 * Unstyled Anchor element for external links that should open in a new tab
 */
export const ExternalLink: FC<AnchorProps> = ({ children, ...rest }) => (
  <a {...externalLinkProps} {...rest}>
    {children}
  </a>
);

/**
 * Link-styled Anchor element for external links that should open in a new tab
 */
export const ExternalStyledLink: FC<AnchorProps & { noIcon?: boolean }> = ({ noIcon, ...rest }) => (
  <DSLink {...externalLinkProps} isExternal={!noIcon} {...rest} />
);

/**
 * Link-styled Anchor element for internal links that are used with react-router
 */
export const InternalStyledLink: FC<LinkProps> = ({ className, ...rest }) => (
  <Link className={classNames('link', className)} {...rest} />
);
