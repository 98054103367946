import { ConfigModelType, mergeFrontendConfig } from 'model/config';
import React, { useMemo } from 'react';
import { getRedirectionServices, RedirectionService } from 'utils/config/redirection-services';

export type ConfigContextType = ConfigModelType['FrontendConfig'] & {
  redirectionServices: RedirectionService[];
};

export const ConfigContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const buildConfig = () => {
    const prefix = 'REACT_APP_AB_';
    const envs = {};

    Object.keys(process.env)
      .filter(key => key.startsWith(prefix))
      .forEach(key => Object.assign(envs, { [key.substring(prefix.length)]: process.env[key] }));
    return JSON.stringify(envs);
  };

  const createFrontendConfig = (runtimeOverrideSource = window.location.search) => {
    return mergeFrontendConfig(
      buildConfig(),
      // First, apply any config overrides from BUILD TIME
      process.env.REACT_APP_CONFIG,
      // Then, apply possible config overrides from DEPLOY TIME
      (document.querySelector('script[type="application/frontend-config"]') || { textContent: '' }).textContent,
      // Finally, apply any RUNTIME overrides; these are subjected to more scrutiny than other sources
      [runtimeOverrideSource, key => !!key.match(/^FEATURE_/)], // only allow changing feature flags
    );
  };

  const config = useMemo(
    () => {
      const legacyContext = createFrontendConfig();
      return { ...legacyContext, redirectionServices: getRedirectionServices() };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

// If no config overrides are used, this config will be used
const defaultConfig: Readonly<ConfigContextType> = {
  ONEWELCOME_CLIENT_ID: 'ONEWELCOME_CONFIG_NOT_SET',
  ONEWELCOME_OPENID_CONFIGURATION_URL: 'ONEWELCOME_CONFIG_NOT_SET',
  ONEWELCOME_AUTHORITY: 'ONEWELCOME_CONFIG_NOT_SET',
  ONEWELCOME_USE_FAKEBANK: false,
  ADA_API_KEY: '',
  API_ENDPOINT_UXAPI: 'API_URL_NOT_SET',
  API_ENDPOINT_SERVICEAPI_V2: 'API_URL_NOT_SET',
  API_ENDPOINT_SPECIALISTAPI_V2: 'API_URL_NOT_SET',
  API_ENDPOINT_CUSTOMERAPI_V2: 'API_URL_NOT_SET',
  API_ENDPOINT_APPOINTMENTAPI: 'API_URL_NOT_SET',
  API_ENDPOINT_CLINICAPI_V2: 'API_URL_NOT_SET',
  API_ENDPOINT_CHATAPI: 'API_URL_NOT_SET',
  API_ENDPOINT_INSURANCECOMPANYAPI: 'API_URL_NOT_SET',
  API_ENDPOINT_REDIRECTIONFORMAPI: 'API_URL_NOT_SET',
  API_ENDPOINT_PRICEAPI: 'API_URL_NOT_SET',
  ENV: '',
  MAINTENANCE_BREAK: false,
  CHAT_URL_BASE: '',
  MAP_SRC: '',
  ANALYTICS_ID: '',
  ANALYTICS_CONSENT_SRC: '',
  GEOCODING_API_KEY: '',
  APPLICATION_INSIGHTS_KEY: '',
  ADDITIONAL_FETCH_ANALYTICS: '',
  DEFAULT_SERVICE_ID: '',
  CHILD_DEFAULT_SERVICE_IDS: [],
  DEFAULT_CITY_ID: '',
  CHILD_DEFAULT_AREA_ID: '',
  OCCUPATIONAL_SERVICES_MAP: {},
  DENTAL_DEFAULT_SERVICE_ID: '',
  CALL_REQUEST_SERVICE_ID: '',
  CORONAVACCINE_SERVICE_ID: '',
  CORONAVACCINE_BOOSTER_SERVICE_IDS: [],
  OCCUPATIONAL_REDIRECT_FORM_CORONA_VACCINE_ID: '',
  USE_CORONA_VACCINE_GUIDE: false,
  FEATURE_CORONA_VACCINE_LINKS: false,
  USE_MANDATORY_GUIDE: false,
  HIDE_RESPIRATORY_SYMPTOMS_SURVEY: false,
  CORONA_SERVICES_MAP: {},
  AB_TEST_DATES: {},
  ALLOW_INSURANCE_PARTNER: true,
  ALLOW_PUBLIC_PARTNER: false,
  ALLOW_HTA_TEAM_PAGE: false,
  ALLOW_MIGRI_PARTNER: false,
  FEEDBACK_API_AUTH: {},
  FEATURE_LOGGING: false,
  FEATURE_ANALYTICS: false,
  FEATURE_CUSTOMER_SERVICE_CHAT: false,
  FEATURE_DISABLE_RM: false,
  FEATURE_CALL_REQUESTS: false,
  FEATURE_HIDE_FROM_LEGAL_GUARDIAN: false,
  FEATURE_PRIVATE_CUSTOMER_SEARCH_V2: false,
  FEATURE_USE_ONEWELCOME: false,
  redirectionServices: [],
};

export const ConfigContext = React.createContext<ConfigContextType>(defaultConfig);
