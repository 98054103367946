/** @jsxImportSource @emotion/react */
import React, { FC, Fragment } from 'react';
import { PhoneNumber } from 'ui/utils/PhoneNumber';

export const TextWithPhoneNumber: FC<{ text: string | null; replaceNumber?: string | null }> = ({
  text,
  replaceNumber,
}) => {
  const phoneNumMatch = text ? text.match(/030[\s0-9]{3,9}[0-9]/) : null;
  if (text && phoneNumMatch && phoneNumMatch.length) {
    const phoneNum = phoneNumMatch[0];
    const index = phoneNumMatch.index || text.indexOf(phoneNum);
    const callNum = replaceNumber || phoneNum;
    return (
      <Fragment>
        {text.slice(0, index)}
        <PhoneNumber numberOverride={callNum} />
        {text.slice(index + phoneNum.length).replace(/^\*/, '')}
      </Fragment>
    );
  }
  return <Fragment>{text}</Fragment>;
};
