/** @jsxImportSource @emotion/react */
import startsWith from 'lodash/startsWith';
import { parse, stringify } from 'query-string';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { RestrictionsState } from 'reducers/filters';
import { CoronaVaccineGuideContent } from 'ui/vaccine-guide/CoronaVaccineGuideContent';
import { CoronaVaccineGuideLander } from 'ui/vaccine-guide/CoronaVaccineGuideLander';
import { parseBookingParamsFromString } from 'utils/filters/helpers';
import { useConfig, useHistory, useReduxDispatch, useReduxState, useTranslatedRoute } from 'utils/react/ui-context';
import { routes } from 'utils/routes/routes';
import { getStorageObjectItem, removeStorageItem, setStorageObjectItem } from 'utils/storage/helpers';
import { useSkipMandatoryGuide } from 'utils/symptoms/helpers';

export const openParamName = 'vaccineGuideOpen';
export const storageKey = 'vaccineState';

export const useShowCoronaVaccineGuide = () => {
  // Use state directly instead of helper hook to access id faster and therefore reduce flashing of other content
  const specialistOrService = useReduxState(state => state.filters.specialistOrServiceFilter);

  const { CORONAVACCINE_SERVICE_ID, USE_CORONA_VACCINE_GUIDE } = useConfig();
  const vaccineState = useReduxState(state => state.notice.vaccineGuideState);
  const location = useLocation();

  if (
    !USE_CORONA_VACCINE_GUIDE ||
    !specialistOrService ||
    specialistOrService.type !== 'service' ||
    specialistOrService.serviceId !== CORONAVACCINE_SERVICE_ID
  ) {
    return false;
  }

  if (!vaccineState || !vaccineState.completed) {
    return true;
  }

  const { [openParamName]: openParam } = parse(location.search);
  return !!openParam;
};

export const useOpenVaccineGuide = () => {
  const dispatch = useReduxDispatch();
  const history = useHistory();
  const { CORONAVACCINE_SERVICE_ID } = useConfig();

  return () => {
    if (!history.location.search || history.location.state) {
      history.push({ ...history.location, state: undefined }); // Do a history event to cause correct browser back behaviour
    }
    removeStorageItem(storageKey);
    dispatch.filterByService({ type: 'service', serviceId: CORONAVACCINE_SERVICE_ID });
    dispatch.setVaccineGuideState(null);
  };
};

export const useResetVaccineGuide = () => {
  const dispatch = useReduxDispatch();

  return () => {
    removeStorageItem(storageKey);
    dispatch.setVaccineGuideState(null);
  };
};

export const useEffectResetVaccineGuide = () => {
  const showVaccineGuide = useShowCoronaVaccineGuide();
  const vaccineState = useReduxState(state => state.notice.vaccineGuideState);
  const dispatch = useReduxDispatch();

  const isAnswered = !showVaccineGuide && (!!getStorageObjectItem(storageKey) || !!vaccineState);

  useEffect(() => {
    if (isAnswered) {
      removeStorageItem(storageKey);
      dispatch.setVaccineGuideState(null);
    }
  }, [isAnswered, dispatch]);
};

export const useVaccineGuideStorageSync = (disabled?: boolean) => {
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const dispatch = useReduxDispatch();

  useEffect(() => {
    if (!disabled) {
      const storedState = getStorageObjectItem(storageKey);
      const choices = typeof storedState.choices === 'string' ? storedState.choices : '';
      const restrictions = parseBookingParamsFromString(
        typeof storedState.restrictions === 'string' ? storedState.restrictions : '',
      );
      if (storedState.type === 'occupational' && bookingRoot === 'occupationalHealthcare') {
        dispatch.setVaccineGuideState({ type: 'occupational', completed: true, choices });
        if (restrictions) dispatch.setRestrictions({ ...restrictions, instructions: null });
      } else if (
        storedState.type === 'municipality' &&
        (bookingRoot === 'voucher' || bookingRoot === 'publicPartner')
      ) {
        dispatch.setVaccineGuideState({ type: 'municipality', completed: true, choices });
        if (restrictions) dispatch.setRestrictions({ ...restrictions, instructions: null });
      } else if (storedState) {
        removeStorageItem(storageKey);
      }
    }
  }, [bookingRoot, dispatch, disabled]);
};

export const useVaccineGuideChoices = (serviceId: string | null) => {
  const vaccineState = useReduxState(state => state.notice.vaccineGuideState);
  const { CORONAVACCINE_SERVICE_ID } = useConfig();

  const isVaccineService = serviceId === CORONAVACCINE_SERVICE_ID;

  useVaccineGuideStorageSync(!isVaccineService);

  if (!isVaccineService) {
    return undefined;
  }

  return vaccineState && vaccineState.completed ? vaccineState.choices : undefined;
};

const storeGuideState = (type: 'occupational' | 'municipality', choices: string, restrictions?: string) =>
  setStorageObjectItem(storageKey, { type, choices, restrictions });

const CoronaVaccineGuide: FC = () => {
  const troute = useTranslatedRoute();
  const vaccineState = useReduxState(state => state.notice.vaccineGuideState);
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const dispatch = useReduxDispatch();
  const location = useLocation();
  const history = useHistory();
  const skipMandatoryGuide = useSkipMandatoryGuide();

  const { [openParamName]: openParam, ...rest } = parse(location.search);
  const openParamBasisOccupational = 'CO';
  const openParamBasisMunicipality = 'CM';
  const noTracking = rest.tracking === 'false';

  // Enable re-opening the guide with browser back navigation
  useEffect(() => {
    if (!!openParam && typeof openParam === 'string' && (!vaccineState || vaccineState.completed)) {
      removeStorageItem(storageKey);
      if (startsWith(openParam, openParamBasisOccupational)) {
        dispatch.setVaccineGuideState({ type: 'occupational', completed: false });
      } else if (startsWith(openParam, openParamBasisMunicipality)) {
        dispatch.setVaccineGuideState({ type: 'municipality', completed: false });
      } else {
        history.replace({
          pathname: history.location.pathname,
          search: stringify({ ...rest, [openParamName]: undefined }),
        });
      }
    } else if (!openParam && vaccineState && !vaccineState.completed) {
      removeStorageItem(storageKey);
      dispatch.setVaccineGuideState(null);
    }
  }, [openParam, vaccineState, dispatch, history, rest]);

  // Sync state from stogage always as this Guide component is only rendered when relevant
  useVaccineGuideStorageSync();

  if (vaccineState) {
    if (!vaccineState.completed) {
      return (
        <CoronaVaccineGuideContent
          openParamName={openParamName}
          openParamBasis={
            vaccineState.type === 'occupational' ? openParamBasisOccupational : openParamBasisMunicipality
          }
          noTracking={noTracking}
          onClose={(choiceTexts, restrictionsObj) => {
            const choices = choiceTexts.join(', ');
            const restrictions = restrictionsObj ? restrictionsToQueryString(restrictionsObj) : undefined;
            storeGuideState(vaccineState.type, choices, restrictions);
            dispatch.setVaccineGuideState({ ...vaccineState, completed: true, choices });
            skipMandatoryGuide();
          }}
        />
      );
    }
    if (vaccineState.type === 'occupational' && bookingRoot === 'occupationalHealthcare') {
      return null;
    } else if (vaccineState.type === 'municipality' && (bookingRoot === 'voucher' || bookingRoot === 'publicPartner')) {
      return null;
    }
  }

  return (
    <CoronaVaccineGuideLander
      defaultSelected={bookingRoot === 'publicPartner' ? 'municipality' : undefined}
      noTracking={noTracking}
      onContinue={root => {
        dispatch.setVaccineGuideState({ type: root, completed: false });
        skipMandatoryGuide();
        history.push({
          pathname: troute(
            routes[
              root === 'occupational'
                ? 'occupationalHealthcare'
                : bookingRoot === 'publicPartner'
                ? 'publicPartner'
                : 'voucher'
            ].root,
          ),
          search: stringify({
            ...rest,
            [openParamName]: root === 'occupational' ? openParamBasisOccupational : openParamBasisMunicipality,
          }),
        });
      }}
    />
  );
};

/** Transforms restrictions to the same format as is used in url filters */
function restrictionsToQueryString(restrictions: Exclude<RestrictionsState, null>) {
  const { appointmentTypes, clinicIds, ...rest } = restrictions;
  const asUrlFiltersObj = {
    ...rest,
    type: appointmentTypes,
    clinicId: clinicIds,
  };
  return stringify(asUrlFiltersObj);
}

export default CoronaVaccineGuide;
