/** @jsxImportSource @emotion/react */
import { SkeletonLine } from '@terveystalo/design-system-react-components';
import React, { FC } from 'react';
import { RedirectionFormRichText } from 'ui/redirection-form/RedirectionFormRichText';
import { classNames } from 'utils/styles/helpers';

export const RedirectionFormResultText: FC<{
  loading?: boolean;
  text: string | null;
  type?: 'default' | 'info' | 'subtitle';
  className?: string;
}> = ({ loading, text, type = 'default', className }) => (
  <div className={classNames(type === 'info' && 'inline', className)}>
    {loading ? (
      <SkeletonLine width="80%" className={classNames('my-1.5')} />
    ) : (
      <RedirectionFormRichText
        text={text}
        className={classNames(
          type === 'subtitle' ? 'text-style-subtitle-xs' : type !== 'info' && 'text-body-l',
          type === 'info' && 'inline',
        )}
      />
    )}
  </div>
);
