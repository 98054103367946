import * as t from 'io-ts';
import { ConfigLayer, mergeConfig } from 'utils/config/config-utils';
import { defineModels, ModelType } from 'utils/types/models';

// Define a category of Models for storing configuration
const ConfigModel = defineModels({
  FrontendConfig: {
    ONEWELCOME_CLIENT_ID: t.string,
    ONEWELCOME_OPENID_CONFIGURATION_URL: t.string,
    ONEWELCOME_AUTHORITY: t.string,
    ONEWELCOME_USE_FAKEBANK: t.boolean,
    ADA_API_KEY: t.string,
    API_ENDPOINT_UXAPI: t.string,
    // See https://tt.cbyte.fi/confluence/display/ADA/3.+API+Services for endpoint listing
    API_ENDPOINT_SERVICEAPI_V2: t.string,
    API_ENDPOINT_SPECIALISTAPI_V2: t.string,
    API_ENDPOINT_CUSTOMERAPI_V2: t.string,
    API_ENDPOINT_APPOINTMENTAPI: t.string,
    API_ENDPOINT_CLINICAPI_V2: t.string,
    API_ENDPOINT_CHATAPI: t.string,
    API_ENDPOINT_INSURANCECOMPANYAPI: t.string,
    API_ENDPOINT_REDIRECTIONFORMAPI: t.string,
    API_ENDPOINT_PRICEAPI: t.string,
    ENV: t.string,
    MAINTENANCE_BREAK: t.boolean,
    CHAT_URL_BASE: t.string,
    MAP_SRC: t.string,
    ANALYTICS_ID: t.string,
    ANALYTICS_CONSENT_SRC: t.string,
    GEOCODING_API_KEY: t.string,
    APPLICATION_INSIGHTS_KEY: t.string,
    ADDITIONAL_FETCH_ANALYTICS: t.string,
    // Default selection and static list ids
    DEFAULT_SERVICE_ID: t.string,
    CHILD_DEFAULT_SERVICE_IDS: t.array(t.string),
    DEFAULT_CITY_ID: t.string,
    CHILD_DEFAULT_AREA_ID: t.string,
    OCCUPATIONAL_SERVICES_MAP: t.record(t.string, t.string),
    DENTAL_DEFAULT_SERVICE_ID: t.string,
    CALL_REQUEST_SERVICE_ID: t.string,
    CORONAVACCINE_SERVICE_ID: t.string,
    CORONAVACCINE_BOOSTER_SERVICE_IDS: t.array(t.string),
    OCCUPATIONAL_REDIRECT_FORM_CORONA_VACCINE_ID: t.string,
    USE_CORONA_VACCINE_GUIDE: t.boolean,
    FEATURE_CORONA_VACCINE_LINKS: t.boolean,
    // Use a mandatory guide on front page, also called Corona-popup
    USE_MANDATORY_GUIDE: t.boolean,
    // Hides the respiratory symptoms survey (also known as page 0) from mandatory quide
    HIDE_RESPIRATORY_SYMPTOMS_SURVEY: t.boolean,
    CORONA_SERVICES_MAP: t.record(t.string, t.string),
    // Start and end dates for ab tests: dates in format "2020-01-01;2020-02-01" (both optional, start inclusive, end not)
    AB_TEST_DATES: t.record(t.string, t.string),
    // Enable insurance partner booking
    ALLOW_INSURANCE_PARTNER: t.boolean,
    // Enable puclic partner booking
    ALLOW_PUBLIC_PARTNER: t.boolean,
    // Enable redirection form page for HTA team use
    ALLOW_HTA_TEAM_PAGE: t.boolean,
    // Enable Migri partner booking
    ALLOW_MIGRI_PARTNER: t.boolean,
    FEEDBACK_API_AUTH: t.record(t.string, t.string),
    // Feature flags: these (and only these!) values can be changed during runtime via the query string.
    // The distinction is made by the config key name: keys that match /^FEATURE_/ are allowed, others not.
    FEATURE_LOGGING: t.boolean,
    FEATURE_ANALYTICS: t.boolean,
    FEATURE_CUSTOMER_SERVICE_CHAT: t.boolean,
    FEATURE_DISABLE_RM: t.boolean, // controls disabling Revenue management in local and test envs
    FEATURE_CALL_REQUESTS: t.boolean,
    FEATURE_HIDE_FROM_LEGAL_GUARDIAN: t.boolean,
    FEATURE_USE_ONEWELCOME: t.boolean,
    FEATURE_PRIVATE_CUSTOMER_SEARCH_V2: t.boolean,
  },
});

// Export the compile-time types with a separate name, so both them and the run-time types can be imported at the same time, where needed
export type ConfigModelType = ModelType<typeof ConfigModel>;

// If no config overrides are used, this config will be used
const defaultConfig: Readonly<ConfigModelType> = {
  FrontendConfig: {
    ONEWELCOME_CLIENT_ID: 'ONEWELCOME_CONFIG_NOT_SET',
    ONEWELCOME_OPENID_CONFIGURATION_URL: 'ONEWELCOME_CONFIG_NOT_SET',
    ONEWELCOME_AUTHORITY: 'ONEWELCOME_CONFIG_NOT_SET',
    ONEWELCOME_USE_FAKEBANK: false,
    ADA_API_KEY: '',
    API_ENDPOINT_UXAPI: 'API_URL_NOT_SET',
    API_ENDPOINT_SERVICEAPI_V2: 'API_URL_NOT_SET',
    API_ENDPOINT_SPECIALISTAPI_V2: 'API_URL_NOT_SET',
    API_ENDPOINT_CUSTOMERAPI_V2: 'API_URL_NOT_SET',
    API_ENDPOINT_APPOINTMENTAPI: 'API_URL_NOT_SET',
    API_ENDPOINT_CLINICAPI_V2: 'API_URL_NOT_SET',
    API_ENDPOINT_CHATAPI: 'API_URL_NOT_SET',
    API_ENDPOINT_INSURANCECOMPANYAPI: 'API_URL_NOT_SET',
    API_ENDPOINT_REDIRECTIONFORMAPI: 'API_URL_NOT_SET',
    API_ENDPOINT_PRICEAPI: 'API_URL_NOT_SET',
    ENV: '',
    MAINTENANCE_BREAK: false,
    CHAT_URL_BASE: '',
    MAP_SRC: '',
    ANALYTICS_ID: '',
    ANALYTICS_CONSENT_SRC: '',
    GEOCODING_API_KEY: '',
    APPLICATION_INSIGHTS_KEY: '',
    ADDITIONAL_FETCH_ANALYTICS: '',
    DEFAULT_SERVICE_ID: '',
    CHILD_DEFAULT_SERVICE_IDS: [],
    DEFAULT_CITY_ID: '',
    CHILD_DEFAULT_AREA_ID: '',
    OCCUPATIONAL_SERVICES_MAP: {},
    DENTAL_DEFAULT_SERVICE_ID: '',
    CALL_REQUEST_SERVICE_ID: '',
    CORONAVACCINE_SERVICE_ID: '',
    CORONAVACCINE_BOOSTER_SERVICE_IDS: [],
    OCCUPATIONAL_REDIRECT_FORM_CORONA_VACCINE_ID: '',
    USE_CORONA_VACCINE_GUIDE: false,
    FEATURE_CORONA_VACCINE_LINKS: false,
    USE_MANDATORY_GUIDE: false,
    HIDE_RESPIRATORY_SYMPTOMS_SURVEY: false,
    CORONA_SERVICES_MAP: {},
    AB_TEST_DATES: {},
    ALLOW_INSURANCE_PARTNER: true,
    ALLOW_PUBLIC_PARTNER: false,
    ALLOW_HTA_TEAM_PAGE: false,
    ALLOW_MIGRI_PARTNER: false,
    FEEDBACK_API_AUTH: {},
    FEATURE_LOGGING: false,
    FEATURE_ANALYTICS: false,
    FEATURE_CUSTOMER_SERVICE_CHAT: false,
    FEATURE_DISABLE_RM: false,
    FEATURE_CALL_REQUESTS: false,
    FEATURE_HIDE_FROM_LEGAL_GUARDIAN: false,
    FEATURE_PRIVATE_CUSTOMER_SEARCH_V2: false,
    FEATURE_USE_ONEWELCOME: false,
  },
};

// Convenience function for using mergeConfig() to produce a FrontendConfig, as that's what it's almost always used for
export function mergeFrontendConfig(...layers: ConfigLayer[]): ConfigModelType['FrontendConfig'] {
  return mergeConfig(ConfigModel.FrontendConfig as any, defaultConfig.FrontendConfig, layers);
}
