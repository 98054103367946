import React, { FC } from 'react';
import { HeaderMain } from 'ui/header-and-footer/HeaderMain';
import { PartnerHeader } from 'ui/header-and-footer/PartnerHeader';
import { useLogoutButtonClick } from 'ui/login/LogoutButton';
import { Environment, useHistory, useReduxState, useTranslatedRoute } from 'utils/react/ui-context';
import { BookingRoot, isPartnerRoot, routes } from 'utils/routes/routes';

type Props = {
  titleOverride?: string;
  bookingRoot: BookingRoot | undefined;
  environment: Environment;
};

export const Header: FC<Props> = ({ bookingRoot, titleOverride, environment }) => {
  const troute = useTranslatedRoute();
  const auth = useReduxState(state => state.auth);
  const history = useHistory();
  const onLogoutClick = useLogoutButtonClick();

  if (bookingRoot && isPartnerRoot(bookingRoot)) {
    return (
      <PartnerHeader
        bookingRoot={bookingRoot}
        titleOverride={titleOverride}
        envText={environment !== 'prod' ? environment : undefined}
      />
    );
  }

  return (
    <HeaderMain
      auth={auth}
      environment={environment}
      bookingRoot={bookingRoot}
      titleOverride={titleOverride}
      onLoginClick={() => {
        const pathname = history.location.pathname;
        if (pathname !== troute(routes.logIn)) {
          history.push(
            troute(routes.logIn),
            pathname !== troute(routes.loggedOut) && pathname !== troute(routes.autoLoggedOut)
              ? { prevRoute: pathname }
              : undefined,
          );
        }
      }}
      onLogoutClick={onLogoutClick}
    />
  );
};
