/** @jsxImportSource @emotion/react */
import { SessionExpirationDialog, SessionTimeoutDialog } from '@terveystalo/design-system-react-organisms';
import React, { FC, useEffect, useState } from 'react';
import { useLogoutButtonClick } from 'ui/login/LogoutButton';
import { useLoginType } from 'utils/auth/helpers';
import { useReduxState, useUiContext } from 'utils/react/ui-context';

export const AuthTimeoutTracker: FC = () => {
  const dialogType = useAuthTimeoutDialog();
  if (!useIsPast(dialogType.showDialogAt)) {
    return null;
  }

  switch (dialogType.type) {
    case 'notracking':
      return null;
    case 'expiration':
      return <ExpirationDialog sessionEndsAt={dialogType.sessionEndsAt} />;
    case 'timeout':
      return <TimeoutDialog sessionEndsAt={dialogType.sessionEndsAt} />;
  }
};

type Props = { sessionEndsAt: number };
export const ExpirationDialog: FC<Props> = ({ sessionEndsAt }) => {
  const lang = useReduxState(state => state.location.lang);

  // Performs first a logout as the authentication session needs to be recreated.
  // Only doing a login will not extend the OneWelcome session, even though authentication is enforced.
  const relogin = useLogoutButtonClick(true);
  const logout = useLogoutButtonClick();

  return (
    <SessionExpirationDialog
      open={true}
      language={lang}
      timeoutAt={sessionEndsAt}
      onReLogin={relogin}
      onLogout={logout}
    />
  );
};

export const TimeoutDialog: FC<Props> = ({ sessionEndsAt }) => {
  const lang = useReduxState(state => state.location.lang);
  const { oneWelcomeClient } = useUiContext();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [refreshFailed, setRefreshFailed] = useState(false);

  const logout = useLogoutButtonClick();

  const onContinue = async () => {
    setRefreshFailed(false);
    setIsRefreshing(true);

    try {
      if (!oneWelcomeClient) {
        throw new Error('No authClient where expected!');
      }
      oneWelcomeClient ? oneWelcomeClient.refreshSession() : null;
    } catch {
      setIsRefreshing(false);
      setRefreshFailed(true);
    }
  };

  return (
    <SessionTimeoutDialog
      open={true}
      language={lang}
      timeoutAt={sessionEndsAt}
      onContinue={onContinue}
      continueState={isRefreshing ? { type: 'Loading' } : refreshFailed ? { type: 'Error' } : { type: 'Success' }}
      onLogout={logout}
    />
  );
};

/**
 * Number of minutes before session timeout at which the session timeout
 * notification dialog is shown to the user (same value as in Terveystalo Online)
 */
const timeoutDialogDurationMinutes = 2;
/**
 * Minimum reasonable session duration in minutes to allow user to refresh the session
 * (same value as in Terveystalo Online)
 */
const minimumReasonableSessionDurationMinutes = 0.5;

type TimeoutDialogType =
  | { type: 'timeout' | 'expiration'; showDialogAt: number; sessionEndsAt: number }
  | { type: 'notracking'; showDialogAt?: number };
function useAuthTimeoutDialog(): TimeoutDialogType {
  const authType = useLoginType();
  const embedInApp = useReduxState(state => state.location.embedInApp);
  const { oneWelcomeClient } = useUiContext();
  const [dialogType, setDialogType] = useState<TimeoutDialogType>({ type: 'notracking' });

  // OneWelcome version
  useEffect(() => {
    if (authType === 'oneWelcome' && !embedInApp && oneWelcomeClient) {
      return oneWelcomeClient.listenToAuthState((timeoutAt, expiresAt) => {
        if (!timeoutAt || !expiresAt) {
          setDialogType({ type: 'notracking' });
        } else {
          const sessionEndsAt = Math.min(expiresAt.getTime(), timeoutAt.getTime());
          const showDialogAt = sessionEndsAt - timeoutDialogDurationMinutes * 60 * 1000;

          // Allow user to continue session only if there will still be a reasonable amount of time
          // after extending the session before the session expiry dialog will kick in
          const reasonableTimeTillExpiry =
            expiresAt.getTime() - (timeoutDialogDurationMinutes + minimumReasonableSessionDurationMinutes) * 60 * 1000;
          const type = sessionEndsAt < reasonableTimeTillExpiry ? 'timeout' : 'expiration';
          setDialogType({ type, showDialogAt, sessionEndsAt });
        }
      });
    }
  }, [oneWelcomeClient, authType, embedInApp]);

  if (authType !== 'oneWelcome' || embedInApp) {
    return { type: 'notracking' };
  }

  return dialogType;
}

/**
 * React hook that tracks whether the current time is past the given timestamp.
 *
 * Use timeouts internally to update the state automatically.
 *
 * @param timestamp Milliseconds since epoch
 * @returns `true` if the current date is after the given `timestamp`.
 *          Always returns `false` if `timestamp` is `undefined`.
 */
function useIsPast(timestamp: number | undefined): boolean {
  const getCurrentTime = useUiContext().getCurrentTime;
  const isPastAtInvocation = timestamp !== undefined && getCurrentTime() > timestamp;
  const [isPast, setIsPast] = useState(isPastAtInvocation);

  // Update state immediately if a new timestamp has been passed
  if (isPast !== isPastAtInvocation) {
    setIsPast(isPastAtInvocation);
  }

  useEffect(() => {
    if (timestamp === undefined) {
      return;
    }

    const diff = timestamp - getCurrentTime();
    if (diff > 0) {
      const timeoutId = setTimeout(() => setIsPast(true), diff);
      return () => clearTimeout(timeoutId);
    }
  }, [timestamp, getCurrentTime]);

  return isPast;
}
