/** @jsxImportSource @emotion/react */
import {
  Button,
  CheckboxItem,
  FormFieldLabel,
  List,
  ListItem,
  TextButton,
} from '@terveystalo/design-system-react-components';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { VaccineGuideRoot } from 'reducers/notice';
import { ExternalLink } from 'ui/utils/LinkHelper';
import { WarningCard } from 'ui/utils/WarningCard';
import { CoronaVaccineGuideWrapper } from 'ui/vaccine-guide/CoronaVaccineGuideWrapper';
import {
  useHistory,
  useReduxDispatch,
  useReduxState,
  useTranslatedRoute,
  useTranslations,
  useUiContext,
} from 'utils/react/ui-context';
import { isPartnerRoot, routes } from 'utils/routes/routes';
import { classNames } from 'utils/styles/helpers';

export const CoronaVaccineGuideLander: FC<{
  defaultSelected?: VaccineGuideRoot;
  noTracking?: boolean;
  onContinue: (type: VaccineGuideRoot) => void;
}> = ({ defaultSelected, noTracking, onContinue }) => {
  const t = useTranslations();
  const troute = useTranslatedRoute();
  const dispatch = useReduxDispatch();
  const { bookingRoot, embedInApp } = useReduxState(state => state.location);
  const history = useHistory();
  const analytics = useUiContext().analytics;

  const confirmOptions = ['occupational' as const, 'municipality' as const, 'none' as const];
  const [confirmed, setConfirmed] = useState<typeof confirmOptions[number] | undefined>(defaultSelected);

  const firstViewSent = useRef(false);
  useEffect(() => {
    if (!firstViewSent.current && !noTracking) {
      analytics.trackCoronaVaccineGuide(undefined, { type: 'show' }); // We do not know the formId yet
      firstViewSent.current = true;
    }
  }, [analytics, noTracking]);

  return (
    <CoronaVaccineGuideWrapper>
      <form
        noValidate={true}
        onSubmit={event => {
          event.preventDefault();
          if (!confirmed || confirmed === 'none') {
            return; // prevent submit if the user has not selected a valid option
          }
          if (!noTracking) {
            analytics.trackCoronaVaccineGuide(undefined, { type: 'start', choice: confirmed });
          }
          onContinue(confirmed);
        }}
        className={classNames('mb-3')}
      >
        <h1 className={classNames('text-style-subtitle-mplus', 's:text-subtitle-l', 'mb-1', 'text-matisse-blue')}>
          {t.welcome_to_book_corona_vaccination}
        </h1>
        <p className={classNames('mb-1')}>{t.corona_vaccinations_done_gradually}</p>
        <p className={classNames('mb-1')}>{t.we_ask_questions_corona_vaccination_order}</p>
        <fieldset className={classNames('mb-2')} id="corona-vaccination-instructed">
          <FormFieldLabel as="legend" label={t.choose_best} fieldId="corona-vaccination-instructed" />
          {confirmOptions.map(o => (
            <CheckboxItem
              key={`confirm-${o}`}
              name={`confirm-${o}`}
              grayBackground={true}
              label={parseEmphasis(
                o === 'none'
                  ? t.no_instruction_to_corona_vaccination
                  : o === 'occupational'
                  ? t.my_employer_instructed_corona_vaccination
                  : t.my_municipality_instructed_corona_vaccination,
              )}
              checked={confirmed === o}
              onChange={() => setConfirmed(confirmed === o ? undefined : o)}
            />
          ))}
        </fieldset>
        {confirmed === 'none' ? (
          <WarningCard text={t.you_cannot_book_corona_vaccination} />
        ) : (
          !!confirmed && (
            <Fragment>
              <p className={classNames('mb-0.75', 'text-center', 'font-bold')}>
                {t.i_guarantee_i_have_rights_to_corona_vaccination}
              </p>
              <Button as="button" type="submit" icon="ArrowRight" className={classNames('w-full')}>
                {t.continue_to_booking}
              </Button>
            </Fragment>
          )
        )}
      </form>
      <List>
        <ListItem
          as={ExternalLink}
          href={t.ext_link_corona_vaccination}
          iconLeft="Info"
          iconRight="ArrowRight"
          onWhiteBackground={true}
        >
          {t.vaccination_order_by_municipality}
        </ListItem>
        <ListItem
          as={ExternalLink}
          href={t.ext_link_additional_corona_vaccination_info}
          iconLeft="Vaccination"
          iconRight="ArrowRight"
          onWhiteBackground={true}
        >
          {t.more_information}
        </ListItem>
      </List>
      {!embedInApp && (
        <TextButton
          as="button"
          type="button"
          iconLeft="ArrowLeft"
          onClick={() => {
            history.push(troute(bookingRoot && isPartnerRoot(bookingRoot) ? routes[bookingRoot].root : routes.root));
            dispatch.filterByService(null);
          }}
          className={classNames('mt-3')}
        >
          {t.to_booking_front_page}
        </TextButton>
      )}
    </CoronaVaccineGuideWrapper>
  );
};

function parseEmphasis(text: string) {
  return (
    <span>{text.split('**').map((part, i) => (i === 1 ? <strong key={`${part}-${i}`}>{part}</strong> : part))}</span>
  );
}
