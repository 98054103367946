/** @jsxImportSource @emotion/react */
import { imageUrl } from '@terveystalo/design-system-cdn';
import React, { FC } from 'react';
import { classNames } from 'utils/styles/helpers';

export const LoadingPageContent: FC<{ translations: { loading: string }; descriptionText?: string }> = ({
  translations,
  descriptionText,
}) => (
  <div className={classNames('py-6', 's:py-9', 'text-center')}>
    <img
      src={imageUrl('icons', 'Spinner')}
      alt={translations.loading}
      className={classNames('mx-auto', 'h-5', 'w-5')}
    />
    {Boolean(descriptionText) && <p className={classNames('mt-1')}>{descriptionText}</p>}
  </div>
);
