import { createAdaApiClient } from 'api/AdaApiClient';
import { createChatApiClient } from 'api/ChatApiClient';
import { createGeocodingApiClient } from 'api/GeocodingApiClient';
import { createPartnerPermissionApiClient } from 'api/PartnerPermissionApiClient';
import { createUxApiClient } from 'api/UxApiClient';
import axios from 'axios';
import React, { useContext, useMemo } from 'react';
import { AnalyticsContext } from 'utils/analytics/AnalyticsContextProvider';
import { ConfigContext } from 'utils/config/ConfigContextProvider';

export type ApiClient = ReturnType<typeof createUxApiClient> &
  ReturnType<typeof createAdaApiClient> &
  ReturnType<typeof createChatApiClient> &
  ReturnType<typeof createPartnerPermissionApiClient> &
  ReturnType<typeof createGeocodingApiClient>;

export const ApiClientContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { analytics } = useContext(AnalyticsContext);
  const config = useContext(ConfigContext);

  const apiClient = useMemo(() => {
    if (!config) {
      return defaultClient;
    }
    return {
      ...createUxApiClient(config, axios, analytics),
      ...createAdaApiClient(config, axios, analytics),
      ...createChatApiClient(config, axios, analytics),
      ...createPartnerPermissionApiClient(config, axios),
      ...createGeocodingApiClient(config, axios, analytics),
    };
  }, [analytics, config]);

  return <ApiClientContext.Provider value={apiClient}>{children}</ApiClientContext.Provider>;
};

const defaultClient = {} as ApiClient;

export const ApiClientContext = React.createContext<ApiClient>(defaultClient);
