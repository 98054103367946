import noop from 'lodash/noop';
import { createApplicationInsightsClient } from 'utils/analytics/application-insights';
import { createPiwikAnalyticsClient } from 'utils/analytics/piwik-analytics';

type AIMethods = ReturnType<typeof createApplicationInsightsClient>;
type PiwikMethods = ReturnType<typeof createPiwikAnalyticsClient>;

export type Analytics = {
  sendFetchDetails: AIMethods['sendFetchDetails'];
  trackPageView: PiwikMethods['trackPageView'];
  sendInitialDataLoaded: AIMethods['sendInitialDataLoaded'];
  sendFetchBatchMetrics: AIMethods['sendFetchBatchMetrics'];
  sendDataConversionError: AIMethods['sendDataConversionError'];
  trackBookingProcess: PiwikMethods['trackBookingProcess'];
  trackAppointmentsSearch: PiwikMethods['trackAppointmentsSearch'];
  trackAlternativeServicesAppointmentsSearch: PiwikMethods['trackAlternativeServicesAppointmentsSearch'];
  trackOccupationalLanderSelection: PiwikMethods['trackOccupationalLanderSelection'];
  trackPartnerBookingProcess: PiwikMethods['trackPartnerBookingProcess'];
  sendStrongAuthError: AIMethods['sendStrongAuthError'];
  trackOccupationalDisallowed: PiwikMethods['trackOccupationalDisallowed'];
  sendGeoLocationSearch: AIMethods['sendGeoLocationSearch'];
  trackGuideModal: PiwikMethods['trackGuideModal'];
  sendInvalidRedirectionFormParams: AIMethods['sendInvalidRedirectionFormParams'];
  trackOccupationalRedirectionForm: PiwikMethods['trackOccupationalRedirectionForm'];
  trackOccupationalPersonalization: PiwikMethods['trackOccupationalPersonalization'];
  trackCoronaVaccineGuide: PiwikMethods['trackCoronaVaccineGuide'];
  trackHtaTeamForm: PiwikMethods['trackHtaTeamForm'];
  sendBookingFailureDetails: AIMethods['sendBookingFailureDetails'];
  sendStorageNotEnabled: AIMethods['sendStorageNotEnabled'];
  sendMaintenanceBreakPageShown: AIMethods['sendMaintenanceBreakPageShown'];
  trackManageBooking: PiwikMethods['trackManageBooking'];
  trackEditBooking: PiwikMethods['trackEditBooking'];
  trackCancelBooking: PiwikMethods['trackCancelBooking'];
  sendCustomerServiceChatObserverFailed: AIMethods['sendCustomerServiceChatObserverFailed'];
  sendOccupationalWebReservationDeniedFound: AIMethods['sendOccupationalWebReservationDeniedFound'];
  trackFrontPageClick: PiwikMethods['trackFrontPageClick'];
  trackClickManageBooking: PiwikMethods['trackClickManageBooking'];
  trackOpenSpecialistModal: PiwikMethods['trackOpenSpecialistModal'];
  trackOpenClinicModal: PiwikMethods['trackOpenClinicModal'];
  trackServiceBannerClick: PiwikMethods['trackServiceBannerClick'];
  trackRelaLinkClick: PiwikMethods['trackRelaLinkClick'];
  trackRehabLinkClick: PiwikMethods['trackRehabLinkClick'];
  sendAppError: AIMethods['sendAppError'];
  trackServiceDescriptionExpandClick: PiwikMethods['trackServiceDescriptionExpandClick'];
  trackUserIsLoggedIn: PiwikMethods['trackUserIsLoggedIn'];
  trackRemoteAppoinmentsAvailableBanner: PiwikMethods['trackRemoteAppoinmentsAvailableBanner'];
  trackThankYouCheckYourCustomerInformation: PiwikMethods['trackThankYouCheckYourCustomerInformation'];
  trackThankYouGoToTerveystaloComFrontPage: PiwikMethods['trackThankYouGoToTerveystaloComFrontPage'];
  trackThankYouGoToTerveystaloForTeens: PiwikMethods['trackThankYouGoToTerveystaloForTeens'];
  trackGiftCardSuccess: PiwikMethods['trackGiftCardSuccess'];
  trackInsuranceCompanySelected: PiwikMethods['trackInsuranceCompanySelected'];
  trackDirectCompensationPreview: PiwikMethods['trackDirectCompensationPreview'];
  trackIcChooseAppointmentType: PiwikMethods['trackIcChooseAppointmentType'];
  trackDirectCompensationError: PiwikMethods['trackDirectCompensationError'];
};

export const NO_ANALYTICS: Analytics = {
  sendFetchDetails: noop,
  trackPageView: noop,
  sendInitialDataLoaded: noop,
  sendFetchBatchMetrics: noop,
  sendDataConversionError: noop,
  trackBookingProcess: noop,
  trackAppointmentsSearch: noop,
  trackAlternativeServicesAppointmentsSearch: noop,
  trackOccupationalLanderSelection: noop,
  trackPartnerBookingProcess: noop,
  sendStrongAuthError: noop,
  trackOccupationalDisallowed: noop,
  sendGeoLocationSearch: noop,
  trackGuideModal: noop,
  sendInvalidRedirectionFormParams: noop,
  trackOccupationalRedirectionForm: noop,
  trackOccupationalPersonalization: noop,
  trackCoronaVaccineGuide: noop,
  trackHtaTeamForm: noop,
  sendBookingFailureDetails: noop,
  sendStorageNotEnabled: noop,
  sendMaintenanceBreakPageShown: noop,
  trackManageBooking: noop,
  trackEditBooking: noop,
  trackCancelBooking: noop,
  sendCustomerServiceChatObserverFailed: noop,
  sendOccupationalWebReservationDeniedFound: noop,
  trackFrontPageClick: noop,
  trackClickManageBooking: noop,
  trackOpenSpecialistModal: noop,
  trackOpenClinicModal: noop,
  trackServiceBannerClick: noop,
  trackRelaLinkClick: noop,
  trackRehabLinkClick: noop,
  sendAppError: noop,
  trackServiceDescriptionExpandClick: noop,
  trackUserIsLoggedIn: noop,
  trackRemoteAppoinmentsAvailableBanner: noop,
  trackThankYouCheckYourCustomerInformation: noop,
  trackThankYouGoToTerveystaloComFrontPage: noop,
  trackThankYouGoToTerveystaloForTeens: noop,
  trackGiftCardSuccess: noop,
  trackInsuranceCompanySelected: noop,
  trackDirectCompensationPreview: noop,
  trackIcChooseAppointmentType: noop,
  trackDirectCompensationError: noop,
};
