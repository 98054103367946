/** @jsxImportSource @emotion/react */
import { Button } from '@terveystalo/design-system-react-components';
import { ApiModelType } from 'model/api';
import React, { FC, Fragment, useEffect, useRef } from 'react';
import { RestrictionsState } from 'reducers/filters';
import { RedirectionFormResultText } from 'ui/redirection-form/RedirectionFormResultText';
import { TextWithPhoneNumber } from 'ui/utils/TextWithPhoneNumber';
import { useValidateBookingParams } from 'utils/filters/helpers';
import { useReduxDispatch, useTranslations } from 'utils/react/ui-context';
import { classNames, smallerButtonTextCss } from 'utils/styles/helpers';

export const RedirectionFormBookingResult: FC<{
  text: string | null;
  button: ApiModelType['RedirectionFormButton'];
  disabled: boolean;
  replaceNumber?: string | null;
  additionalParams?: string;
  onButtonClick: (restrictions: RestrictionsState) => void;
  sendInvalidParamsAnalytics: () => void;
}> = ({ text, button, disabled, replaceNumber, additionalParams, onButtonClick, sendInvalidParamsAnalytics }) => {
  const t = useTranslations();
  const dispatch = useReduxDispatch();
  const analyticsSent = useRef(false);

  const { bookingParams, loading, isInvalid } = useValidateBookingParams(
    button.params || additionalParams ? `${additionalParams || ''}&${button.params || ''}` : null,
  );

  useEffect(() => {
    if (isInvalid && !analyticsSent.current) {
      sendInvalidParamsAnalytics();
      analyticsSent.current = true;
    }
  }, [isInvalid, sendInvalidParamsAnalytics]);

  return (
    <Fragment>
      <RedirectionFormResultText text={text} className={classNames(!!text && 'mb-1')} />
      {!disabled && (
        <Button
          as="button"
          icon={loading ? 'Spinner' : 'ArrowRight'}
          className={classNames('w-full')}
          css={smallerButtonTextCss}
          onClick={isInvalid || loading ? undefined : onClick}
          disabled={!!isInvalid}
        >
          {button.text || button.defaultText}
        </Button>
      )}
      {!disabled && isInvalid && !loading && (
        <div className={classNames('mt-0.75', 'mb-2', 'text-center')}>
          <TextWithPhoneNumber
            text={`${t.error}. ${t.call_our_booking_services} ${t.tel_only_number}.`}
            replaceNumber={replaceNumber}
          />
        </div>
      )}
    </Fragment>
  );

  function onClick() {
    const restrictions = { ...(bookingParams || {}), instructions: text };
    dispatch.setRestrictions(restrictions);
    if (!!bookingParams) {
      const { specialistId, serviceId, clinicIds, cityId, areaId, appointmentTypes } = bookingParams;

      // Set initial search filters based on most dominant values
      if (specialistId) {
        dispatch.filterByService({ type: 'specialist', specialistId });
      } else if (serviceId) {
        dispatch.filterByService({ type: 'service', serviceId });
      }
      if (clinicIds) {
        if (clinicIds.length === 1) {
          dispatch.filterByLocation({ type: 'clinic', clinicId: clinicIds[0] });
        } else {
          dispatch.filterByLocation({ type: 'multiple-clinics', subType: 'other-clinics', clinicIds });
        }
      } else if (cityId) {
        dispatch.filterByLocation({ type: 'city', cityId });
      } else if (areaId) {
        dispatch.filterByLocation({ type: 'area', areaId });
      } else if (appointmentTypes && !appointmentTypes.includes('clinic')) {
        dispatch.filterByLocation({ type: 'remote' });
      }
      if (appointmentTypes) {
        dispatch.filterByAppointmentTypes(appointmentTypes);
      }
    }
    onButtonClick(restrictions);
  }
};
