/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 * Chat API v3
 * CChat API v3
 *
 * The version of the OpenAPI document: 3.1.4
 * Contact: karo.kuokkanen@terveystalo.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ActivateChatResponse
 */
export interface ActivateChatResponse {
  /**
   *
   * @type {boolean}
   * @memberof ActivateChatResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface ChatAppointmentResponseInner
 */
export interface ChatAppointmentResponseInner {
  /**
   * Unique chat type id
   * @type {number}
   * @memberof ChatAppointmentResponseInner
   */
  appointmentId?: number;
  /**
   * Unique chat type id
   * @type {string}
   * @memberof ChatAppointmentResponseInner
   */
  specialistId?: string;
  /**
   * Unique chat type id
   * @type {string}
   * @memberof ChatAppointmentResponseInner
   */
  specialistName?: string;
  /**
   * Appointment date and time
   * @type {string}
   * @memberof ChatAppointmentResponseInner
   */
  appointmentTime?: string;
  /**
   * Chat activation date and time (by default 5 minutes before actual appointment time)
   * @type {string}
   * @memberof ChatAppointmentResponseInner
   */
  chatOpenTime?: string;
  /**
   * Appointment duration in minutes
   * @type {number}
   * @memberof ChatAppointmentResponseInner
   */
  duration?: number;
  /**
   * Arrival reason, should be passed to Ninchat as chat description
   * @type {string}
   * @memberof ChatAppointmentResponseInner
   */
  arrivalReason?: string;
  /**
   * True if chat can be accessed right away; when true, following chatProperties are also returned
   * @type {boolean}
   * @memberof ChatAppointmentResponseInner
   */
  chatActive?: boolean;
  /**
   *
   * @type {ChatAppointmentResponseInnerChatProperties}
   * @memberof ChatAppointmentResponseInner
   */
  chatProperties?: ChatAppointmentResponseInnerChatProperties;
}
/**
 *
 * @export
 * @interface ChatAppointmentResponseInnerChatProperties
 */
export interface ChatAppointmentResponseInnerChatProperties {
  /**
   *
   * @type {number}
   * @memberof ChatAppointmentResponseInnerChatProperties
   */
  chatTypeId?: number;
  /**
   *
   * @type {string}
   * @memberof ChatAppointmentResponseInnerChatProperties
   */
  chatTypeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ChatAppointmentResponseInnerChatProperties
   */
  chatTypeName?: string;
  /**
   *
   * @type {string}
   * @memberof ChatAppointmentResponseInnerChatProperties
   */
  realmId?: string;
  /**
   *
   * @type {string}
   * @memberof ChatAppointmentResponseInnerChatProperties
   */
  queueId?: string;
  /**
   *
   * @type {string}
   * @memberof ChatAppointmentResponseInnerChatProperties
   */
  siteConfig?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChatAppointmentResponseInnerChatProperties
   */
  queueOpen?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChatAppointmentResponseInnerChatProperties
   */
  queueLength?: number;
}
/**
 *
 * @export
 * @interface ChatCodeValidResponse
 */
export interface ChatCodeValidResponse {
  /**
   *
   * @type {boolean}
   * @memberof ChatCodeValidResponse
   */
  isValid?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChatCodeValidResponse
   */
  description?: string;
}
/**
 *
 * @export
 * @interface ChatPropertiesErrorResponse
 */
export interface ChatPropertiesErrorResponse {
  /**
   * error message
   * @type {string}
   * @memberof ChatPropertiesErrorResponse
   */
  msg?: string;
}
/**
 *
 * @export
 * @interface ChatPropertiesResponse
 */
export interface ChatPropertiesResponse {
  /**
   * Unique chat type id
   * @type {number}
   * @memberof ChatPropertiesResponse
   */
  chatTypeId?: number;
  /**
   * Chat name (by default in Finnish), translated  \'language\' param entered and translation exists
   * @type {string}
   * @memberof ChatPropertiesResponse
   */
  chatTypeName?: string;
  /**
   * Chat description (by default in Finnish), translated if \'language\' param entered and translation exists
   * @type {string}
   * @memberof ChatPropertiesResponse
   */
  chatTypeDesc?: string;
  /**
   * true if queue is open in Ninchat, false if queue is closed or Ninchat cannot be reached
   * @type {boolean}
   * @memberof ChatPropertiesResponse
   */
  queueOpen?: boolean;
  /**
   * number of persons in Ninchat queue, or -1 if Ninchat cannot be reached
   * @type {number}
   * @memberof ChatPropertiesResponse
   */
  queueLength?: number;
}
/**
 *
 * @export
 * @interface ChatResponse
 */
export interface ChatResponse {
  /**
   * Unique chat type id
   * @type {number}
   * @memberof ChatResponse
   */
  chatTypeId?: number;
  /**
   * Unique chat type code
   * @type {string}
   * @memberof ChatResponse
   */
  chatTypeCode?: string;
  /**
   * Chat name (by default in Finnish), translated  \'language\' param entered and translation exists
   * @type {string}
   * @memberof ChatResponse
   */
  chatTypeName?: string;
  /**
   * Chat description (by default in Finnish), translated if \'language\' param entered and translation exists
   * @type {string}
   * @memberof ChatResponse
   */
  chatTypeDesc?: string;
  /**
   * Ninchat realm id
   * @type {string}
   * @memberof ChatResponse
   */
  realmId?: string;
  /**
   * Ninchat queue id
   * @type {string}
   * @memberof ChatResponse
   */
  queueId?: string;
  /**
   * Ninchat site config file name
   * @type {string}
   * @memberof ChatResponse
   */
  siteConfig?: string;
  /**
   * Order number of the chat
   * @type {number}
   * @memberof ChatResponse
   */
  sequence?: number;
  /**
   * If true, chat is shown even if it is not active for user
   * @type {boolean}
   * @memberof ChatResponse
   */
  visibleIfNotActive?: boolean;
  /**
   * If true, chat may be activated by payment
   * @type {boolean}
   * @memberof ChatResponse
   */
  patientOrderable?: boolean;
  /**
   * Price in euros
   * @type {number}
   * @memberof ChatResponse
   */
  productPrice?: number;
  /**
   * Price in string format
   * @type {string}
   * @memberof ChatResponse
   */
  productPriceStr?: string;
  /**
   * Vat in %
   * @type {number}
   * @memberof ChatResponse
   */
  percentVAT?: number;
  /**
   * True if payment step can be omitted by entering a valid code
   * @type {boolean}
   * @memberof ChatResponse
   */
  overridePaymentByCode?: boolean;
  /**
   * False if one-off right, True if issued by company or concept code
   * @type {boolean}
   * @memberof ChatResponse
   */
  persistent?: boolean;
  /**
   * If false, chat should not be displayed if queue is closed in Ninchat
   * @type {boolean}
   * @memberof ChatResponse
   */
  visibleIfQueueClosed?: boolean;
  /**
   * Name of the insurance company (only if chat category starts with \"INSURANCE\")
   * @type {string}
   * @memberof ChatResponse
   */
  insuranceCompany?: string;
  /**
   * Chat category
   * @type {string}
   * @memberof ChatResponse
   */
  chatCategory?: ChatResponseChatCategoryEnum;
  /**
   * Chat category in user interface
   * @type {string}
   * @memberof ChatResponse
   */
  chatUICategory?: ChatResponseChatUICategoryEnum;
  /**
   * True if given user can access chat without payment
   * @type {boolean}
   * @memberof ChatResponse
   */
  chatActive?: boolean;
  /**
   * If true, user is blacklisted and should not be able to access this chat queue (in this case chatActive is always false)
   * @type {boolean}
   * @memberof ChatResponse
   */
  chatDenied?: boolean;
  /**
   *
   * @type {ChatResponseChatSessionProps}
   * @memberof ChatResponse
   */
  chatSessionProps?: ChatResponseChatSessionProps;
  /**
   * true if queue is open in Ninchat, false if queue is closed or Ninchat cannot be reached
   * @type {boolean}
   * @memberof ChatResponse
   */
  queueOpen?: boolean;
  /**
   * number of persons in Ninchat queue, or -1 if Ninchat cannot be reached
   * @type {number}
   * @memberof ChatResponse
   */
  queueLength?: number;
}

export const ChatResponseChatCategoryEnum = {
  Concept: 'CONCEPT',
  Customer: 'CUSTOMER',
  InsuranceIf: 'INSURANCE_IF',
  Orderable: 'ORDERABLE',
  TthExtranet: 'TTH_EXTRANET',
  Empty: '',
} as const;

export type ChatResponseChatCategoryEnum = typeof ChatResponseChatCategoryEnum[keyof typeof ChatResponseChatCategoryEnum];
export const ChatResponseChatUICategoryEnum = {
  Customer: 'CUSTOMER',
  Other: 'OTHER',
  Orderable: 'ORDERABLE',
  Insurance: 'INSURANCE',
} as const;

export type ChatResponseChatUICategoryEnum = typeof ChatResponseChatUICategoryEnum[keyof typeof ChatResponseChatUICategoryEnum];

/**
 * Note! Provided only if chat is active
 * @export
 * @interface ChatResponseChatSessionProps
 */
export interface ChatResponseChatSessionProps {
  /**
   * Description of the chat right, provided during activation. Should be passed to Ninchat
   * @type {string}
   * @memberof ChatResponseChatSessionProps
   */
  description?: string;
  /**
   * Payment order number (is this necessary?)
   * @type {string}
   * @memberof ChatResponseChatSessionProps
   */
  orderNumber?: string;
  /**
   * Product code provided during activation (is this necessary?)
   * @type {string}
   * @memberof ChatResponseChatSessionProps
   */
  productCode?: string;
  /**
   * Product name provided during activation (is this necessary?)
   * @type {string}
   * @memberof ChatResponseChatSessionProps
   */
  productName?: string;
  /**
   * payment method provided during activation (is this necessary?)
   * @type {string}
   * @memberof ChatResponseChatSessionProps
   */
  paymentForm?: string;
  /**
   * Chat activation tool provided during activation
   * @type {string}
   * @memberof ChatResponseChatSessionProps
   */
  addedByTool?: string;
  /**
   * Chat activation user provided during activation
   * @type {string}
   * @memberof ChatResponseChatSessionProps
   */
  addedByUser?: string;
  /**
   * Chat activation code provided during activation
   * @type {string}
   * @memberof ChatResponseChatSessionProps
   */
  chatCode?: string;
}
/**
 *
 * @export
 * @interface ChatUsedResponse
 */
export interface ChatUsedResponse {
  /**
   *
   * @type {boolean}
   * @memberof ChatUsedResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface IfChatAllowedResponse
 */
export interface IfChatAllowedResponse {
  /**
   *
   * @type {boolean}
   * @memberof IfChatAllowedResponse
   */
  allowed?: boolean;
}
/**
 *
 * @export
 * @interface OrderableChatListResponseInner
 */
export interface OrderableChatListResponseInner {
  /**
   * Unique chat type id
   * @type {number}
   * @memberof OrderableChatListResponseInner
   */
  chatTypeId?: number;
  /**
   * Unique chat type code
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  chatTypeCode?: string;
  /**
   * Chat name (by default in Finnish), translated if \'language\' param entered and translation exists
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  chatTypeName?: string;
  /**
   * Chat description (by default in Finnish), translated if \'language\' param entered and translation exists
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  chatTypeDesc?: string;
  /**
   * Ninchat realm id
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  realmId?: string;
  /**
   * Ninchat queue id
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  queueId?: string;
  /**
   * Ninchat site config file name
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  siteConfig?: string;
  /**
   * Order number of the chat
   * @type {number}
   * @memberof OrderableChatListResponseInner
   */
  sequence?: number;
  /**
   * If true, chat is shown even if it is not active for user
   * @type {boolean}
   * @memberof OrderableChatListResponseInner
   */
  visibleIfNotActive?: boolean;
  /**
   * Always true
   * @type {boolean}
   * @memberof OrderableChatListResponseInner
   */
  patientOrderable?: boolean;
  /**
   * Price in euros
   * @type {number}
   * @memberof OrderableChatListResponseInner
   */
  productPrice?: number;
  /**
   * Price in string format
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  productPriceStr?: string;
  /**
   * Vat in %
   * @type {number}
   * @memberof OrderableChatListResponseInner
   */
  percentVAT?: number;
  /**
   * True if payment step can be omitted by entering a valid code
   * @type {boolean}
   * @memberof OrderableChatListResponseInner
   */
  overridePaymentByCode?: boolean;
  /**
   * always false
   * @type {boolean}
   * @memberof OrderableChatListResponseInner
   */
  persistent?: boolean;
  /**
   * If false, chat should not be displayed if queue is closed in Ninchat
   * @type {boolean}
   * @memberof OrderableChatListResponseInner
   */
  visibleIfQueueClosed?: boolean;
  /**
   * Name of the insurance company (always empty)
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  insuranceCompany?: string;
  /**
   * Chat category
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  chatCategory?: OrderableChatListResponseInnerChatCategoryEnum;
  /**
   * Chat category in user interface
   * @type {string}
   * @memberof OrderableChatListResponseInner
   */
  chatUICategory?: OrderableChatListResponseInnerChatUICategoryEnum;
  /**
   * true if queue is open in Ninchat, false if queue is closed or Ninchat cannot be reached
   * @type {boolean}
   * @memberof OrderableChatListResponseInner
   */
  queueOpen?: boolean;
  /**
   * number of persons in Ninchat queue, or -1 if Ninchat cannot be reached
   * @type {number}
   * @memberof OrderableChatListResponseInner
   */
  queueLength?: number;
}

export const OrderableChatListResponseInnerChatCategoryEnum = {
  Orderable: 'ORDERABLE',
} as const;

export type OrderableChatListResponseInnerChatCategoryEnum = typeof OrderableChatListResponseInnerChatCategoryEnum[keyof typeof OrderableChatListResponseInnerChatCategoryEnum];
export const OrderableChatListResponseInnerChatUICategoryEnum = {
  Orderable: 'ORDERABLE',
} as const;

export type OrderableChatListResponseInnerChatUICategoryEnum = typeof OrderableChatListResponseInnerChatUICategoryEnum[keyof typeof OrderableChatListResponseInnerChatUICategoryEnum];

/**
 * JWTAuthenticationApi - axios parameter creator
 * @export
 */
export const JWTAuthenticationApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Activate chat for given person. (Not necessarily needed if payment is done afterwards?)
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [description] Chat description used in activation
     * @param {string} [chatCode] Chat code used in activation
     * @param {string} [addedByUser] User that activated chat
     * @param {string} [addedByTool] Chat activation tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateChatPatientIdChatTypeIdPost: async (
      patientId: string,
      chatTypeId: number,
      description?: string,
      chatCode?: string,
      addedByUser?: string,
      addedByTool?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('activateChatPatientIdChatTypeIdPost', 'patientId', patientId);
      // verify required parameter 'chatTypeId' is not null or undefined
      assertParamExists('activateChatPatientIdChatTypeIdPost', 'chatTypeId', chatTypeId);
      const localVarPath = `/activate-chat/{patientId}/{chatTypeId}`
        .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
        .replace(`{${'chatTypeId'}}`, encodeURIComponent(String(chatTypeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (description !== undefined) {
        localVarFormParams.append('description', description as any);
      }

      if (chatCode !== undefined) {
        localVarFormParams.append('chatCode', chatCode as any);
      }

      if (addedByUser !== undefined) {
        localVarFormParams.append('addedByUser', addedByUser as any);
      }

      if (addedByTool !== undefined) {
        localVarFormParams.append('addedByTool', addedByTool as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get given chat appointment information for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} appointmentId Chat appointment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatAppointmentPatientIdAppointmentIdGet: async (
      patientId: string,
      appointmentId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('chatAppointmentPatientIdAppointmentIdGet', 'patientId', patientId);
      // verify required parameter 'appointmentId' is not null or undefined
      assertParamExists('chatAppointmentPatientIdAppointmentIdGet', 'appointmentId', appointmentId);
      const localVarPath = `/chat-appointment/{patientId}/{appointmentId}`
        .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
        .replace(`{${'appointmentId'}}`, encodeURIComponent(String(appointmentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available chat appointments for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatAppointmentPatientIdGet: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('chatAppointmentPatientIdGet', 'patientId', patientId);
      const localVarPath = `/chat-appointment/{patientId}`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check if given chat code is valid
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [chatCode] Chat code to be checked
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatCodeValidPatientIdChatTypeIdPost: async (
      patientId: string,
      chatTypeId: number,
      chatCode?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('chatCodeValidPatientIdChatTypeIdPost', 'patientId', patientId);
      // verify required parameter 'chatTypeId' is not null or undefined
      assertParamExists('chatCodeValidPatientIdChatTypeIdPost', 'chatTypeId', chatTypeId);
      const localVarPath = `/chat-code-valid/{patientId}/{chatTypeId}`
        .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
        .replace(`{${'chatTypeId'}}`, encodeURIComponent(String(chatTypeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (chatCode !== undefined) {
        localVarFormParams.append('chatCode', chatCode as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get orderable chat types
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatGet: async (language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/chat`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available chat types for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatPatientIdGet: async (
      patientId: string,
      language?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('chatPatientIdGet', 'patientId', patientId);
      const localVarPath = `/chat/{patientId}`.replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary This route should be called when user logs in to chat queue
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [description] Chat description used in activation
     * @param {string} [chatCode] Chat code used in activation
     * @param {string} [addedByUser] User that activated chat
     * @param {string} [addedByTool] Chat activation tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatUsedPatientIdChatTypeIdPost: async (
      patientId: string,
      chatTypeId: number,
      description?: string,
      chatCode?: string,
      addedByUser?: string,
      addedByTool?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('chatUsedPatientIdChatTypeIdPost', 'patientId', patientId);
      // verify required parameter 'chatTypeId' is not null or undefined
      assertParamExists('chatUsedPatientIdChatTypeIdPost', 'chatTypeId', chatTypeId);
      const localVarPath = `/chat-used/{patientId}/{chatTypeId}`
        .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
        .replace(`{${'chatTypeId'}}`, encodeURIComponent(String(chatTypeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (description !== undefined) {
        localVarFormParams.append('description', description as any);
      }

      if (chatCode !== undefined) {
        localVarFormParams.append('chatCode', chatCode as any);
      }

      if (addedByUser !== undefined) {
        localVarFormParams.append('addedByUser', addedByUser as any);
      }

      if (addedByTool !== undefined) {
        localVarFormParams.append('addedByTool', addedByTool as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary To check if user may use insurance company direct billing (IF suorakorvaus)
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ifChatAllowedPatientIdGet: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('ifChatAllowedPatientIdGet', 'patientId', patientId);
      const localVarPath = `/if-chat-allowed/{patientId}`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JWTAuthenticationApi - functional programming interface
 * @export
 */
export const JWTAuthenticationApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = JWTAuthenticationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Activate chat for given person. (Not necessarily needed if payment is done afterwards?)
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [description] Chat description used in activation
     * @param {string} [chatCode] Chat code used in activation
     * @param {string} [addedByUser] User that activated chat
     * @param {string} [addedByTool] Chat activation tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateChatPatientIdChatTypeIdPost(
      patientId: string,
      chatTypeId: number,
      description?: string,
      chatCode?: string,
      addedByUser?: string,
      addedByTool?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateChatResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activateChatPatientIdChatTypeIdPost(
        patientId,
        chatTypeId,
        description,
        chatCode,
        addedByUser,
        addedByTool,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get given chat appointment information for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} appointmentId Chat appointment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatAppointmentPatientIdAppointmentIdGet(
      patientId: string,
      appointmentId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatAppointmentResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatAppointmentPatientIdAppointmentIdGet(
        patientId,
        appointmentId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get available chat appointments for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatAppointmentPatientIdGet(
      patientId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatAppointmentResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatAppointmentPatientIdGet(patientId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Check if given chat code is valid
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [chatCode] Chat code to be checked
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatCodeValidPatientIdChatTypeIdPost(
      patientId: string,
      chatTypeId: number,
      chatCode?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatCodeValidResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatCodeValidPatientIdChatTypeIdPost(
        patientId,
        chatTypeId,
        chatCode,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get orderable chat types
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatGet(
      language?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderableChatListResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatGet(language, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get available chat types for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatPatientIdGet(
      patientId: string,
      language?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatPatientIdGet(patientId, language, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary This route should be called when user logs in to chat queue
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [description] Chat description used in activation
     * @param {string} [chatCode] Chat code used in activation
     * @param {string} [addedByUser] User that activated chat
     * @param {string} [addedByTool] Chat activation tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatUsedPatientIdChatTypeIdPost(
      patientId: string,
      chatTypeId: number,
      description?: string,
      chatCode?: string,
      addedByUser?: string,
      addedByTool?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatUsedResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatUsedPatientIdChatTypeIdPost(
        patientId,
        chatTypeId,
        description,
        chatCode,
        addedByUser,
        addedByTool,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary To check if user may use insurance company direct billing (IF suorakorvaus)
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ifChatAllowedPatientIdGet(
      patientId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IfChatAllowedResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ifChatAllowedPatientIdGet(patientId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * JWTAuthenticationApi - factory interface
 * @export
 */
export const JWTAuthenticationApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JWTAuthenticationApiFp(configuration);
  return {
    /**
     *
     * @summary Activate chat for given person. (Not necessarily needed if payment is done afterwards?)
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [description] Chat description used in activation
     * @param {string} [chatCode] Chat code used in activation
     * @param {string} [addedByUser] User that activated chat
     * @param {string} [addedByTool] Chat activation tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateChatPatientIdChatTypeIdPost(
      patientId: string,
      chatTypeId: number,
      description?: string,
      chatCode?: string,
      addedByUser?: string,
      addedByTool?: string,
      options?: any,
    ): AxiosPromise<ActivateChatResponse> {
      return localVarFp
        .activateChatPatientIdChatTypeIdPost(
          patientId,
          chatTypeId,
          description,
          chatCode,
          addedByUser,
          addedByTool,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get given chat appointment information for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} appointmentId Chat appointment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatAppointmentPatientIdAppointmentIdGet(
      patientId: string,
      appointmentId: number,
      options?: any,
    ): AxiosPromise<Array<ChatAppointmentResponseInner>> {
      return localVarFp
        .chatAppointmentPatientIdAppointmentIdGet(patientId, appointmentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get available chat appointments for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatAppointmentPatientIdGet(patientId: string, options?: any): AxiosPromise<Array<ChatAppointmentResponseInner>> {
      return localVarFp.chatAppointmentPatientIdGet(patientId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Check if given chat code is valid
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [chatCode] Chat code to be checked
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatCodeValidPatientIdChatTypeIdPost(
      patientId: string,
      chatTypeId: number,
      chatCode?: string,
      options?: any,
    ): AxiosPromise<ChatCodeValidResponse> {
      return localVarFp
        .chatCodeValidPatientIdChatTypeIdPost(patientId, chatTypeId, chatCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get orderable chat types
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatGet(language?: string, options?: any): AxiosPromise<Array<OrderableChatListResponseInner>> {
      return localVarFp.chatGet(language, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get available chat types for given user
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatPatientIdGet(patientId: string, language?: string, options?: any): AxiosPromise<Array<ChatResponse>> {
      return localVarFp.chatPatientIdGet(patientId, language, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary This route should be called when user logs in to chat queue
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {number} chatTypeId Chat type id
     * @param {string} [description] Chat description used in activation
     * @param {string} [chatCode] Chat code used in activation
     * @param {string} [addedByUser] User that activated chat
     * @param {string} [addedByTool] Chat activation tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatUsedPatientIdChatTypeIdPost(
      patientId: string,
      chatTypeId: number,
      description?: string,
      chatCode?: string,
      addedByUser?: string,
      addedByTool?: string,
      options?: any,
    ): AxiosPromise<ChatUsedResponse> {
      return localVarFp
        .chatUsedPatientIdChatTypeIdPost(
          patientId,
          chatTypeId,
          description,
          chatCode,
          addedByUser,
          addedByTool,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary To check if user may use insurance company direct billing (IF suorakorvaus)
     * @param {string} patientId Patient\&#39;s id (henkilötunnus)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ifChatAllowedPatientIdGet(patientId: string, options?: any): AxiosPromise<IfChatAllowedResponse> {
      return localVarFp.ifChatAllowedPatientIdGet(patientId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * JWTAuthenticationApi - object-oriented interface
 * @export
 * @class JWTAuthenticationApi
 * @extends {BaseAPI}
 */
export class JWTAuthenticationApi extends BaseAPI {
  /**
   *
   * @summary Activate chat for given person. (Not necessarily needed if payment is done afterwards?)
   * @param {string} patientId Patient\&#39;s id (henkilötunnus)
   * @param {number} chatTypeId Chat type id
   * @param {string} [description] Chat description used in activation
   * @param {string} [chatCode] Chat code used in activation
   * @param {string} [addedByUser] User that activated chat
   * @param {string} [addedByTool] Chat activation tool
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JWTAuthenticationApi
   */
  public activateChatPatientIdChatTypeIdPost(
    patientId: string,
    chatTypeId: number,
    description?: string,
    chatCode?: string,
    addedByUser?: string,
    addedByTool?: string,
    options?: AxiosRequestConfig,
  ) {
    return JWTAuthenticationApiFp(this.configuration)
      .activateChatPatientIdChatTypeIdPost(
        patientId,
        chatTypeId,
        description,
        chatCode,
        addedByUser,
        addedByTool,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get given chat appointment information for given user
   * @param {string} patientId Patient\&#39;s id (henkilötunnus)
   * @param {number} appointmentId Chat appointment id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JWTAuthenticationApi
   */
  public chatAppointmentPatientIdAppointmentIdGet(
    patientId: string,
    appointmentId: number,
    options?: AxiosRequestConfig,
  ) {
    return JWTAuthenticationApiFp(this.configuration)
      .chatAppointmentPatientIdAppointmentIdGet(patientId, appointmentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available chat appointments for given user
   * @param {string} patientId Patient\&#39;s id (henkilötunnus)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JWTAuthenticationApi
   */
  public chatAppointmentPatientIdGet(patientId: string, options?: AxiosRequestConfig) {
    return JWTAuthenticationApiFp(this.configuration)
      .chatAppointmentPatientIdGet(patientId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check if given chat code is valid
   * @param {string} patientId Patient\&#39;s id (henkilötunnus)
   * @param {number} chatTypeId Chat type id
   * @param {string} [chatCode] Chat code to be checked
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JWTAuthenticationApi
   */
  public chatCodeValidPatientIdChatTypeIdPost(
    patientId: string,
    chatTypeId: number,
    chatCode?: string,
    options?: AxiosRequestConfig,
  ) {
    return JWTAuthenticationApiFp(this.configuration)
      .chatCodeValidPatientIdChatTypeIdPost(patientId, chatTypeId, chatCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get orderable chat types
   * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JWTAuthenticationApi
   */
  public chatGet(language?: string, options?: AxiosRequestConfig) {
    return JWTAuthenticationApiFp(this.configuration)
      .chatGet(language, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available chat types for given user
   * @param {string} patientId Patient\&#39;s id (henkilötunnus)
   * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JWTAuthenticationApi
   */
  public chatPatientIdGet(patientId: string, language?: string, options?: AxiosRequestConfig) {
    return JWTAuthenticationApiFp(this.configuration)
      .chatPatientIdGet(patientId, language, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary This route should be called when user logs in to chat queue
   * @param {string} patientId Patient\&#39;s id (henkilötunnus)
   * @param {number} chatTypeId Chat type id
   * @param {string} [description] Chat description used in activation
   * @param {string} [chatCode] Chat code used in activation
   * @param {string} [addedByUser] User that activated chat
   * @param {string} [addedByTool] Chat activation tool
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JWTAuthenticationApi
   */
  public chatUsedPatientIdChatTypeIdPost(
    patientId: string,
    chatTypeId: number,
    description?: string,
    chatCode?: string,
    addedByUser?: string,
    addedByTool?: string,
    options?: AxiosRequestConfig,
  ) {
    return JWTAuthenticationApiFp(this.configuration)
      .chatUsedPatientIdChatTypeIdPost(patientId, chatTypeId, description, chatCode, addedByUser, addedByTool, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary To check if user may use insurance company direct billing (IF suorakorvaus)
   * @param {string} patientId Patient\&#39;s id (henkilötunnus)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JWTAuthenticationApi
   */
  public ifChatAllowedPatientIdGet(patientId: string, options?: AxiosRequestConfig) {
    return JWTAuthenticationApiFp(this.configuration)
      .ifChatAllowedPatientIdGet(patientId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NoAuthenticationApi - axios parameter creator
 * @export
 */
export const NoAuthenticationApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns single chat properties (for orderable chats only). Note; if Ninchat connection fails, queueOpen will be false and queueLenth -1.
     * @param {number} chatTypeId Chat type id
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatPropertiesChatTypeIdGet: async (
      chatTypeId: number,
      language?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'chatTypeId' is not null or undefined
      assertParamExists('chatPropertiesChatTypeIdGet', 'chatTypeId', chatTypeId);
      const localVarPath = `/chats/{chatTypeId}/properties`.replace(
        `{${'chatTypeId'}}`,
        encodeURIComponent(String(chatTypeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns valid jwt token. Note that this works only in test environment. In production, calling party should generate its own jwt token.
     * @param {string} [username] User name
     * @param {string} [pass] User name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jwtPost: async (username?: string, pass?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/jwt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (username !== undefined) {
        localVarFormParams.append('username', username as any);
      }

      if (pass !== undefined) {
        localVarFormParams.append('pass', pass as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Test route.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns this documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/swagger`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns this documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerJsonGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/swagger-json`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NoAuthenticationApi - functional programming interface
 * @export
 */
export const NoAuthenticationApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = NoAuthenticationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns single chat properties (for orderable chats only). Note; if Ninchat connection fails, queueOpen will be false and queueLenth -1.
     * @param {number} chatTypeId Chat type id
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatPropertiesChatTypeIdGet(
      chatTypeId: number,
      language?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatPropertiesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatPropertiesChatTypeIdGet(
        chatTypeId,
        language,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Returns valid jwt token. Note that this works only in test environment. In production, calling party should generate its own jwt token.
     * @param {string} [username] User name
     * @param {string} [pass] User name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jwtPost(
      username?: string,
      pass?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jwtPost(username, pass, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Test route.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pingGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Returns this documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async swaggerGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.swaggerGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Returns this documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async swaggerJsonGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.swaggerJsonGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NoAuthenticationApi - factory interface
 * @export
 */
export const NoAuthenticationApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NoAuthenticationApiFp(configuration);
  return {
    /**
     *
     * @summary Returns single chat properties (for orderable chats only). Note; if Ninchat connection fails, queueOpen will be false and queueLenth -1.
     * @param {number} chatTypeId Chat type id
     * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatPropertiesChatTypeIdGet(
      chatTypeId: number,
      language?: string,
      options?: any,
    ): AxiosPromise<ChatPropertiesResponse> {
      return localVarFp
        .chatPropertiesChatTypeIdGet(chatTypeId, language, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns valid jwt token. Note that this works only in test environment. In production, calling party should generate its own jwt token.
     * @param {string} [username] User name
     * @param {string} [pass] User name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jwtPost(username?: string, pass?: string, options?: any): AxiosPromise<string> {
      return localVarFp.jwtPost(username, pass, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Test route.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet(options?: any): AxiosPromise<object> {
      return localVarFp.pingGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns this documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerGet(options?: any): AxiosPromise<string> {
      return localVarFp.swaggerGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns this documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerJsonGet(options?: any): AxiosPromise<object> {
      return localVarFp.swaggerJsonGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * NoAuthenticationApi - object-oriented interface
 * @export
 * @class NoAuthenticationApi
 * @extends {BaseAPI}
 */
export class NoAuthenticationApi extends BaseAPI {
  /**
   *
   * @summary Returns single chat properties (for orderable chats only). Note; if Ninchat connection fails, queueOpen will be false and queueLenth -1.
   * @param {number} chatTypeId Chat type id
   * @param {string} [language] Either \&#39;lang\&#39; or \&#39;language\&#39;. Supported values are \&#39;en\&#39; and \&#39;sv\&#39;, default is \&#39;fi\&#39;. chatTypeName and chatTypeDesc are translated according to this value, if translation is provided.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoAuthenticationApi
   */
  public chatPropertiesChatTypeIdGet(chatTypeId: number, language?: string, options?: AxiosRequestConfig) {
    return NoAuthenticationApiFp(this.configuration)
      .chatPropertiesChatTypeIdGet(chatTypeId, language, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns valid jwt token. Note that this works only in test environment. In production, calling party should generate its own jwt token.
   * @param {string} [username] User name
   * @param {string} [pass] User name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoAuthenticationApi
   */
  public jwtPost(username?: string, pass?: string, options?: AxiosRequestConfig) {
    return NoAuthenticationApiFp(this.configuration)
      .jwtPost(username, pass, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Test route.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoAuthenticationApi
   */
  public pingGet(options?: AxiosRequestConfig) {
    return NoAuthenticationApiFp(this.configuration)
      .pingGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns this documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoAuthenticationApi
   */
  public swaggerGet(options?: AxiosRequestConfig) {
    return NoAuthenticationApiFp(this.configuration)
      .swaggerGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns this documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoAuthenticationApi
   */
  public swaggerJsonGet(options?: AxiosRequestConfig) {
    return NoAuthenticationApiFp(this.configuration)
      .swaggerJsonGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}
