/** @jsxImportSource @emotion/react */
import { Icon } from '@terveystalo/design-system-react-components';
import React, { FC } from 'react';
import { PhoneNumber } from 'ui/utils/PhoneNumber';
import { useTranslations } from 'utils/react/ui-context';
import { classNames } from 'utils/styles/helpers';

type Props = {
  title?: string;
  titleLevel?: 'h1' | 'h2' | 'h3';
  message?: string;
  noIcon?: boolean;
  children?: React.ReactNode;
};

export const ErrorPageContent: FC<Props> = ({ title, titleLevel: TitleTag = 'h1', message, noIcon, children }) => {
  const t = useTranslations();

  return (
    <div className={classNames('pt-3', 's:pt-5', 's:pb-3', 'text-center')}>
      <div>
        {!noIcon && (
          <Icon
            name="AlertOctagon"
            color="attentionRed"
            className={classNames('mb-0.5', 'mx-auto')}
            style={{
              width: '5rem',
              height: '5rem',
            }}
          />
        )}
        <TitleTag className={classNames('text-style-subtitle-m', 's:text-subtitle-l', 'mb-1')}>
          {title || t.error}
        </TitleTag>
        <p className={classNames('text-body-l', 'mb-1')}>{message || t.try_again_later}</p>
      </div>
      {children}
      <div className={classNames('mt-3')}>
        <div className={classNames('font-bold', 'mb-0.5')}>{t.customer_service_24_7}</div>
        <PhoneNumber large />
      </div>
    </div>
  );
};
