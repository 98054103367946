import clone from 'lodash/clone';
import get from 'lodash/get';
import initial from 'lodash/initial';
import last from 'lodash/last';
import setWith from 'lodash/setWith';

// Implements a thin but well-typed wrapper around lodash's get().
// Max depth can be increased as needed.
export function deepGet<T extends Record<string, any>, P1 extends keyof T>(obj: T, p1: P1): T[P1];
export function deepGet<T extends Record<string, any>, P1 extends keyof T, P2 extends keyof T[P1]>(
  obj: T,
  p1: P1,
  p2: P2,
): T[P1][P2];
export function deepGet<
  T extends Record<string, any>,
  P1 extends keyof T,
  P2 extends keyof T[P1],
  P3 extends keyof T[P1][P2]
>(obj: T, p1: P1, p2: P2, p3: P3): T[P1][P2][P3];
// Overloads where keys along the path may be undefined:
export function deepGet<T extends Record<string, any>, P1 extends keyof T, P2 extends keyof Def<T[P1]>>(
  obj: T,
  p1: P1,
  p2: P2,
): Def<T[P1]>[P2];
export function deepGet<
  T extends Record<string, any>,
  P1 extends keyof T,
  P2 extends keyof Def<T[P1]>,
  P3 extends keyof Def<Def<T[P1]>[P2]>
>(obj: T, p1: P1, p2: P2, p3: P3): Def<Def<T[P1]>[P2]>[P3] | undefined;
export function deepGet(obj: any, ...args: any[]): any {
  return get(obj, args);
}

// i.e. short for "Defined"
type Def<T> = Exclude<T, undefined>;

// Implements a thin but well-typed wrapper around lodash's set(), while enforcing immutability.
// Max depth can be increased as needed.
// Undefined is Excluded, because index types may not yet have a key at the given position.
export function deepSet<T extends Record<string, any>, P1 extends keyof T, V extends Def<T[P1]>>(
  obj: T,
  p1: P1,
  val: V,
): Readonly<T>;
export function deepSet<
  T extends Record<string, any>,
  P1 extends keyof T,
  P2 extends keyof Def<T[P1]>,
  V extends Def<Def<T[P1]>[P2]>
>(obj: T, p1: P1, p2: P2, val: V): Readonly<T>;
export function deepSet<
  T extends Record<string, any>,
  P1 extends keyof T,
  P2 extends keyof Def<T[P1]>,
  P3 extends keyof Def<Def<T[P1]>[P2]>,
  V extends Def<Def<Def<T[P1]>[P2]>[P3]>
>(obj: T, p1: P1, p2: P2, p3: P3, val: V): Readonly<T>;
export function deepSet(obj: any, ...args: any[]): any {
  const path = initial(args);
  const val = last(args);
  return setWith(clone(obj), path, val, clone); // https://github.com/lodash/lodash/issues/1696#issuecomment-328335502
}
