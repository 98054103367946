/** @jsxImportSource @emotion/react */
import { Footer as DSFooter } from '@terveystalo/design-system-react-organisms';
import React, { FC } from 'react';
import { Environment, useReduxState, useTranslations } from 'utils/react/ui-context';

export const priceInfoId = 'customer_service_call_fee';

export const Footer: FC<{ environment: Environment }> = ({ environment }) => {
  const { lang } = useReduxState(state => state.location);
  const t = useTranslations();

  return (
    <DSFooter
      callPriceInfoId={priceInfoId}
      environment={environment}
      language={lang}
      navLinks={
        t.pricelist === '' // Do not render the links until translations have been loaded
          ? []
          : [
              { href: t.ext_link_prices, text: t.pricelist },
              { href: t.ext_link_customer_feedback, text: t.customer_feedback },
            ]
      }
      legalLinks={
        t.terms_and_conditions === '' // Do not render the links until translations have been loaded
          ? []
          : [
              { href: t.ext_link_terms_and_conditions, text: t.terms_and_conditions },
              { href: t.ext_link_accessibility_statement, text: t.accessibility_statement },
              { href: t.ext_link_privacy_statement, text: t.privacy_statement },
            ]
      }
    />
  );
};
