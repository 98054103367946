/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 * Clinic API
 * Misc
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AreaItem
 */
export interface AreaItem {
  /**
   *
   * @type {string}
   * @memberof AreaItem
   */
  Id?: string;
  /**
   *
   * @type {Array<Localized>}
   * @memberof AreaItem
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AreaItem
   */
  ClinicIDs?: Array<string> | null;
}
/**
 *
 * @export
 * @interface CityItem
 */
export interface CityItem {
  /**
   *
   * @type {string}
   * @memberof CityItem
   */
  Id?: string;
  /**
   *
   * @type {Array<Localized>}
   * @memberof CityItem
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CityItem
   */
  ClinicIDs?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ClinicDetailItem
 */
export interface ClinicDetailItem {
  /**
   *
   * @type {string}
   * @memberof ClinicDetailItem
   */
  Id?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClinicDetailItem
   */
  EnableBooking?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClinicDetailItem
   */
  Fax?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClinicDetailItem
   */
  Phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClinicDetailItem
   */
  PhoneReservation?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClinicDetailItem
   */
  PhoneSwitch?: string | null;
  /**
   *
   * @type {number}
   * @memberof ClinicDetailItem
   */
  PostCode?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicDetailItem
   */
  FallBackClinicsIds?: Array<string> | null;
  /**
   *
   * @type {GeoLocation}
   * @memberof ClinicDetailItem
   */
  GeoLocation?: GeoLocation;
  /**
   *
   * @type {string}
   * @memberof ClinicDetailItem
   */
  AreaId?: string | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  Address?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  ArrivalInstructions?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  CheckInInstructions?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  Description?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  EntryInstructions?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  ManagerDescription?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  ParkingInstructions?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  SpecialityDescription?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof ClinicDetailItem
   */
  City?: Array<Localized> | null;
  /**
   *
   * @type {Array<ServiceSubsystemID>}
   * @memberof ClinicDetailItem
   */
  SubsystemIds?: Array<ServiceSubsystemID> | null;
}
/**
 *
 * @export
 * @interface GeoLocation
 */
export interface GeoLocation {
  /**
   *
   * @type {number}
   * @memberof GeoLocation
   */
  Latitude?: number;
  /**
   *
   * @type {number}
   * @memberof GeoLocation
   */
  Longitude?: number;
}
/**
 *
 * @export
 * @interface Localized
 */
export interface Localized {
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  TwoLetterISOLanguage?: string | null;
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  Value?: string | null;
}
/**
 *
 * @export
 * @interface PingResult
 */
export interface PingResult {
  /**
   *
   * @type {string}
   * @memberof PingResult
   */
  Message?: string | null;
}
/**
 *
 * @export
 * @interface ServiceSubsystemID
 */
export interface ServiceSubsystemID {
  /**
   *
   * @type {string}
   * @memberof ServiceSubsystemID
   */
  ID?: string | null;
  /**
   *
   * @type {TerveystaloBaseAPIAbstractionsSubsystem}
   * @memberof ServiceSubsystemID
   */
  Subsystem?: TerveystaloBaseAPIAbstractionsSubsystem;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloBaseAPIAbstractionsSubsystem = {
  Osku: 'Osku',
  Assisdent: 'Assisdent',
  DynamicHealth: 'DynamicHealth',
} as const;

export type TerveystaloBaseAPIAbstractionsSubsystem = typeof TerveystaloBaseAPIAbstractionsSubsystem[keyof typeof TerveystaloBaseAPIAbstractionsSubsystem];

/**
 *
 * @export
 * @interface TerveystaloClinicAPIAbstractionsV2ClinicClinicListItem2
 */
export interface TerveystaloClinicAPIAbstractionsV2ClinicClinicListItem2 {
  /**
   *
   * @type {string}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicClinicListItem2
   */
  Id?: string;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicClinicListItem2
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {GeoLocation}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicClinicListItem2
   */
  GeoLocation?: GeoLocation;
}
/**
 *
 * @export
 * @interface TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest
 */
export interface TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest {
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest
   */
  Visible?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest
   */
  AreaID?: string | null;
  /**
   *
   * @type {Array<TerveystaloClinicAPIAbstractionsV2ClinicMedicalField>}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest
   */
  MedicalFields?: Array<TerveystaloClinicAPIAbstractionsV2ClinicMedicalField> | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest
   */
  BookingEnabled?: boolean | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloClinicAPIAbstractionsV2ClinicMedicalField = {
  Medicine: 'Medicine',
  Dental: 'Dental',
} as const;

export type TerveystaloClinicAPIAbstractionsV2ClinicMedicalField = typeof TerveystaloClinicAPIAbstractionsV2ClinicMedicalField[keyof typeof TerveystaloClinicAPIAbstractionsV2ClinicMedicalField];

/**
 *
 * @export
 * @interface TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest
 */
export interface TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest {
  /**
   *
   * @type {number}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest
   */
  Longitude?: number;
  /**
   *
   * @type {number}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest
   */
  Latitude?: number;
  /**
   * Maximum number of clinics to return
   * @type {number}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest
   */
  Take?: number | null;
  /**
   * Filters Medicine and Dental clinics
   * @type {Array<TerveystaloClinicAPIAbstractionsV2ClinicMedicalField>}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest
   */
  MedicalFields?: Array<TerveystaloClinicAPIAbstractionsV2ClinicMedicalField> | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest
   */
  BookingEnabled?: boolean | null;
}
/**
 *
 * @export
 * @interface TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2
 */
export interface TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2 {
  /**
   *
   * @type {string}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2
   */
  AreaID?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2
   */
  Query?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2
   */
  BookingEnabled?: boolean | null;
  /**
   *
   * @type {Array<TerveystaloClinicAPIAbstractionsV2ClinicMedicalField>}
   * @memberof TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2
   */
  MedicalFields?: Array<TerveystaloClinicAPIAbstractionsV2ClinicMedicalField> | null;
}
/**
 *
 * @export
 * @interface TerveystaloClinicAPICoreServiceHealthAndMonitoringGetSystemHealthResponse
 */
export interface TerveystaloClinicAPICoreServiceHealthAndMonitoringGetSystemHealthResponse {
  /**
   *
   * @type {TerveystaloClinicAPICoreServiceHealthAndMonitoringHealthState}
   * @memberof TerveystaloClinicAPICoreServiceHealthAndMonitoringGetSystemHealthResponse
   */
  System?: TerveystaloClinicAPICoreServiceHealthAndMonitoringHealthState;
  /**
   *
   * @type {{ [key: string]: TerveystaloClinicAPICoreServiceHealthAndMonitoringHealthState; }}
   * @memberof TerveystaloClinicAPICoreServiceHealthAndMonitoringGetSystemHealthResponse
   */
  Features?: { [key: string]: TerveystaloClinicAPICoreServiceHealthAndMonitoringHealthState } | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloClinicAPICoreServiceHealthAndMonitoringHealthState = {
  Ok: 'Ok',
  Error: 'Error',
} as const;

export type TerveystaloClinicAPICoreServiceHealthAndMonitoringHealthState = typeof TerveystaloClinicAPICoreServiceHealthAndMonitoringHealthState[keyof typeof TerveystaloClinicAPICoreServiceHealthAndMonitoringHealthState];

/**
 * AreaApi - axios parameter creator
 * @export
 */
export const AreaApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {boolean} [bookingEnabled]
     * @param {boolean} [visible]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2AreasGet: async (
      bookingEnabled?: boolean,
      visible?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/areas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (bookingEnabled !== undefined) {
        localVarQueryParameter['bookingEnabled'] = bookingEnabled;
      }

      if (visible !== undefined) {
        localVarQueryParameter['visible'] = visible;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AreaApi - functional programming interface
 * @export
 */
export const AreaApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AreaApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {boolean} [bookingEnabled]
     * @param {boolean} [visible]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2AreasGet(
      bookingEnabled?: boolean,
      visible?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AreaItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2AreasGet(bookingEnabled, visible, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AreaApi - factory interface
 * @export
 */
export const AreaApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AreaApiFp(configuration);
  return {
    /**
     *
     * @param {boolean} [bookingEnabled]
     * @param {boolean} [visible]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2AreasGet(bookingEnabled?: boolean, visible?: boolean, options?: any): AxiosPromise<Array<AreaItem>> {
      return localVarFp.v2AreasGet(bookingEnabled, visible, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AreaApi - object-oriented interface
 * @export
 * @class AreaApi
 * @extends {BaseAPI}
 */
export class AreaApi extends BaseAPI {
  /**
   *
   * @param {boolean} [bookingEnabled]
   * @param {boolean} [visible]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AreaApi
   */
  public v2AreasGet(bookingEnabled?: boolean, visible?: boolean, options?: AxiosRequestConfig) {
    return AreaApiFp(this.configuration)
      .v2AreasGet(bookingEnabled, visible, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CityApi - axios parameter creator
 * @export
 */
export const CityApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {boolean} [bookingEnabled]
     * @param {boolean} [visible]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CitiesGet: async (
      bookingEnabled?: boolean,
      visible?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/cities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (bookingEnabled !== undefined) {
        localVarQueryParameter['bookingEnabled'] = bookingEnabled;
      }

      if (visible !== undefined) {
        localVarQueryParameter['visible'] = visible;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CityApi - functional programming interface
 * @export
 */
export const CityApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CityApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {boolean} [bookingEnabled]
     * @param {boolean} [visible]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2CitiesGet(
      bookingEnabled?: boolean,
      visible?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2CitiesGet(bookingEnabled, visible, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CityApi - factory interface
 * @export
 */
export const CityApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CityApiFp(configuration);
  return {
    /**
     *
     * @param {boolean} [bookingEnabled]
     * @param {boolean} [visible]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2CitiesGet(bookingEnabled?: boolean, visible?: boolean, options?: any): AxiosPromise<Array<CityItem>> {
      return localVarFp.v2CitiesGet(bookingEnabled, visible, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CityApi - object-oriented interface
 * @export
 * @class CityApi
 * @extends {BaseAPI}
 */
export class CityApi extends BaseAPI {
  /**
   *
   * @param {boolean} [bookingEnabled]
   * @param {boolean} [visible]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CityApi
   */
  public v2CitiesGet(bookingEnabled?: boolean, visible?: boolean, options?: AxiosRequestConfig) {
    return CityApiFp(this.configuration)
      .v2CitiesGet(bookingEnabled, visible, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ClinicApi - axios parameter creator
 * @export
 */
export const ClinicApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('v2ClinicsIdGet', 'id', id);
      const localVarPath = `/v2/clinics/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Find nearest clinics for given coordinates
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest} [terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsNearestPost: async (
      terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest?: TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/clinics/nearest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest} [terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsPost: async (
      terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest?: TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/clinics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2} [terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsSearchPost: async (
      terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2?: TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/clinics/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get clinic ID by subsystem ID
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsSubsystemSubsystemIDGet: async (
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'subsystem' is not null or undefined
      assertParamExists('v2ClinicsSubsystemSubsystemIDGet', 'subsystem', subsystem);
      // verify required parameter 'subsystemID' is not null or undefined
      assertParamExists('v2ClinicsSubsystemSubsystemIDGet', 'subsystemID', subsystemID);
      const localVarPath = `/v2/clinics/{subsystem}/{subsystemID}`
        .replace(`{${'subsystem'}}`, encodeURIComponent(String(subsystem)))
        .replace(`{${'subsystemID'}}`, encodeURIComponent(String(subsystemID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClinicApi - functional programming interface
 * @export
 */
export const ClinicApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ClinicApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2ClinicsIdGet(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicDetailItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2ClinicsIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Find nearest clinics for given coordinates
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest} [terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2ClinicsNearestPost(
      terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest?: TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2ClinicsNearestPost(
        terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest} [terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2ClinicsPost(
      terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest?: TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicDetailItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2ClinicsPost(
        terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2} [terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2ClinicsSearchPost(
      terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2?: TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TerveystaloClinicAPIAbstractionsV2ClinicClinicListItem2>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2ClinicsSearchPost(
        terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get clinic ID by subsystem ID
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2ClinicsSubsystemSubsystemIDGet(
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2ClinicsSubsystemSubsystemIDGet(
        subsystem,
        subsystemID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ClinicApi - factory interface
 * @export
 */
export const ClinicApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ClinicApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsIdGet(id: string, options?: any): AxiosPromise<ClinicDetailItem> {
      return localVarFp.v2ClinicsIdGet(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Find nearest clinics for given coordinates
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest} [terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsNearestPost(
      terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest?: TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .v2ClinicsNearestPost(terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest} [terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsPost(
      terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest?: TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest,
      options?: any,
    ): AxiosPromise<Array<ClinicDetailItem>> {
      return localVarFp
        .v2ClinicsPost(terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2} [terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsSearchPost(
      terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2?: TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2,
      options?: any,
    ): AxiosPromise<Array<TerveystaloClinicAPIAbstractionsV2ClinicClinicListItem2>> {
      return localVarFp
        .v2ClinicsSearchPost(terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get clinic ID by subsystem ID
     * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
     * @param {string} subsystemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2ClinicsSubsystemSubsystemIDGet(
      subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
      subsystemID: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .v2ClinicsSubsystemSubsystemIDGet(subsystem, subsystemID, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ClinicApi - object-oriented interface
 * @export
 * @class ClinicApi
 * @extends {BaseAPI}
 */
export class ClinicApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicApi
   */
  public v2ClinicsIdGet(id: string, options?: AxiosRequestConfig) {
    return ClinicApiFp(this.configuration)
      .v2ClinicsIdGet(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Find nearest clinics for given coordinates
   * @param {TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest} [terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicApi
   */
  public v2ClinicsNearestPost(
    terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest?: TerveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClinicApiFp(this.configuration)
      .v2ClinicsNearestPost(terveystaloClinicAPIAbstractionsV2ClinicNearestClinicsRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest} [terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicApi
   */
  public v2ClinicsPost(
    terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest?: TerveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClinicApiFp(this.configuration)
      .v2ClinicsPost(terveystaloClinicAPIAbstractionsV2ClinicGetClinicsRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2} [terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicApi
   */
  public v2ClinicsSearchPost(
    terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2?: TerveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2,
    options?: AxiosRequestConfig,
  ) {
    return ClinicApiFp(this.configuration)
      .v2ClinicsSearchPost(terveystaloClinicAPIAbstractionsV2ClinicSearchClinicsRequest2, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get clinic ID by subsystem ID
   * @param {TerveystaloBaseAPIAbstractionsSubsystem} subsystem
   * @param {string} subsystemID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicApi
   */
  public v2ClinicsSubsystemSubsystemIDGet(
    subsystem: TerveystaloBaseAPIAbstractionsSubsystem,
    subsystemID: string,
    options?: AxiosRequestConfig,
  ) {
    return ClinicApiFp(this.configuration)
      .v2ClinicsSubsystemSubsystemIDGet(subsystem, subsystemID, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HealthAndMonitoringApi - axios parameter creator
 * @export
 */
export const HealthAndMonitoringApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    integrationtestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/integrationtest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthAndMonitoringApi - functional programming interface
 * @export
 */
export const HealthAndMonitoringApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthAndMonitoringApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloClinicAPICoreServiceHealthAndMonitoringGetSystemHealthResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async integrationtestGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.integrationtestGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pingGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HealthAndMonitoringApi - factory interface
 * @export
 */
export const HealthAndMonitoringApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthAndMonitoringApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthGet(options?: any): AxiosPromise<TerveystaloClinicAPICoreServiceHealthAndMonitoringGetSystemHealthResponse> {
      return localVarFp.healthGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    integrationtestGet(options?: any): AxiosPromise<void> {
      return localVarFp.integrationtestGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet(options?: any): AxiosPromise<PingResult> {
      return localVarFp.pingGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HealthAndMonitoringApi - object-oriented interface
 * @export
 * @class HealthAndMonitoringApi
 * @extends {BaseAPI}
 */
export class HealthAndMonitoringApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public healthGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .healthGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public integrationtestGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .integrationtestGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Method to test service
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public pingGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .pingGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}
