import { FetchReturnType } from 'api/ApiFetcher';
import { ApiModelType } from 'model/api';
import React, { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { ErrorPageContent } from 'ui/error-pages/ErrorPageContent';
import { useNavigationAppMessages } from 'utils/react/mobileapp-helpers';
import { useTranslations, useUiContext } from 'utils/react/ui-context';

type MaintenanceBreakType = 'manual' | 'automatic' | 'failure';
export type MaintenanceBreak = { type?: MaintenanceBreakType; data?: FetchReturnType<ApiModelType['ApiHealth']> };

export const MaintenanceBreakPage: FC<MaintenanceBreak> = ({ type = 'automatic', data }) => {
  const t = useTranslations();
  const analytics = useUiContext().analytics;
  const analyticsSent = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (analyticsSent.current === false) {
      analytics.sendMaintenanceBreakPageShown(type, data);
      analyticsSent.current = true;
    }
  }, [type, data, analytics]);

  // If the user ends up on this page when embed in mobile app, make sure the user can close the booking
  useNavigationAppMessages(location);

  return <ErrorPageContent title={t.maintenance_break} message={t.maintenance_break_booking_unavailable} />;
};
