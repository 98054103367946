/** @jsxImportSource @emotion/react */
import { Alert } from '@terveystalo/design-system-react-components';
import React, { FC } from 'react';
import { TextWithPhoneNumber } from 'ui/utils/TextWithPhoneNumber';

export const WarningCard: FC<{ text?: string; danger?: boolean; className?: string; children?: React.ReactNode }> = ({
  text,
  danger,
  children,
  className,
}) => (
  <Alert icon="Info" variant={danger ? 'error' : 'warning'} childAs="p" className={className}>
    {text && <TextWithPhoneNumber text={text} />}
    {children}
  </Alert>
);
