import * as t from 'io-ts';
import assign from 'lodash/assign';
import clone from 'lodash/clone';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import stubTrue from 'lodash/stubTrue';
import { assertIs, createError, isError } from 'model/error';

// Config values/objects are intentionally constrained to only things that serialize cleanly to JSON;
// undefined doesn't per se, but it serializes cleanly to missing values, and is thus allowed and useful.
type ConfigValue = string | string[] | Array<[string, string]> | number | boolean | undefined | null;
type ConfigObject = { [key: string]: ConfigValue | ConfigObject };
type PlainConfigLayer = string | ConfigObject | undefined | null;
type ConfigFilter = (configKey: string, configValue: unknown) => boolean;
export type ConfigLayer = PlainConfigLayer | [PlainConfigLayer, ConfigFilter];

// Takes the given baseline config, and applies zero or more "layers" on top of it.
// Each layer can override one or more key/value pairs in the config.
export function mergeConfig<
  C extends t.TypeC<any>,
  B extends t.TypeOf<C> & ConfigObject // note that we don't allow arbitrary codecs, only ones that match our accepted ConfigObject's
>(
  codec: C, // this is the io-ts "codec" (or runtime type) that specifies the shape of the resulting configuration object
  baseline: B, // these are the default values; it must contain a valid value for each key, so that we can guarantee the return value contains all keys
  layers: ConfigLayer[] = [], // these can be incomplete or string-serialized subsets of the full configuration; unknown/invalid layers are ignored
): t.TypeOf<C> {
  assertIs(codec)(baseline); // ensure that the object provided as baseline is recognized by the provided codec
  return layers.reduce(
    (memoLayer: ConfigObject, nextLayer: ConfigLayer) => {
      let filterFunc: ConfigFilter = stubTrue; // default the filter function to always return true
      if (Array.isArray(nextLayer)) {
        filterFunc = nextLayer[1]; // this is a [ layer, filter ] tuple -> unwrap it
        nextLayer = nextLayer[0];
      }
      const assumedLayer = tryAsJson(nextLayer) || tryAsQueryString(nextLayer) || nextLayer;
      if (!t.UnknownRecord.is(assumedLayer)) return memoLayer; // if despite our best efforts we can't interpret the given layer as a dictionary, give up and ignore it
      const filteredLayer = pickBy(
        pick(assumedLayer, keys(baseline)), // only include keys that are also present in the baseline
        (val, key) => filterFunc(key, val), // only include keys that pass the filter function that was provided for this layer
      );
      return assign(
        memoLayer,
        mapValues(filteredLayer, (val, key) => coerceAsNeeded(codec.name, codec.props[key], key, val)),
      );
    },
    clone(baseline), // clone to ensure we never touch the original
  );
}

// If the given codec doesn't recognize the given value as being its type, attempt to coerce the value to the desired type.
// If all attempts at coercion fail, or we don't know how to coerce to the desired type, throw an error.
function coerceAsNeeded(name: string, codec: t.Type<any>, key: string, value: any) {
  if (codec.is(value)) return value; // the value is already of the expected type -> no coercion needed
  const error = (details: string) =>
    createError.ModelValidationError(`Invalid value provided for ${name} key "${key}"`, {
      expectedModel: `io-ts/${codec.name}`,
      failureDetails: [details],
    });
  try {
    switch (codec) {
      case t.string:
        return coerceToString(value);
      case t.number:
        return coerceToNumber(value);
      case t.boolean:
        return coerceToBoolean(value);
      default:
        throw error(`Coercion into type "${codec.name}" isn't possible`);
    }
  } catch (err) {
    if (isError.ModelValidationError(err)) {
      throw err; // this error has already been refined -> let it go... LET IT GO..!
    } else {
      // eslint-disable-next-line
      // @ts-ignore: err unknown was used here 3yr already when newest version of ts started alerting
      throw error(err.message); // wrap this error into our custom type before throwing
    }
  }
}

function tryAsQueryString(input: any) {
  try {
    return parseQueryString(input);
  } catch (err) {
    return null;
  }
}

function tryAsJson(input: any) {
  try {
    return JSON.parse(input);
  } catch (err) {
    return null;
  }
}

// @example "/what/ever?foo=bar" => { foo: "bar" }
export function parseQueryString(url: string): Record<string, string> {
  const query = (url.match(/\?(.*)/) || [])[1] || url; // if there's a ?, skip everything before it; if not, use the entire string
  if (!query.match('=')) throw new Error(`Cannot parse given query string "${query}" without at least one "="`);
  return fromPairs(
    query
      .split('&')
      .map(decodeURIComponent)
      .map(part => splitOnlyOnce(part, '=')),
  );
}

// @example 'foo=bar=='.split('=', 2) => [ "foo", "bar" ]
// @example splitOnlyOnce('foo=bar==', '=') => [ "foo", "bar==" ]
export function splitOnlyOnce(stringToSplit: string, delimiter: string) {
  const parts = stringToSplit.split(delimiter);
  return [parts.shift(), parts.join(delimiter)];
}

// Coerce value to number if possible; if not, throw
function coerceToNumber(value: any): number {
  const i = parseFloat(value);
  if (isFinite(i)) return i;
  throw new Error(`Expecting number, got "${value}"`);
}

// Coerce value to boolean if possible; if not, throw
function coerceToBoolean(value: any): boolean {
  if (typeof value === 'boolean') return value;
  if ((typeof value === 'string' && value.toLowerCase() === 'true') || value === '1') return true;
  if ((typeof value === 'string' && value.toLowerCase() === 'false') || value === '0') return false;
  throw new Error(`Expecting boolean, got "${value}"`);
}

// Coerce value to string if possible; if not, throw
function coerceToString(value: any): string {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return '' + value;
  if (typeof value === 'boolean') return '' + value;
  throw new Error(`Expecting string, got "${value}"`);
}
