import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import assign from 'lodash/assign';
import isErrorLodash from 'lodash/isError';
import mapValues from 'lodash/mapValues';
import { MappedObjectAny, mapValuesExactly } from 'utils/types/misc';
import { defineModels, ModelType } from 'utils/types/models';

// These types of details are used by several custom error types having to do with validation.
// They can all still be differentiated (if needed) by their name.
const ModelValidationError = {
  expectedModel: t.string,
  failureDetails: t.array(t.string), // these are fed directly from the io-ts reporter
};

// Define a category of Models for Errors specific to our app
export const ErrorModel = defineModels({
  // These are thrown when given data doesn't match our expectations of what a specific Model type should look like
  ModelValidationError,

  // Error objects with these specific fields represent failures in converting from the API model types to our own
  ModelConversionError: ModelValidationError,

  // Same as ModelConversionError, but for bulk conversions
  ModelArrayConversionError: {
    causes: t.array(t.type(ModelValidationError)),
  },

  // Error objects with these special fields represent specific failure modes on the ADA API.
  // The "managed" part means in contrast to just the server being down, for example, where we can't know what the actual issue was.
  AdaApiManagedError: {
    apiErrorCode: t.number,
    requestMethod: t.string,
    requestUrl: t.string,
    responseData: t.any,
  },
});

// Export the compile-time types with a separate name, so both them and the run-time types can be imported at the same time, where needed
export type ErrorModelType = ModelType<typeof ErrorModel>;

// A "custom error" is just a regular Error, with an added property that:
// a) is named after the custom ErrorModel type
// b) contains the custom payload for that custom error type
type CustomError<Name extends keyof typeof ErrorModel> = Error &
  Pick<{ [key in keyof typeof ErrorModel]: ErrorModelType[key] }, Name>;

// Helpers for ensuring custom Errors have their required custom field set
export const createError: {
  [key in keyof typeof ErrorModel]: (message: string, payload: ErrorModelType[key]) => CustomError<key>;
} = mapValues(ErrorModel, (_, errorName) => (message: any, payload: any) =>
  assign(new Error(`${errorName}: ${message}`), { [errorName]: payload }),
) as MappedObjectAny;

// Type guard for checking that something is both a regular Error object and the named custom Error
export const isError: {
  [key in keyof typeof ErrorModel]: (x: unknown) => x is CustomError<key>; // inferring all this from just the mapValuesExactly() return type doesn't seem possible -> use explicit type
} = mapValuesExactly(ErrorModel, (_, errorName) => (x: any) =>
  errorName in x && ErrorModel[errorName].is(x[errorName]) && isErrorLodash(x),
) as MappedObjectAny;

export function getErrorAsString<Name extends keyof typeof ErrorModel>(err: CustomError<Name> | unknown): string {
  for (const errorName in ErrorModel) {
    if (isError[errorName as Name](err)) {
      return JSON.stringify((err as CustomError<Name>)[errorName as Name]);
    }
  }
  if (err instanceof Error) {
    return err.message;
  }
  return '';
}

// Returns a function that either throws, or returns a valid instance of the Model type provided
export function assertIs<C extends t.Any>(codec: C): (x: unknown) => t.TypeOf<C> {
  return x => {
    const result = codec.decode(x); // attempt decoding the given value as the Model type
    if (result.isRight()) {
      return result.value; // success -> return the value
    } else {
      throw createError.ModelValidationError(`Given data does NOT look like a Model of type "${codec.name}"`, {
        expectedModel: codec.name,
        failureDetails: PathReporter.report(result),
      });
    }
  };
}

export function parseErrorCode(err: any) {
  return isError.AdaApiManagedError(err)
    ? err.AdaApiManagedError.apiErrorCode
    : err.response && typeof err.response.status === 'number'
    ? (err.response.status as number)
    : undefined;
}
