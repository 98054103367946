/** @jsxImportSource @emotion/react */
import { Header } from '@terveystalo/design-system-react-components';
import React, { FC, Fragment } from 'react';
import { HeaderBanner } from 'ui/header-and-footer/HeaderBanner';
import { PartnerHeaderDetail } from 'ui/header-and-footer/PartnerHeaderDetail';
import { useResetVaccineGuide } from 'ui/vaccine-guide/CoronaVaccineGuide';
import { useApiData, useHistory, useReduxDispatch, useTranslatedRoute, useUiContext } from 'utils/react/ui-context';
import { BookingRoot, routes } from 'utils/routes/routes';
import { useResetMandatoryGuide } from 'utils/symptoms/helpers';
import { notAsked } from 'utils/types/remoteData';

type Props = {
  bookingRoot: BookingRoot;
  titleOverride?: string;
  envText?: string;
  /* for stories only */
  skipAccessCheck?: boolean;
};

export const PartnerHeader: FC<Props> = ({ bookingRoot, titleOverride, envText, skipAccessCheck }) => {
  const history = useHistory();
  const troute = useTranslatedRoute();
  const dispatch = useReduxDispatch();
  const { getCurrentTime } = useUiContext();
  const resetMandatoryGuide = useResetMandatoryGuide();
  const resetVaccineGuide = useResetVaccineGuide();

  // Check if the user has permission to use the insurance partner booking
  // If not, do not show the company details
  const getPermission = useApiData('getPermission');
  const permissionData = bookingRoot !== 'htaTeam' && !skipAccessCheck ? getPermission([bookingRoot]) : notAsked;

  return (
    <Fragment>
      <Header
        baseUrl={troute(routes[bookingRoot].root)}
        onClick={e => {
          e.preventDefault();
          dispatch.filtersReset(getCurrentTime());
          history.push(troute(routes[bookingRoot].root));
          resetMandatoryGuide();
          resetVaccineGuide();
        }}
        tag={envText}
        rightContent={
          (skipAccessCheck || permissionData.status === 'REMOTE_DATA_SUCCESS') && (
            <PartnerHeaderDetail bookingRoot={bookingRoot} />
          )
        }
      />
      <HeaderBanner bookingRoot={bookingRoot} titleOverride={titleOverride} />
    </Fragment>
  );
};
