/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { imageUrl } from '@terveystalo/design-system-cdn';
import { focusBoxShadow, List, ListItem, TextButton, TextField } from '@terveystalo/design-system-react-components';
import { theme } from '@terveystalo/design-system-theme';
import { ApiModelType, RedirectionFormQuestion } from 'model/api';
import React, { FC, Fragment, RefObject, useState } from 'react';
import { RestrictionsState } from 'reducers/filters';
import { RedirectionFormChoicesSummary } from 'ui/redirection-form/RedirectionFormChoicesSummary';
import { RedirectionFormResult } from 'ui/redirection-form/RedirectionFormResult';
import { RedirectionFormRichText } from 'ui/redirection-form/RedirectionFormRichText';
import { TextWithPhoneNumber } from 'ui/utils/TextWithPhoneNumber';
import { useValidateBookingParams } from 'utils/filters/helpers';
import { useReduxState, useTranslations } from 'utils/react/ui-context';
import { classNames } from 'utils/styles/helpers';

export const RedirectionFormContent: FC<{
  contentRef?: RefObject<HTMLDivElement>;
  redirectionForm: ApiModelType['RedirectionForm'];
  directCallOnly?: boolean;
  choices: RedirectionFormQuestion[];
  showBackAlways?: boolean;
  showChoicesSummary?: boolean;
  onSelect: (i: number) => void;
  onBack: () => void;
  onClose: (restrictions: RestrictionsState) => void;
  goToStart: () => void;
  sendResultAnalytics: (props: { type: 'chat' | 'booking' | 'link' | 'deadend'; actionParams?: string }) => void;
}> = ({
  contentRef,
  redirectionForm,
  directCallOnly,
  choices,
  showBackAlways,
  showChoicesSummary,
  onSelect,
  onBack,
  onClose,
  goToStart,
  sendResultAnalytics,
}) => {
  const t = useTranslations();
  const embedInApp = useReduxState(state => state.location.embedInApp);
  const keyBase = choices.map(c => c.questionId).join('-');
  const [searchTexts, setSearchTexts] = useState<Record<string, string>>({});

  const current = choices.length ? choices[choices.length - 1] : null;
  const children = current ? current.children : redirectionForm.children;
  const questionChildren = children.filter(c => c.kind === 'question') as RedirectionFormQuestion[];
  const titleReplacementResult = children.find(
    c => c.kind === 'result' && !!c.titleReplacement,
  ) as ApiModelType['RedirectionFormResult'];

  const currentSearchText = searchTexts[keyBase];
  const filteringText = currentSearchText ? currentSearchText.trim().toLowerCase() : undefined;

  // Validate possible params already to make load in end result faster
  useValidateBookingParams(current && current.bookingParams);

  const groupedChildren =
    children.length > 0
      ? children.slice(1).reduce(
          (list, c) => {
            const currentList = list[list.length - 1];
            const previousKind = currentList[currentList.length - 1].kind;
            if (previousKind !== c.kind) {
              list.push([c]);
            } else {
              currentList.push(c);
            }
            return list;
          },
          [[children[0]]],
        )
      : [children];

  return (
    <div ref={contentRef}>
      {!embedInApp && (!!current || showBackAlways) && (
        <TextButton as="button" type="button" iconLeft="ArrowLeft" onClick={onBack} className={classNames('mb-1')}>
          {t.back}
        </TextButton>
      )}
      <h1
        key={`redirection-form-title-${keyBase}`}
        className={classNames('text-style-subtitle-mplus', 's:text-style-subtitle-l', 'mb-1')}
        css={css`
          word-break: break-word;
        `}
      >
        {titleReplacementResult
          ? titleReplacementResult.titleReplacement
          : current
          ? current.text || current.defaultText
          : redirectionForm.name || redirectionForm.defaultName}
      </h1>
      {directCallOnly ? (
        <div className={classNames('mt-2', 'mb-5')}>
          <p className={classNames('mb-3', 'text-body-l')}>
            <TextWithPhoneNumber
              text={`${t.call_our_booking_services} ${t.tel_only_number}.`}
              replaceNumber={redirectionForm.customerServiceNumber}
            />
          </p>
          <TextButton as="button" type="button" iconLeft="ArrowLeft" onClick={goToStart}>
            {t.to_the_start}
          </TextButton>
        </div>
      ) : (
        <Fragment>
          {!current && (
            <RedirectionFormRichText
              text={redirectionForm.text || redirectionForm.defaultText}
              className={classNames('mb-0.75')}
            />
          )}
          {showChoicesSummary && !questionChildren.length && (
            <RedirectionFormChoicesSummary title={t.your_choices} choices={choices} />
          )}
          {questionChildren.length >= 20 && (
            <form
              className={classNames('relative')}
              autoComplete="off"
              noValidate={true}
              onSubmit={e => e.preventDefault()}
            >
              <TextField
                as="input"
                id={`question-search-${keyBase}`}
                icon="SearchLarge"
                label={t.search}
                value={currentSearchText || ''}
                autoComplete="off"
                onChange={e => {
                  const val = e.target.value;
                  setSearchTexts(old => ({ ...old, [keyBase]: val }));
                }}
                grayBackground={true}
                css={css`
                  margin-bottom: ${theme.spacing['0_75']};
                `}
              />
              <button
                type="button"
                aria-label={t.clear}
                className={classNames('flex', 'justify-end', 'absolute', focusBoxShadow)}
                css={css`
                  background: url(${imageUrl('icons', 'Clear')}) no-repeat center;
                  background-size: 1.5rem;
                  height: 1.5rem;
                  width: 1.5rem;
                  right: 1rem;
                  bottom: 1rem;
                  &:hover,
                  &:focus,
                  &:active {
                    background-image: url(${imageUrl('icons', 'Clear', { color: 'skyIsBlue' })});
                  }
                `}
                onClick={() => setSearchTexts(old => ({ ...old, [keyBase]: '' }))}
              />
            </form>
          )}
          {groupedChildren.map((l, i) => (
            <List
              as={l.length > 1 ? 'ul' : 'div'}
              childAs={l.length > 1 ? 'li' : 'div'}
              key={`${keyBase}-list-${i}`}
              css={
                i > 0
                  ? css`
                      margin-top: ${theme.spacing['0_75']};
                    `
                  : undefined
              }
            >
              {l.map((c, j) =>
                c.kind === 'question' ? (
                  c.disabled ||
                  (!!filteringText && !(c.text || c.defaultText).toLowerCase().includes(filteringText)) ? null : (
                    <ListItem
                      key={`${keyBase}-${i}-question-${c.questionId}`}
                      as="button"
                      className={classNames('break-words')}
                      onWhiteBackground={true}
                      css={css`
                        span {
                          font-size: ${theme.typography.fontSize.body.l};
                        }
                      `}
                      iconRight="ChevronRight"
                      onClick={() => onSelect(questionChildren.findIndex(q => q.questionId === c.questionId))}
                    >
                      {c.text || c.defaultText}
                    </ListItem>
                  )
                ) : (
                  <div
                    key={`redirection-form-${keyBase}-${i}-result-${c.resultId}`}
                    className={classNames(i > 0 && j === 0 && 'mt-1.5', j < l.length - 1 && 'mb-2')}
                  >
                    {j > 0 && (
                      <div className={classNames('flex', 'justify-center', '-mt-0.75', 'mb-1.5')}>
                        <div
                          className={classNames(
                            'text-zodiac-black-65',
                            'font-bold',
                            'uppercase',
                            'bg-white',
                            'px-0.75',
                          )}
                        >
                          {t.or}
                        </div>
                      </div>
                    )}
                    <RedirectionFormResult
                      boldTextOnly={i > 0 && j === 0}
                      asInfoText={i === 0 && !!questionChildren.length}
                      result={c}
                      choices={choices}
                      onClose={onClose}
                      replaceNumber={redirectionForm.customerServiceNumber}
                      isDeadEnd={!questionChildren.length && !children.some(c => c.kind === 'result' && c.button)}
                      sendAnalytics={sendResultAnalytics}
                    />
                  </div>
                ),
              )}
            </List>
          ))}
        </Fragment>
      )}
    </div>
  );
};
