/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';
import { cardOnNotSmallClassNames, classNames, onNotSmall } from 'utils/styles/helpers';

type Props = {
  children?: React.ReactNode;
};

export const CoronaVaccineGuideWrapper: FC<Props> = props => (
  <div
    className={classNames(
      's:px-3',
      'py-1',
      's:pt-2',
      's:pb-3',
      'mx-auto',
      's:mt-2',
      'm:mt-3',
      's:w-4/5',
      'w-full',
      cardOnNotSmallClassNames,
    )}
    css={css`
      ${onNotSmall} {
        max-width: 650px; /* to reduce the line length for easier reading */
        min-height: 550px;
      }
    `}
    {...props}
  />
);
