/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 * RedirectionAPI
 * Misc
 *
 * The version of the OpenAPI document: V1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Retrieves the form used with corono vaccine reservations with the form tree structure.              The questions and results are mapped into tree and included in the result.
     * @param {string} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiV1Coronavaccineform: async (language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'language' is not null or undefined
      assertParamExists('getApiV1Coronavaccineform', 'language', language);
      const localVarPath = `/coronavaccineform`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Retrieves the form used with corono vaccine reservations with the form tree structure.              The questions and results are mapped into tree and included in the result.
     * @param {string} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApiV1Coronavaccineform(
      language: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1Coronavaccineform(language, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Retrieves the form used with corono vaccine reservations with the form tree structure.              The questions and results are mapped into tree and included in the result.
     * @param {string} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiV1Coronavaccineform(language: string, options?: any): AxiosPromise<void> {
      return localVarFp.getApiV1Coronavaccineform(language, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Retrieves the form used with corono vaccine reservations with the form tree structure.              The questions and results are mapped into tree and included in the result.
   * @param {string} language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getApiV1Coronavaccineform(language: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getApiV1Coronavaccineform(language, options)
      .then(request => request(this.axios, this.basePath));
  }
}
