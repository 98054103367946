export type RedirectionService = {
  from: URL;
  to: URL;
};

export const getRedirectionServices = (): RedirectionService[] => {
  // todo: load from UX API
  const redirectionServicesString = getRawServices();

  if (!redirectionServicesString) {
    return [];
  }

  try {
    const redirectionServices = JSON.parse(redirectionServicesString) as { from: string; to: string }[];
    return redirectionServices.map(({ from, to }) => ({ from: new URL(from), to: new URL(to) }));
  } catch (error) {
    return [];
  }
};

const getRawServices = (): string | null => {
  if (process.env.REACT_APP_AB_REDIRECTION_SERVICES) {
    return process.env.REACT_APP_AB_REDIRECTION_SERVICES;
  }

  // on envs
  const configRaw = document.querySelector('script[type="application/frontend-config"]')?.textContent;

  if (configRaw) {
    try {
      return JSON.parse(configRaw)['REACT_APP_AB_REDIRECTION_SERVICES'];
    } catch (error) {
      return null;
    }
  }

  return null;
};
