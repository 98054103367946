import { theme } from '@terveystalo/design-system-theme';
import { Action } from 'utils/redux/actions';

const emStrToNumPx = (em: string) => parseFloat(em) * 16;

const isLargeWidth = (width: number) => width >= emStrToNumPx(theme.media.breakpoint.m);
const isSmallWidth = (width: number) => width < emStrToNumPx(theme.media.breakpoint.s);

export const initState: { isLarge: boolean; isSmall: boolean; height: number; width: number } = {
  isLarge: isLargeWidth(window.innerWidth),
  isSmall: isSmallWidth(window.innerWidth),
  height: window.innerHeight,
  width: window.innerWidth,
};

export default function reducer(state = initState, action: Action) {
  switch (action.type) {
    case 'changeScreenSize':
      return {
        ...state,
        isLarge: isLargeWidth(action.width),
        isSmall: isSmallWidth(action.width),
        height: action.height,
        width: action.width,
      };
    default:
      return state;
  }
}
