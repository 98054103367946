/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { ExternalStyledLink, InternalStyledLink } from 'ui/utils/LinkHelper';
import { useSendCloseAppMessage } from 'utils/react/mobileapp-helpers';
import { useReduxState } from 'utils/react/ui-context';
import { classNames } from 'utils/styles/helpers';

export const RedirectionFormResultLink: FC<{
  text: string;
  small?: boolean;
  linkUrl?: string;
  onClick: () => void;
}> = ({ text, small, linkUrl, onClick }) => {
  const embedInApp = useReduxState(state => state.location.embedInApp);
  const sendCloseMessage = useSendCloseAppMessage();

  // Check if the url is internal: handle those differently
  if (!linkUrl || /^\/[a-z]/.test(linkUrl)) {
    return (
      <InternalStyledLink
        to={linkUrl || ''}
        className={classNames(!small && 'text-body-l')}
        onClick={() => {
          onClick();
          if (embedInApp) sendCloseMessage(); // Close booking in app as handling changes in bookingRoot are not yet implemented
        }}
      >
        {text}
      </InternalStyledLink>
    );
  }

  // Use a component that will open in a new tab to need to handle user logout
  return (
    <ExternalStyledLink
      href={linkUrl}
      className={classNames(!small && 'text-body-l')}
      onClick={() => {
        onClick();
        if (embedInApp) setTimeout(sendCloseMessage, 500); // Close booking in app (with a delay) if user is directed to browser
      }}
    >
      {text}
    </ExternalStyledLink>
  );
};
