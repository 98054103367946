import { ApiModelType, AppointmentType } from 'model/api';
import { Action } from 'utils/redux/actions';
import { BookingRoot } from 'utils/routes/routes';

export type BookingError = {
  appointmentOrBooking?: ApiModelType['Appointment'] | ApiModelType['TemporaryBooking'] | ApiModelType['Booking'];
  callRequest?: ApiModelType['CallRequestBase'];
  appointmentType?: AppointmentType;
  title: string;
  message?: string;
};
export type BookingErrorState = BookingError | null;
export type CancelledBookingState = {
  booking: ApiModelType['Booking'] | ApiModelType['CallRequestBookingBase'];
  bookingRoot?: BookingRoot;
} | null;
export type BookingResultTitleState = string | null;
export type MandatoryGuideState = 'symptoms' | 'no-symptoms' | 'ask-test' | 'ask-test-no-symptoms' | 'not-asked';
export type VaccineGuideRoot = 'occupational' | 'municipality';
export type VaccineGuideState =
  | { type: VaccineGuideRoot; completed: false }
  | { type: VaccineGuideRoot; completed: true; choices: string }
  | null;

export const initState: {
  bookingError: BookingErrorState;
  bookingResultsTitle: BookingResultTitleState;
  cancelledBooking: CancelledBookingState;
  criticalNoticeClosed: boolean;
  criticalNoticeDentalClosed: boolean;
  infoNoticeClosed: boolean;
  infoNoticeDentalClosed: boolean;
  mandatoryGuideState: MandatoryGuideState;
  vaccineGuideState: VaccineGuideState;
} = {
  bookingError: null,
  bookingResultsTitle: null,
  cancelledBooking: null,
  criticalNoticeClosed: true,
  criticalNoticeDentalClosed: true,
  infoNoticeClosed: false,
  infoNoticeDentalClosed: false,
  mandatoryGuideState: 'not-asked',
  vaccineGuideState: null,
};

export default function reducer(state = initState, action: Action) {
  switch (action.type) {
    case 'setBookingError':
      return { ...state, bookingError: action.bookingError, bookingResultsTitle: action.bookingResultsTitle };
    case 'setCancelledBooking':
      return { ...state, cancelledBooking: action.cancelledBooking };
    case 'closeCriticalNotice':
      return { ...state, criticalNoticeClosed: true };
    case 'closeCriticalNoticeDental':
      return { ...state, criticalNoticeDentalClosed: true };
    case 'closeInfoNotice':
      return { ...state, infoNoticeClosed: true };
    case 'closeInfoNoticeDental':
      return { ...state, infoNoticeDentalClosed: true };
    case 'setMandatoryGuideState':
      return { ...state, mandatoryGuideState: action.guideState };
    case 'setVaccineGuideState':
      return { ...state, vaccineGuideState: action.guideState };
    case 'changeLang':
    case 'filterByService':
    case 'filterByLocation':
    case 'filterByDate':
    case 'filterByFromTimeOfDay':
    case 'filterByToTimeOfDay':
    case 'filterByAppointmentTypes':
    case 'filterBySpecialistGender':
    case 'filterBySpecialistLanguage':
      // User actions should hide/remove the booking error
      if (action.resetErrors) {
        return { ...state, bookingError: null, bookingResultsTitle: null };
      } else {
        return { ...state };
      }

    default:
      return state;
  }
}
