/** @jsxImportSource @emotion/react */
import flatMap from 'lodash/flatMap';
import React, { FC, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Language, languages } from 'translations/generated/translation-languages';
import { MaintenanceBreak, MaintenanceBreakPage } from 'ui/error-pages/MaintenanceBreakPage';
import { PageWrapper } from 'ui/utils/PageWrapper';
import { useConfig, useTranslations } from 'utils/react/ui-context';
import { extraRedirectRoutes } from 'utils/routes/extraRedirectRoutes';
import {
  BookingRoot,
  htaTeamBookingRoot,
  insurancePartnerBookingRoots,
  migriBookingRoot,
  normalBookingRoots,
  publicPartnerBookingRoot,
  routes,
} from 'utils/routes/routes';
import useLogging from 'utils/useLogging';

const AppointmentPreviewPage = lazy(() => import('ui/AppointmentPreviewPage'));
const BookingCancelledPage = lazy(() => import('ui/BookingCancelledPage'));
const BookingConfirmPage = lazy(() => import('ui/BookingConfirmPage'));
const BookingPatientPage = lazy(() => import('ui/BookingPatientPage'));
const BookingCallRequestPatientPage = lazy(() => import('ui/BookingCallRequestPatientPage'));
const BookingSummaryPage = lazy(() => import('ui/BookingSummaryPage'));
const FrontPage = lazy(() => import('ui/FrontPage'));
const InvoicingDetailsPage = lazy(() => import('ui/InvoicingDetailsPage'));
const LoginPage = lazy(() => import('ui/LoginPage'));
const LoggedOutPage = lazy(() => import('ui/LoggedOutPage'));
const MyAppointmentEditPage = lazy(() => import('ui/MyAppointmentEditPage'));
const MyAppointmentsPage = lazy(() => import('ui/MyAppointmentsPage'));
const MyAppointmentSummaryPage = lazy(() => import('ui/MyAppointmentSummaryPage'));
const OccupationalTeamPage = lazy(() => import('ui/OccupationalTeamPage'));
const SearchAppointmentsPage = lazy(() => import('ui/SearchAppointmentsPage'));
const SearchAppointmentsPageV2 = lazy(() => import('ui/SearchAppointmentsPageV2'));
const DirectCompensationsPage = lazy(() => import('ui/DirectCompensationsPage'));
const DirectCompensationPreviewPage = lazy(() => import('ui/DirectCompensationPreviewPage'));

export const Routes: FC<{ maintenanceBreak?: MaintenanceBreak }> = ({ maintenanceBreak }) => {
  useLogging('Routes');
  const config = useConfig();
  const t = useTranslations();
  const {
    ALLOW_INSURANCE_PARTNER,
    ALLOW_PUBLIC_PARTNER,
    ALLOW_HTA_TEAM_PAGE,
    ALLOW_MIGRI_PARTNER,
    FEATURE_PRIVATE_CUSTOMER_SEARCH_V2,
  } = config;

  const maintenanceRoute = (r: BookingRoot | undefined, lang: Language, props: MaintenanceBreak) => (
    <Route key={`maintenance-${r || 'root'}-${lang}`} path={r ? routes[r].root[lang] : routes.root[lang]}>
      <PageWrapper>
        <MaintenanceBreakPage {...props} />
      </PageWrapper>
    </Route>
  );

  return (
    <Switch>
      {extraRedirectRoutes(config).map(({ from, to }) => (
        <Redirect key={`extra-route-${from}`} exact from={from} to={{ ...to, state: { initialRedirect: true } }} />
      ))}
      {ALLOW_INSURANCE_PARTNER
        ? flatMap(insurancePartnerBookingRoots, r =>
            maintenanceBreak
              ? maintenanceRoute(r, 'fi', maintenanceBreak)
              : [
                  <Route key={`route-${r}-insurance-fi`} exact path={routes[r].insuranceDetails(':bookingId')['fi']}>
                    <PageWrapper>
                      <InvoicingDetailsPage userType="insurance" />
                    </PageWrapper>
                  </Route>,
                  ...baseBookingRoutes(r, 'fi', FEATURE_PRIVATE_CUSTOMER_SEARCH_V2),
                ],
          )
        : null}
      {ALLOW_PUBLIC_PARTNER
        ? maintenanceBreak
          ? maintenanceRoute(publicPartnerBookingRoot, 'fi', maintenanceBreak)
          : [
              <Route
                key="route-publicPartner-fi"
                exact
                path={routes[publicPartnerBookingRoot].invoicingDetails(':bookingId')['fi']}
              >
                <PageWrapper>
                  <InvoicingDetailsPage userType="otherPublic" />
                </PageWrapper>
              </Route>,
              ...baseBookingRoutes(publicPartnerBookingRoot, 'fi', FEATURE_PRIVATE_CUSTOMER_SEARCH_V2),
            ]
        : null}
      {ALLOW_HTA_TEAM_PAGE
        ? maintenanceBreak
          ? [maintenanceRoute(htaTeamBookingRoot, 'fi', maintenanceBreak)]
          : baseBookingRoutes(htaTeamBookingRoot, 'fi', FEATURE_PRIVATE_CUSTOMER_SEARCH_V2)
        : null}
      {ALLOW_MIGRI_PARTNER
        ? maintenanceBreak
          ? maintenanceRoute(migriBookingRoot, 'fi', maintenanceBreak)
          : [
              <Route key="route-migri-fi" exact path={routes[migriBookingRoot].invoicingDetails(':bookingId')['fi']}>
                <PageWrapper>
                  <InvoicingDetailsPage userType="migri" />
                </PageWrapper>
              </Route>,
              ...baseBookingRoutes(migriBookingRoot, 'fi', FEATURE_PRIVATE_CUSTOMER_SEARCH_V2),
            ]
        : null}
      {flatMap(languages, lang =>
        maintenanceBreak
          ? [
              ...normalBookingRoots.map(r => maintenanceRoute(r, lang, maintenanceBreak)),
              maintenanceRoute(undefined, lang, maintenanceBreak),
            ]
          : [
              <Route key={`route-login-${lang}`} exact path={routes.logIn[lang]}>
                <PageWrapper>
                  <LoginPage />
                </PageWrapper>
              </Route>,
              <Route key={`route-logged-out-${lang}`} exact path={routes.loggedOut[lang]}>
                <PageWrapper>
                  <LoggedOutPage />
                </PageWrapper>
              </Route>,
              <Route key={`route-auto-logged-out-${lang}`} exact path={routes.autoLoggedOut[lang]}>
                <PageWrapper>
                  <LoggedOutPage auto />
                </PageWrapper>
              </Route>,
              <Route key={`route-summary-${lang}`} exact path={routes.myAppointmentEdit(':bookingCode')[lang]}>
                <PageWrapper headerTitleOverride={t.my_appointments}>
                  <MyAppointmentEditPage />
                </PageWrapper>
              </Route>,
              <Route key={`route-summary-${lang}`} exact path={routes.myAppointmentSummary(':bookingCode')[lang]}>
                <PageWrapper headerTitleOverride={t.my_appointments}>
                  <MyAppointmentSummaryPage />
                </PageWrapper>
              </Route>,
              <Route key={`route-my-appointments-${lang}`} exact path={routes.myAppointments[lang]}>
                <PageWrapper headerTitleOverride={t.my_appointments}>
                  <MyAppointmentsPage />
                </PageWrapper>
              </Route>,
              <Route key={`route-booking-cancelled-${lang}`} exact path={routes.bookingCancelled[lang]}>
                <PageWrapper>
                  <BookingCancelledPage />
                </PageWrapper>
              </Route>,
              <Route key={`route-occupational-team-${lang}`} exact path={routes.occupationalTeam[lang]}>
                <PageWrapper headerTitleOverride={t.your_occupational_healthcare_team}>
                  <OccupationalTeamPage />
                </PageWrapper>
              </Route>,
              ...normalBookingRoots.map(r => baseBookingRoutes(r, lang, FEATURE_PRIVATE_CUSTOMER_SEARCH_V2)),
              <Route key={`route-root-${lang}`} exact path={routes.root[lang]}>
                <PageWrapper withNotice={true} noMaxWidthMain={true}>
                  <FrontPage />
                </PageWrapper>
              </Route>,
            ],
      )}
      <Redirect to={{ pathname: routes.root.fi, search: location.search, state: { initialRedirect: true } }} />
    </Switch>
  );
};

const baseBookingRoutes = (r: BookingRoot, lang: Language, v2Search: boolean) => [
  <Route key={`route-${r}-summary-${lang}`} exact path={routes[r].bookingSummary(':bookingId')[lang]}>
    <PageWrapper>
      <BookingSummaryPage />
    </PageWrapper>
  </Route>,
  <Route key={`route-${r}-confirm-${lang}`} exact path={routes[r].bookingConfirm(':bookingId')[lang]}>
    <PageWrapper>
      <BookingConfirmPage />
    </PageWrapper>
  </Route>,
  <Route
    key={`route-${r}-booking-callrequest-login-${lang}`}
    exact
    path={routes[r].bookingPatient(':slotId', 'callrequest')[lang]}
  >
    <PageWrapper>
      <BookingCallRequestPatientPage />
    </PageWrapper>
  </Route>,
  <Route
    key={`route-${r}-booking-login-with-service-${lang}`}
    exact
    path={routes[r].bookingPatientWithService(':appointmentId', ':appointmentType', ':serviceId')[lang]}
  >
    <PageWrapper>
      <BookingPatientPage />
    </PageWrapper>
  </Route>,
  <Route
    key={`route-${r}-booking-login-${lang}`}
    exact
    path={routes[r].bookingPatient(':appointmentId', ':appointmentType')[lang]}
  >
    <PageWrapper>
      <BookingPatientPage />
    </PageWrapper>
  </Route>,
  <Route
    key={`route-${r}-preview-with-service-${lang}`}
    exact
    path={routes[r].appointmentPreviewWithService(':appointmentId', ':serviceId')[lang]}
  >
    <PageWrapper>
      <AppointmentPreviewPage />
    </PageWrapper>
  </Route>,
  <Route key={`route-${r}-preview-${lang}`} exact path={routes[r].appointmentPreview(':appointmentId')[lang]}>
    <PageWrapper>
      <AppointmentPreviewPage />
    </PageWrapper>
  </Route>,
  <Route key={`route-${r}-${lang}`} exact path={routes[r].root[lang]}>
    <PageWrapper withNotice={true}>{v2Search ? <SearchAppointmentsPageV2 /> : <SearchAppointmentsPage />}</PageWrapper>
  </Route>,
  <Route key={`route-${r}-hidden-${lang}`} exact path={routes[r].privateCustomerHidden[lang]}>
    <PageWrapper withNotice={true}>{v2Search ? <SearchAppointmentsPage /> : <SearchAppointmentsPageV2 />}</PageWrapper>
  </Route>,
  <Route key={`route-${r}-direct-compensation-${lang}`} exact path={routes[r].directCompensations[lang]}>
    <PageWrapper withNotice={true}>
      <DirectCompensationsPage />
    </PageWrapper>
  </Route>,
  <Route
    key={`route-${r}-direct-compensation-preview-${lang}`}
    exact
    path={routes[r].directCompensationPreview(':claimId')[lang]}
  >
    <PageWrapper withNotice={true}>
      <DirectCompensationPreviewPage />
    </PageWrapper>
  </Route>,
];
