import compact from 'lodash/compact';
import { useContext } from 'react';
import { AnalyticsContext } from 'utils/analytics/AnalyticsContextProvider';
import { selfTriageIdFromParam } from 'utils/booking/helpers';
import { getIcIDFromParam } from 'utils/partnerAndInsurance/helpers';
import { useApiData, useEnvironment, useReduxState } from 'utils/react/ui-context';
import { assertExhausted } from 'utils/types/misc';
import { notAsked } from 'utils/types/remoteData';

const useBookingFeedbackParams = () => {
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const auth = useReduxState(state => state.auth);
  const delegate = useReduxState(state => state.delegate);
  const getOccupationalData = useApiData('getOccupationalData');
  const analyticsState = useContext(AnalyticsContext);

  let typeText = '';
  if (delegate.status !== 'NOTHING') {
    typeText = 'puolesta';
  } else {
    switch (bookingRoot) {
      case 'privateCustomer':
      case 'privateCustomerV2':
        typeText = 'yksityinen';
        break;
      case 'occupationalHealthcare':
        const occupationalData = auth.status === 'LOGGED_IN' ? getOccupationalData([auth.token]) : notAsked;
        if (occupationalData.status === 'REMOTE_DATA_SUCCESS' && occupationalData.data.redirectionFormId) {
          typeText = 'tyoterveyslomake';
        } else {
          typeText = 'tyoterveys';
        }
        break;
      case 'dental':
        typeText = 'hammas';
        break;
      case 'insurance':
        typeText = 'vakuutus';
        break;
      case 'voucher':
        typeText = 'palveluseteli';
        break;
      case 'terveysmestari':
      case 'terveyshelppi':
      case 'fenniahoitaja':
        typeText = 'vakuutusyhtio';
        break;
      case 'publicPartner':
        typeText = 'julkisenkumppani';
        break;
      case 'htaTeam':
        typeText = 'htatiimi';
        break;
      case 'migri':
        typeText = 'Migri';
        break;
      case undefined:
        break;
      default:
        assertExhausted(bookingRoot);
    }
  }

  // Return a function as the piwik id can change without any state change (from user's consent manager selection)
  return () => {
    const piwikId = analyticsState.getPiwikVisitorId() || undefined;
    return {
      asiakastyyppi: typeText,
      pusv: piwikId,
    };
  };
};

export const useFeedbackTags = (context: string) => {
  const { lang, embedInApp } = useReduxState(state => state.location);
  const isSmall = useReduxState(state => state.screenSize.isSmall);
  const getParams = useBookingFeedbackParams();
  const environment = useEnvironment();
  const selfTriageId = selfTriageIdFromParam(window.location.search);
  const icid = getIcIDFromParam(window.location.search);
  const { selectedDirectCompensation } = useReduxState(state => state.location);

  return () => {
    const paramsObj = getParams();
    const paramsList = compact(
      Object.keys(paramsObj).map(name => {
        const value = paramsObj[name as keyof typeof paramsObj];
        if (value !== undefined) {
          return { name, value };
        }
        return null;
      }),
    );

    const result = [
      ...paramsList,
      { name: 'laite', value: embedInApp ? 'sovellus' : isSmall ? 'mobiili' : 'desktop' },
      { name: 'language', value: lang },
      { name: 'asiointitapa', value: 'ajanvaraus' },
      { name: 'environment', value: environment },
      { name: 'context', value: context },
    ];

    if (selfTriageId) {
      result.push({ name: 'self-triage', value: 'true' });
    }

    if (!!icid) {
      result.push({ name: 'insurance-company-id', value: icid });
    }

    if (!!selectedDirectCompensation) {
      result.push({ name: 'direct-invoicing-customer', value: 'true' });
    }

    return result;
  };
};
