import { ApiModelType } from 'model/api';
import { DelegateError, DelegateToken } from 'reducers/delegate';
import { defineActionCreators } from 'utils/redux/actions';

export default defineActionCreators({
  initDelegation(age: number | undefined) {
    return { age };
  },
  setDelegateRegistration(registration: string, code: string) {
    return { registration, code };
  },
  setDelegateAge(age: number | undefined) {
    return { age };
  },
  delegateTokenLoading() {
    return {};
  },
  delegateError(error: DelegateError) {
    return { error };
  },
  setDelegateToken(token: DelegateToken) {
    return { token };
  },
  delegateUserDataLoading(token: DelegateToken) {
    return { token };
  },
  newDelegateUser(name: string, token: DelegateToken, age: number | undefined) {
    return { name, token, age };
  },
  delegateLogin(user: ApiModelType['DelegateUser'], token: DelegateToken, age: number | undefined) {
    return { user, token, age };
  },
  delegateChange() {
    return {};
  },
  delegateExit() {
    return {};
  },
});
