import startsWith from 'lodash/startsWith';
import { ApiModel } from 'model/api';
import { Action } from 'utils/redux/actions';
import { deepSet } from 'utils/types/deepGetSet';
import { OneOrArrayOf } from 'utils/types/misc';
import { AnyModel } from 'utils/types/models';
import { RemoteData } from 'utils/types/remoteData';

export const initState = {
  data: {} as {
    [resourceId: string]:
      | RemoteData<OneOrArrayOf<AnyModel<typeof ApiModel> | string>> // some resources may be singular, some arrays
      | undefined; // ...because not every resourceId will be defined!
  },
};

export default function reducer(state = initState, action: Action) {
  switch (action.type) {
    case 'fetchStarted':
      return deepSet(state, 'data', action.resourceId, { status: 'REMOTE_DATA_LOADING' });
    case 'fetchSucceeded':
      return deepSet(state, 'data', action.resourceId, {
        status: 'REMOTE_DATA_SUCCESS',
        data: action.data,
      });
    case 'fetchFailed':
      return deepSet(state, 'data', action.resourceId, {
        status: 'REMOTE_DATA_FAILURE',
        error: action.errorMessage,
        errorCode: action.errorCode,
        errorId: action.errorid,
      });
    case 'clearData':
      const { [action.resourceId]: removed, ...rest } = state.data;
      return { ...state, data: rest };
    case 'clearDataWithPatterns':
      const newData = { ...state.data };
      Object.keys(state.data).forEach(k => {
        if (action.patterns.some(p => startsWith(k, p))) {
          delete newData[k];
        }
      });
      return { ...state, data: newData };
    case 'clearAllApiData':
      return initState;
    default:
      return state;
  }
}
