import { MenuItem } from '@terveystalo/design-system-react-components';
import { Header as DSHeader } from '@terveystalo/design-system-react-organisms';
import { AuthState } from '@terveystalo/design-system-react-organisms/dist/types/Header';
import React, { FC, Fragment } from 'react';
import { useLocation } from 'react-router';
import { initState } from 'reducers/auth';
import { HeaderBanner } from 'ui/header-and-footer/HeaderBanner';
import { OccupationalContractWrapper } from 'ui/occupational/OccupationalContractWrapper';
import { useTtComServicesUrl } from 'utils/ext-links/helpers';
import { Environment, useHistory, useReduxState, useTranslatedRoute, useTranslations } from 'utils/react/ui-context';
import { routeMatcher } from 'utils/routes/helpers';
import { BookingRoot, routes } from 'utils/routes/routes';
import { assertExhausted } from 'utils/types/misc';

type Props = {
  auth: typeof initState;
  environment: Environment;
  bookingRoot: BookingRoot | undefined;
  titleOverride?: string;
  onLoginClick: () => void;
  onLogoutClick: () => void;
};

export const HeaderMain: FC<Props> = ({
  auth,
  environment,
  bookingRoot,
  titleOverride,
  onLoginClick,
  onLogoutClick,
}) => {
  const t = useTranslations();
  const troute = useTranslatedRoute();
  const lang = useReduxState(state => state.location.lang);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const currentLangRoute = routeMatcher(pathname, lang);
  const ttComUrl = useTtComServicesUrl();

  let authState: AuthState;
  switch (auth.status) {
    case 'NOTHING':
      authState = { type: 'LoggedOut' };
      break;
    case 'ERROR':
      authState =
        auth.error.type === 'oneWelcome_error' ? { type: 'LoggedOut' } : { type: 'LoggedIn', user: { type: 'Error' } };
      break;
    case 'TOKEN_RETRIEVED':
    case 'LOADING_USER_DATA':
      authState = { type: 'LoggedIn', user: { type: 'Loading' } };
      break;
    case 'USER_NOT_REGISTERED':
      // We do not (yet) have the user name data in this case
      authState = { type: 'LoggedIn', user: { type: 'Success', lastName: '' } };
      break;
    case 'LOGGED_IN':
      authState = {
        type: 'LoggedIn',
        user: { type: 'Success', givenNames: auth.user.firstName, lastName: auth.user.lastName },
      };
      break;
    default:
      authState = assertExhausted(auth);
  }

  return (
    <Fragment>
      <DSHeader
        authState={authState}
        environment={environment}
        subtitle={{
          // If more use cases for translation record emerges, implement generalised functionality to provide them from translations
          fi: 'Verkkopalvelu',
          sv: 'Webbtjänst',
          en: 'Online Service',
        }}
        language={lang}
        localizedUrls={{
          fi: history.createHref({ pathname: currentLangRoute.fi, search }),
          sv: history.createHref({ pathname: currentLangRoute.sv, search }),
          en: history.createHref({ pathname: currentLangRoute.en, search }),
        }}
        activeService="booking"
        baseUrl={troute(routes.root)}
        onLoginClick={onLoginClick}
        onLogoutClick={onLogoutClick}
        additionalUserMenuItems={[
          ...(bookingRoot === 'occupationalHealthcare' && auth.status === 'LOGGED_IN'
            ? [
                <OccupationalContractWrapper
                  key="occupational-health-contract"
                  name="Menu"
                  buttonComponent={props => (
                    <MenuItem as="button" iconLeft="Document" {...props}>
                      {t.information_your_occupational_services}
                    </MenuItem>
                  )}
                />,
              ]
            : []),
          <MenuItem key="my-appointments-link" as="a" iconLeft="Edit" href={ttComUrl}>
            {t.my_booked_appointments}
          </MenuItem>,
        ]}
      />
      <HeaderBanner bookingRoot={bookingRoot} titleOverride={titleOverride} />
    </Fragment>
  );
};
