/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 * AppointmentAPI
 * <p>Accept-Language header supported values: fi-FI (default),sv-FI,en-FI</p><p>Using preview features add header for request                      -H \'Preview: true\'</p><p>Authorization token can be created with                  CustomerAPI POST /v2/weaktoken. It must be passed with                  api-key header in format: Bearer {token}</p>
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AppointmentBookingBackendBaseAPIExceptionsInvalidParameterException
 */
export interface AppointmentBookingBackendBaseAPIExceptionsInvalidParameterException {
  /**
   *
   * @type {string}
   * @memberof AppointmentBookingBackendBaseAPIExceptionsInvalidParameterException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface AppointmentReservationConfirmedCancelPost499Response
 */
export interface AppointmentReservationConfirmedCancelPost499Response {
  /**
   *
   * @type {string}
   * @memberof AppointmentReservationConfirmedCancelPost499Response
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof AppointmentReservationConfirmedCancelPost499Response
   */
  message?: string;
  /**
   * Error specific details
   * @type {any}
   * @memberof AppointmentReservationConfirmedCancelPost499Response
   */
  details?: any;
}
/**
 *
 * @export
 * @enum {string}
 */

export const CallRequestCustomerType = {
  Occupational: 'Occupational',
  Private: 'Private',
} as const;

export type CallRequestCustomerType = typeof CallRequestCustomerType[keyof typeof CallRequestCustomerType];

/**
 *
 * @export
 * @interface Health
 */
export interface Health {
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOHealthState}
   * @memberof Health
   */
  System?: TerveystaloAppointmentAPIAPIAbstractionV1DTOHealthState;
  /**
   *
   * @type {{ [key: string]: TerveystaloAppointmentAPIAPIAbstractionV1DTOHealthState; }}
   * @memberof Health
   */
  Features?: { [key: string]: TerveystaloAppointmentAPIAPIAbstractionV1DTOHealthState } | null;
}
/**
 *
 * @export
 * @interface Localized
 */
export interface Localized {
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  TwoLetterISOLanguage?: string | null;
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  Value?: string | null;
}
/**
 *
 * @export
 * @interface PingResult
 */
export interface PingResult {
  /**
   *
   * @type {string}
   * @memberof PingResult
   */
  Message?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIAPIAbstractionAPICustomerType = {
  Occupational: 'Occupational',
  Municipality: 'Municipality',
  Insurance: 'Insurance',
  Private: 'Private',
} as const;

export type TerveystaloAppointmentAPIAPIAbstractionAPICustomerType = typeof TerveystaloAppointmentAPIAPIAbstractionAPICustomerType[keyof typeof TerveystaloAppointmentAPIAPIAbstractionAPICustomerType];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIAPIAbstractionAppointmentType = {
  ChatReception: 'ChatReception',
  PhoneReception: 'PhoneReception',
  Reception: 'Reception',
  RemoteReception: 'RemoteReception',
  CallRequest: 'CallRequest',
} as const;

export type TerveystaloAppointmentAPIAPIAbstractionAppointmentType = typeof TerveystaloAppointmentAPIAPIAbstractionAppointmentType[keyof typeof TerveystaloAppointmentAPIAPIAbstractionAppointmentType];

/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsAppointmentNotFoundAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsAppointmentNotFoundAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsAppointmentNotFoundAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsAppointmentReservationFailedAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsAppointmentReservationFailedAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsAppointmentReservationFailedAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsCancelReservationFailedAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsCancelReservationFailedAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsCancelReservationFailedAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsCustomerAgeNotWithinSpecialistLimitsAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsCustomerAgeNotWithinSpecialistLimitsAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsCustomerAgeNotWithinSpecialistLimitsAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsCustomerHasConflictingAppointmentAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsCustomerHasConflictingAppointmentAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsCustomerHasConflictingAppointmentAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsInvalidAppointmentTypeException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsInvalidAppointmentTypeException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsInvalidAppointmentTypeException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsInvalidOnBehalfConnectionApiException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsInvalidOnBehalfConnectionApiException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsInvalidOnBehalfConnectionApiException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsOccupationalCustomerAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsOccupationalCustomerAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsOccupationalCustomerAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsPrivateCustomerTypeBlockedForSpecialistAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsPrivateCustomerTypeBlockedForSpecialistAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsPrivateCustomerTypeBlockedForSpecialistAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsReservationIsOutDatedAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsReservationIsOutDatedAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsReservationIsOutDatedAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsReservationNotFoundAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsReservationNotFoundAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsReservationNotFoundAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsSubsystemDownAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsSubsystemDownAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsSubsystemDownAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsSubsystemTimeoutAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsSubsystemTimeoutAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsSubsystemTimeoutAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionExceptionsTooManyReservationsForCustomerAPIException
 */
export interface TerveystaloAppointmentAPIAPIAbstractionExceptionsTooManyReservationsForCustomerAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionExceptionsTooManyReservationsForCustomerAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItem
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItem {
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItem
   */
  Appointments?: Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment> | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAlternativeClinic}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItem
   */
  Clinic?: TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAlternativeClinic;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAlternativeClinic
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAlternativeClinic {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAlternativeClinic
   */
  Id?: string;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAlternativeClinic
   */
  Name?: Array<Localized> | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment
   */
  DateTime?: string;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment
   */
  Specialist?: TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment
   */
  DurationInMinutes?: number;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment
   */
  Service?: TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAppointmentType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItemAppointment
   */
  AppointmentType?: TerveystaloAppointmentAPIAPIAbstractionAppointmentType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIAPIAbstractionV1DTOApiAppointmentFlag = {
  Emergency: 'Emergency',
} as const;

export type TerveystaloAppointmentAPIAPIAbstractionV1DTOApiAppointmentFlag = typeof TerveystaloAppointmentAPIAPIAbstractionV1DTOApiAppointmentFlag[keyof typeof TerveystaloAppointmentAPIAPIAbstractionV1DTOApiAppointmentFlag];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk = {
  None: 'None',
  Respiratory: 'Respiratory',
} as const;

export type TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk = typeof TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk[keyof typeof TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk];

/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentDateCount
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentDateCount {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentDateCount
   */
  Date?: string;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentDateCount
   */
  AppointmentCount?: number;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  DateTime?: string;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  Clinic?: TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  Specialist?: TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  DurationInMinutes?: number;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  AppointmentTypes?: Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType> | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  Service?: TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  Services?: Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem> | null;
  /**
   *
   * @type {Set<TerveystaloAppointmentAPIAPIAbstractionV1DTOApiAppointmentFlag>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  Flags?: Set<TerveystaloAppointmentAPIAPIAbstractionV1DTOApiAppointmentFlag> | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem
   */
  HideClinicDetails?: boolean;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOCallRequestDateCount
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOCallRequestDateCount {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOCallRequestDateCount
   */
  Date?: string;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOCallRequestDateCount
   */
  CallRequestCount?: number;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
   */
  Id?: string;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
   */
  CheckInInstructions?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
   */
  ArrivalInstructions?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
   */
  StreetAddress?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
   */
  CityName?: Array<Localized> | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
   */
  PostCode?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1RequestGeoLocation}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem
   */
  GeoLocation?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGeoLocation;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTempReservationResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTempReservationResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTempReservationResponse
   */
  Id?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTemporaryCallRequestReservationResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTemporaryCallRequestReservationResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTemporaryCallRequestReservationResponse
   */
  ReservationId?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  DateTime?: string;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  Service?: TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  Clinic?: TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  Specialist?: TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  DurationInMinutes?: number;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAppointmentType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  AppointmentType?: TerveystaloAppointmentAPIAPIAbstractionAppointmentType;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  AppointmentTypes?: Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType> | null;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  Services?: Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem> | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  HideClinicDetails?: boolean;
  /**
   *
   * @type {Set<TerveystaloAppointmentAPIAPIAbstractionV1DTOApiAppointmentFlag>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse
   */
  Flags?: Set<TerveystaloAppointmentAPIAPIAbstractionV1DTOApiAppointmentFlag> | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableCallRequestResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableCallRequestResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableCallRequestResponse
   */
  IntervalStartDateTime?: string;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableCallRequestResponse
   */
  IntervalDurationInMinutes?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableCallRequestResponse
   */
  SpecialistId?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedCallRequestReservationResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedCallRequestReservationResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedCallRequestReservationResponse
   */
  IntervalStartDateTime?: string;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedCallRequestReservationResponse
   */
  IntervalDurationInMinutes?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedCallRequestReservationResponse
   */
  SpecialistId?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedCallRequestReservationResponse
   */
  PrincipalName?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  DateTime?: string;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  Clinic?: TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  Specialist?: TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  DurationInMinutes?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  WebCode?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAppointmentType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  AppointmentType?: TerveystaloAppointmentAPIAPIAbstractionAppointmentType;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  InfectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  LocalAlternativeReceptionInfectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  WorkTimeType?: string | null;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  OptionalAppointmentTypes?: Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType> | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  Service?: TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  FallbackSpecialistName?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPICoreDomainCustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  CustomerType?: TerveystaloAppointmentAPICoreDomainCustomerType;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  PrincipalName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  HideClinicDetails?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  VisibleForGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse
   */
  Accident?: boolean;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse {
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAppointmentType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse
   */
  AppointmentType?: TerveystaloAppointmentAPIAPIAbstractionAppointmentType;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse
   */
  WorkTimeType?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPICoreDomainCustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse
   */
  CustomerType?: TerveystaloAppointmentAPICoreDomainCustomerType;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse
   */
  Datetime?: string;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse
   */
  Specialist?: TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse
   */
  SpecialistId?: string;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse
   */
  IntervalDurationInMinutes?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse
   */
  IntervalStartDateTime?: string;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse
   */
  TemporaryReservationExpiresInSeconds?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse
   */
  PrincipalName?: string | null;
  /**
   * Customer ID when user makes reservation on behalf of another person.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse
   */
  PrincipalCustomerId?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  DateTime?: string;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  Clinic?: TerveystaloAppointmentAPIAPIAbstractionV1DTOClinicItem;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  Specialist?: TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  DurationInMinutes?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  TemporaryReservationExpires?: string;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAppointmentType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  AppointmentType?: TerveystaloAppointmentAPIAPIAbstractionAppointmentType;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  InfectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  LocalAlternativeReceptionInfectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  WorkTimeType?: string | null;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  OptionalAppointmentTypes?: Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType> | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  Service?: TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  FallbackSpecialistName?: string | null;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  TemporaryReservationExpiresInSeconds?: number;
  /**
   *
   * @type {TerveystaloAppointmentAPICoreDomainCustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  CustomerType?: TerveystaloAppointmentAPICoreDomainCustomerType;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  PrincipalName?: string | null;
  /**
   * Customer ID when user makes reservation on behalf of another person.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  PrincipalCustomerId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  HideClinicDetails?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse
   */
  Accident?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIAPIAbstractionV1DTOHealthState = {
  Ok: 'Ok',
  Error: 'Error',
  Maintenance: 'Maintenance',
} as const;

export type TerveystaloAppointmentAPIAPIAbstractionV1DTOHealthState = typeof TerveystaloAppointmentAPIAPIAbstractionV1DTOHealthState[keyof typeof TerveystaloAppointmentAPIAPIAbstractionV1DTOHealthState];

/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOIsAppointmentAvailableResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOIsAppointmentAvailableResponse {
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOIsAppointmentAvailableResponse
   */
  AppointmentAvailable?: boolean;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem
   */
  Id?: string;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem
   */
  PreInformation?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOServiceItem
   */
  ReservationInstructions?: Array<Localized> | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
   */
  Id?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
   */
  FullName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
   */
  FirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
   */
  LastName?: string | null;
  /**
   * Specialist initials concatenated firstname + lastname firstletters
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
   */
  Intitials?: string | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
   */
  Title?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
   */
  PreInformation?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1DTOSpecialistItem
   */
  ReservationInstructions?: Array<Localized> | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest
   */
  CustomerId: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest
   */
  AppointmentId: string;
  /**
   * Reason text is limited to 60 characters in some case e.g. Appointment is in DH.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest
   */
  Reason?: string | null;
  /**
   * CustomerAPI generated token for delegation.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest
   */
  DelegateToken?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  AppointmentId?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  CustomerId?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  ServiceId?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAppointmentType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  AppointmentType?: TerveystaloAppointmentAPIAPIAbstractionAppointmentType;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  CustomerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType;
  /**
   * CustomerAPI generated token for delegation.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  DelegateToken?: string | null;
  /**
   * Customer ID when user makes reservation on behalf of another person.  Can\'t be used with DelegateToken.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  PrincipalCustomerId?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  InfectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  VisibleForGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  Accident?: boolean;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest
   */
  SelfTriageId?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  ReservationId?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  SmsNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  PhoneNumberToCall?: string | null;
  /**
   * CustomerAPI generated token for delegation.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  DelegateToken?: string | null;
  /**
   * Customer name when user makes reservation on behalf of another person.  Can\'t be used with DelegateToken.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  PrincipalName?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  CustomerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  EstimatedPrice?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  VisibleForGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  Accident?: boolean;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest
   */
  ReminderPhoneNumber?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  AppointmentId?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  CustomerId?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  Reason?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  GiftCardNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  SMSNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  DentalPhoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  PhoneNumberToCall?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAppointmentType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  AppointmentType?: TerveystaloAppointmentAPIAPIAbstractionAppointmentType;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  EstimatedPrice?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  InsuranceCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  PartnerCompany?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPICoreDomainPartnerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  PartnerType?: TerveystaloAppointmentAPICoreDomainPartnerType;
  /**
   * CustomerAPI generated token for delegation.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  DelegateToken?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  CustomerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType;
  /**
   * Check-in at the checkout
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  BookingOnlyAtReception?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  OutsourcedService?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  VisibleForGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  Accident?: boolean;
  /**
   * Used only when VisibleForGuardian is false
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  ReminderPhoneNumber?: string | null;
  /**
   * Customer ID when user makes reservation on behalf of another person.  Can\'t be used with DelegateToken.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  PrincipalCustomerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest
   */
  DirectCompensationPermitId?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest
   */
  AppointmentId?: string;
  /**
   * CustomerAPI generated token for delegation.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest
   */
  DelegateToken?: string | null;
  /**
   * Customer ID when user makes reservation on behalf of another person.  Can\'t be used with DelegateToken.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest
   */
  PrincipalCustomerId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest
   */
  VisibleForGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest
   */
  Accident?: boolean;
  /**
   *
   * @type {CallRequestCustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest
   */
  CustomerType?: CallRequestCustomerType;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestDate
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestDate {
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDate
   */
  Year?: number;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDate
   */
  Month?: number;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDate
   */
  Day?: number;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest {
  /**
   * Appointment reservationId
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
   */
  ReservationId: string;
  /**
   * CustomerId
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
   */
  CustomerId: string;
  /**
   * Validity period
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
   */
  ValidityPeriod: string;
  /**
   * Direct invoicing services
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
   */
  DirectInvoicingServices?: Array<string> | null;
  /**
   * Insurance company appointer name
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
   */
  AppointerName: string;
  /**
   * Other information
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
   */
  OtherInformation?: string | null;
  /**
   * Payer number
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
   */
  PayerNumber: string;
  /**
   * Appointer email address
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest
   */
  AppointerEmail: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIAPIAbstractionV1RequestGender = {
  Male: 'Male',
  Female: 'Female',
} as const;

export type TerveystaloAppointmentAPIAPIAbstractionV1RequestGender = typeof TerveystaloAppointmentAPIAPIAbstractionV1RequestGender[keyof typeof TerveystaloAppointmentAPIAPIAbstractionV1RequestGender];

/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestGeoLocation
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestGeoLocation {
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGeoLocation
   */
  Latitude?: number;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGeoLocation
   */
  Longitude?: number;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest {
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1RequestDate}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  From?: TerveystaloAppointmentAPIAPIAbstractionV1RequestDate;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  Days?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  FromTimeSpan?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  ToTimeSpan?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  SpecialistIDs?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  ClinicIDs?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  ServiceID?: string | null;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  AppointmentTypes?: Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType> | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  CustomerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  InfectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk;
  /**
   * Used to filter out appointments by age
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  CustomerAge?: number | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  SpecialistTwoLetterIsoLanguage?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1RequestGender}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  Gender?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGender;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest
   */
  DurationInMinutes?: number | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest {
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1RequestDate}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  From?: TerveystaloAppointmentAPIAPIAbstractionV1RequestDate;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  Days?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  FromTimeSpan?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  ToTimeSpan?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  SpecialistIDs?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  ClinicIDs?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  ServiceID?: string | null;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  AppointmentTypes?: Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType> | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  InfectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1RequestGender}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  Gender?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGender;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  SpecialistTwoLetterISOLanguage?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  CustomerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType;
  /**
   * If MinimumCount appointments are not found in From + Days,  then search next days until  MaximumLookaheadDays is reached.
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  MinimumCount?: number | null;
  /**
   * See MinimumCount. Default 14.
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  MaximumLookaheadDays?: number | null;
  /**
   * Used to filter out appointments by age
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  CustomerAge?: number | null;
  /**
   * Items to skip from beginning. Used for pagination.
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  Skip?: number;
  /**
   * Maximum count of items to return. Used for pagination.
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  Take?: number;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  DisableRevenueManagement?: boolean;
  /**
   * Used to filter out appointments by duration
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest
   */
  DurationInMinutes?: number | null;
}
/**
 * New request for pagination response. Key different from base is limitation on the take parameter.
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest {
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1RequestDate}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  From?: TerveystaloAppointmentAPIAPIAbstractionV1RequestDate;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  Days?: number;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  FromTimeSpan?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  ToTimeSpan?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  SpecialistIDs?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  ClinicIDs?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  ServiceID?: string | null;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  AppointmentTypes?: Array<TerveystaloAppointmentAPIAPIAbstractionAppointmentType> | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  InfectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionV1RequestGender}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  Gender?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGender;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  SpecialistTwoLetterISOLanguage?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  CustomerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType;
  /**
   * If MinimumCount appointments are not found in From + Days,  then search next days until  MaximumLookaheadDays is reached.
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  MinimumCount?: number | null;
  /**
   * See MinimumCount. Default 14.
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  MaximumLookaheadDays?: number | null;
  /**
   * Used to filter out appointments by age
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  CustomerAge?: number | null;
  /**
   * Items to skip from beginning. Used for pagination.
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  Skip?: number;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  DisableRevenueManagement?: boolean;
  /**
   * Used to filter out appointments by duration
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  DurationInMinutes?: number | null;
  /**
   * Maximum count of items to return. Used for pagination.
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest
   */
  Take?: number;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest {
  /**
   * Appointment reservationId
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  ReservationId: string;
  /**
   * CustomerId
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  CustomerId: string;
  /**
   * Validity period
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  ValidityPeriod: string;
  /**
   * Direct invoicing services
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  DirectInvoicingServices?: Array<string> | null;
  /**
   * Insurance company appointer name
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  AppointerName: string;
  /**
   * Other information
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  OtherInformation?: string | null;
  /**
   * Insurance organization name
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  InsuranceOrganization: string;
  /**
   * Invoicing max amount
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  InvoicingMaxAmount?: string | null;
  /**
   * Patient deductible amount
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  PatientDeductibleAmount?: string | null;
  /**
   * Social Insurance Institute direct compensation noted
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  SiiDirectCompensationNoted: boolean;
  /**
   * Injury type
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  InjuryType: string;
  /**
   * Issue number
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  IssueNumber: string;
  /**
   * Accident date
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  AccidentDate?: string;
  /**
   * Company
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  Company?: string | null;
  /**
   * Symptom
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  Symptom: string;
  /**
   * Additional information for Terveystalo Customerservice
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest
   */
  CustomerServiceInfo?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest {
  /**
   * Appointment reservationId
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  ReservationId: string;
  /**
   * CustomerId
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  CustomerId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  AllowedServices?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  PatientUmaNumber: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  BookerName: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  BookerEmail: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  ReceptionCenterName: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  ReceptionCenterCustomerNumber: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  ReceptionCenterPostalCode: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  ReceptionCenterCity: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  ReceptionCenterAdress: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  ReceptionCenterNursePhoneNum: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  InterpreterDetails: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest
   */
  Notes: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  AppointmentId?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  CustomerId?: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  Reason?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  GiftCardNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  SMSNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  PhoneNumberToCall?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAppointmentType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  AppointmentType?: TerveystaloAppointmentAPIAPIAbstractionAppointmentType;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  EstimatedPrice?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  VisibleForGuardian?: boolean;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  InsuranceCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  PartnerCompany?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPICoreDomainPartnerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  PartnerType?: TerveystaloAppointmentAPICoreDomainPartnerType;
  /**
   * Used only when VisibleForGuardian is false
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  ReminderPhoneNumber?: string | null;
  /**
   * CustomerAPI generated token for delegation.
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  DelegateToken?: string | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest
   */
  CustomerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponse
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponse {
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponseSlot>}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponse
   */
  Slots?: Array<TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponseSlot> | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponseSlot
 */
export interface TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponseSlot {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponseSlot
   */
  Id?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponseSlot
   */
  IntervalStartDateTime?: string;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponseSlot
   */
  IntervalDurationInMinutes?: number;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIControllersV1APIEmployeeContractLimitation
 */
export interface TerveystaloAppointmentAPIControllersV1APIEmployeeContractLimitation {
  /**
   *
   * @type {Set<TerveystaloAppointmentAPIControllersV1ClinicRule>}
   * @memberof TerveystaloAppointmentAPIControllersV1APIEmployeeContractLimitation
   */
  Clinics?: Set<TerveystaloAppointmentAPIControllersV1ClinicRule> | null;
  /**
   *
   * @type {Set<string>}
   * @memberof TerveystaloAppointmentAPIControllersV1APIEmployeeContractLimitation
   */
  SpecialistIds?: Set<string> | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIControllersV1ClinicRule
 */
export interface TerveystaloAppointmentAPIControllersV1ClinicRule {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIControllersV1ClinicRule
   */
  Id?: string;
  /**
   * Whether this is customer\'s home clinic
   * @type {boolean}
   * @memberof TerveystaloAppointmentAPIControllersV1ClinicRule
   */
  Home?: boolean;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest
 */
export interface TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest
   */
  TemporaryReservationId: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest
   */
  ServiceId: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest
   */
  EmailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest
   */
  Reason?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest
   */
  SmsNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest
   */
  EstimatedPrice?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIControllersV1OccupationalAppointmentsResponse
 */
export interface TerveystaloAppointmentAPIControllersV1OccupationalAppointmentsResponse {
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem>}
   * @memberof TerveystaloAppointmentAPIControllersV1OccupationalAppointmentsResponse
   */
  AvailableAppointments?: Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem> | null;
  /**
   *
   * @type {TerveystaloAppointmentAPIControllersV1APIEmployeeContractLimitation}
   * @memberof TerveystaloAppointmentAPIControllersV1OccupationalAppointmentsResponse
   */
  OccupationalRules?: TerveystaloAppointmentAPIControllersV1APIEmployeeContractLimitation;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPICoreDomainCustomerType = {
  Occupational: 'Occupational',
  Municipality: 'Municipality',
  Insurance: 'Insurance',
  Private: 'Private',
} as const;

export type TerveystaloAppointmentAPICoreDomainCustomerType = typeof TerveystaloAppointmentAPICoreDomainCustomerType[keyof typeof TerveystaloAppointmentAPICoreDomainCustomerType];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPICoreDomainPartnerType = {
  InsuranceCompany: 'InsuranceCompany',
  Other: 'Other',
} as const;

export type TerveystaloAppointmentAPICoreDomainPartnerType = typeof TerveystaloAppointmentAPICoreDomainPartnerType[keyof typeof TerveystaloAppointmentAPICoreDomainPartnerType];

/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponse
 */
export interface TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponse {
  /**
   *
   * @type {TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus}
   * @memberof TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponse
   */
  Status?: TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus = {
  Ok: 'Ok',
  Error: 'Error',
} as const;

export type TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus = typeof TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus[keyof typeof TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponseResponseStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany = {
  Stub: 'Stub',
  Adis: 'Adis',
  Aktia: 'Aktia',
  Fennia: 'Fennia',
  If: 'If',
  LahiTapiola: 'LahiTapiola',
  Mandatum: 'Mandatum',
  Mela: 'Mela',
  Pohjola: 'Pohjola',
  Pohjantahti: 'Pohjantahti',
  Pop: 'Pop',
  Protector: 'Protector',
  Pv: 'Pv',
  Saastopankki: 'Saastopankki',
  Turva: 'Turva',
  Valtionkonttori: 'Valtionkonttori',
  HealthInsuranceFund: 'HealthInsuranceFund',
  Other: 'Other',
} as const;

export type TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany = typeof TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany[keyof typeof TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany];

/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponse
 */
export interface TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponse {
  /**
   *
   * @type {TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponseDirectCompensationPermitResponseStatus}
   * @memberof TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponse
   */
  Status?: TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponseDirectCompensationPermitResponseStatus;
  /**
   *
   * @type {Array<TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponsePermit>}
   * @memberof TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponse
   */
  Permits?: Array<TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponsePermit> | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponseDirectCompensationPermitResponseStatus = {
  Ok: 'Ok',
  NoPolicy: 'NoPolicy',
  UserNotFound: 'UserNotFound',
} as const;

export type TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponseDirectCompensationPermitResponseStatus = typeof TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponseDirectCompensationPermitResponseStatus[keyof typeof TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponseDirectCompensationPermitResponseStatus];

/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponsePermit
 */
export interface TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponsePermit {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponsePermit
   */
  Id: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponsePermit
   */
  ValidFrom: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponsePermit
   */
  ValidTo: string;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponsePermit
   */
  Description: string;
}
/**
 *
 * @export
 * @interface TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest
 */
export interface TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest
   */
  DirectCompensationPermitId: string;
}

/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Get appointments from fallback clinics for each given clinic until  appointments from 3 clinics are found.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableAlternativePost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/available/alternative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return appointment details by given appointmentid
     * @param {string} id
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk} [infectionRisk]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableIdIdGet: async (
      id: string,
      infectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('appointmentAvailableIdIdGet', 'id', id);
      const localVarPath = `/appointment/available/id/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (infectionRisk !== undefined) {
        localVarQueryParameter['infectionRisk'] = infectionRisk;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone).
     * @summary Return available private appointments.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailablePrivateSearchPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/available/private/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return appointment count grouped by date
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableSearchDaysPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/available/search/days`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return list of available occupational appointments by given parameters
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableSearchOccupationalPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/available/search/occupational`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return list of available appointments by given parameters.  Pagination with parameters skip and take is not exact.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableSearchPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/available/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check if appointment is reserved
     * @param {string} [appointmentId]
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk} [infectionRisk]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentIsappointmentavailableGet: async (
      appointmentId?: string,
      infectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/isappointmentavailable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (appointmentId !== undefined) {
        localVarQueryParameter['appointmentId'] = appointmentId;
      }

      if (infectionRisk !== undefined) {
        localVarQueryParameter['infectionRisk'] = infectionRisk;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of specialists customer has seen in the past
     * @param {number} [maxAppointmentAgeInDays]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentPastSpecialistAllGet: async (
      maxAppointmentAgeInDays?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/past/specialist/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (maxAppointmentAgeInDays !== undefined) {
        localVarQueryParameter['maxAppointmentAgeInDays'] = maxAppointmentAgeInDays;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of customer reservations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/confirmed/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel appointment
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedCancelPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/confirmed/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} [reservationID]
     * @param {string} [customerID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedGet: async (
      reservationID?: string,
      customerID?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/confirmed`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (reservationID !== undefined) {
        localVarQueryParameter['reservationID'] = reservationID;
      }

      if (customerID !== undefined) {
        localVarQueryParameter['customerID'] = customerID;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update reservation appointment type
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedUpdatePut: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/confirmed/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} [customerID]
     * @param {string} [webCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedWebcodeGet: async (
      customerID?: string,
      webCode?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/confirmed/webcode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (customerID !== undefined) {
        localVarQueryParameter['customerID'] = customerID;
      }

      if (webCode !== undefined) {
        localVarQueryParameter['webCode'] = webCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send\'s public healthcare direct invoice  form to \"RPA\" -process.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationSenddirectinvoiceformPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/senddirectinvoiceform`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send\'s insurance form to \"RPA\" -process.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationSendinsuranceformPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/sendinsuranceform`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send migri information.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationSendmigriformPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/sendmigriform`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel appointment
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationTemporaryCancelPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/temporary/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Confirm reservation
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationTemporaryConfirmPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/temporary/confirm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reserve appointment temporarily. Will be canceled, if not confirmed in 5 minutes
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationTemporaryCreatePost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/temporary/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get details of temporary reservation
     * @param {string} [reservationID]
     * @param {string} [customerID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationTemporaryGet: async (
      reservationID?: string,
      customerID?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/reservation/temporary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (reservationID !== undefined) {
        localVarQueryParameter['reservationID'] = reservationID;
      }

      if (customerID !== undefined) {
        localVarQueryParameter['customerID'] = customerID;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Confirm reservation
     * @param {string} webCode
     * @param {string} [reason]
     * @param {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType} [customerType]
     * @param {string} [estimatedPrice]
     * @param {string} [reminderPhoneNumber]
     * @param {boolean} [visibleForGuardian]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webCodeConfirmPut: async (
      webCode: string,
      reason?: string,
      customerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType,
      estimatedPrice?: string,
      reminderPhoneNumber?: string,
      visibleForGuardian?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'webCode' is not null or undefined
      assertParamExists('webCodeConfirmPut', 'webCode', webCode);
      const localVarPath = `/{webCode}/confirm`.replace(`{${'webCode'}}`, encodeURIComponent(String(webCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (reason !== undefined) {
        localVarQueryParameter['reason'] = reason;
      }

      if (customerType !== undefined) {
        localVarQueryParameter['customerType'] = customerType;
      }

      if (estimatedPrice !== undefined) {
        localVarQueryParameter['estimatedPrice'] = estimatedPrice;
      }

      if (reminderPhoneNumber !== undefined) {
        localVarQueryParameter['reminderPhoneNumber'] = reminderPhoneNumber;
      }

      if (visibleForGuardian !== undefined) {
        localVarQueryParameter['visibleForGuardian'] = visibleForGuardian;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Move appointment.
     * @param {string} webCode
     * @param {TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest} [terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webCodeMovePut: async (
      webCode: string,
      terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest?: TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'webCode' is not null or undefined
      assertParamExists('webCodeMovePut', 'webCode', webCode);
      const localVarPath = `/{webCode}/move`.replace(`{${'webCode'}}`, encodeURIComponent(String(webCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AppointmentApiAxiosParamCreator(configuration);
  return {
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Get appointments from fallback clinics for each given clinic until  appointments from 3 clinics are found.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentAvailableAlternativePost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItem>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentAvailableAlternativePost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Return appointment details by given appointmentid
     * @param {string} id
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk} [infectionRisk]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentAvailableIdIdGet(
      id: string,
      infectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentAvailableIdIdGet(id, infectionRisk, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone).
     * @summary Return available private appointments.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentAvailablePrivateSearchPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentAvailablePrivateSearchPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return appointment count grouped by date
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentAvailableSearchDaysPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentDateCount>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentAvailableSearchDaysPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return list of available occupational appointments by given parameters
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentAvailableSearchOccupationalPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIControllersV1OccupationalAppointmentsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentAvailableSearchOccupationalPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return list of available appointments by given parameters.  Pagination with parameters skip and take is not exact.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentAvailableSearchPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentAvailableSearchPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Check if appointment is reserved
     * @param {string} [appointmentId]
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk} [infectionRisk]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentIsappointmentavailableGet(
      appointmentId?: string,
      infectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOIsAppointmentAvailableResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentIsappointmentavailableGet(
        appointmentId,
        infectionRisk,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get list of specialists customer has seen in the past
     * @param {number} [maxAppointmentAgeInDays]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentPastSpecialistAllGet(
      maxAppointmentAgeInDays?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentPastSpecialistAllGet(
        maxAppointmentAgeInDays,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get list of customer reservations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationConfirmedAllGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationConfirmedAllGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Cancel appointment
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationConfirmedCancelPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationConfirmedCancelPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} [reservationID]
     * @param {string} [customerID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationConfirmedGet(
      reservationID?: string,
      customerID?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationConfirmedGet(
        reservationID,
        customerID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update reservation appointment type
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationConfirmedUpdatePut(
      terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationConfirmedUpdatePut(
        terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} [customerID]
     * @param {string} [webCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationConfirmedWebcodeGet(
      customerID?: string,
      webCode?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationConfirmedWebcodeGet(
        customerID,
        webCode,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Send\'s public healthcare direct invoice  form to \"RPA\" -process.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationSenddirectinvoiceformPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationSenddirectinvoiceformPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Send\'s insurance form to \"RPA\" -process.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationSendinsuranceformPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationSendinsuranceformPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Send migri information.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationSendmigriformPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationSendmigriformPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Cancel appointment
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationTemporaryCancelPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationTemporaryCancelPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Confirm reservation
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationTemporaryConfirmPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationTemporaryConfirmPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Reserve appointment temporarily. Will be canceled, if not confirmed in 5 minutes
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationTemporaryCreatePost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTempReservationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationTemporaryCreatePost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get details of temporary reservation
     * @param {string} [reservationID]
     * @param {string} [customerID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentReservationTemporaryGet(
      reservationID?: string,
      customerID?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentReservationTemporaryGet(
        reservationID,
        customerID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Confirm reservation
     * @param {string} webCode
     * @param {string} [reason]
     * @param {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType} [customerType]
     * @param {string} [estimatedPrice]
     * @param {string} [reminderPhoneNumber]
     * @param {boolean} [visibleForGuardian]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webCodeConfirmPut(
      webCode: string,
      reason?: string,
      customerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType,
      estimatedPrice?: string,
      reminderPhoneNumber?: string,
      visibleForGuardian?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webCodeConfirmPut(
        webCode,
        reason,
        customerType,
        estimatedPrice,
        reminderPhoneNumber,
        visibleForGuardian,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Move appointment.
     * @param {string} webCode
     * @param {TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest} [terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webCodeMovePut(
      webCode: string,
      terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest?: TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webCodeMovePut(
        webCode,
        terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AppointmentApiFp(configuration);
  return {
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Get appointments from fallback clinics for each given clinic until  appointments from 3 clinics are found.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableAlternativePost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options?: any,
    ): AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAlternativeAppointmentListItem>> {
      return localVarFp
        .appointmentAvailableAlternativePost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return appointment details by given appointmentid
     * @param {string} id
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk} [infectionRisk]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableIdIdGet(
      id: string,
      infectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableAppointmentResponse> {
      return localVarFp
        .appointmentAvailableIdIdGet(id, infectionRisk, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone).
     * @summary Return available private appointments.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailablePrivateSearchPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest,
      options?: any,
    ): AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem>> {
      return localVarFp
        .appointmentAvailablePrivateSearchPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return appointment count grouped by date
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableSearchDaysPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest,
      options?: any,
    ): AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentDateCount>> {
      return localVarFp
        .appointmentAvailableSearchDaysPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return list of available occupational appointments by given parameters
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableSearchOccupationalPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIControllersV1OccupationalAppointmentsResponse> {
      return localVarFp
        .appointmentAvailableSearchOccupationalPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Pass times in FLE Standard time for API (Finnish TimeZone)
     * @summary Return list of available appointments by given parameters.  Pagination with parameters skip and take is not exact.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAvailableSearchPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
      options?: any,
    ): AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOAppointmentListItem>> {
      return localVarFp
        .appointmentAvailableSearchPost(terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Check if appointment is reserved
     * @param {string} [appointmentId]
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk} [infectionRisk]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentIsappointmentavailableGet(
      appointmentId?: string,
      infectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOIsAppointmentAvailableResponse> {
      return localVarFp
        .appointmentIsappointmentavailableGet(appointmentId, infectionRisk, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get list of specialists customer has seen in the past
     * @param {number} [maxAppointmentAgeInDays]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentPastSpecialistAllGet(
      maxAppointmentAgeInDays?: number,
      options?: any,
    ): AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetPastAppointmentResponse>> {
      return localVarFp
        .appointmentPastSpecialistAllGet(maxAppointmentAgeInDays, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get list of customer reservations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedAllGet(
      options?: any,
    ): AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse>> {
      return localVarFp.appointmentReservationConfirmedAllGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Cancel appointment
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedCancelPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appointmentReservationConfirmedCancelPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} [reservationID]
     * @param {string} [customerID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedGet(
      reservationID?: string,
      customerID?: string,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse> {
      return localVarFp
        .appointmentReservationConfirmedGet(reservationID, customerID, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update reservation appointment type
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedUpdatePut(
      terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appointmentReservationConfirmedUpdatePut(
          terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} [customerID]
     * @param {string} [webCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationConfirmedWebcodeGet(
      customerID?: string,
      webCode?: string,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedReservationResponse> {
      return localVarFp
        .appointmentReservationConfirmedWebcodeGet(customerID, webCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Send\'s public healthcare direct invoice  form to \"RPA\" -process.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationSenddirectinvoiceformPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appointmentReservationSenddirectinvoiceformPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Send\'s insurance form to \"RPA\" -process.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationSendinsuranceformPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appointmentReservationSendinsuranceformPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Send migri information.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationSendmigriformPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appointmentReservationSendmigriformPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Cancel appointment
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationTemporaryCancelPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appointmentReservationTemporaryCancelPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Confirm reservation
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationTemporaryConfirmPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appointmentReservationTemporaryConfirmPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Reserve appointment temporarily. Will be canceled, if not confirmed in 5 minutes
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationTemporaryCreatePost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTempReservationResponse> {
      return localVarFp
        .appointmentReservationTemporaryCreatePost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get details of temporary reservation
     * @param {string} [reservationID]
     * @param {string} [customerID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentReservationTemporaryGet(
      reservationID?: string,
      customerID?: string,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryReservationResponse> {
      return localVarFp
        .appointmentReservationTemporaryGet(reservationID, customerID, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Confirm reservation
     * @param {string} webCode
     * @param {string} [reason]
     * @param {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType} [customerType]
     * @param {string} [estimatedPrice]
     * @param {string} [reminderPhoneNumber]
     * @param {boolean} [visibleForGuardian]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webCodeConfirmPut(
      webCode: string,
      reason?: string,
      customerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType,
      estimatedPrice?: string,
      reminderPhoneNumber?: string,
      visibleForGuardian?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .webCodeConfirmPut(
          webCode,
          reason,
          customerType,
          estimatedPrice,
          reminderPhoneNumber,
          visibleForGuardian,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Move appointment.
     * @param {string} webCode
     * @param {TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest} [terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webCodeMovePut(
      webCode: string,
      terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest?: TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .webCodeMovePut(webCode, terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
  /**
   * Pass times in FLE Standard time for API (Finnish TimeZone)
   * @summary Get appointments from fallback clinics for each given clinic until  appointments from 3 clinics are found.
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentAvailableAlternativePost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentAvailableAlternativePost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return appointment details by given appointmentid
   * @param {string} id
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk} [infectionRisk]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentAvailableIdIdGet(
    id: string,
    infectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentAvailableIdIdGet(id, infectionRisk, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Pass times in FLE Standard time for API (Finnish TimeZone).
   * @summary Return available private appointments.
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentAvailablePrivateSearchPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentAvailablePrivateSearchPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetPrivateAppointmentsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Pass times in FLE Standard time for API (Finnish TimeZone)
   * @summary Return appointment count grouped by date
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentAvailableSearchDaysPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentAvailableSearchDaysPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentDaysRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Pass times in FLE Standard time for API (Finnish TimeZone)
   * @summary Return list of available occupational appointments by given parameters
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentAvailableSearchOccupationalPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentAvailableSearchOccupationalPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Pass times in FLE Standard time for API (Finnish TimeZone)
   * @summary Return list of available appointments by given parameters.  Pagination with parameters skip and take is not exact.
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentAvailableSearchPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentAvailableSearchPost(terveystaloAppointmentAPIAPIAbstractionV1RequestGetAppointmentsRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check if appointment is reserved
   * @param {string} [appointmentId]
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk} [infectionRisk]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentIsappointmentavailableGet(
    appointmentId?: string,
    infectionRisk?: TerveystaloAppointmentAPIAPIAbstractionV1DTOApiInfectionRisk,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentIsappointmentavailableGet(appointmentId, infectionRisk, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get list of specialists customer has seen in the past
   * @param {number} [maxAppointmentAgeInDays]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentPastSpecialistAllGet(maxAppointmentAgeInDays?: number, options?: AxiosRequestConfig) {
    return AppointmentApiFp(this.configuration)
      .appointmentPastSpecialistAllGet(maxAppointmentAgeInDays, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get list of customer reservations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationConfirmedAllGet(options?: AxiosRequestConfig) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationConfirmedAllGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cancel appointment
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationConfirmedCancelPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationConfirmedCancelPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get details of confirmed reservation
   * @param {string} [reservationID]
   * @param {string} [customerID]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationConfirmedGet(reservationID?: string, customerID?: string, options?: AxiosRequestConfig) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationConfirmedGet(reservationID, customerID, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update reservation appointment type
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationConfirmedUpdatePut(
    terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationConfirmedUpdatePut(
        terveystaloAppointmentAPIAPIAbstractionV1RequestUpdateConfirmedAppointmentRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get details of confirmed reservation
   * @param {string} [customerID]
   * @param {string} [webCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationConfirmedWebcodeGet(
    customerID?: string,
    webCode?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationConfirmedWebcodeGet(customerID, webCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send\'s public healthcare direct invoice  form to \"RPA\" -process.
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationSenddirectinvoiceformPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationSenddirectinvoiceformPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestDirectInvoiceFormRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send\'s insurance form to \"RPA\" -process.
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationSendinsuranceformPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationSendinsuranceformPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestInsuranceFormRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send migri information.
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationSendmigriformPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationSendmigriformPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestMigriFormRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cancel appointment
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationTemporaryCancelPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationTemporaryCancelPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentCancelRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Confirm reservation
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationTemporaryConfirmPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationTemporaryConfirmPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmReservationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reserve appointment temporarily. Will be canceled, if not confirmed in 5 minutes
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationTemporaryCreatePost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationTemporaryCreatePost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestAppointmentReservationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get details of temporary reservation
   * @param {string} [reservationID]
   * @param {string} [customerID]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentReservationTemporaryGet(reservationID?: string, customerID?: string, options?: AxiosRequestConfig) {
    return AppointmentApiFp(this.configuration)
      .appointmentReservationTemporaryGet(reservationID, customerID, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Confirm reservation
   * @param {string} webCode
   * @param {string} [reason]
   * @param {TerveystaloAppointmentAPIAPIAbstractionAPICustomerType} [customerType]
   * @param {string} [estimatedPrice]
   * @param {string} [reminderPhoneNumber]
   * @param {boolean} [visibleForGuardian]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public webCodeConfirmPut(
    webCode: string,
    reason?: string,
    customerType?: TerveystaloAppointmentAPIAPIAbstractionAPICustomerType,
    estimatedPrice?: string,
    reminderPhoneNumber?: string,
    visibleForGuardian?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .webCodeConfirmPut(
        webCode,
        reason,
        customerType,
        estimatedPrice,
        reminderPhoneNumber,
        visibleForGuardian,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Move appointment.
   * @param {string} webCode
   * @param {TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest} [terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public webCodeMovePut(
    webCode: string,
    terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest?: TerveystaloAppointmentAPIControllersV1MoveByWebCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .webCodeMovePut(webCode, terveystaloAppointmentAPIControllersV1MoveByWebCodeRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CallRequestApi - axios parameter creator
 * @export
 */
export const CallRequestApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return call request details by given appointmentId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestAvailableIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('callrequestAvailableIdGet', 'id', id);
      const localVarPath = `/callrequest/available/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return available call request count grouped by date
     * @param {string} specialistId
     * @param {string} startDate
     * @param {number} days
     * @param {string} intervalStartTime
     * @param {boolean} [disableRevenueManagement]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestAvailableSearchDaysGet: async (
      specialistId: string,
      startDate: string,
      days: number,
      intervalStartTime: string,
      disableRevenueManagement?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'specialistId' is not null or undefined
      assertParamExists('callrequestAvailableSearchDaysGet', 'specialistId', specialistId);
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists('callrequestAvailableSearchDaysGet', 'startDate', startDate);
      // verify required parameter 'days' is not null or undefined
      assertParamExists('callrequestAvailableSearchDaysGet', 'days', days);
      // verify required parameter 'intervalStartTime' is not null or undefined
      assertParamExists('callrequestAvailableSearchDaysGet', 'intervalStartTime', intervalStartTime);
      const localVarPath = `/callrequest/available/search/days`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (specialistId !== undefined) {
        localVarQueryParameter['specialistId'] = specialistId;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (days !== undefined) {
        localVarQueryParameter['days'] = days;
      }

      if (intervalStartTime !== undefined) {
        localVarQueryParameter['intervalStartTime'] = intervalStartTime;
      }

      if (disableRevenueManagement !== undefined) {
        localVarQueryParameter['disableRevenueManagement'] = disableRevenueManagement;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search for available call request slots
     * @param {string} specialistId
     * @param {string} startDate
     * @param {number} days
     * @param {string} intervalStartTime
     * @param {boolean} [disableRevenueManagement]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestAvailableSearchGet: async (
      specialistId: string,
      startDate: string,
      days: number,
      intervalStartTime: string,
      disableRevenueManagement?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'specialistId' is not null or undefined
      assertParamExists('callrequestAvailableSearchGet', 'specialistId', specialistId);
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists('callrequestAvailableSearchGet', 'startDate', startDate);
      // verify required parameter 'days' is not null or undefined
      assertParamExists('callrequestAvailableSearchGet', 'days', days);
      // verify required parameter 'intervalStartTime' is not null or undefined
      assertParamExists('callrequestAvailableSearchGet', 'intervalStartTime', intervalStartTime);
      const localVarPath = `/callrequest/available/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (specialistId !== undefined) {
        localVarQueryParameter['specialistId'] = specialistId;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (days !== undefined) {
        localVarQueryParameter['days'] = days;
      }

      if (intervalStartTime !== undefined) {
        localVarQueryParameter['intervalStartTime'] = intervalStartTime;
      }

      if (disableRevenueManagement !== undefined) {
        localVarQueryParameter['disableRevenueManagement'] = disableRevenueManagement;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestReservationConfirmedReservationIdGet: async (
      reservationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      assertParamExists('callrequestReservationConfirmedReservationIdGet', 'reservationId', reservationId);
      const localVarPath = `/callrequest/reservation/confirmed/{reservationId}`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Confirm reservation
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestReservationTemporaryConfirmPost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/callrequest/reservation/temporary/confirm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reserve appointment temporarily. Will be canceled, if not confirmed in 5 minutes.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestReservationTemporaryCreatePost: async (
      terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/callrequest/reservation/temporary/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get details of temporary reservation
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestReservationTemporaryReservationIdGet: async (
      reservationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      assertParamExists('callrequestReservationTemporaryReservationIdGet', 'reservationId', reservationId);
      const localVarPath = `/callrequest/reservation/temporary/{reservationId}`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CallRequestApi - functional programming interface
 * @export
 */
export const CallRequestApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CallRequestApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Return call request details by given appointmentId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callrequestAvailableIdGet(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableCallRequestResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callrequestAvailableIdGet(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Return available call request count grouped by date
     * @param {string} specialistId
     * @param {string} startDate
     * @param {number} days
     * @param {string} intervalStartTime
     * @param {boolean} [disableRevenueManagement]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callrequestAvailableSearchDaysGet(
      specialistId: string,
      startDate: string,
      days: number,
      intervalStartTime: string,
      disableRevenueManagement?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOCallRequestDateCount>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callrequestAvailableSearchDaysGet(
        specialistId,
        startDate,
        days,
        intervalStartTime,
        disableRevenueManagement,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Search for available call request slots
     * @param {string} specialistId
     * @param {string} startDate
     * @param {number} days
     * @param {string} intervalStartTime
     * @param {boolean} [disableRevenueManagement]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callrequestAvailableSearchGet(
      specialistId: string,
      startDate: string,
      days: number,
      intervalStartTime: string,
      disableRevenueManagement?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callrequestAvailableSearchGet(
        specialistId,
        startDate,
        days,
        intervalStartTime,
        disableRevenueManagement,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callrequestReservationConfirmedReservationIdGet(
      reservationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedCallRequestReservationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callrequestReservationConfirmedReservationIdGet(
        reservationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Confirm reservation
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callrequestReservationTemporaryConfirmPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callrequestReservationTemporaryConfirmPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Reserve appointment temporarily. Will be canceled, if not confirmed in 5 minutes.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callrequestReservationTemporaryCreatePost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTemporaryCallRequestReservationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callrequestReservationTemporaryCreatePost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get details of temporary reservation
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callrequestReservationTemporaryReservationIdGet(
      reservationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callrequestReservationTemporaryReservationIdGet(
        reservationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CallRequestApi - factory interface
 * @export
 */
export const CallRequestApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CallRequestApiFp(configuration);
  return {
    /**
     *
     * @summary Return call request details by given appointmentId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestAvailableIdGet(
      id: string,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetAvailableCallRequestResponse> {
      return localVarFp.callrequestAvailableIdGet(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return available call request count grouped by date
     * @param {string} specialistId
     * @param {string} startDate
     * @param {number} days
     * @param {string} intervalStartTime
     * @param {boolean} [disableRevenueManagement]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestAvailableSearchDaysGet(
      specialistId: string,
      startDate: string,
      days: number,
      intervalStartTime: string,
      disableRevenueManagement?: boolean,
      options?: any,
    ): AxiosPromise<Array<TerveystaloAppointmentAPIAPIAbstractionV1DTOCallRequestDateCount>> {
      return localVarFp
        .callrequestAvailableSearchDaysGet(
          specialistId,
          startDate,
          days,
          intervalStartTime,
          disableRevenueManagement,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search for available call request slots
     * @param {string} specialistId
     * @param {string} startDate
     * @param {number} days
     * @param {string} intervalStartTime
     * @param {boolean} [disableRevenueManagement]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestAvailableSearchGet(
      specialistId: string,
      startDate: string,
      days: number,
      intervalStartTime: string,
      disableRevenueManagement?: boolean,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1SearchCallRequestsResponse> {
      return localVarFp
        .callrequestAvailableSearchGet(
          specialistId,
          startDate,
          days,
          intervalStartTime,
          disableRevenueManagement,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get details of confirmed reservation
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestReservationConfirmedReservationIdGet(
      reservationId: string,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetConfirmedCallRequestReservationResponse> {
      return localVarFp
        .callrequestReservationConfirmedReservationIdGet(reservationId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Confirm reservation
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestReservationTemporaryConfirmPost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .callrequestReservationTemporaryConfirmPost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Reserve appointment temporarily. Will be canceled, if not confirmed in 5 minutes.
     * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestReservationTemporaryCreatePost(
      terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOCreateTemporaryCallRequestReservationResponse> {
      return localVarFp
        .callrequestReservationTemporaryCreatePost(
          terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get details of temporary reservation
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callrequestReservationTemporaryReservationIdGet(
      reservationId: string,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIAPIAbstractionV1DTOGetTemporaryCallRequestReservationResponse> {
      return localVarFp
        .callrequestReservationTemporaryReservationIdGet(reservationId, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CallRequestApi - object-oriented interface
 * @export
 * @class CallRequestApi
 * @extends {BaseAPI}
 */
export class CallRequestApi extends BaseAPI {
  /**
   *
   * @summary Return call request details by given appointmentId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallRequestApi
   */
  public callrequestAvailableIdGet(id: string, options?: AxiosRequestConfig) {
    return CallRequestApiFp(this.configuration)
      .callrequestAvailableIdGet(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return available call request count grouped by date
   * @param {string} specialistId
   * @param {string} startDate
   * @param {number} days
   * @param {string} intervalStartTime
   * @param {boolean} [disableRevenueManagement]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallRequestApi
   */
  public callrequestAvailableSearchDaysGet(
    specialistId: string,
    startDate: string,
    days: number,
    intervalStartTime: string,
    disableRevenueManagement?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return CallRequestApiFp(this.configuration)
      .callrequestAvailableSearchDaysGet(
        specialistId,
        startDate,
        days,
        intervalStartTime,
        disableRevenueManagement,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search for available call request slots
   * @param {string} specialistId
   * @param {string} startDate
   * @param {number} days
   * @param {string} intervalStartTime
   * @param {boolean} [disableRevenueManagement]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallRequestApi
   */
  public callrequestAvailableSearchGet(
    specialistId: string,
    startDate: string,
    days: number,
    intervalStartTime: string,
    disableRevenueManagement?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return CallRequestApiFp(this.configuration)
      .callrequestAvailableSearchGet(
        specialistId,
        startDate,
        days,
        intervalStartTime,
        disableRevenueManagement,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get details of confirmed reservation
   * @param {string} reservationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallRequestApi
   */
  public callrequestReservationConfirmedReservationIdGet(reservationId: string, options?: AxiosRequestConfig) {
    return CallRequestApiFp(this.configuration)
      .callrequestReservationConfirmedReservationIdGet(reservationId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Confirm reservation
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallRequestApi
   */
  public callrequestReservationTemporaryConfirmPost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CallRequestApiFp(this.configuration)
      .callrequestReservationTemporaryConfirmPost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestConfirmCallRequestReservationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reserve appointment temporarily. Will be canceled, if not confirmed in 5 minutes.
   * @param {TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest} [terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallRequestApi
   */
  public callrequestReservationTemporaryCreatePost(
    terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest?: TerveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CallRequestApiFp(this.configuration)
      .callrequestReservationTemporaryCreatePost(
        terveystaloAppointmentAPIAPIAbstractionV1RequestCreateTemporaryCallRequestReservationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get details of temporary reservation
   * @param {string} reservationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallRequestApi
   */
  public callrequestReservationTemporaryReservationIdGet(reservationId: string, options?: AxiosRequestConfig) {
    return CallRequestApiFp(this.configuration)
      .callrequestReservationTemporaryReservationIdGet(reservationId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HealthAndMonitoringApi - axios parameter creator
 * @export
 */
export const HealthAndMonitoringApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get system database health state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    databasehealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/databasehealth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get system health state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Required by  https://github.com/terveystalo/handbook/tree/main/policies/api_responses#liveness-endpoint-mandatory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthLiveGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/health/live`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Health check for App Service  https://learn.microsoft.com/en-us/azure/app-service/monitor-instances-health-check?tabs=dotnet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/healthcheck`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to run integration test for configuration and application dependencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    integrationtestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/integrationtest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthAndMonitoringApi - functional programming interface
 * @export
 */
export const HealthAndMonitoringApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthAndMonitoringApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get system database health state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async databasehealthGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.databasehealthGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get system health state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Health>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Required by  https://github.com/terveystalo/handbook/tree/main/policies/api_responses#liveness-endpoint-mandatory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthLiveGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthLiveGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Health check for App Service  https://learn.microsoft.com/en-us/azure/app-service/monitor-instances-health-check?tabs=dotnet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthcheckGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheckGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to run integration test for configuration and application dependencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async integrationtestGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.integrationtestGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pingGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HealthAndMonitoringApi - factory interface
 * @export
 */
export const HealthAndMonitoringApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthAndMonitoringApiFp(configuration);
  return {
    /**
     *
     * @summary Get system database health state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    databasehealthGet(options?: any): AxiosPromise<boolean> {
      return localVarFp.databasehealthGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get system health state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthGet(options?: any): AxiosPromise<Health> {
      return localVarFp.healthGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Required by  https://github.com/terveystalo/handbook/tree/main/policies/api_responses#liveness-endpoint-mandatory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthLiveGet(options?: any): AxiosPromise<void> {
      return localVarFp.healthLiveGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Health check for App Service  https://learn.microsoft.com/en-us/azure/app-service/monitor-instances-health-check?tabs=dotnet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheckGet(options?: any): AxiosPromise<void> {
      return localVarFp.healthcheckGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used to run integration test for configuration and application dependencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    integrationtestGet(options?: any): AxiosPromise<void> {
      return localVarFp.integrationtestGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet(options?: any): AxiosPromise<PingResult> {
      return localVarFp.pingGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HealthAndMonitoringApi - object-oriented interface
 * @export
 * @class HealthAndMonitoringApi
 * @extends {BaseAPI}
 */
export class HealthAndMonitoringApi extends BaseAPI {
  /**
   *
   * @summary Get system database health state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public databasehealthGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .databasehealthGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get system health state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public healthGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .healthGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Required by  https://github.com/terveystalo/handbook/tree/main/policies/api_responses#liveness-endpoint-mandatory
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public healthLiveGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .healthLiveGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Health check for App Service  https://learn.microsoft.com/en-us/azure/app-service/monitor-instances-health-check?tabs=dotnet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public healthcheckGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .healthcheckGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to run integration test for configuration and application dependencies
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public integrationtestGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .integrationtestGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Method to test service
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public pingGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .pingGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InsuranceApi - axios parameter creator
 * @export
 */
export const InsuranceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get health care policy status of current user or delegated patient
     * @param {TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany} insuranceCompany
     * @param {string} [delegateToken]
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1InsuranceInsuranceCompanyDirectCompensationPermitsGet: async (
      insuranceCompany: TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
      delegateToken?: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceCompany' is not null or undefined
      assertParamExists(
        'v1InsuranceInsuranceCompanyDirectCompensationPermitsGet',
        'insuranceCompany',
        insuranceCompany,
      );
      const localVarPath = `/v1/insurance/{insuranceCompany}/directCompensationPermits`.replace(
        `{${'insuranceCompany'}}`,
        encodeURIComponent(String(insuranceCompany)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (delegateToken !== undefined && delegateToken !== null) {
        localVarHeaderParameter['delegateToken'] = String(delegateToken);
      }

      if (acceptLanguage !== undefined && acceptLanguage !== null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get health care policy status of current user or delegated patient
     * @param {TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany} insuranceCompany
     * @param {string} [delegateToken]
     * @param {TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest} [terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost: async (
      insuranceCompany: TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
      delegateToken?: string,
      terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest?: TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceCompany' is not null or undefined
      assertParamExists(
        'v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost',
        'insuranceCompany',
        insuranceCompany,
      );
      const localVarPath = `/v1/insurance/{insuranceCompany}/directCompensationPermits/selectForChat`.replace(
        `{${'insuranceCompany'}}`,
        encodeURIComponent(String(insuranceCompany)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CustomerAPI required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication CustomerAPI2 required
      await setApiKeyToObject(localVarHeaderParameter, 'api-key', configuration);

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (delegateToken !== undefined && delegateToken !== null) {
        localVarHeaderParameter['delegateToken'] = String(delegateToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InsuranceApi - functional programming interface
 * @export
 */
export const InsuranceApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = InsuranceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get health care policy status of current user or delegated patient
     * @param {TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany} insuranceCompany
     * @param {string} [delegateToken]
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1InsuranceInsuranceCompanyDirectCompensationPermitsGet(
      insuranceCompany: TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
      delegateToken?: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1InsuranceInsuranceCompanyDirectCompensationPermitsGet(
        insuranceCompany,
        delegateToken,
        acceptLanguage,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get health care policy status of current user or delegated patient
     * @param {TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany} insuranceCompany
     * @param {string} [delegateToken]
     * @param {TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest} [terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost(
      insuranceCompany: TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
      delegateToken?: string,
      terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest?: TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost(
        insuranceCompany,
        delegateToken,
        terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InsuranceApi - factory interface
 * @export
 */
export const InsuranceApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InsuranceApiFp(configuration);
  return {
    /**
     *
     * @summary Get health care policy status of current user or delegated patient
     * @param {TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany} insuranceCompany
     * @param {string} [delegateToken]
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1InsuranceInsuranceCompanyDirectCompensationPermitsGet(
      insuranceCompany: TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
      delegateToken?: string,
      acceptLanguage?: string,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIWebControllersV2DirectCompensationPermitResponse> {
      return localVarFp
        .v1InsuranceInsuranceCompanyDirectCompensationPermitsGet(
          insuranceCompany,
          delegateToken,
          acceptLanguage,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get health care policy status of current user or delegated patient
     * @param {TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany} insuranceCompany
     * @param {string} [delegateToken]
     * @param {TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest} [terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost(
      insuranceCompany: TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
      delegateToken?: string,
      terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest?: TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest,
      options?: any,
    ): AxiosPromise<TerveystaloAppointmentAPIWebControllersV1SelectCompensationPermitForChatResponse> {
      return localVarFp
        .v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost(
          insuranceCompany,
          delegateToken,
          terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * InsuranceApi - object-oriented interface
 * @export
 * @class InsuranceApi
 * @extends {BaseAPI}
 */
export class InsuranceApi extends BaseAPI {
  /**
   *
   * @summary Get health care policy status of current user or delegated patient
   * @param {TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany} insuranceCompany
   * @param {string} [delegateToken]
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceApi
   */
  public v1InsuranceInsuranceCompanyDirectCompensationPermitsGet(
    insuranceCompany: TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
    delegateToken?: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig,
  ) {
    return InsuranceApiFp(this.configuration)
      .v1InsuranceInsuranceCompanyDirectCompensationPermitsGet(insuranceCompany, delegateToken, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get health care policy status of current user or delegated patient
   * @param {TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany} insuranceCompany
   * @param {string} [delegateToken]
   * @param {TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest} [terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceApi
   */
  public v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost(
    insuranceCompany: TerveystaloAppointmentAPIWebControllersV2ApiInsuranceCompany,
    delegateToken?: string,
    terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest?: TerveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest,
    options?: AxiosRequestConfig,
  ) {
    return InsuranceApiFp(this.configuration)
      .v1InsuranceInsuranceCompanyDirectCompensationPermitsSelectForChatPost(
        insuranceCompany,
        delegateToken,
        terveystaloAppointmentAPIWebControllersV2SelectCompensationPermitForChatRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}
