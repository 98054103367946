import { FC, useEffect } from 'react';
import { MaintenanceBreak } from 'ui/error-pages/MaintenanceBreakPage';
import { useApiData } from 'utils/react/ui-context';

// A helper component for checking the need to show maintenance break
// Use a component instead of a custom hook to reduce amount of App rerenders
export const ApiHealthChecker: FC<{ isBreak: boolean; setMaintenanceBreak: (m: MaintenanceBreak) => void }> = ({
  isBreak,
  setMaintenanceBreak,
}) => {
  const getAppointmentApiHealth = useApiData('getAppointmentApiHealth');
  const healthData = getAppointmentApiHealth([]);

  useEffect(() => {
    if (!isBreak) {
      if (healthData.status === 'REMOTE_DATA_SUCCESS' && healthData.data.system !== 'Ok') {
        setMaintenanceBreak({ type: 'automatic', data: healthData });
      } else if (healthData.status === 'REMOTE_DATA_FAILURE' && healthData.error !== 'Network Error') {
        setMaintenanceBreak({ type: 'failure', data: healthData });
      }
    }
  }, [isBreak, healthData, setMaintenanceBreak]);

  return null;
};
