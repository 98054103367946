/* eslint-disable */

/* tslint:disable */
/* eslint-disable */
/**
 *
 * Misc
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AppointmentPriceFromGet499Response
 */
export interface AppointmentPriceFromGet499Response {
  /**
   *
   * @type {string}
   * @memberof AppointmentPriceFromGet499Response
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof AppointmentPriceFromGet499Response
   */
  message?: string;
  /**
   * Error specific details
   * @type {any}
   * @memberof AppointmentPriceFromGet499Response
   */
  details?: any;
}
/**
 *
 * @export
 * @interface Localized
 */
export interface Localized {
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  TwoLetterISOLanguage?: string | null;
  /**
   *
   * @type {string}
   * @memberof Localized
   */
  Value?: string | null;
}
/**
 *
 * @export
 * @interface PingResult
 */
export interface PingResult {
  /**
   *
   * @type {string}
   * @memberof PingResult
   */
  Message?: string | null;
}
/**
 *
 * @export
 * @interface TerveystaloAdaPriceAPIAPIAbstractionsExceptionsServiceIdOrSpecialistIdIsRequiredAPIException
 */
export interface TerveystaloAdaPriceAPIAPIAbstractionsExceptionsServiceIdOrSpecialistIdIsRequiredAPIException {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsExceptionsServiceIdOrSpecialistIdIsRequiredAPIException
   */
  Message?: string;
}
/**
 *
 * @export
 * @interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentPriceResponse
 */
export interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentPriceResponse {
  /**
   *
   * @type {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentPriceResponse
   */
  price?: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem;
  /**
   * Kiinteä hintainen palvelu
   * @type {boolean}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentPriceResponse
   */
  FixedPriceService?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType = {
  Reception: 'Reception',
  Remote: 'Remote',
  Phone: 'Phone',
} as const;

export type TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType = typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType[keyof typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType];

/**
 *
 * @export
 * @interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
 */
export interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse {
  /**
   *
   * @type {number}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  Price?: number;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  Info?: Array<Localized> | null;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  DeductiblePrice?: number | null;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  Code?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  FixedPrice?: boolean;
  /**
   *
   * @type {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceType}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  PriceType?: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceType;
  /**
   *
   * @type {string}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  ServiceId?: string | null;
  /**
   *
   * @type {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse
   */
  PriceCodeMap?: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse;
}
/**
 *
 * @export
 * @interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponse
 */
export interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponse {
  /**
   *
   * @type {Array<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem>}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponse
   */
  Prices?: Array<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem> | null;
}
/**
 *
 * @export
 * @interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem
 */
export interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem {
  /**
   *
   * @type {string}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem
   */
  Code?: string | null;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem
   */
  Price?: number;
  /**
   *
   * @type {number}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem
   */
  DeductiblePrice?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem
   */
  FixedPrice?: boolean;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem
   */
  Name?: Array<Localized> | null;
  /**
   *
   * @type {Array<Localized>}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponsePriceItem
   */
  Info?: Array<Localized> | null;
}
/**
 *
 * @export
 * @interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetSpecialistPriceResponse
 */
export interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetSpecialistPriceResponse {
  /**
   *
   * @type {Array<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse>}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetSpecialistPriceResponse
   */
  PriceItems?: Array<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetPriceItemResponse> | null;
  /**
   *
   * @type {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOResponseStatus}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetSpecialistPriceResponse
   */
  Status?: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOResponseStatus;
}
/**
 *
 * @export
 * @interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse
 */
export interface TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse {
  /**
   *
   * @type {number}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse
   */
  DurationInMinutes?: number;
  /**
   *
   * @type {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOSpecialistTypeResponse}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse
   */
  SpecialistType?: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOSpecialistTypeResponse;
  /**
   *
   * @type {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse
   */
  TimeOfDay?: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse;
  /**
   *
   * @type {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType}
   * @memberof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceCodeItemResponse
   */
  AppointmentType?: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceType = {
  General: 'General',
  Special: 'Special',
  Service: 'Service',
} as const;

export type TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceType = typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceType[keyof typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOPriceType];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAdaPriceAPIAPIAbstractionsV1DTOResponseStatus = {
  Ok: 'Ok',
  UnknownError: 'UnknownError',
} as const;

export type TerveystaloAdaPriceAPIAPIAbstractionsV1DTOResponseStatus = typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOResponseStatus[keyof typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOResponseStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAdaPriceAPIAPIAbstractionsV1DTOSpecialistTypeResponse = {
  General: 'General',
  Special: 'Special',
  Other: 'Other',
} as const;

export type TerveystaloAdaPriceAPIAPIAbstractionsV1DTOSpecialistTypeResponse = typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOSpecialistTypeResponse[keyof typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOSpecialistTypeResponse];

/**
 *
 * @export
 * @enum {string}
 */

export const TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse = {
  Weekday: 'Weekday',
  Night: 'Night',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
} as const;

export type TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse = typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse[keyof typeof TerveystaloAdaPriceAPIAPIAbstractionsV1DTOTimeOfDayResponse];

/**
 * AppointmentPriceApi - axios parameter creator
 * @export
 */
export const AppointmentPriceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return\'s appointment from/starting price
     * @param {number} durationInMinutes
     * @param {string} datetime
     * @param {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType} appointmentType
     * @param {string} [serviceId]
     * @param {string} [specialistId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentPriceFromGet: async (
      durationInMinutes: number,
      datetime: string,
      appointmentType: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType,
      serviceId?: string,
      specialistId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'durationInMinutes' is not null or undefined
      assertParamExists('appointmentPriceFromGet', 'durationInMinutes', durationInMinutes);
      // verify required parameter 'datetime' is not null or undefined
      assertParamExists('appointmentPriceFromGet', 'datetime', datetime);
      // verify required parameter 'appointmentType' is not null or undefined
      assertParamExists('appointmentPriceFromGet', 'appointmentType', appointmentType);
      const localVarPath = `/appointment/priceFrom`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      if (serviceId !== undefined) {
        localVarQueryParameter['ServiceId'] = serviceId;
      }

      if (specialistId !== undefined) {
        localVarQueryParameter['SpecialistId'] = specialistId;
      }

      if (durationInMinutes !== undefined) {
        localVarQueryParameter['DurationInMinutes'] = durationInMinutes;
      }

      if (datetime !== undefined) {
        localVarQueryParameter['Datetime'] =
          (datetime as any) instanceof Date ? (datetime as any).toISOString() : datetime;
      }

      if (appointmentType !== undefined) {
        localVarQueryParameter['AppointmentType'] = appointmentType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppointmentPriceApi - functional programming interface
 * @export
 */
export const AppointmentPriceApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AppointmentPriceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Return\'s appointment from/starting price
     * @param {number} durationInMinutes
     * @param {string} datetime
     * @param {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType} appointmentType
     * @param {string} [serviceId]
     * @param {string} [specialistId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentPriceFromGet(
      durationInMinutes: number,
      datetime: string,
      appointmentType: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType,
      serviceId?: string,
      specialistId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentPriceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentPriceFromGet(
        durationInMinutes,
        datetime,
        appointmentType,
        serviceId,
        specialistId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AppointmentPriceApi - factory interface
 * @export
 */
export const AppointmentPriceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppointmentPriceApiFp(configuration);
  return {
    /**
     *
     * @summary Return\'s appointment from/starting price
     * @param {number} durationInMinutes
     * @param {string} datetime
     * @param {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType} appointmentType
     * @param {string} [serviceId]
     * @param {string} [specialistId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentPriceFromGet(
      durationInMinutes: number,
      datetime: string,
      appointmentType: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType,
      serviceId?: string,
      specialistId?: string,
      options?: any,
    ): AxiosPromise<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentPriceResponse> {
      return localVarFp
        .appointmentPriceFromGet(durationInMinutes, datetime, appointmentType, serviceId, specialistId, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AppointmentPriceApi - object-oriented interface
 * @export
 * @class AppointmentPriceApi
 * @extends {BaseAPI}
 */
export class AppointmentPriceApi extends BaseAPI {
  /**
   *
   * @summary Return\'s appointment from/starting price
   * @param {number} durationInMinutes
   * @param {string} datetime
   * @param {TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType} appointmentType
   * @param {string} [serviceId]
   * @param {string} [specialistId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentPriceApi
   */
  public appointmentPriceFromGet(
    durationInMinutes: number,
    datetime: string,
    appointmentType: TerveystaloAdaPriceAPIAPIAbstractionsV1DTOAppointmentType,
    serviceId?: string,
    specialistId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppointmentPriceApiFp(this.configuration)
      .appointmentPriceFromGet(durationInMinutes, datetime, appointmentType, serviceId, specialistId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HealthAndMonitoringApi - axios parameter creator
 * @export
 */
export const HealthAndMonitoringApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthAndMonitoringApi - functional programming interface
 * @export
 */
export const HealthAndMonitoringApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthAndMonitoringApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pingGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HealthAndMonitoringApi - factory interface
 * @export
 */
export const HealthAndMonitoringApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthAndMonitoringApiFp(configuration);
  return {
    /**
     *
     * @summary Method to test service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingGet(options?: any): AxiosPromise<PingResult> {
      return localVarFp.pingGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HealthAndMonitoringApi - object-oriented interface
 * @export
 * @class HealthAndMonitoringApi
 * @extends {BaseAPI}
 */
export class HealthAndMonitoringApi extends BaseAPI {
  /**
   *
   * @summary Method to test service
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthAndMonitoringApi
   */
  public pingGet(options?: AxiosRequestConfig) {
    return HealthAndMonitoringApiFp(this.configuration)
      .pingGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PriceApi - axios parameter creator
 * @export
 */
export const PriceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get service price date
     * @param {string} serviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    serviceServiceIdPricesGet: async (serviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'serviceId' is not null or undefined
      assertParamExists('serviceServiceIdPricesGet', 'serviceId', serviceId);
      const localVarPath = `/service/{serviceId}/prices`.replace(
        `{${'serviceId'}}`,
        encodeURIComponent(String(serviceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get specialist price data
     * @param {string} specialistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    specialistSpecialistIdPricesGet: async (
      specialistId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'specialistId' is not null or undefined
      assertParamExists('specialistSpecialistIdPricesGet', 'specialistId', specialistId);
      const localVarPath = `/specialist/{specialistId}/prices`.replace(
        `{${'specialistId'}}`,
        encodeURIComponent(String(specialistId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'Ocp-Apim-Subscription-Key', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PriceApi - functional programming interface
 * @export
 */
export const PriceApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = PriceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get service price date
     * @param {string} serviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async serviceServiceIdPricesGet(
      serviceId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.serviceServiceIdPricesGet(serviceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get specialist price data
     * @param {string} specialistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async specialistSpecialistIdPricesGet(
      specialistId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetSpecialistPriceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.specialistSpecialistIdPricesGet(specialistId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PriceApi - factory interface
 * @export
 */
export const PriceApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PriceApiFp(configuration);
  return {
    /**
     *
     * @summary Get service price date
     * @param {string} serviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    serviceServiceIdPricesGet(
      serviceId: string,
      options?: any,
    ): AxiosPromise<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetServicePricesResponse> {
      return localVarFp.serviceServiceIdPricesGet(serviceId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get specialist price data
     * @param {string} specialistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    specialistSpecialistIdPricesGet(
      specialistId: string,
      options?: any,
    ): AxiosPromise<TerveystaloAdaPriceAPIAPIAbstractionsV1DTOGetSpecialistPriceResponse> {
      return localVarFp
        .specialistSpecialistIdPricesGet(specialistId, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * PriceApi - object-oriented interface
 * @export
 * @class PriceApi
 * @extends {BaseAPI}
 */
export class PriceApi extends BaseAPI {
  /**
   *
   * @summary Get service price date
   * @param {string} serviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceApi
   */
  public serviceServiceIdPricesGet(serviceId: string, options?: AxiosRequestConfig) {
    return PriceApiFp(this.configuration)
      .serviceServiceIdPricesGet(serviceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get specialist price data
   * @param {string} specialistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceApi
   */
  public specialistSpecialistIdPricesGet(specialistId: string, options?: AxiosRequestConfig) {
    return PriceApiFp(this.configuration)
      .specialistSpecialistIdPricesGet(specialistId, options)
      .then(request => request(this.axios, this.basePath));
  }
}
