import { defineActionCreators } from 'utils/redux/actions';

export default defineActionCreators({
  fetchStarted(resourceId: string) {
    return { resourceId };
  },
  fetchSucceeded(resourceId: string, data: any) {
    return { resourceId, data };
  },
  fetchFailed(resourceId: string, errorMessage: string, errorCode?: number, errorid?: string) {
    return { resourceId, errorMessage, errorCode, errorid };
  },
  clearData(resourceId: string) {
    return { resourceId };
  },
  clearDataWithPatterns(patterns: string[]) {
    return { patterns };
  },
  clearAllApiData() {
    return {};
  },
});
