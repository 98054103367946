import { History, Location } from 'history';
import isPlainObject from 'lodash/isPlainObject';
import { AppointmentType } from 'model/api';
import { parse } from 'query-string';
import { useEffect, useRef } from 'react';
import { TranslationMap } from 'translations';
import { useReduxState } from 'utils/react/ui-context';
import { BookingRoot } from 'utils/routes/routes';
import { getCustomerTypeText } from 'utils/routes/texts';

// See https://tt.cbyte.fi/confluence/display/OTM/Ajanvarauksen+upotus for specification

// Send message indicating whether a back or close button should be shown in the app header
type NavigationMessage =
  | {
      type: 'NavigationStateChange';
      state: 'AllowBack';
    }
  | {
      type: 'NavigationStateChange';
      state: 'AllowClose';
      navigation: 'EventsList' | undefined;
    };

type CloseMessage = {
  type: 'Close';
  navigation?: 'ChatQueues' | 'EventsList';
  chatTypeId?: number;
};

type AppointmentBookedMessage = {
  type: 'AppointmentBooked';
  customerType: BookingRoot;
};

type AddAppointmentToCalendarMessage = {
  type: 'AddAppointmentToCalendar';
  title: string;
  location: string | undefined;
  date: string; // ISO-8601
  duration: number; // minutes
};

type BookingModifiedMessage = {
  type: 'AppointmentReservationModified';
  newAppointmentType: AppointmentType;
  confirmationMessageType: 'email' | 'sms' | null;
};

type CustomerTypeChangeMessage = {
  type: 'CustomerTypeChange';
  customerTypeText: string;
};

type LoginMessage = {
  type: 'LoginRequest';
};

type OpenSelfTriage = {
  type: 'OpenSelfTriage';
};

const getAppObject = (): Record<any, unknown> | null => {
  const obj = (window as any).omaTerveysMobile;
  if (!!obj && isPlainObject(obj)) {
    return obj;
  }
  return null;
};

export const parseMobileAuthToken = (history: History) => {
  // Check app object for auth token
  const parentApp = getAppObject();
  if (parentApp) {
    const { mobileToken, expiresIn } = parentApp;
    if (mobileToken && typeof mobileToken === 'string') {
      return {
        accessToken: mobileToken,
        expiresIn: (typeof expiresIn === 'number' && expiresIn) || undefined,
      };
    }
  }

  // DEPRECATED: Check url hash for token
  const { mobileToken, expiresIn } = parse(history.location.hash);
  if (mobileToken && typeof mobileToken === 'string') {
    // Remove the token from the url after parsing it to state
    history.replace({ ...history.location, hash: undefined });
    return {
      accessToken: mobileToken,
      expiresIn: (typeof expiresIn === 'string' && parseInt(expiresIn, 10)) || undefined,
    };
  }
  return null;
};

export const parseMobileDelegateToken = () => {
  const parentApp = getAppObject();
  if (parentApp) {
    const { principalToken } = parentApp;
    if (principalToken && typeof principalToken === 'string') {
      return principalToken;
    }
  }
  return null;
};

const sendMessage = (
  message:
    | NavigationMessage
    | AppointmentBookedMessage
    | BookingModifiedMessage
    | CloseMessage
    | AddAppointmentToCalendarMessage
    | CustomerTypeChangeMessage
    | LoginMessage
    | OpenSelfTriage,
) => {
  // Uses custom implementation both here and in the app side to catch the messages
  // because the mobile app origin is not a valid domain for window.postMessage and the webview postMessage only accepts string payloads
  const parentApp = getAppObject();
  if (parentApp && typeof parentApp.sendMessage === 'function') {
    parentApp.sendMessage(message);
  }
};

export const useNavigationAppMessages = (location: Location, withNavigationToEventsList?: boolean) => {
  const navigationState = useRef<NavigationMessage>();
  const embedInApp = useReduxState(state => state.location.embedInApp);
  const withBackToPreviousService = useReduxState(state => state.filters.withBackToPreviousService);

  useEffect(() => {
    if (embedInApp) {
      const params = parse(location.search);
      // Check if there is a modal open = the user is not on the first page from the mobile app's view
      if (withBackToPreviousService || Object.keys(params).some(k => k.match(/Open$/))) {
        if (!navigationState.current || navigationState.current.state !== 'AllowBack') {
          const newState: NavigationMessage = { type: 'NavigationStateChange', state: 'AllowBack' };
          navigationState.current = newState;
          sendMessage(navigationState.current);
        }
      } else {
        if (!navigationState.current || navigationState.current.state !== 'AllowClose') {
          const newState: NavigationMessage = {
            type: 'NavigationStateChange',
            state: 'AllowClose',
            navigation: withNavigationToEventsList ? 'EventsList' : undefined,
          };
          navigationState.current = newState;
          sendMessage(newState);
        }
      }
    }
  }, [embedInApp, withBackToPreviousService, location, withNavigationToEventsList]);

  // Set to AllowBack when the SearchAppointmentsPage unmounts, i.e. the user leaves the page
  useEffect(() => {
    return () => {
      const currentState: NavigationMessage = { type: 'NavigationStateChange', state: 'AllowBack' };
      navigationState.current = currentState;
      sendMessage(currentState);
    };
  }, []);
};

export const sendAppointmentBookedMessage = (customerType: BookingRoot) =>
  sendMessage({ type: 'AppointmentBooked', customerType });

export const sendAddAppointmentToCalendarMessage = (eventData: Omit<AddAppointmentToCalendarMessage, 'type'>) =>
  sendMessage({
    type: 'AddAppointmentToCalendar',
    ...eventData,
  });

export const sendBookingModifiedMessage = (
  newAppointmentType: BookingModifiedMessage['newAppointmentType'],
  confirmationMessageType: BookingModifiedMessage['confirmationMessageType'],
) =>
  sendMessage({
    type: 'AppointmentReservationModified',
    newAppointmentType,
    confirmationMessageType,
  });

export const useSendCloseAppMessage = () => (navigation?: CloseMessage['navigation']) =>
  sendMessage({ type: 'Close', navigation });

export const sendGoToChatMessage = (chatId: number | null) =>
  sendMessage({ type: 'Close', navigation: 'ChatQueues', chatTypeId: chatId || undefined });

export const sendCustomerTypeChangeMessage = (bookingRoot: BookingRoot, translations: TranslationMap) =>
  sendMessage({ type: 'CustomerTypeChange', customerTypeText: getCustomerTypeText(bookingRoot, translations) });

export const sendLoginAppMessage = () => sendMessage({ type: 'LoginRequest' });

export const sendOpenSelfTriageMessage = () => sendMessage({ type: 'OpenSelfTriage' });
