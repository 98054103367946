/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Notice } from 'ui/misc/Notice';
import { useReduxDispatch, useReduxState, useTranslations } from 'utils/react/ui-context';
import { getStorageItem, setStorageItem } from 'utils/storage/helpers';

const storageKeyGeneral = 'st-cnotice';
const storageKeyDental = 'st-cnotice-dental';

export const CriticalNotice: FC<{ className?: string }> = ({ className }) => {
  const t = useTranslations();
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const storageKey = bookingRoot === 'dental' ? storageKeyDental : storageKeyGeneral;
  const closed = useReduxState(state =>
    bookingRoot === 'dental' ? state.notice.criticalNoticeDentalClosed : state.notice.criticalNoticeClosed,
  );
  const dispatch = useReduxDispatch();

  // Dental version
  if (bookingRoot === 'dental') {
    if (!t.critical_notice_text_dental || !t.critical_notice_link_dental) return null;
    // Other than dental
  } else {
    if (!t.critical_notice_text || !t.critical_notice_link) return null;
  }
  return (
    <Notice
      variant="error"
      text={bookingRoot === 'dental' ? t.critical_notice_text_dental : t.may_notice_text}
      close={{
        closed: closed || !!getStorageItem(storageKey),
        onClose: () => {
          bookingRoot === 'dental' ? dispatch.closeCriticalNoticeDental() : dispatch.closeCriticalNotice();
          setStorageItem(storageKey, 'closed');
        },
        buttonLabel: t.close,
      }}
      className={className}
    />
  );
};
