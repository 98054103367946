import { CustomerType } from 'model/api';
import { parse } from 'query-string';
import { useConfig, useReduxState } from 'utils/react/ui-context';
import { BookingRoot } from 'utils/routes/routes';
import { assertExhausted } from 'utils/types/misc';

// Delegates under age of 12 are considered children
export const useIsBookingForChildren = () => {
  const delegate = useReduxState(state => state.delegate);
  return delegate.status !== 'NOTHING' && (typeof delegate.age !== 'number' || delegate.age < 12);
};

/* 
  Boolean check for childs self-determination right feature to be able to hide ones booking from legal guardian.
  Returns true if user is logged in, has age data, age is under 18 and feature flag is true, otherwise returns false
*/
export const useHideFromLegalGuardianBooking = () => {
  const auth = useReduxState(state => state.auth);
  const { FEATURE_HIDE_FROM_LEGAL_GUARDIAN } = useConfig();
  if (auth.status === 'LOGGED_IN') {
    return auth.user.age && auth.user.age < 18 && FEATURE_HIDE_FROM_LEGAL_GUARDIAN;
  }
  return false;
};

export const getCustomerTypeParam = (): CustomerType | undefined => {
  // No need to track url changes here, so we use window.location instead of the internal history
  const { customerType } = parse(window.location.search);
  if (
    customerType === 'private' ||
    customerType === 'occupational' ||
    customerType === 'insurance' ||
    customerType === 'municipality'
  ) {
    return customerType;
  }
  return undefined;
};

export const rootToCustomerType = (bookingRoot: BookingRoot): CustomerType | undefined => {
  switch (bookingRoot) {
    case 'privateCustomer':
    case 'privateCustomerV2':
      return 'private';
    case 'occupationalHealthcare':
      return 'occupational';
    case 'insurance':
    case 'terveysmestari':
    case 'fenniahoitaja':
    case 'terveyshelppi':
      return 'insurance';
    case 'dental':
      return undefined;
    case 'voucher':
    case 'publicPartner':
    case 'migri':
      return 'municipality';
    case 'htaTeam':
      return undefined;
    default:
      return assertExhausted(bookingRoot);
  }
};

export const customerTypeToBookingRoot = (customerType: CustomerType): BookingRoot => {
  switch (customerType) {
    case 'private':
      return 'privateCustomer';
    case 'occupational':
      return 'occupationalHealthcare';
    case 'insurance':
      return 'insurance';
    case 'municipality':
      return 'voucher';
    default:
      return assertExhausted(customerType);
  }
};

export const useCustomerType = (fallback: CustomerType = 'private'): CustomerType => {
  const { bookingRoot, customerTypeAddition } = useReduxState(state => state.location);

  if (!bookingRoot) {
    return fallback;
  }

  if (bookingRoot === 'htaTeam') {
    // Fall back to addition only when in hta booking
    return customerTypeAddition || 'private';
  }

  const type = rootToCustomerType(bookingRoot);
  if (!type) {
    return fallback;
  }

  return type;
};

export const alternativeServiceIdFromUrlParam = (locationSearch: string) => {
  const { altService } = parse(locationSearch);
  return (typeof altService === 'string' && altService) || undefined;
};

export const getGiftCardCodeFromParam = (): string | undefined => {
  const { voucherId } = parse(window.location.search);
  if (voucherId && typeof voucherId === 'string') {
    return voucherId;
  }
  return undefined;
};

export const selfTriageIdFromParam = (locationSearch: string) => {
  const { selfTriageId } = parse(locationSearch);
  if (selfTriageId && typeof selfTriageId === 'string') return selfTriageId;
  return undefined;
};

export const noRedirectionFromParam = (locationSearch: string) => {
  const { noRedir } = parse(locationSearch);
  if (noRedir && typeof noRedir === 'string' && noRedir === 'true') return true;
  return false;
};

/*
  This is now used exclusively for HTIA "other reason" case when we want to skip redirection to HTIA without selfTriageId param.
  Changes to this need to be tested and taken care in that context also (OccupationalSearchAppointments.tsx)
*/
export const forceRedirectionFormFromParam = (locationSearch: string) => {
  const { redirectionForm } = parse(locationSearch);
  if (redirectionForm && typeof redirectionForm === 'string' && redirectionForm === 'true') return true;
  return false;
};

export const getServiceFromParam = (locationSearch: string) => {
  const { serviceId } = parse(locationSearch);
  if (serviceId && typeof serviceId === 'string') return serviceId;
  return undefined;
};

export const getHTIAUrgencyFromParam = (locationSearch: string) => {
  const { urgency } = parse(locationSearch);
  if (urgency && typeof urgency === 'string') return urgency;
  return undefined;
};
