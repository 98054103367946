export const addScript = (src: string, attributes: Array<{ key: string; value: string }> = []) => {
  // Create a script tag and add it to document head
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.async = true;
  scriptTag.defer = true;
  scriptTag.src = src;
  attributes.forEach(attr => scriptTag.setAttribute(attr.key, attr.value));
  document.head.appendChild(scriptTag);
};
