import { NoAuthenticationApiFactory as ChatApiFactory } from 'api/generated/ChatApi/api';
import axios from 'axios';
import { ApiModel } from 'model/api';
import { ConfigModelType } from 'model/config';
import { Language } from 'translations/generated/translation-languages';
import { Analytics } from 'utils/analytics/analytics-utils';
import { createLogger } from 'utils/logging/logging-utils';
import { defined, guardedModelConversion } from 'utils/types/api';

// Creates low-level bindings to the Chat API.
export function createChatApiClient(
  config: ConfigModelType['FrontendConfig'],
  axiosImpl = axios,
  analytics: Analytics,
) {
  const log = createLogger(config, 'ChatApiClient');
  // Dummy for openAPI typing
  const isJsonMime = () => {
    return true;
  };
  const api = {
    ChatApi: ChatApiFactory({ apiKey: config.ADA_API_KEY, isJsonMime }, config.API_ENDPOINT_CHATAPI, axiosImpl),
  };
  return {
    getChatDetails(lang: Language, chatId: number) {
      return api.ChatApi.chatPropertiesChatTypeIdGet(chatId, lang).then(
        guardedModelConversion(analytics, log, ApiModel.ChatDetails, ({ data }) => ({
          chatId: defined(data.chatTypeId, 'chatId'),
          chatName: defined(data.chatTypeName, 'chatName'),
          chatDescription: defined(data.chatTypeDesc, 'chatDescription'),
          queueOpen: defined(data.queueOpen, 'queueOpen'),
          queueLength: defined(data.queueLength, 'queueLength'),
        })),
      );
    },
  };
}
