import { Action } from 'utils/redux/actions';

export const initState: {
  globalNotesOpen: boolean;
  globalNotesText: string;
  globalNotesSize: { width: number; height: number };
} = {
  globalNotesOpen: false,
  globalNotesText: '',
  globalNotesSize: { width: 240, height: 100 },
};

export default function reducer(state = initState, action: Action) {
  switch (action.type) {
    case 'setGlobalNotesOpen':
      return { ...state, globalNotesOpen: action.isOpen };
    case 'changeGlobalNotesText':
      return { ...state, globalNotesText: action.text };
    case 'resizeGlobalNotes':
      return { ...state, globalNotesSize: { ...state.globalNotesSize, ...action.size } };
    default:
      return state;
  }
}
