/** @jsxImportSource @emotion/react */
import { ApiModelType, RedirectionFormQuestion } from 'model/api';
import { parse, stringify } from 'query-string';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { RestrictionsState } from 'reducers/filters';
import { RedirectionFormContent } from 'ui/redirection-form/RedirectionFormContent';
import { useHistory } from 'utils/react/ui-context';

export const RedirectionForm: FC<{
  redirectionForm: ApiModelType['RedirectionForm'];
  openParamName: string;
  openParamBasis?: string;
  directCallOnly?: boolean;
  showChoicesSummary?: boolean;
  onClose?: (choiceTexts: string[], restrictions: RestrictionsState) => void;
  onGoToStart: () => void;
  children?: React.ReactNode;
  sendAnalytics: (props: {
    type: 'choice' | 'chat' | 'booking' | 'link' | 'back' | 'deadend';
    navigationStr?: string;
    choices?: string[];
    actionParams?: string;
  }) => void;
}> = ({
  redirectionForm,
  openParamName,
  openParamBasis = '',
  directCallOnly,
  showChoicesSummary,
  onClose,
  onGoToStart,
  sendAnalytics,
  children,
}) => {
  const history = useHistory();
  const contentEl = useRef<HTMLDivElement>(null);

  const { [openParamName]: openParam, ...otherSearch } = parse(history.location.search);
  const currentPage =
    openParam && typeof openParam === 'string' ? openParam.replace(new RegExp(`^${openParamBasis}`), '') : '';

  const pathToChoices = (p: string) => {
    const parts = p ? p.split('-') : [];
    return parts.reduce<RedirectionFormQuestion[]>((list, index) => {
      const curr = list.length ? list[list.length - 1] : undefined;
      const children = (curr === undefined ? redirectionForm.children : curr.children).filter(
        c => c.kind === 'question',
      );
      const parsedIndex = parseInt(index, 10);
      if (children.length && !isNaN(parsedIndex) && parsedIndex >= 0 && parsedIndex < children.length) {
        const item = children[parsedIndex];
        if (item.kind === 'question') {
          list.push(item);
        }
      }
      return list;
    }, []);
  };
  const choices = pathToChoices(currentPage);

  const [previousPage, setPreviousPage] = useState(currentPage);
  const doScroll = currentPage.length > previousPage.length; // On when going forward

  useEffect(() => {
    if (doScroll) {
      const offset = contentEl.current ? contentEl.current.offsetTop : 0;
      if (window.pageYOffset === undefined || window.pageYOffset > offset) {
        window.scrollTo(0, offset);
      }
    }
  }, [doScroll]);

  // Update the previous value when the value changes
  useEffect(() => {
    setPreviousPage(currentPage);
  }, [currentPage]);

  return (
    <Fragment>
      <RedirectionFormContent
        contentRef={contentEl}
        redirectionForm={redirectionForm}
        directCallOnly={directCallOnly}
        choices={choices}
        showBackAlways={!!openParamBasis}
        showChoicesSummary={showChoicesSummary}
        sendResultAnalytics={p => sendAnalytics({ ...p, navigationStr: currentPage, choices: choicesToText(choices) })}
        onSelect={i => {
          const nextPath = currentPage ? `${currentPage}-${i}` : `${i}`;
          sendAnalytics({
            type: 'choice',
            navigationStr: nextPath,
            choices: choicesToText(pathToChoices(nextPath)),
          });
          history.push({
            pathname: history.location.pathname,
            search: stringify({
              ...otherSearch,
              [openParamName]: `${openParamBasis}${nextPath}`,
            }),
          });
        }}
        onBack={() => {
          sendAnalytics({ type: 'back', navigationStr: currentPage, choices: choicesToText(choices) });
          history.push({
            pathname: history.location.pathname,
            search: stringify({
              ...otherSearch,
              [openParamName]: currentPage
                ? `${openParamBasis}${currentPage
                    .split('-')
                    .slice(0, -1)
                    .join('-')}`
                : undefined,
            }),
          });
        }}
        onClose={restrictions => {
          history.push({
            pathname: history.location.pathname,
            search: stringify({
              ...otherSearch,
              [openParamName]: undefined,
            }),
          });
          if (onClose) {
            onClose(choicesToText(choices), restrictions);
          }
        }}
        goToStart={onGoToStart}
      />
      {!choices.length && children}
    </Fragment>
  );
};

function choicesToText(choices: RedirectionFormQuestion[]) {
  return choices.map(c => c.text || c.defaultText);
}
