import { parse, stringify } from 'query-string';
import { useEffect } from 'react';
import { useSpecialistOrServiceFilter } from 'utils/filters/helpers';
import { useConfig, useHistory, useReduxDispatch, useReduxState, useUiContext } from 'utils/react/ui-context';
import { getStorageItem, removeStorageItem, setStorageItem } from 'utils/storage/helpers';
import { assertExhausted } from 'utils/types/misc';

const storageKey = 'guideState';
export const getMandatoryGuideStoredState = () => getStorageItem(storageKey);
export const storeGuideState = (value: 'symptoms' | 'no-symptoms') => setStorageItem(storageKey, value);
const removeStoredGuideState = () => removeStorageItem(storageKey);

export type RespiratorySymptoms = 'symptoms' | 'no-symptoms' | undefined;

// Deduce from mandatoryGuideState whether to filter with or without respiratory symptoms
export function useRespiratorySymptoms(): RespiratorySymptoms {
  const { HIDE_RESPIRATORY_SYMPTOMS_SURVEY } = useConfig();
  const guideState = useReduxState(state => state.notice.mandatoryGuideState);
  const dispatch = useReduxDispatch();
  const storedState = getMandatoryGuideStoredState();
  const history = useHistory();

  // Sync the end symptoms state between storage and redux
  useEffect(() => {
    if (
      (storedState === 'symptoms' || storedState === 'no-symptoms') &&
      guideState !== storedState &&
      guideState !== 'ask-test' &&
      guideState !== 'ask-test-no-symptoms'
    ) {
      dispatch.setMandatoryGuideState(storedState);
      // Make sure there doesn't remain a query param
      history.replace({
        pathname: history.location.pathname,
        search: stringify({ ...parse(history.location.search), guideOpen: undefined }),
      });
    }
  }, [guideState, storedState, dispatch, history]);

  switch (guideState) {
    case 'symptoms':
    case 'ask-test':
      return 'symptoms';
    case 'no-symptoms':
    case 'ask-test-no-symptoms':
      return 'no-symptoms';
    case 'not-asked':
      const symptoms = !HIDE_RESPIRATORY_SYMPTOMS_SURVEY ? undefined : 'no-symptoms';
      return symptoms;
    default:
      assertExhausted(guideState);
      return undefined;
  }
}

export const useEffectResetMandatoryGuide = () => {
  const guideState = useReduxState(state => state.notice.mandatoryGuideState);
  const dispatch = useReduxDispatch();

  const isClosed = !!getMandatoryGuideStoredState() || guideState !== 'not-asked';

  useEffect(() => {
    if (isClosed) {
      removeStoredGuideState();
      dispatch.setMandatoryGuideState('not-asked');
    }
  }, [isClosed, dispatch]);
};

export const useResetMandatoryGuide = () => {
  const dispatch = useReduxDispatch();

  return () => {
    removeStoredGuideState();
    dispatch.setMandatoryGuideState('not-asked');
  };
};

export const useSkipMandatoryGuide = () => {
  const dispatch = useReduxDispatch();

  return () => {
    storeGuideState('no-symptoms');
    dispatch.setMandatoryGuideState('no-symptoms');
  };
};

export const useOpenCoronaTestGuide = () => {
  const guideState = useReduxState(state => state.notice.mandatoryGuideState);
  const bookingRoot = useReduxState(state => state.location.bookingRoot);
  const delegate = useReduxState(state => state.delegate);
  const dispatch = useReduxDispatch();
  const { history, analytics } = useUiContext();

  return (analyticsName: string) => {
    dispatch.setMandatoryGuideState(guideState === 'no-symptoms' ? 'ask-test-no-symptoms' : 'ask-test');
    history.push({
      pathname: history.location.pathname,
      search: stringify({ ...parse(history.location.search), guideOpen: '100' }),
    });
    analytics.trackGuideModal(analyticsName, bookingRoot, delegate.status !== 'NOTHING');
  };
};
export const useIsCoronaTestBooking = (serviceSelection: 'mainLabOnly' | 'anyTest' = 'anyTest') => {
  const specialistOrService = useSpecialistOrServiceFilter();
  const selectedServiceId = specialistOrService.type === 'service' ? specialistOrService.id : undefined;
  const servicesMap = useConfig().CORONA_SERVICES_MAP;
  const serviceIds =
    serviceSelection === 'mainLabOnly'
      ? [servicesMap.corona_lab]
      : [
          servicesMap.corona_lab,
          servicesMap.corona_quick_test,
          servicesMap.corona_lab_2,
          servicesMap.corona_vaccine,
          servicesMap.influenza_quick_test,
          servicesMap.comprehensive_quick_test,
        ];

  return selectedServiceId && serviceIds.includes(selectedServiceId);
};
