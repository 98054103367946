/* eslint-disable no-console */
import { useContext, useEffect, useRef } from 'react';
import { ConfigContext } from 'utils/config/ConfigContextProvider';

function useLogging(componentName: string) {
  const renderCount = useRef<number>(0);
  const mountCount = useRef<number>(0);
  const { FEATURE_LOGGING } = useContext(ConfigContext);

  // This effect runs after the component has mounted
  useEffect(() => {
    if (!FEATURE_LOGGING) {
      return;
    }
    console.log('Mounting', componentName);
    mountCount.current += 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This effect runs after every render
  useEffect(() => {
    if (!FEATURE_LOGGING) {
      return;
    }
    console.log('Render', componentName, 'times', renderCount.current + 1);
    renderCount.current += 1;
  });

  // This effect runs when the component unmounts
  useEffect(() => {
    return () => {
      if (!FEATURE_LOGGING) {
        return;
      }

      console.log(`${componentName} mounted ${mountCount.current} times, rendered ${renderCount.current} times`);
    };
  }, [componentName, FEATURE_LOGGING, renderCount, mountCount]);
}

export default useLogging;
